import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import slugify from 'slugify';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { noImage } from '../../config/host';

const SingleRelated = ({ item, lang }) => {
  const config ={
    readArticle: {
      en: 'Read Article',
      es: 'Lee el artículo',
      it: `Leggi l'articolo`
    }
  }

  return (
    <div className="blog-item">
      <div className="item-image">
        <LazyLoadImage 
          src={item.logo || noImage}
          alt={item.name}
        />
        <div className="item-date">
          <i className="fal fa-fw fa-calendar-alt"></i>
          <span>
            { moment(new Date(item.publishDate)).format('DD MMM YYYY') }
          </span>
        </div>
        <div className="item-overlay">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#2f2f95" fillOpacity="1" d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
          </svg>
        </div>
      </div>
      <div className="item-content">
        <div className="item-title">{ item.name }</div>
        <div className="item-text">{ item.short_description }</div>
        <div className="item-action">
          <Link 
            to={`/${lang}/blog-details/${item.id}/${slugify(item.name.toLowerCase())}`} className="item-link"
          >
            { config.readArticle[lang] || config.readArticle.en }
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(SingleRelated);
