import React from 'react'

const OnlineService = ({ item }) => {
  return (
    <div className="item">
      <div className="icon">
        <i className={(!item.icon.includes('fab ')) ? "fal fa-fw " + item.icon : item.icon}></i>
      </div>
      <div className="item-title">{ item.name }</div>
      <div className="item-text" dangerouslySetInnerHTML={{ __html: item.description }}>
      </div>
    </div>
  )
}

export default OnlineService;
