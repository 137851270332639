import OpenTok from "opentok";
import io from "socket.io-client";
import { adminHost, host, sockUrl } from "../../config/host";
import { fetchData } from "../fetch";

// const session = OT.Session;

export const getAppointmentsAsync = async (user, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${adminHost}/appointments/get`,
      user,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getPastAppointmentAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${adminHost}/past-appointments/get`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getInProgressAppointmentAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${adminHost}/inprocess-appointments/get`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getUpcomingAppointmentAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${adminHost}/upcomming-appointments/get`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getAppointmentAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/appointments/details`,
      body,
      signal
    );
    const data = res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getClientListAsync = async (user, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${adminHost}/appointments/get`,
      user,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getBlockedUserListAsync = async (user, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/block-user/list`,
      user,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const blockUserManageAsync = async (user) => {
  try {
    const res = await fetchData("POST", `${host}/block-user`, user);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const endAppointmentMailSendAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/end-appointment/mail-send`,
      body,
      signal
    );
    const data = res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const addAppointmentNodeAsync = async (body) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/appointments/note-saved`,
      body
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
export const bookImmeadiateAppointmentAsync = async (body) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/consultant/instant-appointment`,
      body
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const endAppointmentAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/end-appointment`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const scheduleAppointmentAsync = async (body) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/consultant/booking-appointment`,
      body
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const consultantJoinAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/appointments/consultant-join`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const consultantLeaveAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/appointments/consultant-leave`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const consultantRejectAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/appointments/consultant-reject`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteAppointmentAsync = async (body) => {
  try {
    const res = await fetchData(
      "POST",
      `${adminHost}/appointments-delete`,
      body
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const refundAmountAsync = async (body) => {
  try {
    const res = await fetchData("POST", `${host}/refund-amount`, body);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const userJoinLeaveAsync = async (body, signal = null) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/user-join-leave`,
      body,
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createSession = async (apiKey, apiSecret, signal = null) => {
  // const sessionId = session.sessionId;
  console.log(apiKey, apiSecret);
  let token;

  const opentok = new OpenTok(apiKey, apiSecret, { timeout: 30000 });

  return new Promise((res, rej) => {
    opentok.createSession({ mediaMode: "relayed" }, (err, initSession) => {
      if (err) {
        console.log("error");
        rej(err);
      }

      if (initSession) {
        const sessionId = initSession.sessionId;
        token = opentok.generateToken(initSession.sessionId);

        res({ sessionId, token });
      }
    });
  });
};
export const createArchive = async (apiKey, apiSecret, signal = null) => {
  // const sessionId = session.sessionId;
  //console.log(sessionId)
  let token;

  const opentok = new OpenTok(apiKey, apiSecret, { timeout: 30000 });

  return new Promise((res, rej) => {
    opentok.createSession({ mediaMode: "relayed" }, (err, initSession) => {
      if (err) {
        console.log("error");
        rej(err);
      }

      if (initSession) {
        const sessionId = initSession.sessionId;
        token = opentok.generateToken(initSession.sessionId);

        res({ sessionId, token });
      }
    });
  });
};

export const createASession = async (apiKey, apiSecret, signal = null) => {
  // const sessionId = session.sessionId;
  //console.log(sessionId)
  let token;

  const opentok = new OpenTok(apiKey, apiSecret, { timeout: 30000 });

  return new Promise((res, rej) => {
    opentok.createSession({ mediaMode: "routed" }, (err, initSession) => {
      if (err) {
        rej(err);
      }

      if (initSession) {
        const sessionId = initSession.sessionId;
        token = opentok.generateToken(initSession.sessionId);

        res({ sessionId, token });
      }
    });
  });
};

export const generateOpentokToken = async (
  apiKey,
  apiSecret,
  sessionId,
  signal = null
) => {
  let token;

  const opentok = new OpenTok(apiKey, apiSecret, { timeout: 30000 });

  return new Promise((res, rej) => {
    token = opentok.generateToken(sessionId);
    res(token);
  });
};

// export const initSession = async (apiKey, initSession, initToken) => {
//   let session = OT.Session;
//   let token;

//   if(initSession && initToken) {
//     session = OT.initSession(apiKey, initSession);
//     token = initToken;

//     return { session, token };
//   }
// }

export const connect = async (session, token) => {
  return new Promise((res, rej) => {
    session.connect(token, (err) => {
      if (err) rej(err);

      res(session);
    });
  });
};

export const disconnect = async (session) => {
  session.disconnect();
};

export const sockConnect = () => {
  const socket = io(sockUrl);
  return socket;
};
