import React from 'react';
import { modalLang } from '../../config/lang';

const EndAppointmentModal = ({ 
	endOngoingAppointment, closeRef, spinner, setShown, lang, ...props 
}) => {
	const close = () => {
		if(closeRef?.current) closeRef.current.click();
		setShown(false);
		props.setBrowserClosed(false);
	}

  return (
    <div className="conversation-modal">
      <div 
				className={`modal fade${props.shown ? ' show' : ''}`} 
				id="endAppointment" 
				tabIndex="-1"
				style={{ 
					display: `${props.shown ? 'block' : 'none'}`, 
					paddingRight: `${props.shown ? '17px' : 'none'}` 
				}}
			>
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">

						<div className="modal-body">
							<button type="button" className="modal-close d-none" data-dismiss="modal" 
								aria-label="Close" ref={closeRef}
							>
								<i className="fal fa-fw fa-times"></i>
							</button>
							<div className="subtitle d-none">Start conversation with</div>
							<div className="title text-center mb-0" style={{ fontSize: '25px' }}>
                { modalLang.confirmationAlert[lang] || modalLang.confirmationAlert.en }
              </div>
							<div className="py-3 text-center">
								{ modalLang.sureToDisconnect[lang] || modalLang.sureToDisconnect.en }
							</div>
							<div className="form-group">
								<div className="row d-flex align-items-center">
									<div className="col-4">
										
										<div className="consultant-rate d-none"
										style={{ fontSize: '16px', fontWeight: '600', color: 'var(--primaryColor)' }}
									>
										
									</div>
									</div>
								</div>
							</div>

              <div className="communication-mode w-100">
								<div className="communication" style={{ width: '50%', maxWidth: 'unset' }}>
									{/* <input className="communication-input" name="communication-mode" type="radio" value=""/> */}
									<button className="communication-label bg-danger" type="button"
                    style={{color: '#fff', cursor: 'pointer' }} onClick={close}
                  >
                    { modalLang.cancel[lang] || modalLang.cancel.en }
                  </button>
								</div>
								<div className="communication" style={{ width: '50%', maxWidth: 'unset' }}>
									{/* <input className="communication-input" name="communication-mode" type="radio" value="" /> */}
									<button className="communication-label" 
                    style={{ background: 'var(--primaryColor)', color: '#fff', cursor: 'pointer' }}
										type="button" onClick={endOngoingAppointment}
                  >
                    <div className="d-flex align-items-center">
											<span>{ modalLang.confirm[lang] || modalLang.confirm.en }</span>
											{ spinner &&
												<div className="loader-spinner ml-2">
													<div></div>
													<div></div>
													<div></div>
													<div></div>
												</div>
											}
										</div>
                  </button>
								 </div>
							</div>
							<div className="form-group form-group-actions d-none">
								<button className="btn-submit" type="button">
									{ modalLang.continue[lang] || modalLang.continue.en }
								</button>
							</div>
						</div>
          </div>
        </div>
      </div>
	  </div>
  );
}

export default EndAppointmentModal;