import React, { useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { useForm } from "react-hook-form";
import { forgetPassword } from "../../actions/auth";
import PrefooterOverlay from "../../prefooterOverlay";
import { homeLang, accountLang } from "../../config/lang";
// import { jsVoid } from '../../config/jsVoid';
import { toast } from "react-toastify";
const SecForgotPassword = ({
  forgetPassword,
  setStatus,
  setUserId,
  setEmail,
  lang,
}) => {
  const [spinner, setSpinner] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setSpinner(true);

      try {
        const res = await forgetPassword(data.email);
        setUserId(res.data.userId);
        setStatus(true);
        setSpinner(false);
        toast.success(res.message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  const config = {
    sendOtp: {
      en: "Send OTP",
      es: "Enviar OTP",
      it: "Invia OTP",
    },
  };

  return (
    <section className="sec-authentication prefooter-last">
      <div className="authentication-wrap">
        <div className="authentication-content">
          {/* <!--<div className="title"><span>Reset Password</span></div>--> */}
          <div className="title">
            <span>
              {homeLang.forgotPassword[lang] || homeLang.forgotPassword.en}
            </span>
          </div>
          <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="form-input-group">
                <div className="icon">
                  <i className="fal fa-fw fa-envelope"></i>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder={accountLang.email[lang] || accountLang.email.en}
                  {...register("email")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn-submit" disabled={spinner}>
                <i className="fal fa-fw fa-sign-in"></i>
                {/* <!--						<span>Submit</span>--> */}
                <div className="d-flex align-items-center">
                  <span>{config.sendOtp[lang] || config.sendOtp.en}</span>
                  {spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </form>
          {/* <!--			<div className="authentication-options">Already have an account? <a href={jsVoid.void}>Signin Now</a></div>--> */}
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
  };
};

export default connect(mapStateToProps, { forgetPassword })(SecForgotPassword);
