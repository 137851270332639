import React, { useState } from "react";
import ListCard from "../Children/ListCard";
import AppointmentNoteModal from "../Children/Modals/AppointmentNoteModal";
import NoDataFound from "./NoDataFound";

const SetAppointmentList = ({
  appointments,
  user,
  isLoaded,
  count,
  last,
  callback,
  lang,
  isShowDetails,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [appointmentNote, setAppointmentNote] = useState("");
  const loadMore = async () => {
    setSpinner(true);

    try {
      if (last) {
        await callback({
          userId: user?.id,
          limit: "10",
          offset: "0",
          lastId: last?.ids ? last?.ids : "0",
        });

        setSpinner(false);
      }
    } catch (err) {
      setSpinner(false);
      console.log(err.message);
    }
  };

  const config = {
    loadMore: {
      en: "Load more",
      es: "Carga más",
      it: "Carica di più",
    },
    clientNotes: {
      en: "Client Notes",
      es: "Notas del cliente",
      it: "Note del cliente",
    },
    add: {
      en: "Add",
      es: "Agregar",
      it: "Aggiungere",
    },
    addNote: {
      en: "Save Note",
      es: "Guardar nota",
      it: "Salva Nota",
    },
    note: {
      en: "Note",
      es: "Nota",
      it: "Nota",
    },
    save: {
      en: "Save",
      es: "Ahorrar",
      it: "Salva",
    },
    cancel: {
      en: "Cancel",
      es: "Cancelar",
      it: "Annulla",
    },
  };
  return (
    <>
      <div className="row" id="past">
        <div className="col-md-12">
          <div className="list-card-link">
            {appointments.length ? (
              appointments
                .sort((a, b) =>
                  a.appointment_start_time > b.appointment_start_time ? -1 : 1
                )
                .map((item) => {
                  return (
                    <ListCard
                      key={item.ids}
                      item={item}
                      user={user}
                      isShowDetails={isShowDetails}
                      setAppointmentId={setAppointmentId}
                      setAppointmentNote={setAppointmentNote}
                      appointmentNote={appointmentNote}
                    />
                  );
                })
            ) : isLoaded ? (
              <NoDataFound />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {isLoaded &&
        appointments.length < count &&
        count !== appointments.length && (
          <div className="row">
            <div className="col-md-12">
              <button
                className="w-100 mt-3 btn loadMoreButton text-center"
                onClick={loadMore}
                style={{
                  background: "var(--primaryColor)",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <span>{config.loadMore[lang] || config.loadMore.en}</span>
                  {spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
      <div style={{ position: "relative" }}>
        <AppointmentNoteModal
          lang={lang}
          user={user}
          appointmentId={appointmentId}
          config={config}
          appointmentNote={appointmentNote}
        />
      </div>
    </>
  );
};

export default SetAppointmentList;
