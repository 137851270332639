import { GET_PAGES_INTERVENTIONS } from '../../../actions/types';

const interventionReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_PAGES_INTERVENTIONS:
      return { ...state, ...action.payload }
    default:
      return state;
  }
}

export default interventionReducer;