import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getHomeGettingStartedList } from '../../actions/home';
import GettingStartedItem from './GettingStartedItem';


const Steps = ({ gettingStartedList, getHomeGettingStartedList, lang }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadGettingStartedList = async () => {
      if(!gettingStartedList.length) {
        try {
          await getHomeGettingStartedList({ lang }, ac.signal);
          setIsLoaded(true);

        } catch(err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      } else {
        setIsLoaded(true);
      }
    }

    loadGettingStartedList();
    
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, gettingStartedList.length ]);

  const config = {
    gettingStartedIsEasy: {
      en: 'GETTING STARTED IS EASY',
      es: 'EMPEZAR ES FÁCIL',
      it: 'INIZIARE È FACILE'
    }
  }

  return (
    <section className="sec-steps">
      <div className="container">
        <div className="section-title title-alt">
          <h1 className="title">
            { config.gettingStartedIsEasy[lang] || config.gettingStartedIsEasy.en }
          </h1>
        </div>
        <div className="steps-wrap">
          {
            (isLoaded && gettingStartedList) ?
            gettingStartedList.map(item => <GettingStartedItem key={item.id} item={item} />)
            : ''
          }
        </div>
      </div> 
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    gettingStartedList: Object.values(state.home.homeGettingStarted)
  }
}

export default connect(mapStateToProps, { getHomeGettingStartedList })(Steps);