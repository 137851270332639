import React from 'react';

const AppointmentDetailsNav = ({ lang, reviewTabRef, reviewTab, setReviewTab }) => {
  const config = {
    details: {
      en: 'Details',
      es: 'Detalles',
      it: 'Dettagli'
    },
    payments: {
      en: 'Payments',
      es: 'Pagos',
      it: 'Pagamenti'
    },
    reviews: {
      en: 'Reviews',
      es: 'Reseñas',
      it: 'Recensioni'
    }
  }

  return (
    <div className="card-nav">
      <nav onClick={() => setReviewTab(null)}>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a className={`nav-link ${reviewTab ? '' : 'active'}`} id="nav-details-tab" data-toggle="tab" 
            href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true"
          >
            { config.details[lang] || config.details.en }
          </a>
          <a className="nav-link" id="nav-payments-tab" data-toggle="tab" href="#nav-payments" role="tab" aria-controls="nav-payments" aria-selected="false">
            { config.payments[lang] || config.payments.en }
          </a>
          <a 
            className={`nav-link ${reviewTab ? 'active' : ''}`} 
            id="nav-reviews-tab" data-toggle="tab" 
            href="#nav-reviews" 
            role="tab" 
            aria-controls="nav-reviews"
            aria-selected="false" 
            ref={reviewTabRef}
          >
            { config.reviews[lang] || config.reviews.en }
          </a>
        </div>
      </nav>
    </div>
  )
}

export default AppointmentDetailsNav
