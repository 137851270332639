import React, { useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { verifyRegistration, forgetPassword } from "../../actions/auth";
import { useForm } from "react-hook-form";
import PrefooterOverlay from "../../prefooterOverlay";
import { toast } from "react-toastify";
// import { lang } from '../../config/lang';

const SecOTPVerification = ({
  verifyRegistration,
  forgetPasswordOTP,
  history,
  userId,
  id,
  lang,
  email,
  forgetPassword,
}) => {
  const [spinner, setSpinner] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // Cheching errors are empty
    if (!Object.keys(errors).length) {
      setSpinner(true);

      try {
        if (!forgetPasswordOTP && userId) {
          // Registration OTP
          const message = await verifyRegistration(userId, data.otp);
          setSpinner(false);
          history.push(`/${lang}/login`);
          toast.success(message);
        } else if (id) {
          // Forgot Password OTP
          const res = await forgetPasswordOTP(data.otp, id);
          setSpinner(false);
          toast.success(res.message);

          return history.push({
            pathname: `/${lang}/password-change`,
            state: { userId: id },
          });
        }
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  const resendPassword = async (e) => {
    try {
      e.preventDefault();

      const message = await forgetPassword(email);
      toast.success(message.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const config = {
    otpVeri: {
      en: "OTP Verification",
      es: "Verificación OTP",
      it: "Verifica OTP",
    },
    otpVeriSubtitle: {
      en: "Welcome! We have sent an OTP code to your mailbox. Please type the code in the space below to complete the verification. If you have not received the OTP, please check the spam folder of your email address",
      es: "Bienvenidos! Hemos enviado un código OTP a su buzón. Escriba el código en el espacio a continuación para completar la verificación. Si no ha recibido la OTP, verifique la carpeta de correo no deseado de su dirección de correo electrónico",
      it: "Benvenuto! Abbiamo inviato un codice OTP alla tua casella di posta elettronica. Ti preghiamo di digitare il codice nello spazio sottostante per completare la verifica. Se non hai ricevuto l'OTP, ti preghiamo di controllare nella cartella spam del tuo indirizzo email",
    },
    enterOtp: {
      en: "Enter OTP",
      es: "Ingresar OTP",
      it: "Inserisci OTP",
    },
    verify: {
      en: "Verify",
      es: "Verificar",
      it: "Verificare",
    },
    idnrac: {
      en: "I did not recive any code.",
      es: "No recibí ningún código.",
      it: "Non ho ricevuto alcun codice.",
    },
    resend: {
      en: "Resend",
      es: "reenviar",
      it: "Rinviare",
    },
  };

  return (
    <section className="sec-authentication prefooter-last">
      <div className="authentication-wrap">
        <div className="authentication-content">
          <div className="title">
            <span>{config.otpVeri[lang] || config.otpVeri.en}</span>
          </div>
          <div className="sub-title">
            <span>
              {config.otpVeriSubtitle[lang] || config.otpVeriSubtitle.en}
            </span>
          </div>
          <form
            className="form-content ng-untouched ng-pristine ng-valid"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group">
              <div className="form-input-group">
                <div className="icon">
                  <i className="fal fa-fw fa-user"></i>
                </div>
                <input
                  className="form-control ng-untouched ng-pristine ng-valid"
                  name="entered_otp"
                  type="number"
                  placeholder={config.enterOtp[lang] || config.enterOtp.en}
                  {...register("otp")}
                />
              </div>
            </div>

            <div className="form-group text-center">
              <button className="btn-submit" type="submit" disabled={spinner}>
                <i className="fal fa-fw fa-sign-in"></i>
                <div className="d-flex align-items-center">
                  <span>{config.verify[lang] || config.verify.en}</span>
                  {spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </form>

          <div className="authentication-options">
            {config.idnrac[lang] || config.idnrac.en}{" "}
            <a href="#!" onClick={resendPassword}>
              {config.resend[lang] || config.resend.en}
            </a>
          </div>
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
  };
};

export default connect(mapStateToProps, { verifyRegistration, forgetPassword })(
  SecOTPVerification
);
