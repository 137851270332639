import { host } from "../../config/host";
import { fetchData } from "../fetch";

export const getEmailListAsync = async (user, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/email/list`, user, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteEmailAsync = async email => {
  try {
    const res = await fetchData('POST', `${host}/email/delete`, email);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const sendEmailAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/email/send`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getEmailDetailsAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/email/details`, body, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}