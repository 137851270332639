import { 
  UPDATE_PROFILE, 
  UPDATE_FISCAL_INFO, 
  UPDATE_MEMBERSHIP_INFO, 
  UPDATE_INSURANCE_INFO, 
  
  UPDATE_BIOGRAPHY_INFO,
  UPDATE_LANGUAGE_INFO,
  UPDATE_INTERVENTION_INFO,
  UPDATE_ACCOUNT_INFO,
  GET_CONSULTANT_SERVICES,
  DELETE_CONSULTANT_SERVICE,
  UPDATE_CONSULTANT_ACCOUNT_INFO,
  GET_DAILY_TIMETABLE,
  GET_STRIPE_CUSTOMER,
  GET_PAYMENT_INFO,
  // ADD_REVIEW
} from "./types";
import { getProfileInfo } from "./auth";
import { 
  updateProfileAsync, 
  updateFiscalInfoAsync ,

  updateMembershipInfoAsync,
  updateInsuranceInfoAsync,
  updateBiographyInfoAsync,
  updateLanguageInfoAsync,
  updateInterventionInfoAsync,
  updateAccountInfoAsync,
  updateDailyScheduleInfoAsync,
  updateActiveStatusAsync,
  getConsultantServicesAsync,

  updateConsultantServicesAsync,
  deleteConsultantServiceAsync,
  addReviewAsync,
  getStripeCustomerAsync,
  closeAccountAsync,
  accountPasswordUpdateAsync,
  deleteStripeAccountAsync
} from "../asynchronus/Profile";

export const updateProfile = user => async dispatch => {
  try {
    const res = await updateProfileAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_PROFILE, payload: profileInfo });

      if(profileInfo?.payment_info) {
        dispatch({ type: GET_PAYMENT_INFO, payload: profileInfo?.payment_info });
      }

      return res.message;
    } else {
      throw new Error(res.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateFiscalInfo = user => async dispatch => {
  try {
    const res = await updateFiscalInfoAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_FISCAL_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


export const updateMembershipInfo = user => async dispatch => {
  try {
    const res = await updateMembershipInfoAsync(user);
    
    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_MEMBERSHIP_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }

}

export const updateInsuranceInfo = user => async dispatch => {
  try {
    const res = await updateInsuranceInfoAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_INSURANCE_INFO, payload: profileInfo });
      
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }

}

export const updateBiographyInfo = user => async dispatch => {
  try {
    const res = await updateBiographyInfoAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_BIOGRAPHY_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateLanguageInfo = user => async dispatch => {
  
  try {
    const res = await updateLanguageInfoAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      
      dispatch({ type: UPDATE_LANGUAGE_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


export const updateInterventionInfo = user => async dispatch => {
  try {
    const res = await updateInterventionInfoAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_INTERVENTION_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


export const updateAccountInfo = user => async dispatch => {
  try {
    const res = await updateAccountInfoAsync(user);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const accountPasswordUpdate = body => async dispatch => {
  try {
    const res = await accountPasswordUpdateAsync(body);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(body.userId);
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


export const updateDailyScheduleInfo = user => async dispatch => {
  try {
    const res = await updateDailyScheduleInfoAsync(user);

    if(res.status === 200) {
      const dailySchedule = await getConsultantServicesAsync({
        lang: localStorage.getItem('lang') || 'en',
        userId: user.userId
      });
      
      const profileInfo = await getProfileInfo(user.userId);

      dispatch({ type: GET_DAILY_TIMETABLE, payload: dailySchedule.data.dailyTimetable });
      dispatch({ type: UPDATE_INTERVENTION_INFO, payload: profileInfo });

      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateActiveStatus = user => async dispatch => {
  try {
    const res = await updateActiveStatusAsync(user);
    if(res.status === 200) {
      
      const profileInfo = await getProfileInfo(user.userId);
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getConsultantServices = (user, signal = null) => async dispatch => {
  try {
    const serviceDetails = await getConsultantServicesAsync(user, signal);
    
    if(serviceDetails.status === 200) {
      dispatch({ type: GET_CONSULTANT_SERVICES, payload: serviceDetails.data.consultantServices })
      
      if(serviceDetails.data.dailyTimetable.length) {
        dispatch({ type: GET_DAILY_TIMETABLE, payload: serviceDetails.data.dailyTimetable });
      }
    } else {
      throw new Error(serviceDetails.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateConsultantServices = user => async dispatch => {
  try {
    const res = await updateConsultantServicesAsync(user);

    if(res.status === 200) {
      const serviceDetails = await getConsultantServicesAsync({ 
        lang: localStorage.getItem('lang') || 'en',
        userId: user.userId
      });

      const profileInfo = await getProfileInfo(user.userId);

      dispatch({ type: GET_CONSULTANT_SERVICES, payload: serviceDetails.data.consultantServices })
      dispatch({ type: UPDATE_CONSULTANT_ACCOUNT_INFO, payload: profileInfo });
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: profileInfo });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteConsultantSerice = (user, id) => async dispatch => {
  try {
    const res = await deleteConsultantServiceAsync(user);

    if(res.status === 200) {
      dispatch({ type: DELETE_CONSULTANT_SERVICE, payload: id });
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addReview = user => async dispatch => {
  try {
    const review = await addReviewAsync(user);

    if(review.status === 200) {
      return review.message;
    } else {
      throw new Error(review.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getStripeCustomer = (body, signal) => async dispatch => {
  try {
    const cards = await getStripeCustomerAsync(body, signal);
    
    if(cards.status === 200) {
      dispatch({ type: GET_STRIPE_CUSTOMER, payload: cards.data });
    } else {
      throw new Error(cards.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const closeAccount = body => async dispatch => {
  try {
    const res = await closeAccountAsync(body);
    return res.message;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteStripeAccount = body => async dispatch => {
  try {
    const res = await deleteStripeAccountAsync(body);

    if(res.status === 200) {
      const profileInfo = await getProfileInfo(body.consultantId);
      dispatch({ type: UPDATE_ACCOUNT_INFO, payload: profileInfo });
      
      console.log(res);
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}