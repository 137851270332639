import React from 'react'
import LayoutContainer from '../containers/LayoutContainer';
import SecReviewList from '../components/Consultant/SecReviewList';

function reviewList({ location }) {
  
  const id = new URLSearchParams(location.search).get('id');
  
  return (
    <LayoutContainer>
      <SecReviewList id={id} />
    </LayoutContainer>
  )
}

export default reviewList
