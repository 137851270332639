import React from "react";
const StripeCardListItemForBookingAppointment = ({
  setRadioCheckedBookingAppointment,
  radioCheckedBookingAppointment,
  bookingAppointmentKey,
  items,
  setCardIdBookingAppointment,
}) => {
  const handleChange1 = (event) => {
    setCardIdBookingAppointment(event.target.value);
    setRadioCheckedBookingAppointment(bookingAppointmentKey);
  };
  return (
    <>
      <div className="card-item">
        <div className="custom-radio">
          <input
            type="radio"
            name="stripeCardRadio1"
            value={items.id}
            checked={bookingAppointmentKey === radioCheckedBookingAppointment}
            onChange={handleChange1}
            className="custom-radio-input"
          />
          <div className="custom-radio-label">
            <div className="img-wrap">
              <img
                src={
                  require(`../../../assets/img/cardicon/${items.brand}.png`)
                    .default
                }
                alt="card_image"
              />
            </div>
            <div className="text">XXXX-XXXX-XXXX-{items.last4}</div>
          </div>
        </div>
      </div>
      {/* <div>
      <label className="payment_card_sec d-flex py-2 align-items-center">
        <input
          type="radio"
          name="stripeCardRadio1"
          value={items.id}
          checked={bookingAppointmentKey === radioCheckedBookingAppointment}
          onChange={handleChange1}
        />
        <div className="payment_card_img mx-3">
          <img
            src={
              require(`../../../assets/img/cardicon/${items.brand}.png`).default
            }
            alt="icon"
          />{" "}
          {items.brand}
        </div>
        <div className=""> XXXX-XXXX-XXXX-{items.last4}</div>
        <div className=""></div>
      </label>
    </div> */}
    </>
  );
};

export default StripeCardListItemForBookingAppointment;
