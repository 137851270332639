import React from 'react'

const AppointmentDetailsContailer = props => {
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-md-12">
            { props.children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppointmentDetailsContailer
