import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAppointment } from "../../../actions/appointments";
import Details from "../Children/AppointmentComponents/Details";
import PaymentInfo from "../Children/AppointmentComponents/PaymentInfo";
import Reviews from "../Children/AppointmentComponents/Reviews";
import InvoiceModal from "../../Modals/InvoiceModal";
import NoDataFound from "./NoDataFound";

const SecAppointmentDetails = ({
  id,
  user,
  countries,
  setAppointmentLoaded,
  getAppointment,
  lang,
  reviewTab,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadAppointment = async () => {
      try {
        if (!isLoaded && user && user.id) {
          const newAppointment = await getAppointment(
            { lang, appointmentId: id, userId: user.id },
            ac.signal
          );
          setAppointment(newAppointment);
          setIsLoaded(true);
          setAppointmentLoaded(true);
        }
      } catch (err) {
        setIsLoaded(true);
        setAppointmentLoaded(true);
        console.error(err.message);
      }
    };

    loadAppointment();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <>
      <div className="tab-content" id="nav-tabContent">
        {Object.keys(user).length && appointment && countries.length ? (
          <>
            <Details
              user={user}
              appointment={appointment}
              countries={countries}
              lang={lang}
              reviewTab={reviewTab}
            />
            <PaymentInfo appointment={appointment} user={user} lang={lang} />
            <Reviews
              appointment={appointment}
              appointmentDetails={true}
              reviewTab={reviewTab}
            />
          </>
        ) : isLoaded ? (
          <NoDataFound />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    appointments: state.appointments,
    countries: Object.values(state.cms.countryList),
  };
};

export default connect(mapStateToProps, { getAppointment })(
  SecAppointmentDetails
);
