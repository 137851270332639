import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { 
  GET_INPROGRESS_APPOINTMENTS, 
  GET_MODIFIED_INPROGRESS_APPOINTMENTS,
  GET_INPROGRESS_APPOINTMENT,
  REMOVE_INPROGRESS_APPOINTMENT,
  REMOVE_APPOINTMENT,
  LOGOUT
} from "../../actions/types";

const inprogressAppointmentReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_INPROGRESS_APPOINTMENTS:
      return { ...state, ...mapKeys(action.payload, 'ids') };
    case GET_MODIFIED_INPROGRESS_APPOINTMENTS:
      return { ...mapKeys(action.payload, 'ids') };
    case GET_INPROGRESS_APPOINTMENT:
      return { ...state, [action.payload.ids]: action.payload };
    case REMOVE_INPROGRESS_APPOINTMENT:
      return omit(state, action.payload);
    case REMOVE_APPOINTMENT:
      const findAppointment = Object.values(state).find(app => app.ids === action.payload);
      if(findAppointment) return omit(state, action.payload);
      return state;
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default inprogressAppointmentReducer;