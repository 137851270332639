import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import { blockUserManage } from "../../../actions/appointments";
import { noImage } from "../../../config/host";
import { acNavLangFormal, accountLang } from "../../../config/lang";
import { toast } from "react-toastify";
const SingleClient = ({
  client,
  countries,
  user,
  blockUserManage,
  lang,
  blockedUsers,
}) => {
  const blockUser = async (e) => {
    e.preventDefault();

    try {
      const message = await blockUserManage(
        {
          lang,
          consultantId: user?.id,
          userId: client.user_id,
        },
        findBlockedUser(client.user_id)
      );
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const findBlockedUser = (userId) =>
    blockedUsers.find((user) => user.user_id === userId) || null;

  const config = {
    cbi: {
      en: "Client Business Information",
      es: "Información comercial del cliente",
      it: "Informazioni commerciali del cliente",
    },
  };

  return (
    <div className="email">
      <div className="e-image">
        <img
          src={client.image}
          alt={client.user_profile_name}
          onError={(e) => (e.target.src = `${noImage}`)}
        />
      </div>
      <div className="e-content">
        <div className="e-name">{client.user_profile_name}</div>
        <div className="e-text">{client.user_personal_email}</div>
        <div className="e-date">
          {config.cbi[lang] || config.cbi.en}:{" "}
          {client.user_fiscal_address ? client.user_fiscal_address + "," : ""}{" "}
          {client.user_fiscal_province ? client.user_fiscal_province + "," : ""}{" "}
          {client.user_fiscal_city ? client.user_fiscal_city + "," : ""}{" "}
          {client.user_fiscal_zipcode ? client.user_fiscal_zipcode + "," : ""}{" "}
          {countries.find((el) => el.id === client.personal_country)?.name ||
            ""}
        </div>
        <div className="e-date">
          {acNavLangFormal.address[lang] || acNavLangFormal.address.en}:{" "}
          {client.personal_address ? client.personal_address + "," : ""}{" "}
          {client.personal_province ? client.personal_province + "," : ""}{" "}
          {client.personal_city ? client.personal_city + "," : ""}{" "}
          {client.personal_zipcode ? client.personal_zipcode + "," : ""}{" "}
          {countries.find((el) => el.id === client.personal_country)?.name ||
            ""}
        </div>
        <div className="e-date">
          {acNavLangFormal.birth[lang] || acNavLangFormal.birth.en}:{" "}
          {client.birth_province ? client.birth_province + "," : ""}{" "}
          {client.birth_city ? client.birth_city + "," : ""}{" "}
          {client.birth_zipcode ? client.birth_zipcode + "," : ""}{" "}
          {countries.find((el) => el.id === client.birth_country)?.name || ""}
        </div>
      </div>
      <div className="e-option">
        <a
          href="#!"
          className={`btn-action btn-block ${
            findBlockedUser(client.user_id) ? "active" : ""
          }`}
          onClick={blockUser}
        >
          <i className="fal fa-fw fa-ban mr-2"></i>
          {findBlockedUser(client.user_id) ? "Blocked" : "Block"}
        </a>
        <Link
          to={`/${lang}/client-notes?id=${client.user_id}`}
          className="btn-action"
        >
          <i className="fal fa-fw fa-sticky-note mr-1"></i>
          {accountLang.note[lang] || accountLang.note.en}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    blockedUsers: Object.values(state.appointments.blockedUsers),
  };
};

export default connect(mapStateToProps, { blockUserManage })(SingleClient);
