import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { adminSideNavLang } from '../../config/lang';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecMyAccount from '../../components/Admin/Sections/SecMyAccount';

const MyAccount = ({
  user, nationalities, siteSettings, languages, timezones, countries, lang
}) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      !$.isEmptyObject(siteSettings) && languages.length && timezones.length && countries.length;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries ]);

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={ adminSideNavLang.myAccount[lang] || adminSideNavLang.myAccount.en }>
        <SecMyAccount />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList)
  }
}

export default connect(mapStateToProps, null)(MyAccount);