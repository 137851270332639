export const scheduleState = {
  0: {
    dayName: 'Monday',
    dayNumber: '1',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  },
  1: {
    dayName: 'Tuesday',
    dayNumber: '2',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  }, 
  2: {
    dayName: 'Wednesday',
    dayNumber: '3',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  }, 
  3: {
    dayName: 'Thursday',
    dayNumber: '4',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  },
  4: {
    dayName: 'Friday',
    dayNumber: '5',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  },
  5: {
    dayName: 'Saturday',
    dayNumber: '6',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  },
  6: {
    dayName: 'Sunday',
    dayNumber: '7',
    firstFrom: '00:00',
    firstTo: '00:00',
    firstIsClose: false,
    secondFrom: '00:00',
    secondTo: '00:00',
    secondIsClose: false
  }
}