import React, { useState, useEffect } from "react";
import $ from "jquery";
import moment from "moment";
import { noImage } from "../../../config/host";
import {
  accountLang,
  langConfig,
  personalTooltipLang,
} from "../../../config/lang";
import flags from "../../../helpers/flags";
import TooltipSec from "./Forms/TooltipSec";
// import IN from '../../../assets/img/flags/4x3/in.svg';

const AccountInfo = ({
  auth,
  user,
  register,
  countries,
  timezones,
  image,
  imageChange,
  setValue,
  nationalities,
  ...props
}) => {
  const [phoneCode, setPhoneCode] = useState(null);
  const [phoneCodeId, setPhoneCodeId] = useState(null);
  const [allPhoneCodes, setAllPhoneCodes] = useState([]);
  const { status } = auth;
  const { lang, getValues } = props;
  console.log(image);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => managePhoneCode(), [user?.phone_code]);

  useEffect(() => {
    $("#gender")
      .val(getValues("gender") || user?.gender)
      .trigger("change");
    $("#nationality")
      .val(getValues("nationality") || user?.nationality)
      .trigger("change");
    $("#timezone")
      .val(getValues("timezone") || user?.timezone)
      .trigger("change");
    $("#gender").on("change", function (e) {
      setValue("gender", this.value);
    });
    $("#nationality").on("change", function (e) {
      setValue("nationality", this.value);
    });
    $("#timezone").on("change", function (e) {
      setValue("timezone", this.value);
    });
  });

  useEffect(() => {
    console.log(user?.dob);
    $("#personal_dob").datetimepicker({
      value: new Date(),
      timepicker: false,
      format: "d-m-Y",
      scrollMonth: false,
      maxDate: "-18Y",
      onSelectDate: function (e) {
        setValue("personalDob", moment(new Date(e)).format("DD-MM-Y"));
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.dob]);

  useEffect(() => {
    if (countries.length) {
      setAllPhoneCodes([...countries]);
    }
  }, [countries]);

  const getCountry = (phoneCode) => {
    if (!phoneCodeId) {
      return countries.find((el) => el.phonecode === phoneCode)?.iso2;
    }

    // console.log(countries.find(el => el.id === phoneCodeId)?.phonecode)

    return countries.find((el) => el.id === phoneCodeId)?.iso2;
  };

  const managePhoneCode = () => {
    if (!phoneCode) setPhoneCode(user?.phone_code);
  };

  const searchPhoneCode = (keyword) => {
    const searchPhoneCodes = countries.filter((el) =>
      el.phonecode.includes(keyword)
    );
    keyword
      ? setAllPhoneCodes([...searchPhoneCodes])
      : setAllPhoneCodes([...countries]);
  };

  return (
    <div className="row">
      <div className="col-md-5 col-lg-3">
        {/* Profile image */}
        <div className="myaccount-profile-img">
          <label className="" htmlFor="profilePicture">
            <div className="myaccount-profile-img-warp">
              {status && (
                <img
                  alt={user.name}
                  src={image || user?.image || noImage}
                  onError={(e) => (e.target.src = `${noImage}`)}
                />
              )}
            </div>
            <input id="profilePicture" type="file" onChange={imageChange} />
            <div className="upload-icon">
              <i className="fal fa-camera"></i>
            </div>
          </label>
        </div>
      </div>

      <div className="col-md-12 col-lg-9">
        <div className="row form-row">
          {/* Firstname */}
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label className="control-label required">
                {accountLang.name[lang] || accountLang.name.en}
                <TooltipSec
                  title={
                    personalTooltipLang.name[lang] ||
                    personalTooltipLang.name.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                name="first_name"
                type="text"
                placeholder={accountLang.name[lang] || accountLang.name.en}
                defaultValue={status ? user.firstname : ""}
                {...register("firstname")}
              />
            </div>
          </div>
          {/* LastName */}
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label className="control-label required">
                {accountLang.surname[lang] || accountLang.surname.en}
                <TooltipSec
                  title={
                    personalTooltipLang.surname[lang] ||
                    personalTooltipLang.surname.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                name="last_name"
                type="text"
                placeholder={
                  accountLang.surname[lang] || accountLang.surname.en
                }
                defaultValue={status ? user.lastname : ""}
                {...register("lastname")}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            {/* Gender */}
            <div className="form-group">
              <label className="control-label required">
                {langConfig.gender[lang] || langConfig.gender.en}
                <TooltipSec
                  title={
                    personalTooltipLang.gender[lang] ||
                    personalTooltipLang.gender.en
                  }
                ></TooltipSec>
              </label>
              <select
                className="form-control select2 select2-hidden-accessible"
                id="gender"
                tabIndex="-1"
                aria-hidden="true"
              >
                <option value="">
                  {langConfig.gender[lang] || langConfig.gender.en}{" "}
                </option>
                <option value="1">
                  {langConfig.male[lang] || langConfig.male.en}{" "}
                </option>
                <option value="2">
                  {langConfig.female[lang] || langConfig.female.en}{" "}
                </option>
              </select>
            </div>
          </div>
          {/* Second Surname */}
          {/* <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label className="control-label">
                { accountLang.secondSurname[lang] || accountLang.secondSurname.en } 
              </label>
              <input className="form-control ng-untouched ng-pristine ng-valid" 
                name="second_last_name" type="text" 
                placeholder={ accountLang.secondSurname[lang] || accountLang.secondSurname.en } 
                defaultValue={ status ? user.middlename : '' }
                { ...register('middlename') }
              />
              </div>
          </div> */}
        </div>

        <div className="row form-row">
          <div className="col-5 col-md-2 col-lg-2">
            {/* Telephone Code */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.telephoneCode[lang] ||
                  accountLang.telephoneCode.en}
                <TooltipSec
                  title={
                    personalTooltipLang.phoneCode[lang] ||
                    personalTooltipLang.phoneCode.en
                  }
                ></TooltipSec>
              </label>
              <div className="phonecode dropdown">
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  href="#!"
                  id="dropdown-toggle"
                >
                  <span className="flag">
                    <img
                      src={flags[getCountry(phoneCode)]?.default}
                      alt={phoneCode}
                      className={
                        !flags[getCountry(phoneCode)]?.default ? "d-none" : ""
                      }
                    />
                  </span>
                  <span className="phonecode-name">{phoneCode || ""}</span>
                </a>
                <div className="dropdown-menu">
                  <div className="input-search">
                    <input
                      className="form-control"
                      name="phonecode_search"
                      placeholder="search"
                      type="text"
                      onChange={(e) => searchPhoneCode(e.target.value)}
                    />
                  </div>
                  <div className="dropdown-menu-wrap">
                    {allPhoneCodes.length
                      ? allPhoneCodes.map((el) => {
                          return (
                            <a
                              className="dropdown-item"
                              href="#!"
                              id={el.iso2.toLowerCase()}
                              key={el.iso2.toLowerCase()}
                              onClick={(e) => {
                                e.preventDefault();
                                setPhoneCode(el.phonecode);
                                setPhoneCodeId(el.id);
                                setValue("phoneCode", el.phonecode);
                              }}
                            >
                              <span className="flag">
                                <img
                                  src={flags[el.iso2]?.default}
                                  alt="English"
                                />
                              </span>
                              <span className="phonecode-name">
                                {el.phonecode}
                              </span>
                            </a>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-7 col-md-2 col-lg-2">
            {/* Telephone */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.telephone[lang] || accountLang.telephone.en}
                <TooltipSec
                  title={
                    personalTooltipLang.phone[lang] ||
                    personalTooltipLang.phone.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                name="personal_telephone"
                type="tel"
                placeholder={
                  accountLang.telephone[lang] || accountLang.telephone.en
                }
                defaultValue={status ? user.phone : ""}
                {...register("phone")}
              />
            </div>
          </div>

          <div className="col-md-2 col-lg-4">
            {/* Nationality */}
            <div className="form-group">
              <label className="control-label required">
                {langConfig.nationality[lang] || langConfig.nationality.en}
                <TooltipSec
                  title={
                    personalTooltipLang.nationality[lang] ||
                    personalTooltipLang.nationality.en
                  }
                ></TooltipSec>
              </label>
              <select
                className="form-control select2 select2-hidden-accessible"
                id="nationality"
                tabIndex="-1"
                aria-hidden="true"
              >
                <option value="">
                  {langConfig.nationality[lang] || langConfig.nationality.en}
                </option>
                {nationalities &&
                  nationalities.map((country, i) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.nationality}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-5 col-lg-4">
            {/* Timezone */}
            <div className="form-group ">
              <label className="control-label required" placement="top">
                {accountLang.selectTimezone[lang] ||
                  accountLang.selectTimezone.en}
                <TooltipSec
                  title={
                    personalTooltipLang.timezone[lang] ||
                    personalTooltipLang.timezone.en
                  }
                ></TooltipSec>
              </label>
              <select
                className="select2 select2-hidden-accessible"
                id="timezone"
                name="timezone"
                tabIndex="-1"
                aria-hidden="true"
              >
                <option value="">
                  {accountLang.selectTimezone[lang] ||
                    accountLang.selectTimezone.en}
                </option>
                {timezones &&
                  timezones.map((timezone, i) => {
                    return (
                      <option key={i} value={timezone.id}>
                        {timezone.zone}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-4">
            {/* Date of birth */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.dateOfBirth[lang] || accountLang.dateOfBirth.en}
                <TooltipSec
                  title={
                    personalTooltipLang.dOB[lang] || personalTooltipLang.dOB.en
                  }
                ></TooltipSec>
              </label>
              <input
                autoComplete="off"
                className="form-control"
                id="personal_dob"
                name="dob"
                type="text"
                placeholder={
                  accountLang.dateOfBirth[lang] || accountLang.dateOfBirth.en
                }
                defaultValue={status ? user.dob : ""}
                {...register("personalDob")}
              />
            </div>
          </div>
          <div className="col-md-5 col-lg-4">
            {/* City of birth */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.cityOfBirth[lang] || accountLang.cityOfBirth.en}
                <TooltipSec
                  title={
                    personalTooltipLang.cityOB[lang] ||
                    personalTooltipLang.cityOB.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                id="birth_city"
                name="birth_city"
                type="text"
                placeholder={
                  accountLang.cityOfBirth[lang] || accountLang.cityOfBirth.en
                }
                defaultValue={status ? user.birth_city : ""}
                {...register("birthCity")}
              />
            </div>
          </div>

          <div className="col-md-5 col-lg-4">
            {/* Province of birth */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.provinceOfBirth[lang] ||
                  accountLang.provinceOfBirth.en}
                <TooltipSec
                  title={
                    personalTooltipLang.pOB[lang] || personalTooltipLang.pOB.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                id="birth_province"
                name="birth_province"
                type="text"
                placeholder={
                  accountLang.provinceOfBirth[lang] ||
                  accountLang.provinceOfBirth.en
                }
                defaultValue={status ? user.birth_province : ""}
                {...register("birthProvince")}
              />
            </div>
          </div>
          <div className="col-md-5 col-lg-4">
            {/* Country of birth */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.countryOfBirth[lang] ||
                  accountLang.countryOfBirth.en}
                <TooltipSec
                  title={
                    personalTooltipLang.countryOB[lang] ||
                    personalTooltipLang.countryOB.en
                  }
                ></TooltipSec>
              </label>
              <select
                className="form-control select2 select2-hidden-accessible"
                id="birth_country"
                tabIndex="-1"
                aria-hidden="true"
              >
                <option value="">
                  {accountLang.countryOfBirth[lang] ||
                    accountLang.countryOfBirth.en}
                </option>
                {countries &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.id}>
                        {country.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
