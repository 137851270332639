import React, { useEffect } from "react";
import $ from "jquery";
import { accountLang, personalTooltipLang } from "../../../config/lang";
import TooltipSec from "./Forms/TooltipSec";
const AddressAndBirthInfo = ({
  auth,
  user,
  register,
  countries,
  setValue,
  config,
  lang,
  getValues,
}) => {
  const { status } = auth;

  useEffect(() => {
    $("#personal_country")
      .val(getValues("personalCountry") || user?.personal_country)
      .trigger("change");

    $("#birth_country")
      .val(getValues("birthCountry") || user?.birth_country)
      .trigger("change");

    $("#personal_country").on("change", function (e) {
      setValue("personalCountry", this.value);
    });
    $("#birth_country").on("change", function (e) {
      setValue("birthCountry", this.value);
    });
  });

  return (
    <div className="row">
      <div className="col-12">
        <div className="sec-myaccount-header mt-4">
          <div className="lable-text">
            {config.address[lang] || config.address.en}
          </div>
        </div>
        <div className="row form-row">
          <div className="col-lg-12">
            {/* Address of Residence */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.addressOfResidence[lang] ||
                  accountLang.addressOfResidence.en}
                <TooltipSec
                  title={
                    personalTooltipLang.aOR[lang] || personalTooltipLang.aOR.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                name="personal_address"
                type="text"
                placeholder={
                  accountLang.addressOfResidence[lang] ||
                  accountLang.addressOfResidence.en
                }
                defaultValue={status ? user.personal_address : ""}
                {...register("personalAddress")}
              />
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-3">
            {/* Country of residence */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.countryOfResidence[lang] ||
                  accountLang.countryOfResidence.en}
                <TooltipSec
                  title={
                    personalTooltipLang.countryOR[lang] ||
                    personalTooltipLang.countryOR.en
                  }
                ></TooltipSec>
              </label>
              <select
                className="form-control select2 select2-hidden-accessible"
                id="personal_country"
                name="personal_country"
                data-select2-id="personal_country"
                tabIndex="-1"
                aria-hidden="true"
              >
                <option value="">
                  {accountLang.countryOfResidence[lang] ||
                    accountLang.countryOfResidence.en}
                </option>
                {countries &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.id}>
                        {country.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-3">
            {/* Province of residence */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.provinceOfResidence[lang] ||
                  accountLang.provinceOfResidence.en}
                <TooltipSec
                  title={
                    personalTooltipLang.pOR[lang] || personalTooltipLang.pOR.en
                  }
                ></TooltipSec>
              </label>

              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                id="personal_province"
                name="personal_province"
                type="text"
                placeholder={
                  accountLang.provinceOfResidence[lang] ||
                  accountLang.provinceOfResidence.en
                }
                defaultValue={status ? user.personal_province : ""}
                {...register("personalProvince")}
              />
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-3">
            {/* City of residence */}

            <div className="form-group">
              <label className="control-label required">
                {accountLang.cityOfResidence[lang] ||
                  accountLang.cityOfResidence.en}
                <TooltipSec
                  title={
                    personalTooltipLang.cityOR[lang] ||
                    personalTooltipLang.cityOR.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                id="personal_city"
                name="personal_city"
                type="text"
                placeholder={
                  accountLang.cityOfResidence[lang] ||
                  accountLang.cityOfResidence.en
                }
                defaultValue={status ? user.personal_city : ""}
                {...register("personalCity")}
              />
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-3">
            {/* Zip code */}
            <div className="form-group">
              <label className="control-label required">
                {accountLang.zipCode[lang] || accountLang.zipCode.en}
                <TooltipSec
                  title={
                    personalTooltipLang.zipCode[lang] ||
                    personalTooltipLang.zipCode.en
                  }
                ></TooltipSec>
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                name="personal_zipcode"
                type="text"
                placeholder={
                  accountLang.zipCode[lang] || accountLang.zipCode.en
                }
                defaultValue={status ? user.personal_zipcode : ""}
                {...register("personalZipcode")}
              />
            </div>
          </div>
        </div>

        {/* Birth Information */}
      </div>
    </div>
  );
};

export default AddressAndBirthInfo;
