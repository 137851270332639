import React from 'react';
import { connect } from 'react-redux';
import SingleRelated from './SingleRelated';

const RelatedBlogs = ({ items, lang }) => {
	const config = {
		relatedBlog: {
			en: 'Related Blog',
			es: 'Blog relacionado',
			it: 'Blog correlato'
		}
	}

  return (
    <section className="sec-similar-blog prefooter-last">
			<div className="container">
				<div className="section-title">
					<h1 className="title">{ config.relatedBlog[lang] || config.relatedBlog.en }</h1>
				</div>
				<div className="blog-wrap">
					{
						items.map(item => {
							return <SingleRelated key={item.id} item={item} />
						})
					}
				</div>
			</div>
			<div className="prefooter-overlay">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path fill="#2f2f95" fillOpacity="1" d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
				</svg>
			</div>
		</section>
  )
}

const mapStateToProps = state => {
	return {
		lang: state.defaultLang
	}
}

export default connect(mapStateToProps, null)(RelatedBlogs);
