import { combineReducers } from 'redux';
import pastAppointmentReducer from './pastAppointmentReducer';
import inprogressAppointmentReducer from './inprogressAppointmentReducer';
import upcomingAppointmentReducer from './upcomingAppointmentReducer';
import blockedUserReducer from './blockedUserReducer';
import lastAppointmentReducer from './lastAppointmentReducer';
import countReducer from './Count';

export default combineReducers({
  pastAppointments: pastAppointmentReducer,
  inprogressAppointments: inprogressAppointmentReducer,
  upcomingAppointments: upcomingAppointmentReducer,
  blockedUsers: blockedUserReducer,
  lastAppointment: lastAppointmentReducer,
  counts: countReducer
});