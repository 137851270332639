import React from 'react';
import moment from 'moment';

const SingleRecent = ({ item }) => {
  return (
    <div className="blog-item">
      <div className="item-image">
        <img 
          src={item.logo}
          alt={item.name}
        />
      </div>
      <div className="item-content">
        <div className="item-title">{ item.name }</div>
        <div className="item-date">
          { moment(new Date(item.publishDate)).format('DD MMM YYYY') }
        </div>
        <div className="item-text"
          dangerouslySetInnerHTML={{ __html: item.short_description }}
        >
        </div>
      </div>
    </div>
  )
}

export default SingleRecent
