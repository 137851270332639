import React, { useState, useEffect } from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNationalities,
  getSiteSettings,
  getLanguages,
  getCountryList,
  getInterventionSpecializationList,
  getTimeZoneList,
  setNotifications,
} from "./actions/cms";
import { getHomeInterventionsList } from "./actions/home";
import {
  getSocket,
  removeInprogressAppointment,
  removeUpcomingAppointment,
} from "./actions/appointments";
import { verifyUser } from "./actions/auth";

import history from "./config/history";

import ScrollToTop from "./ScrollToTop";
import ProtectedRoute from "./routes/protectedRoute";
import FreeAuthRoute from "./routes/freeAuthRoute";
import HomeRoute from "./routes/homeRoute";
// import ConsultantOnlyRoute from './routes/consultantOnlyRoute';
import Home from "./pages";
import About from "./pages/about";
import ContactUs from "./pages/contactUs";
import Consultant from "./pages/consultant";
import ConsultantDetails from "./pages/consultantDetails";
import ReviewList from "./pages/reviewList";
import HowItWorks from "./pages/howItWorks";
import Blog from "./pages/blog";
import BlogDetail from "./pages/blogDetail";
import Login from "./pages/login";
import Register from "./pages/register";
import RegisterConsultant from "./pages/registerConsultant";
import PrivacyPolicy from "./pages/privacyPolicy";
import Interventions from "./pages/interventions";
import TermCondition from "./pages/termCondition";
import FAQ from "./pages/faq";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import InterventionDetail from "./pages/interventionDetail";
import Dashboard from "./pages/admin/dashboard";
import MyAccount from "./pages/admin/myAccount";
import FiscalInfo from "./pages/admin/fiscalInfo";
import PaymentInfo from "./pages/admin/paymentInfo";
import AccountInfo from "./pages/admin/accountInfo";
import MemberInfo from "./pages/admin/memberInfo";
import WishList from "./pages/admin/wishlist";
import AppointmentList from "./pages/admin/appointmentList";
import AppointmentDetails from "./pages/admin/appointmentDetails";
import InProgressAppointment from "./pages/admin/inProgressAppointment";
import UpcomingAppointments from "./pages/admin/upcomingAppointments";
import Email from "./pages/admin/email";
import MyClients from "./pages/admin/myClients";
import AddReview from "./pages/admin/addReview";
import MyReviews from "./pages/admin/myReviews";
import InsuranceInfo from "./pages/admin/insuranceInfo";
import BiographyInfo from "./pages/admin/biographyInfo";
import LanguageInfo from "./pages/admin/languageInfo";
import ServiceInfo from "./pages/admin/serviceInfo";
import InterventionInfo from "./pages/admin/interventionInfo";
import DailySchedule from "./pages/admin/dailySchedule";
import NoteList from "./pages/admin/noteList";
import EmailDetails from "./pages/admin/emailDetails";
import AppointmentService from "./pages/appointmentService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import opentok from './pages/opentok';

// import Loader from './Loader';

// const ScrollToTop = React.lazy(() => import('./ScrollToTop'));
// const ProtectedRoute = React.lazy(() => import('./routes/protectedRoute'));
// const FreeAuthRoute = React.lazy(() => import('./routes/freeAuthRoute'));
// const HomeRoute = React.lazy(() => import('./routes/homeRoute'));
// const Home = React.lazy(() => import('./pages'));
// const About = React.lazy(() => import('./pages/about'));
// const ContactUs = React.lazy(() => import('./pages/contactUs'));
// const Consultant = React.lazy(() => import('./pages/consultant'));
// const ConsultantDetails = React.lazy(() => import('./pages/consultantDetails'));
// const ReviewList = React.lazy(() => import('./pages/reviewList'));
// const HowItWorks = React.lazy(() => import('./pages/howItWorks'));
// const Blog = React.lazy(() => import('./pages/blog'));
// const BlogDetail = React.lazy(() => import('./pages/blogDetail'));
// const Login = React.lazy(() => import('./pages/login'));
// const Register = React.lazy(() => import('./pages/register'));
// const PrivacyPolicy = React.lazy(() => import('./pages/privacyPolicy'));
// const Interventions = React.lazy(() => import('./pages/interventions'));
// const TermCondition = React.lazy(() => import('./pages/termCondition'));
// const FAQ = React.lazy(() => import('./pages/faq'));
// const ForgotPassword = React.lazy(() => import('./pages/forgotPassword'));
// const ResetPassword = React.lazy(() => import('./pages/resetPassword'));
// const Dashboard = React.lazy(() => import('./pages/admin/dashboard'));
// const MyAccount = React.lazy(() => import('./pages/admin/myAccount'));
// const FiscalInfo = React.lazy(() => import('./pages/admin/fiscalInfo'));
// const PaymentInfo = React.lazy(() => import('./pages/admin/paymentInfo'));
// const AccountInfo = React.lazy(() => import('./pages/admin/accountInfo'));
// const MemberInfo = React.lazy(() => import('./pages/admin/memberInfo'));
// const WishList = React.lazy(() => import('./pages/admin/wishlist'));
// const AppointmentList = React.lazy(() => import('./pages/admin/appointmentList'));
// const AppointmentDetails = React.lazy(() => import('./pages/admin/appointmentDetails'));
// const InProgressAppointment = React.lazy(() => import('./pages/admin/inProgressAppointment'));
// const UpcomingAppointments = React.lazy(() => import('./pages/admin/upcomingAppointments'));
// const Email = React.lazy(() => import('./pages/admin/email'));
// const MyClients = React.lazy(() => import('./pages/admin/myClients'));
// const AddReview = React.lazy(() => import('./pages/admin/addReview'));
// const MyReviews = React.lazy(() => import('./pages/admin/myReviews'));
// const InsuranceInfo = React.lazy(() => import('./pages/admin/insuranceInfo'));
// const BiographyInfo = React.lazy(() => import('./pages/admin/biographyInfo'));
// const LanguageInfo = React.lazy(() => import('./pages/admin/languageInfo'));
// const ServiceInfo = React.lazy(() => import('./pages/admin/serviceInfo'));
// const InterventionInfo = React.lazy(() => import('./pages/admin/interventionInfo'));
// const DailySchedule = React.lazy(() => import('./pages/admin/dailySchedule'));
// const NoteList = React.lazy(() => import('./pages/admin/noteList'));
// const EmailDetails = React.lazy(() => import('./pages/admin/emailDetails'));
// const AppointmentService = React.lazy(() => import('./pages/appointmentService'));

const App = ({
  getSiteSettings,
  getLanguages,
  verifyUser,
  lang,
  getSocket,
  socket,
  getTimeZoneList,
  user,
  setNotifications,
  notification,
  ...props
}) => {
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSocket(), []);

  // Load site settings
  useEffect(() => {
    const loadSiteSettings = async () => {
      try {
        await getSiteSettings();
      } catch (err) {
        //
      }
    };
    loadSiteSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load languages
  useEffect(() => {
    const loadLanguages = async () => {
      try {
        await getLanguages();
      } catch (err) {
        console.log(err.message);
      }
    };

    loadLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load timezones
  useEffect(() => {
    const loadTimezones = async () => {
      try {
        await getTimeZoneList();
      } catch (err) {
        console.log(err.message);
      }
    };

    loadTimezones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Socket service
  useEffect(() => {
    if (socket?.io) {
      setInterval(function () {
        socket.emit("onlineOffline", {});
      }, 1000);
      setInterval(function () {
        socket.emit("getConsultantList", {});
      }, 4000);
      setInterval(function () {
        socket.emit("getOnlineConsultantList", {});
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  useEffect(() => {
    if (socket?.io) {
      socket.on("call-consultant", (res) => {
        if (res) {
          if (user?.id === res.consultant_id || user?.id === res.user_id) {
            localStorage.setItem("opentok_sessionId", res.opentok_sessionId);
            localStorage.setItem("opentok_token", res.opentok_token);
          }
        }
      });

      socket.on("call-response-consultant", (res) => {
        if (user?.id === res.consultant_id && res.consultant_join === "1") {
          history.push(`/${lang}/appointment/${res.appointment_id}`);
        }

        if (user?.id === res?.client_id && res.consultant_join === "3") {
          localStorage.removeItem("opentok_sessionId");
          localStorage.removeItem("opentok_token");

          history.push(`/${lang}/appointment-details/${res.appointment_id}`);
        }
      });
      socket.on("call-response-user", (res) => {
        if (user?.id === res.user_id && res.user_join === "1") {
          history.push(`/${lang}/appointment/${res.appointment_id}`);
        }

        if (user?.id === res?.client_id && res.user_join === "3") {
          localStorage.removeItem("opentok_sessionId");
          localStorage.removeItem("opentok_token");

          history.push(`/${lang}/appointment-details/${res.appointment_id}`);
        }
      });
      socket.on("call-auto-disconnect", (res) => {
        localStorage.removeItem("opentok_sessionId");
        localStorage.removeItem("opentok_token");
        history.push(`/${lang}/appointment-details/${res.appointment_id}`);
      });

      if (user?.id) {
        socket.emit("getNotification", {
          lang_code: lang,
          user_id: user.id,
          last_id: notification.length > 0 ? notification[0].id : "0",
        });

        socket.on("end-appointment", (res) => {
          //console.log(res);

          if (res.user_id === user.id || res.consultant_id === user.id) {
            localStorage.removeItem("opentok_sessionId");
            localStorage.removeItem("opentok_token");

            props.removeInprogressAppointment(res.ids);
            props.removeUpcomingAppointment(res.ids);
          }

          // socket.emit('updateConsultantStatus', {
          //   userId: res.consultant_id, activeStatus: '1', isManual: '2'
          // });
        });
      }

      socket.on("get-notification", (res) => {
        if (res && res.result.length) {
          setNotifications(res.result);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, lang, socket]);

  // Load settings
  useEffect(() => {
    // console.log(navigator.getBattery().then(res => console.log(res)))
    const loadSettings = async () => {
      try {
        if (!settingsLoaded) {
          if (!localStorage.getItem("lang")) localStorage.setItem("lang", "en");
          await verifyUser();
          setSettingsLoaded(true);
        }
      } catch (err) {
        setSettingsLoaded(true);
        console.error(err.message);
      }
    };

    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadHomeInterventions = async () => {
      try {
        await props.getHomeInterventionsList({ lang });
      } catch (err) {
        console.log(err.message);
      }
    };

    loadHomeInterventions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Router history={history}>
      {/* <React.Suspense fallback={<Loader loaded={true} />}> */}
      <ScrollToTop />
      <Switch>
        <HomeRoute exact path="/:lang/" component={Home} />
        <HomeRoute exact path="/:lang/about" component={About} />
        <HomeRoute exact path="/:lang/consultant" component={Consultant} />
        <HomeRoute
          exact
          path="/:lang/consultant-details/:id/:name?"
          component={ConsultantDetails}
        />
        <HomeRoute exact path="/:lang/review-list" component={ReviewList} />
        <HomeRoute
          exact
          path="/:lang/how-it-works-consultant"
          component={HowItWorks}
        />
        <HomeRoute
          exact
          path="/:lang/how-it-works-user"
          component={HowItWorks}
        />
        <HomeRoute exact path="/:lang/blog" component={Blog} />
        <FreeAuthRoute path="/:lang/login" component={Login} exact />
        <FreeAuthRoute path="/:lang/register" component={Register} exact />
        <FreeAuthRoute
          path="/:lang/register-consultant"
          component={RegisterConsultant}
          exact
        />
        <HomeRoute
          exact
          path="/:lang/privacy-policy"
          component={PrivacyPolicy}
        />
        <HomeRoute
          exact
          path="/:lang/interventions"
          component={Interventions}
        />
        <HomeRoute
          exact
          path="/:lang/term-condition"
          component={TermCondition}
        />
        <HomeRoute
          exact
          path="/:lang/terms-conditions"
          component={TermCondition}
        />
        <HomeRoute exact path="/:lang/faq-consultant" component={FAQ} />
        <HomeRoute exact path="/:lang/faq-user" component={FAQ} />
        <HomeRoute exact path="/:lang/contact" component={ContactUs} />
        <HomeRoute
          path="/:lang/blog-details/:id/:name"
          component={BlogDetail}
        />
        <HomeRoute
          path="/:lang/intervention-detail/:id/:name?"
          component={InterventionDetail}
        />
        {/* <HomeRoute path="/:lang/opentok" component={opentok}/> */}

        <FreeAuthRoute
          path="/:lang/forgot-password"
          component={ForgotPassword}
          exact
        />
        <FreeAuthRoute
          path="/:lang/password-change"
          component={ResetPassword}
          exact
        />
        <ProtectedRoute
          exact
          path="/:lang/appointment/:id?"
          component={AppointmentService}
        />

        <ProtectedRoute path="/:lang/dashboard" component={Dashboard} exact />
        <ProtectedRoute path="/:lang/myaccount" component={MyAccount} exact />
        <ProtectedRoute
          path="/:lang/fiscal-info"
          component={FiscalInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/payment-info"
          component={PaymentInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/account-info"
          component={AccountInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/member-info"
          component={MemberInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/insurance-info"
          component={InsuranceInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/biography-info"
          component={BiographyInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/intervention-specialization"
          component={InterventionInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/language-info"
          component={LanguageInfo}
          exact
        />
        <ProtectedRoute
          path="/:lang/service-info"
          component={ServiceInfo}
          exact
        />
        <ProtectedRoute path="/:lang/wishlist" component={WishList} exact />
        <ProtectedRoute
          path="/:lang/appointment-list"
          component={AppointmentList}
          exact
        />
        <ProtectedRoute
          path="/:lang/appointment-details/:id"
          component={AppointmentDetails}
          exact
        />
        <ProtectedRoute
          path="/:lang/in-progress-appointment"
          component={InProgressAppointment}
          exact
        />
        <ProtectedRoute
          path="/:lang/up-coming-appointments"
          component={UpcomingAppointments}
          exact
        />
        <ProtectedRoute path="/:lang/email" component={Email} exact />
        <ProtectedRoute path="/:lang/my-clients" component={MyClients} exact />
        <ProtectedRoute path="/:lang/add-review" component={AddReview} exact />
        <ProtectedRoute path="/:lang/my-reviews" component={MyReviews} exact />
        <ProtectedRoute
          path="/:lang/daily-schedule"
          component={DailySchedule}
          exact
        />
        <ProtectedRoute path="/:lang/client-notes" component={NoteList} />
        <ProtectedRoute path="/:lang/email-details" component={EmailDetails} />
        <Redirect to={`/${lang}`} />
      </Switch>
      {/* </React.Suspense> */}
      <ToastContainer />
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    timezones: Object.values(state.cms.timeZoneList),
    socket: state.socket,
    notification: Object.values(state.cms.notifications).slice(-1),
  };
};

export default connect(mapStateToProps, {
  getSiteSettings,
  getLanguages,
  getCountryList,
  verifyUser,
  getNationalities,
  getInterventionSpecializationList,
  getTimeZoneList,
  getSocket,
  setNotifications,
  removeInprogressAppointment,
  removeUpcomingAppointment,
  getHomeInterventionsList,
})(App);
