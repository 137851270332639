import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { closeAccount } from '../../actions/profile';
import { logout } from '../../actions/auth';
import { modalLang } from '../../config/lang';
import { toast } from 'react-toastify';
const CloseAccountModal = ({ userId, closeAccount, logout, lang }) => {
	const [spinner, setSpinner] = useState(false);
  const closeRef = useRef(null);

  const closeTheAccount = async userId => {
		setSpinner(true);

    try {
      const message = await closeAccount({ userId, lang });
      closeRef.current.click();

			setSpinner(false);
			toast.success(message);

      await logout();
    } catch(err) {
			setSpinner(false);
			toast.error(err.message);
    }
  }

  const cancel = () => closeRef.current.click();
  
  return (
    <div className="conversation-modal">
      <div className="modal fade" id="closeAccount" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">

						<div className="modal-body">
							<button type="button" className="modal-close" data-dismiss="modal" 
								aria-label="Close" ref={closeRef}
							>
								<i className="fal fa-fw fa-times"></i>
							</button>
							<div className="subtitle d-none">Start conversation with</div>
							<div className="title text-center mb-0" style={{ fontSize: '25px' }}>
								{ modalLang.closeYourAccount[lang] || modalLang.closeYourAccount.en }
              </div>
							<div className="form-group">
								<div className="row d-flex align-items-center">
									<div className="col-4">
										
										<div className="consultant-rate d-none"
										style={{ fontSize: '16px', fontWeight: '600', color: 'var(--primaryColor)' }}
									>
										
									</div>
									</div>
								</div>
							</div>

              <div className="communication-mode w-100">
								<div className="communication" style={{ width: '50%', maxWidth: 'unset' }}>
									{/* <input className="communication-input" name="communication-mode" type="radio" value=""/> */}
									<button className="communication-label bg-danger" type="button"
                    style={{color: '#fff', cursor: 'pointer' }} onClick={cancel}
                  >
                    { modalLang.cancel[lang] || modalLang.cancel.en }
                  </button>
								</div>
								<div className="communication" style={{ width: '50%', maxWidth: 'unset' }}>
									{/* <input className="communication-input" name="communication-mode" type="radio" value="" /> */}
									<button className="communication-label" 
                    style={{ background: 'var(--primaryColor)', color: '#fff', cursor: 'pointer' }}
										type="button" onClick={() => closeTheAccount(userId)}
                  >
                    <div className="d-flex align-items-center">
											<span>{ modalLang.confirm[lang] || modalLang.confirm.en }</span>
											{
												spinner &&
												<div className="loader-spinner ml-2">
													<div></div>
													<div></div>
													<div></div>
													<div></div>
												</div>
											}
										</div>
                  </button>
								 </div>
							</div>
							<div className="form-group form-group-actions d-none">
								<button className="btn-submit" type="button">
									{ modalLang.continue[lang] || modalLang.continue.en }
								</button>
							</div>
						</div>
          </div>
        </div>
      </div>
	  </div>
  )
}

const mapStateToProps = state => {
	return {
		lang: state.defaultLang
	}
}

export default connect(mapStateToProps, { closeAccount, logout })(CloseAccountModal);
