import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { getAppointments, getInProgressAppointments } from '../../actions/appointments';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import AppointmentContainer from '../../containers/Admin/AppointmentContainer';
import SecAppointmentList from '../../components/Admin/Sections/SecAppointmentList';

const InProgressAppointment = ({ 
  user, appointments, nationalities, siteSettings, languages, timezones, countries,
  getAppointments, getInProgressAppointments, count, lang
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const ac = new AbortController();

    const loadAppointments = async () => {
      if(!isLoaded && user && user?.id) {
        try {
          // await getAppointments({ userId: user.id, startDate: "", endDate: "", limit: '', offset: '0' }, ac.signal);

          await getInProgressAppointments({
            userId: user.id, limit: "10", offset: "0", lastId: "0"
          }, ac.signal);
          
          setIsLoaded(true);
        } catch(err) {
          console.error(err.message);
          setIsLoaded(true);
        }
      }
    }

    loadAppointments();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user ]);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      !$.isEmptyObject(siteSettings) && languages.length && timezones.length && countries.length
      && isLoaded;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries, isLoaded ]);

  const findMinAppointment = () => {
    return appointments.find(item => {
      return +item.ids === Math.min.apply(Math, appointments.map((item) => +item.ids ))
    })
  }

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title="Appointments">
        <AppointmentContainer>
          <SecAppointmentList user={user} isLoaded={isLoaded} appointments={appointments} 
            count={count}
            last={findMinAppointment()}
            callback={getInProgressAppointments}
            lang={lang}
            isShowDetails={false}
          />
        </AppointmentContainer>
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    appointments: Object.values(state.appointments.inprogressAppointments).sort((a, b) => {
      if(a?.appointment_start_time) {
        return new Date(a?.appointment_start_time) - new Date(b?.appointment_start_time)
      } else {
        return a?.ids - b?.ids
      }
    }),
    count: state.appointments.counts.inProgressCount
  }
}

export default connect(mapStateToProps, { getAppointments, getInProgressAppointments })(InProgressAppointment);