import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getHomeWhat } from '../../actions/home';
// import { lang } from '../../config/lang';
// import Register from '../../assets/img/banner/what.jpg';

const What = ({ what, getHomeWhat, lang }) => {

  useEffect(() => {
    const ac = new AbortController();

    const loadWhat = async lang => {
      try {
        if(!what?.title) {
          await getHomeWhat({ lang }, ac.signal)
        }
      } catch(err) {
        console.log(err.message);
      }
    }

    loadWhat(lang);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, what?.title ]);

  return (
    <section className="sec-what">
      { what?.logo &&
        <div className="sec-what-image" style={{ backgroundImage: `url(${what.logo})` }}>
        </div>
      }
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-md-7">
            <div className="what-wrap">
              { what?.title && <div className="title">{ what.title }</div> }
              <div className="sub-title">{ what.sub_title }</div>
              {what?.description && 
                <div className="text"
                  dangerouslySetInnerHTML={{ __html: what.description }}
                >
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    what: state.home.homeWhat
  }
}

export default connect(mapStateToProps, { getHomeWhat })(What);