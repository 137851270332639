import mapKeys from 'lodash/mapKeys';
import { GET_LANGUAGES } from "../../actions/types";

const languagesReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_LANGUAGES:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default languagesReducer;