import React from 'react'

const Skill = ({ rating, setRating }) => {
  return (
    <div className="form-group col-md-4">
      <div className="rating-widget"><label className="control-label">Skill and Knowledge</label>
        <ul className="rating-list ratingcontainerdiv">
          <li className="ratingcontainer">
            <ul className="rating-item communication">
              {
                new Array(5).fill().map((_, i) => {
                  return (
                    <li key={i}
                      className={`star ${rating >= (i + 1) ? 'hover' : ''}`} 
                      style={{ cursor: 'pointer' }}
                      onClick={() => setRating(i + 1)}
                    >
                      <i className="fas fa-star fa-fw"></i>
                    </li>
                  )
                })
              }
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Skill
