export const host = "https://devadmin.prontopsy.com/api";
export const adminHost = "https://devadmin.prontopsy.com/api/user/profile";
export const noImage =
  "https://devadmin.prontopsy.com/assets/img/user-default.png";

export const hostUrl = "https://devadmin.prontopsy.com/";
export const sockUrl = "https://devadmin.prontopsy.com:3036";
// export const sockUrl = "http://node.ayanesh.iosx.in:5000";

export const invoiceLink = `${hostUrl}appointments/invoice`;

export const socketConfig = {
  url: sockUrl,
  options: {},
};
