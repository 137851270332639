import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { GET_WISHLIST, REMOVE_FROM_WISHLIST } from "../../actions/types";

const wishlistReducer = (state = {}, action) => {
  switch(action.type) {
    
    case GET_WISHLIST:
      return { ...state, ...mapKeys(action.payload, 'wishlist_id') };
    case REMOVE_FROM_WISHLIST:
      return omit(state, action.payload);
    default:
      return state;
  }
}

export default wishlistReducer;