import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import moment from "moment";
import { createSession } from "../../asynchronus/Appointment";
import { getTimeSlots, clearTimeSlots } from "../../actions/consultant";
import { scheduleAppointment } from "../../actions/appointments";
import { loadStripe } from "@stripe/stripe-js";
import StripeFormOnAppointmentBooking from "../Admin/Children/StripeFormOnAppointmentBooking";
import { Elements } from "@stripe/react-stripe-js";
import StripeCardListItemForBookingAppointment from "../Admin/Children/StripeCardListItemForBookingAppointment";
import { apiKey } from "../../config/stripe";
import { consultantLang, langConfig, cardConfig } from "../../config/lang";
import history from "../../config/history";
import SingleTimeSlot from "../Consultant/SingleTimeSlot";
import { API_KEY, API_SECRET } from "../../config/opektok";
import { modalLang } from "../../config/lang";
import { toast } from "react-toastify";
import BookingConfirmationModal from "./BookingConfirmationModal";
const BookAppointmentModalAlt = ({
  consultant,
  user,
  lang,
  id,
  getTimeSlots,
  timeSlots,
  clearTimeSlots,
  scheduleAppointment,
  timezones,
  socket,
}) => {
  const stripePromise = loadStripe(apiKey);
  const [serviceDetail, setServiceDetail] = useState(null);
  const [consent, setConsent] = useState(false);
  const [consentPrivacy, setConsentPrivacy] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [logDate, setLogDate] = useState(new Date().getTime());
  const [timeSlot, setTimeSlot] = useState(null);
  const [mode, setMode] = useState(null);
  const [audioIconClassname, setAudioIconClassname] = useState("fas fa-phone");
  const [videoIconClassname, setVideoIconClassname] = useState("fas fa-video");
  const [chatIconClassname, setChatIconClassname] = useState(
    "fas fa-comments-alt"
  );
  const [videoCheckBooking, setVideoCheckBooking] = useState("");
  const [audioCheckBooking, setAudioCheckBooking] = useState("");
  const [chatCheckBooking, setChatCheckBooking] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [cardIdBookingAppointment, setCardIdBookingAppointment] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isNewCardBookingAppointment, setIsNewCardBookingAppointment] =
    useState(2);
  const [token, setToken] = useState(null);
  const [radioCheckedBookingAppointment, setRadioCheckedBookingAppointment] =
    useState(0);
  const [isShowBookingConfirmation, setIsBookingConfirmation] = useState(false);
  const closeRef = useRef(null);
  const checkConsultantService = (serviceId) => {
    if (consultant?.service_details) {
      const service = consultant?.service_details.find(
        (item) => item.id === "2"
      );

      if (service?.mode.includes(serviceId)) return true;
    }

    return false;
  };

  useEffect(() => {
    if (consultant?.service_details?.length) {
      const serviceDetails =
        consultant.service_details.find((detail) => detail.id === "2") || null;
      setServiceDetail(serviceDetails);
      if (serviceDetails?.mode.includes(3)) {
        setMode("3");
        setVideoCheckBooking("checked");
        setAudioCheckBooking("");
        setChatCheckBooking("");
        setAudioIconClassname("fas fa-phone");
        setChatIconClassname("fas fa-comments-alt");
        setVideoIconClassname("far fa-video");
      } else if (serviceDetails?.mode.includes(2)) {
        setMode("2");
        setVideoCheckBooking("");
        setAudioCheckBooking("checked");
        setChatCheckBooking("");
        setAudioIconClassname("far fa-phone");
        setChatIconClassname("fas fa-comments-alt");
        setVideoIconClassname("fas fa-video");
      } else if (serviceDetails?.mode.includes(1)) {
        setMode("1");
        setVideoCheckBooking("");
        setAudioCheckBooking("");
        setChatCheckBooking("checked");
        setAudioIconClassname("fas fa-phone");
        setChatIconClassname("far fa-comments-alt");
        setVideoIconClassname("fas fa-video");
      } else {
        setMode("");
        setVideoCheckBooking("");
        setAudioCheckBooking("");
        setChatCheckBooking("");
        setAudioIconClassname("fas fa-phone");
        setChatIconClassname("fas fa-comments-alt");
        setVideoIconClassname("fas fa-video");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultant?.service_details]);

  useEffect(() => {
    if (consultant?.stripeCardDetails?.length) {
      setCardIdBookingAppointment(consultant.stripeCardDetails[0].id);
    }
  }, [consultant?.stripeCardDetails]);

  useEffect(() => {
    const loadTimeSlots = async () => {
      if (startDate) {
        await getConsultantTimeSlots();
      }
    };

    loadTimeSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDate]);

  const getConsultantTimeSlots = async () => {
    try {
      if (user?.id && id) {
        await getTimeSlots({
          lang,
          userId: user?.id,
          consultantId: id,
          date: startDate,
        });
      }
    } catch (err) {
      clearTimeSlots();
      setTimeSlot(null);
      // $.dialog({ title: 'Failure', content: err.message });
    }
  };

  useEffect(() => {
    $("#bookAppointmentPicker").datetimepicker({
      value: startDate ? new Date(startDate) : new Date(),
      timepicker: false,
      inline: true,
      format: "Y-m-d",
      minDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      defaultDate: null,
      scrollMonth: false,
      onSelectDate: function (e) {
        setStartDate(moment(new Date(e)).format("YYYY-MM-DD"));
        setLogDate(new Date().getTime());
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDetail?.id]);

  const removeAmPm = (date) => {
    let newDate = date.replace(" AM", "");
    newDate = newDate.replace(" PM", "");

    return newDate;
  };

  const getData = (user, consultant, appointment, sessionData) => {
    return {
      appointment_id: appointment.appointment_id,
      consultant_timezone: user.timezone,
      from_timezone: user.timezone,
      consultant_name: consultant.consultant_details.name,
      user_name: user.name,
      user_image: user.image,
      consultant_image: consultant.consultant_details.logo,
      service_type: mode,
      consultant_id: id,
      user_id: user.id,
      amount: consultant.consultant_details.servicePrice,
      currency: consultant.consultant_details.serviceCurrency,
      appointment_type: "2",
      consultant_status: consultant.consultant_details.activeStatus,
      opentok_sessionId: sessionData ? sessionData.sessionId : null,
      opentok_token: sessionData ? sessionData.token : null,
    };
  };

  const scheduleAnAppointment = async () => {
    try {
      if (user?.id) {
        setSpinner(true);

        if (
          isNewCardBookingAppointment == 1 &&
          (token == null || token == "")
        ) {
          toast.error(cardConfig.addCard[lang]);
          setSpinner(false);
        } else {
          let sessionData = null;
          let opentokSessionId = "";
          let opentokToken = "";
          if (mode !== "1") {
            sessionData = await createSession(API_KEY, API_SECRET);
            opentokSessionId = sessionData.sessionId;
            opentokToken = sessionData.token;
          }
          const { message, data } = await scheduleAppointment({
            lang,
            userId: user?.id,
            cardId: cardIdBookingAppointment,
            token: token,
            isNewCard: isNewCardBookingAppointment,
            customerId: customerId,
            amount: serviceDetail?.price,
            consultantId: id,
            currency: serviceDetail?.currency,
            serviceType: mode,
            timezone: timezones.find((zone) => zone.id === timeSlots.timezone)
              ?.zone,
            appointmentDate:
              typeof timeSlot === "number"
                ? timeSlots?.allTimeSlot[timeSlot].date
                : null,
            appointmentStartTime:
              typeof timeSlot === "number"
                ? timeSlots?.allTimeSlot[timeSlot].date +
                  " " +
                  removeAmPm(timeSlots?.allTimeSlot[timeSlot].start)
                : null,

            appointmentEndTime:
              typeof timeSlot === "number"
                ? timeSlots?.allTimeSlot[timeSlot].date +
                  " " +
                  removeAmPm(timeSlots?.allTimeSlot[timeSlot].end)
                : "",
            termsAndCondition: consent ? "1" : null,
            privacyPolicy: consentPrivacy ? "1" : null,
            opentokSessionId,
            opentokToken,
          });
          closeRef.current.click();
          toast.success(message);
          if (data.service_type !== "1") {
            const socData = getData(user, consultant, data, sessionData);
            socket.emit("callConsultant", socData);
          }
          setSpinner(false);

          history.push(`/${lang}/up-coming-appointments`);
        }
      }
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };
  const openConfirmationModal = async () => {};

  const handleRedirect = (e) => {
    e.preventDefault();

    closeRef.current.click();
    history.push(`/${lang}/terms-conditions`);
  };

  const handleRedirectPrivacy = (e) => {
    e.preventDefault();

    closeRef.current.click();
    history.push(`/${lang}/privacy-policy`);
  };
  const handleChange = (event) => {
    if (event.target.checked) {
      setIsNewCardBookingAppointment(1);
    } else {
      setIsNewCardBookingAppointment(2);
    }
  };

  return (
    <div className="conversation-modal">
      <div className="modal fade" id="bookInterview" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => clearTimeSlots()}
                ref={closeRef}
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
              <div className="subtitle">
                {consultantLang.bookAnInterviewWith[lang] ||
                  consultantLang.bookAnInterviewWith.en}
              </div>
              <div className="title">
                {consultant?.consultant_details?.name}
              </div>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <div
                      className="control-label"
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        // color: 'var(--primaryColor)'
                      }}
                    >
                      {/* SET THE TIME */}
                      {serviceDetail?.currency}
                      <span className="ml-1">
                        {serviceDetail?.price}
                        <small>/min</small>
                      </span>
                    </div>
                    <div
                      className="consultant-rate d-none"
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "var(--primaryColor)",
                      }}
                    >
                      <small>/min</small>
                    </div>
                  </div>
                  <div className="col-8">
                    <div
                      className="control-label"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      {langConfig.maxCapture[lang] || langConfig.maxCapture.en}
                      {serviceDetail?.currency}
                      <span className="ml-1">
                        {serviceDetail?.price
                          ? (serviceDetail?.price * 60).toFixed(2)
                          : ""}
                      </span>
                    </div>
                    <div
                      className="consultant-rate d-none"
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "var(--primaryColor)",
                      }}
                    >
                      {consultant?.consultant_details?.serviceCurrency}{" "}
                      {consultant?.consultant_details?.servicePrice}
                      <small>/min</small>
                    </div>
                  </div>
                  <div className="col-8 d-none">
                    <div className="dropdown d-none">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Time
                      </button>
                      <div className="dropdown-menu d-none">
                        <button type="button" className="dropdown-item">
                          25 min (Total Cost: €25.00)
                        </button>
                        <button type="button" className="dropdown-item">
                          50 min (Total Cost: €50.00)
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-wrapper">
                <div className="communication-mode mb-3">
                  {checkConsultantService("2") && (
                    <div className="communication" onClick={() => {}}>
                      <input
                        className="communication-input"
                        name="communication-mode1"
                        type="radio"
                        value="2"
                        checked={audioCheckBooking === "checked"}
                        onChange={() => {
                          setMode("2");
                          setAudioCheckBooking("checked");
                          setVideoCheckBooking("");
                          setChatCheckBooking("");
                          setAudioIconClassname("far fa-phone");
                          setChatIconClassname("fas fa-comments-alt");
                          setVideoIconClassname("fas fa-video");
                        }}
                      />
                      <div className="communication-label">
                        {langConfig.voice[lang] || langConfig.voice.en}
                        <i className={"fa-fw ml-1 " + audioIconClassname}></i>
                      </div>
                    </div>
                  )}
                  {checkConsultantService("3") && (
                    <div className="communication" onClick={() => {}}>
                      <input
                        className="communication-input"
                        name="communication-mode1"
                        type="radio"
                        value="3"
                        checked={videoCheckBooking === "checked"}
                        onChange={() => {
                          setMode("3");
                          setAudioCheckBooking("");
                          setVideoCheckBooking("checked");
                          setChatCheckBooking("");
                          setAudioIconClassname("fas fa-phone");
                          setChatIconClassname("fas fa-comments-alt");
                          setVideoIconClassname("far fa-video");
                        }}
                      />
                      <div className="communication-label">
                        {langConfig.video[lang] || langConfig.video.en}
                        <i className={"fa-fw ml-1 " + videoIconClassname}></i>
                      </div>
                    </div>
                  )}
                  {checkConsultantService("1") && (
                    <div className="communication" onClick={() => {}}>
                      <input
                        className="communication-input"
                        name="communication-mode1"
                        type="radio"
                        value="1"
                        checked={chatCheckBooking === "checked"}
                        onChange={() => {
                          setMode("1");
                          setAudioCheckBooking("");
                          setVideoCheckBooking("");
                          setChatCheckBooking("checked");
                          setAudioIconClassname("fas fa-phone");
                          setChatIconClassname("far fa-comments-alt");
                          setVideoIconClassname("fas fa-video");
                        }}
                      />
                      <div className="communication-label">
                        {langConfig.chat[lang] || langConfig.chat.en}
                        <i className={"fa-fw ml-1 " + chatIconClassname}></i>
                      </div>
                    </div>
                  )}
                </div>

                <div className="text d-none">
                  You will be charged €25.00 at the end of the conversation.
                </div>

                {/* Date picker */}
                <div className="form-group" id="bookAppointmentPicker"></div>

                {/* Time slots */}
                <div className="form-group mt-4">
                  <label className="control-label d-flex">
                    <div className="mt-2">
                      {" "}
                      {consultantLang.availableTimeSlotsAccording[lang] ||
                        consultantLang.availableTimeSlotsAccording.en}
                    </div>
                    <div className="mt-2"> : {user?.time_zone_name}</div>
                  </label>
                  <div className="time-slots">
                    {timeSlots &&
                      timeSlots?.allTimeSlot &&
                      timeSlots.allTimeSlot.map((slot, i) => (
                        <SingleTimeSlot
                          index={i}
                          key={i}
                          slot={slot}
                          setTimeSlot={setTimeSlot}
                          timeSlot={timeSlot}
                          timezones={timezones}
                          user={user}
                        />
                      ))}
                  </div>
                </div>
                {consultant?.stripeCardDetails?.length ? (
                  <div className="form-group stripe-card-list">
                    {isNewCardBookingAppointment == 2 &&
                      consultant?.stripeCardDetails.map((items, i) => {
                        return (
                          <StripeCardListItemForBookingAppointment
                            key={i}
                            bookingAppointmentKey={i}
                            items={items}
                            setRadioCheckedBookingAppointment={
                              setRadioCheckedBookingAppointment
                            }
                            radioCheckedBookingAppointment={
                              radioCheckedBookingAppointment
                            }
                            setCardIdBookingAppointment={
                              setCardIdBookingAppointment
                            }
                            cardIdBookingAppointment={cardIdBookingAppointment}
                            setCustomerId={setCustomerId}
                            customerId={customerId}
                          />
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <label className="d-flex align-items-start add-card-sec">
                    <input
                      type="checkbox"
                      name="stripeCardcheckbox"
                      value={1}
                      onChange={handleChange}
                      className="mt-4"
                    />
                    <div className="right_sec card_list_sec ml-3">
                      {/* <div className="add_card_title">{cardConfig.addNewCard[lang]}</div> */}
                      <div className="new_card_img d-flex">
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/card/visa.png").default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/card/masterCard.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/card/American Express.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/Diners Club.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/Discover.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/JCB.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/UnionPay.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/elo.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/maestro.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/troy.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/hipercard-29.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                        <div className="img">
                          <img
                            src={
                              require("../../assets/img/cardicon/mnp.png")
                                .default
                            }
                            alt="card_image"
                          />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="form-group mt-5">
                  {isNewCardBookingAppointment == 1 && (
                    <Elements stripe={stripePromise} options={{ locale: lang }}>
                      <StripeFormOnAppointmentBooking
                        setToken={setToken}
                        lang={lang}
                        isNewCard={isNewCardBookingAppointment}
                      />
                    </Elements>
                  )}
                </div>
              </div>
              <div className="stripe">
                <div className="stripe-label">
                  <i className="fas fa-fw fa-lock mr-2"></i>{" "}
                  <span>
                    Guaranteed <strong>safe & secure</strong> checkout
                  </span>
                </div>
                <div className="stripe-img">
                  <img
                    src={require("../../assets/img/card/stripe.svg").default}
                    alt="stripe"
                  />
                </div>
              </div>
              <div className="form-group checkbox-term mt-2 mb-0">
                <div className="checkbox-term-checkbox">
                  <input
                    className="checkbox-term-input"
                    name="checkbox-terms"
                    type="checkbox"
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                  />
                  <div className="checkbox-term-label"></div>
                </div>
                <div className="checkbox-term-text">
                  {modalLang.rA[lang] || modalLang.rA.en}{" "}
                  <Link
                    to={`/${lang}/terms-conditions`}
                    onClick={handleRedirect}
                  >
                    <u>
                      {modalLang.informedConsent[lang] ||
                        modalLang.informedConsent.en}
                    </u>
                  </Link>{" "}
                  {modalLang.tPc[lang] || modalLang.tPc.en}
                </div>
              </div>

              <div className="form-group checkbox-term">
                <div className="checkbox-term-checkbox">
                  <input
                    className="checkbox-term-input"
                    name="checkbox-terms"
                    type="checkbox"
                    checked={consentPrivacy}
                    onChange={() => setConsentPrivacy(!consentPrivacy)}
                  />
                  <div className="checkbox-term-label"></div>
                </div>
                <div className="checkbox-term-text">
                  {modalLang.iAgree[lang] || modalLang.iAgree.en}{" "}
                  <Link
                    to={`/${lang}/privacy-policy`}
                    onClick={handleRedirectPrivacy}
                  >
                    <u>{modalLang.dApP[lang] || modalLang.dApP.en}</u>
                  </Link>
                </div>
              </div>

              <div
                className="form-group form-group-actions"
                onClick={scheduleAnAppointment}
              >
                <button className="btn-submit" type="button" disabled={spinner}>
                  <div className="d-flex align-items-center">
                    <span>
                      {langConfig.continue[lang] || langConfig.continue.en}
                    </span>
                    {spinner && (
                      <div className="loader-spinner ml-2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingConfirmationModal
        isShowBookingConfirmation={isShowBookingConfirmation}
        setIsBookingConfirmation={setIsBookingConfirmation}
      ></BookingConfirmationModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    socket: state.socket,
    timeSlots: state.consultants.timeSlotList,
    timezones: Object.values(state.cms.timeZoneList),
  };
};

export default connect(mapStateToProps, {
  getTimeSlots,
  clearTimeSlots,
  scheduleAppointment,
})(BookAppointmentModalAlt);
