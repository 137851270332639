export const status = {
  '1' : 'Active',
  '2': 'Cancel',
  '3': 'Closed',
  '4': 'Ip'
}

export const paymentStatus = {
  '1': 'Pending',
  '2': 'Authorized',
  '3': 'Captured',
  '4': 'Authorization failed',
  '5': 'Payment released',
  '6' : 'Capture failed'
}

export const professionalQualification = {
  '1': 'Psychologist',
  '2': 'Psychologist and Psychotherapist'
}

export const servicesOffered = {
  '1': 'Direct Access Online Psychological Counseling',
  '2': 'Scheduled Online Psychological Counseling'
}

export const modes = {
  '1': 'Chat',
  '2': 'Voice',
  '3': 'Video'
}