import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { updateActiveStatus } from '../../actions/profile';
// import { lang } from '../../config/lang';
import { toast } from 'react-toastify';
const ActiveStatus = ({ status, user, userId, updateActiveStatus, socket, lang }) => {
  const [activeStatus, setActiveStatus] = useState(status);

  const manageStatus = async (e) => {
    e.preventDefault();

    try {
      const newActiveStatus = activeStatus == '1' ? '2' : '1';
      
      if(socket?.io && user?.role === 'consultant') {
        socket.emit('updateConsultantStatus', { 
          userId: user.id, activeStatus: newActiveStatus, isManual: '1'
        });
       

      }

      await updateActiveStatus({ lang, userId, activeStatus: newActiveStatus, isManual: '1' });
    } catch(err) {
      toast.error(err.message);
    }
  }
  useEffect(() => {
		if(socket?.io) {
			socket.on("get-consultant-list", (res) => {
				if (res.result) {
				  res.result.map(items =>{
					if(items.id==userId){
            setActiveStatus(items.active_status);
					}
				  });
				 
				}
			  });
		}
	}, [socket,userId])
  const config = {
    set: {
      en: 'Set',
      es: 'Configurar',
      it: 'Imposta'
    }
  }

  return (
    <li>
      <a href="#!" className={`status-switch${activeStatus == 1 ? ' active' : ''}`}
        onClick={manageStatus}
      >
        <span>{ activeStatus == 1 ? 'Online' : activeStatus == 2 ? 'Offline' : 'Busy' }</span>
        { config.set[lang] || config.set.en } { activeStatus == 1 ? 'Offline' : 'Online' }
      </a> 
    </li>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket
  }
}


export default connect(mapStateToProps, { updateActiveStatus })(ActiveStatus);