import React from 'react';
import { noImage } from '../../config/host';

const TestimonialItem = ({ item }) => {
  return (
    <div className="item">
      <div className="item-title">{ item.title }</div>
      <div className="item-subtitle">{ item.subtitle }</div>
      <div className="item-description"
        dangerouslySetInnerHTML={{ __html: item.description }}
      >
      </div>
			<div className="item-info">
				<div className="item-image">
          <img src={ item.image } alt={ item.name }
            onError={(e) => e.target.src=`${noImage}`}
            loading="lazy"
          />
        </div>
				<div className="item-content">
					<div className="item-name">{ item.name }</div>
					<div className="item-location">{ item.profession }</div>
				</div>
			</div>
    </div>
  )
}

export default TestimonialItem
