import mapKeys from 'lodash/mapKeys';
import { GET_CONSULTANT_REVIEWS, CLEAR_CONSULTANT_REVIEWS } from '../../actions/types';

const consultantReviewsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_CONSULTANT_REVIEWS:
      return { ...mapKeys(action.payload, 'id') };
    case CLEAR_CONSULTANT_REVIEWS:
      return action.payload;
    default:
      return state;
  }
}

export default consultantReviewsReducer;