import { 
  VERIFY_USER, 
  GET_USER, 
  UPDATE_PROFILE,
  UPDATE_FISCAL_INFO,

  UPDATE_MEMBERSHIP_INFO,
  UPDATE_INSURANCE_INFO,
  UPDATE_BIOGRAPHY_INFO,
  UPDATE_LANGUAGE_INFO,
  UPDATE_INTERVENTION_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_DAILY_SCHEDULE_INFO,
  UPDATE_CONSULTANT_ACCOUNT_INFO,
  LOGOUT
} from "../../actions/types";

const profileInfoReducer = (state = {}, action) => {
  switch(action.type) {
    case VERIFY_USER:
      
      return { ...state, ...action.payload };
    case GET_USER:
      return { ...state, ...action.payload };
    case UPDATE_PROFILE:
      return { ...state, ...action.payload.data };
    case UPDATE_FISCAL_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_MEMBERSHIP_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_INSURANCE_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_BIOGRAPHY_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_LANGUAGE_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_INTERVENTION_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_ACCOUNT_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_CONSULTANT_ACCOUNT_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_DAILY_SCHEDULE_INFO:
      return { ...state, ...action.payload.data };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default profileInfoReducer;