import mapKeys from 'lodash/mapKeys';
import { GET_HOME_ONLINE_SERVICES } from '../../actions/types';

const homeOnlineServicesReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_HOME_ONLINE_SERVICES:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default homeOnlineServicesReducer;