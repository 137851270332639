import { GET_PAGES_HOME } from "../../../actions/types";

const homeRecuder = (state = {}, action) => {
  switch(action.type) {
    case GET_PAGES_HOME:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default homeRecuder;