import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getHomeInterventionsList } from "../../actions/home";
import { langConfig } from "../../config/lang";
import InterventionItem from "./InterventionItem";
// import InterventionItemAlt from './InterventionItemAlt';
import PrefooterOverlay from "../../prefooterOverlay";

const Category = ({
  interventions,
  getHomeInterventionsList,
  lang,
  noLimit,
  isFeatured,
}) => {
  const [isLoaded, setIsLoaded] = useState(null);
  useEffect(() => {
    const ac = new AbortController();

    const loadInterventions = async () => {
      if (!interventions.length) {
        try {
          const params = { lang };

          if (!noLimit) params.limit = "8";

          await getHomeInterventionsList(params, ac.signal);
          setIsLoaded(true);
        } catch (err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      } else {
        setIsLoaded(true);
      }
    };

    loadInterventions();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, interventions.length]);

  const config = {
    areaOfIntervention: {
      en: "AREA OF INTERVENTION",
      es: "ÁMBITOS DE INTERVENCIÓN",
      it: "AMBITI DI INTERVENTO",
    },
    whatKindOfPsychologistAreYouLookingFor: {
      en: "What kind of Psychologist are you looking for?",
      es: "¿Qué tipo de psicólogo estás buscando?",
      it: "Che tipo di psicologo stai cercando?",
    },
  };

  return (
    <>
      <section className={`sec-category ${noLimit && "prefooter-last"}`}>
        <div className="category-overlay">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#2f2f95"
              fillOpacity="1"
              d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>

        <div className="container">
          <div className="section-title">
            <h1 className="title">
              {config.areaOfIntervention[lang] || config.areaOfIntervention.en}
            </h1>
            <div className="sub-title">
              {config.whatKindOfPsychologistAreYouLookingFor[lang] ||
                config.whatKindOfPsychologistAreYouLookingFor.en}
            </div>
          </div>
          {/*<div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="img-container">
                <LazyLoadImage src="https://newprontopsy.mdemo.us/assets/img/Psychologist-pana.svg" alt="Intervention image" loading="lazy" />
              </div>
            </div>
          </div>*/}
          <div className="row">
            <div className="col-lg-12">
              <div className="category-wrap">
                {isLoaded && interventions.length ? (
                  <>
                    {interventions.map((item) => {
                      if (isFeatured) {
                        if (item.is_featured == 1)
                          return (
                            <InterventionItem
                              key={item.id}
                              item={item}
                              lang={lang}
                              isFeatured={isFeatured}
                            />
                          );
                        else return null;
                      } else
                        return (
                          <InterventionItem
                            key={item.id}
                            item={item}
                            lang={lang}
                            isFeatured={isFeatured}
                          />
                        );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className={`category-actions ${noLimit && "d-none"}`}>
                <Link to={`/${lang}/interventions`} className="btn-action">
                  {langConfig.viewAll[lang] || langConfig.viewAll.en}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {noLimit && <PrefooterOverlay />}
      </section>

      {/* <section className={`sec-category ${noLimit && 'prefooter-last'} d-none`}>
        <div className="container">
          <div className="section-title title-alt">
            <h1 className="title">AREA OF INTERVENTION</h1>
            <div className="sub-title">What kind of Psychologist are you looking for?</div>
          </div>
          <div className="category-wrap">
            {
              (isLoaded && interventions) ?
              interventions.map(item => <InterventionItemAlt key={item.id} item={item} />)
              : ''
            }
          </div>
        </div>

        <PrefooterOverlay />
      </section> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    interventions: Object.values(state.home.homeInterventions),
  };
};

export default connect(mapStateToProps, { getHomeInterventionsList })(Category);
