import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { getNationalities } from '../../actions/cms';
import { withRouter } from 'react-router';
import { langConfig, homeLang } from '../../config/lang';
import history from '../../config/history';

const SearchContainer = ({ 
  search, setSearch, nationalities, languages, interventions, lang, getNationalities
}) => {

  useEffect(() => {
    const ac = new AbortController();

    const loadNationalities = async () => {
      try {
        if(!nationalities.length && search) {
          await getNationalities({ lang }, ac.signal);
        }
      } catch(err) {
        console.log(err.message);
      }
    }

    loadNationalities();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, search, nationalities.length ]);
  
  const manageSearch = (e) => { 
    e.preventDefault(); 
    setSearch(!search); 
  };

  const convertToUrl = params => {
    let url = "";

    for (let i in params) {
      if (url !== "") {
        url += "&";
      }

      url += i + "=" + encodeURIComponent(params[i]);
    }

    return url;
  }

  const [state, setState] = useState({
    keyword: '',
    gender: [],
    activeStatus: [],
    modes: []
  });

  const [nationality, setNationality] = useState('');
  const [language, setLanguage] = useState('');
  const [intervention, setIntervention] = useState('');

  const searchConsultant = e => {
    e.preventDefault();

    try {
      const allParams = { search: true, ...state, nationality, intervention, language,  };
      const params = convertToUrl(allParams)
      history.push(`/${lang}/consultant?${params}`);
    } catch(err) {
      console.error(err.message);
    }
  }

  const handleGender = value => {
    const currentGender = state.gender;

    if(state.gender.includes(value)) {
      setState({ ...state, gender: currentGender.filter(item => item !== value) });
    } else {
      setState({ ...state, gender: [ ...state.gender, value ] });
    }
  }

  const handleStatus = value => {
    const currentStatus = state.activeStatus;

    if(state.activeStatus.includes(value)) {
      setState({ ...state, activeStatus: currentStatus.filter(item => item !== value) });
    } else {
      setState({ ...state, activeStatus: [ ...state.activeStatus, value ] });
    }
  }

  const handleModes = value => {
    const currentModes = state.modes;

    if(state.modes.includes(value)) {
      setState({ ...state, modes: currentModes.filter(item => item !== value) });
    } else {
      setState({ ...state, modes: [ ...state.modes, value ] });
    }
  }

  // Nationality
  useEffect(() => {
    $('#nationality').on('change', function(e) { setNationality(e.target.value) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ nationality ]);

  // Language
  useEffect(() => {
    $('#language').on('change', function(e) { setLanguage(e.target.value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ language ]);

  // Intervention
  useEffect(() => {
    $('#intervention').on('change', function(e) { setIntervention(e.target.value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ intervention ]);

  return (
    <section className={`search-container${search ? ' active' : ''}`}>
      <div className="search-header">
        <div className="search-title">{ langConfig.search[lang] || langConfig.search.en }</div>
        <a className="btn-close" href="#!" onClick={manageSearch}>
          <i className="fal fa-fw fa-times"></i>
        </a>
      </div>
      <div className="input-form">
        <div className="search-input">
          <input type="text" className="form-control" 
            placeholder={ 
              homeLang.searchBannerPlaceholder[lang] || homeLang.searchBannerPlaceholder.en 
            }
            value={state.name}
            onChange={(e) => setState({ ...state, keyword: e.target.value })}
          />
        </div>
        <div className="search-country">
          <label className="control-label">
            { langConfig.nationality[lang] || langConfig.nationality.en }
          </label>
          <select defaultValue="" className="form-control select2" id="nationality">
            <option value="">{ langConfig.any[lang] || langConfig.any.en }</option>
            {nationalities && nationalities.map(el => 
              <option key={el.id} value={el.nationality}>{ el.nationality }</option>
            )}
          </select>
        </div>
        <div className="search-language">
          <label className="control-label">Language</label>
          <select defaultValue="" className="form-control select2-nosearch" id="language">
            <option value="">{ langConfig.any[lang] || langConfig.any.en }</option>
            { languages && languages.map(el => 
              <option key={el.id} value={el.lang}>{ el.name }</option>  
            ) }
          </select>
        </div>
        <div className="search-intervention">
          <label className="control-label">
            { homeLang.areaOfIntervention[lang] || homeLang.areaOfIntervention.en }
          </label>
          <select defaultValue="" className="form-control select2" id="intervention">
            <option value="">{ langConfig.any[lang] || langConfig.any.en }</option>
            { interventions && interventions.map(el => 
              <option key={el.id} value={el.id}>{ el.name }</option>  
            ) }
          </select>
        </div>
        <div className="search-gender">
          <label className="control-label">
            { langConfig.gender[lang] || langConfig.gender.en }
          </label>
          <div className="checkbox-group">
            <div className="custom-checkbox">
              <input type="checkbox" className="custom-checkbox-input" name="gender" value="1"
                onChange={(e) => handleGender(e.target.value)}
              />
              <div className="custom-checkbox-label">
                { langConfig.male[lang] || langConfig.male.en }
              </div>
            </div>
            <div className="custom-checkbox ml-3">
              <input type="checkbox" className="custom-checkbox-input" name="gender" value="2"
                onChange={(e) => handleGender(e.target.value)}
              />
              <div className="custom-checkbox-label">
                { langConfig.female[lang] || langConfig.female.en }
              </div>
            </div>
          </div>
        </div>
        <div className="search-status">
          <label className="control-label">
            { langConfig.status[lang] || langConfig.status.en }
          </label>
          <div className="checkbox-group">
            <div className="custom-checkbox">
              <input type="checkbox" className="custom-checkbox-input" name="status" value="1"
                onChange={(e) => handleStatus(e.target.value)}
              />
              <div className="custom-checkbox-label">Online</div>
            </div>
            <div className="custom-checkbox ml-3">
              <input type="checkbox" className="custom-checkbox-input" name="status" value="2"
                onChange={(e) => handleStatus(e.target.value)}
              />
              <div className="custom-checkbox-label">Offline</div>
            </div>
          </div>
        </div>
        <div className="search-chat">
          <label className="control-label">
            { langConfig.mode[lang] || langConfig.mode.en }
          </label>
          <div className="checkbox-group">
            <div className="custom-checkbox">
              <input type="checkbox" className="custom-checkbox-input" name="checkbox" value="1"
                onChange={(e) => handleModes(e.target.value)}
              />
              <div className="custom-checkbox-label">
                { langConfig.chat[lang] || langConfig.chat.en }
              </div>
            </div>
            <div className="custom-checkbox ml-3">
              <input type="checkbox" className="custom-checkbox-input" name="checkbox" value="2"
                onChange={(e) => handleModes(e.target.value)}
              />
              <div className="custom-checkbox-label">
                { langConfig.voice[lang] || langConfig.voice.en }
              </div>
            </div>
            <div className="custom-checkbox ml-3">
              <input type="checkbox" className="custom-checkbox-input" name="checkbox" value="3"
                onChange={(e) => handleModes(e.target.value)}
              />
              <div className="custom-checkbox-label">
                { langConfig.video[lang] || langConfig.video.en }
              </div>
            </div>
          </div>
        </div>
      </div>
        <a href="#!" className="btn-search" onClick={searchConsultant}>
          <i className="fal fa-fw fa-search mr-2"></i>
          <span>{ langConfig.search[lang] || langConfig.search.en }</span>
        </a>
    </section>
  );

}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    nationalities: Object.values(state.cms.nationalities),
    languages: Object.values(state.cms.languages),
    interventions: Object.values(state.home.homeInterventions)
  }
}

export default withRouter(connect(mapStateToProps, { getNationalities })(SearchContainer));