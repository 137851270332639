import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appointmentLang, consultantLang } from '../../../config/lang';
import { getDashboardData } from '../../../actions/dashboard';

const SecDashboard = ({ getDashboardData, data, user, lang }) => {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadDashboard = async () => {
      if(!isLoaded && localStorage.getItem('userId')) {
        try {
          await getDashboardData(null, ac.signal);
          setIsLoaded(true);
        } catch(err) {
          console.log(err.message);
          setIsLoaded(true);
          // $.dialog({ title: 'Failure', content: err.message });
        }
      }
    }

    loadDashboard();

    return () => ac.abort();
  }, [data, getDashboardData, isLoaded]);

  return (
    <>
    { data &&
      <div className="col-sm-12 col-md-9 col-lg-10">

        <div className="card-container">
          <div className="row">
            <Link to={`/${lang}/appointment-list`} className="col-sm-6 col-lg-4">
              <div className="statistics-card statistics-color-1" >
                <div className="statistics-icon">
                  <i className="fal fa-fw fa-calendar-check"></i>
                </div>
                <div className="statistics-text text-right">
                  <div className="desc">{ data?.pastAppointments || '0' }</div>
                  <div className="title">
                    { 
                      appointmentLang.pastAppointments[lang] || 
                      appointmentLang.pastAppointments.en 
                    }
                  </div>
                </div>
              </div>
            </Link>

            <Link to={`/${lang}/up-coming-appointments`} className="col-sm-6 col-lg-4">
              <div className="statistics-card statistics-color-2" >
                <div className="statistics-icon">
                  <i className="fal fa-fw fa-clock"></i>
                </div>
                <div className="statistics-text text-right">
                  <div className="desc">{ data?.upcomingAppointments || '0' }</div>
                  <div className="title">
                    { 
                      appointmentLang.upcomingAppointments[lang] || 
                      appointmentLang.upcomingAppointments.en 
                    }
                  </div>
                </div>
              </div>
            </Link>


            {(user?.role === 'consultant') && 
              <Link to={`/${lang}/my-reviews`} className="col-sm-6 col-lg-4">
                <div className="statistics-card statistics-color-3" >
                  <div className="statistics-icon">
                    <i className="fal fa-fw fa-comment-alt-check"></i>
                  </div>
                  <div className="statistics-text text-right">
                    <div className="desc">{ data?.feedback || '0' }</div>
                    <div className="title">
                      { consultantLang.reviews[lang] || consultantLang.reviews.en }
                    </div>
                  </div>
                </div>
              </Link>
            }

          </div>
        </div>
      </div>
    }
    </>
  );

}

const mapStateToProps = state => {
  return  {
    lang: state.defaultLang,
    user: state.auth.user,
    data: state.dashboard
  }
}

export default connect(mapStateToProps, { getDashboardData })(SecDashboard);