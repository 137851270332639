import React from "react";
import { interventionTooltipLang } from "../../../../../config/lang";
import TooltipSec from "../TooltipSec";
const Intervention = ({
  intervention,
  interventions,
  getName,
  setIntervention,
  search,
  lang,
  config,
}) => {
  return (
    <div className="form-group">
      <label className="control-label required">
        {config.interventionArea[lang] || config.interventionArea.en}
        <TooltipSec
          title={
            interventionTooltipLang.iVA[lang] || interventionTooltipLang.iVA.en
          }
        ></TooltipSec>
      </label>
      <div className="select-dropdown dropdown">
        <a
          href="#!"
          className="data-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {!intervention && (config.select[lang] || config.select.en)}
          {getName(intervention, interventions) ? (
            <span>{getName(intervention, interventions)}</span>
          ) : (
            ""
          )}
        </a>

        <div className="dropdown-menu intervention">
          <div className="dropdown-search">
            <input
              className="form-control"
              type="text"
              placeholder={config.search[lang] || config.search.en}
              onChange={(e) => search(e.target.value)}
            />
          </div>

          <div className="dropdown-list">
            {interventions &&
              interventions.map((el) => {
                return (
                  <div className="item" key={el.id}>
                    <div className="custom-radio intervention">
                      <input
                        type="radio"
                        className="custom-radio-input"
                        checked={el.id === intervention}
                        onChange={() => setIntervention(el.id)}
                      />
                      <label className="custom-radio-label">{el.name}</label>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intervention;
