import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getNationalities } from '../actions/cms';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import SecConsultantDetails from '../components/Consultant/SecConsultantDetails';

const ConsultantDetails = ({ 
  lang, details, siteSettings, languages, getNationalities, nationalities
}) => {
  const [loader, setLoader] = useState(false);
  const [consultantLoaded, setConsultantLoaded] = useState(false);
  const params = useParams();

  useEffect(() => {
    const ac = new AbortController();

    const loadNationalities = async () => {
      try {
        if(!nationalities.length) {
          await getNationalities({ lang }, ac.signal);
        }
      } catch(err) {
        console.log(err.message);
      } 
    }

    loadNationalities();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, nationalities.length ]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && consultantLoaded) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [consultantLoaded, languages.length, siteSettings]);

  // const id = new URLSearchParams(location.search).get('id');
  const id = params.id;
  
  return (
    <LayoutContainer>
      <Loader loaded={loader} />
      <SecConsultantDetails id={id} setConsultantLoaded={setConsultantLoaded} />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    details: state.consultants.consultantDetails,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
  }
}

export default connect(mapStateToProps, { getNationalities })(ConsultantDetails);