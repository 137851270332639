import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getHowItWorksList } from "../../actions/otherPages";
import Item from "./Item";
import CommonNavBarItem from "./CommonNavBarItem";

const HowItWorks = ({ list, getHowItWorksList, lang }) => {
  const pathname = window.location.pathname;
  const [isLoaded, setIsLoaded] = useState(false);
  const [listData, setListData] = useState("");

  const langConfig = {
    user: {
      en: "Client",
      es: "Cliente",
      it: "Cliente",
    },
    consultant: {
      en: "Consultant",
      es: "Consultor",
      it: "Consulente",
    },
  };
  const [commonNavItemData, setCommonNavItemData] = useState([
    {
      name: `${langConfig.user[lang] || langConfig.user.en}`,
      link: `/${lang}/how-it-works-user`,
      setIsFor: 1,
      iconClass: "fas fa-user",
    },
    {
      name: `${langConfig.consultant[lang] || langConfig.consultant.en}`,
      link: `/${lang}/how-it-works-consultant`,
      setIsFor: 2,
      iconClass: "fas fa-user-md-chat",
    },
  ]);
  const [isFor, setIsFor] = useState();
  useEffect(() => {
    commonNavItemData.map((item, i) => {
      pathname == item.link && setIsFor(item.setIsFor);
    });
  }, []);
  useEffect(() => {
    const ac = new AbortController();

    const loadList = async () => {
      try {
        if (!list.length) {
          await getHowItWorksList({ lang }, ac.signal);

          setIsLoaded(true);
        } else {
          setIsLoaded(true);
        }
        if (list.length) {
          const lists = list.sort((a, b) => {
            return a["ordering"] - b["ordering"];
          });
          setListData(lists);
        }
      } catch (err) {
        setIsLoaded(true);
        console.error(err.message);
      }
    };

    loadList();

    return () => ac.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, list.length]);
  return (
    <section className="sec-howitworks prefooter-last">
      <div className="container">
        <div className="universal-text-center">
          <ul className="universal-tabs-btn">
            {commonNavItemData.map((item, i) => {
              return (
                <CommonNavBarItem
                  key={i}
                  item={item}
                  lang={lang}
                  setIsFor={setIsFor}
                />
              );
            })}
          </ul>
        </div>
        <div className="how-it-works">
          {isLoaded && listData
            ? listData.map((item, i) => {
                if (item.is_for == isFor) {
                  return <Item key={i} item={item} />;
                }
              })
            : ""}
        </div>
      </div>
      <div className="prefooter-overlay">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#2f2f95"
            fillOpacity="1"
            d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    list: Object.values(state.others.howItWorks),
  };
};

export default connect(mapStateToProps, { getHowItWorksList })(HowItWorks);
