import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { hostUrl, noImage } from "../../config/host";
import moment from "moment";
const NotificationItem = ({ item, deleteNotification, user }) => {
  return (
    <div className="noti-item" id="">
      <a href="#!" className="img-container">
        <LazyLoadImage
          src={item.profile_image ? hostUrl + item.profile_image : noImage}
          alt={item.name}
          onError={(e) => (e.target.src = `${noImage}`)}
        />
      </a>
      <div className="noti-content">
        <a href="#!" className="noti-title" onClick={(e) => e.preventDefault()}>
          {item.name}
        </a>
        <a href="#!" className="noti-text" onClick={(e) => e.preventDefault()}>
          {item?.type === 2
            ? item?.message +
              " at " +
              moment(
                String(
                  new Date(
                    `${item?.appointment_start_time.replace(" ", "T")}`
                  ).toLocaleString("en-US", {
                    timeZone: user?.time_zone_name,
                  })
                )
              ).format("HH:mm A on DD MMM YYYY")
            : item?.message}
        </a>
        <a href="#!" className="noti-date" onClick={(e) => e.preventDefault()}>
          {moment(
            String(
              new Date(
                `${item?.notification_time.replace(" ", "T")}`
              ).toLocaleString("en-US", {
                timeZone: user?.time_zone_name,
              })
            )
          ).format("HH:mm A | DD MMM YYYY")}
        </a>
        <a
          href="#!"
          className="noti-close"
          onClick={(e) => {
            e.preventDefault();
            deleteNotification(item.id);
          }}
        >
          &times;
        </a>
      </div>
    </div>
  );
};

export default NotificationItem;
