import { GET_HOME_COUNT } from '../../actions/types';

const homeCountReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_HOME_COUNT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default homeCountReducer;