import { host } from "../config/host";
import { fetchData } from "./fetch";

export const postContactAsync = async data => {
  try {

    const res = await fetchData('POST', `${host}/contactus`, data);
    const resData = await res.json();
    return resData;
  } catch(err) {
    throw new Error(err.message);
  }
}