import React, { useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";
import { dailyLang, dailyscheduleTooltipLang } from "../../../../config/lang";
import { lang } from "../../../../config/lang";
import TooltipSec from "./TooltipSec";

const DailyScheduleForm = ({ item, index, state, setState, lang }) => {
  const onFieldChange = (e, state, index, field) => {
    setState({
      ...state,
      [index]: { ...state[index], [field]: e.target.value || "" },
    });
  };

  const onCheckChange = (e, state, index, field) => {
    setState({
      ...state,
      [index]: { ...state[index], [field]: e.target.checked },
    });
  };

  const convertAbsToTime = (numberValue) => {
    if (numberValue) {
      if (isNaN(Math.abs(numberValue))) return numberValue;

      let absValue = parseInt(numberValue);

      if (absValue.toString().length < 2) {
        // console.log(`0${absValue.toString()}:00`)
        return `0${absValue.toString()}:00`;
      }

      // console.log(`${absValue.toString()}:00`)
      return `${absValue.toString()}:00`;
    }

    return "";
  };

  useEffect(() => {
    $(`#scheduleFirstStart${index}`).datetimepicker({
      value: state[index]["firstFrom"]
        ? convertAbsToTime(state[index]["firstFrom"])
        : "",
      datepicker: false,
      format: "H:i",
      step: 1,
      lang: lang,
      onSelectTime: function (e) {
        if (e) {
          var value = moment(new Date(e)).format("HH:mm");
          setState({
            ...state,
            [index]: { ...state[index], firstFrom: value },
          });
        } else {
          setState({ ...state, [index]: { ...state[index], firstFrom: "" } });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // First to
  useEffect(() => {
    $(`#scheduleFirstTo${index}`).datetimepicker({
      value: state[index]["firstTo"],
      datepicker: false,
      format: "H:i",
      step: 1,
      lang: lang,
      onSelectTime: function (e) {
        if (e) {
          var value = moment(new Date(e)).format("HH:mm");
          setState({ ...state, [index]: { ...state[index], firstTo: value } });
        } else {
          setState({ ...state, [index]: { ...state[index], firstTo: "" } });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Second start
  useEffect(() => {
    $(`#scheduleSecondStart${index}`).datetimepicker({
      value: state[index]["secondFrom"],
      datepicker: false,
      format: "H:i",
      step: 1,
      lang: lang,
      onSelectTime: function (e) {
        if (e) {
          var value = moment(new Date(e)).format("HH:mm");
          setState({
            ...state,
            [index]: { ...state[index], secondFrom: value },
          });
        } else {
          setState({ ...state, [index]: { ...state[index], secondFrom: "" } });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Second to
  useEffect(() => {
    $(`#scheduleSecondTo${index}`).datetimepicker({
      value: state[index]["secondTo"],
      datepicker: false,
      format: "H:i",
      step: 1,
      lang: lang,
      onSelectTime: function (e) {
        if (e) {
          var value = moment(new Date(e)).format("HH:mm");
          setState({ ...state, [index]: { ...state[index], secondTo: value } });
        } else {
          setState({ ...state, [index]: { ...state[index], secondTo: "" } });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <div className="schedule_list">
      <input name="id" className="form-control" hidden />
      <input name="day" className="form-control" hidden />
      <input name="day_number" className="form-control" hidden />

      <div className="schedule-row row form-row">
        <div className="col-12">
          <div className="schedule-title">
            {dailyLang[item.dayName.toLowerCase()][lang]}
          </div>
        </div>
        <div className="col-12">
          <div className="row form-row">
            {/* First half */}
            <div className="col-md-6">
              <div className="schedule-half">
                {dailyLang.firstHalf[lang] || dailyLang.firstHalf.en}
              </div>
              <div className="row form-row">
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label required">
                      {dailyLang.startTime[lang] || dailyLang.startTime.en}
                      <TooltipSec
                        title={
                          dailyscheduleTooltipLang.startTime[lang] ||
                          dailyscheduleTooltipLang.startTime.en
                        }
                      ></TooltipSec>
                    </label>
                    <input
                      type="text"
                      className="form-control timepicker-1"
                      id={`scheduleFirstStart${index}`}
                      placeholder={
                        dailyLang.selectTime[lang] || dailyLang.selectTime.en
                      }
                      onChange={(e) =>
                        onFieldChange(e, state, index, "firstFrom")
                      }
                      value={state[index]["firstFrom"]}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label required">
                      {dailyLang.endTime[lang] || dailyLang.endTime.en}
                      <TooltipSec
                        title={
                          dailyscheduleTooltipLang.endTime[lang] ||
                          dailyscheduleTooltipLang.endTime.en
                        }
                      ></TooltipSec>
                    </label>
                    <input
                      type="text"
                      className="form-control timepicker-1"
                      id={`scheduleFirstTo${index}`}
                      placeholder={
                        dailyLang.selectTime[lang] || dailyLang.selectTime.en
                      }
                      onChange={(e) =>
                        onFieldChange(e, state, index, "firstTo")
                      }
                      value={state[index]["firstTo"]}
                    />
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <div className="form-group m-0">
                    <div className="checkbox-group">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox-input"
                          onChange={(e) =>
                            onCheckChange(e, state, index, "firstIsClose")
                          }
                          checked={state[index]["firstIsClose"]}
                        />
                        <div className="custom-checkbox-label">
                          {dailyLang.close[lang] || dailyLang.close.en}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second half */}
            <div className="col-md-6">
              <div className="schedule-half">
                {dailyLang.secondHalf[lang] || dailyLang.secondHalf.en}
              </div>
              <div className="row form-row">
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label required">
                      {dailyLang.startTime[lang] || dailyLang.startTime.en}
                      <TooltipSec
                        title={
                          dailyscheduleTooltipLang.startTime[lang] ||
                          dailyscheduleTooltipLang.startTime.en
                        }
                      ></TooltipSec>
                    </label>
                    <input
                      type="text"
                      className="form-control timepicker-1"
                      id={`scheduleSecondStart${index}`}
                      placeholder={
                        dailyLang.selectTime[lang] || dailyLang.selectTime.en
                      }
                      onChange={(e) =>
                        onFieldChange(e, state, index, "secondFrom")
                      }
                      value={state[index]["secondFrom"]}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label required">
                      {dailyLang.endTime[lang] || dailyLang.endTime.en}
                      <TooltipSec
                        title={
                          dailyscheduleTooltipLang.endTime[lang] ||
                          dailyscheduleTooltipLang.endTime.en
                        }
                      ></TooltipSec>
                    </label>
                    <input
                      type="text"
                      className="form-control timepicker-1"
                      id={`scheduleSecondTo${index}`}
                      placeholder={
                        dailyLang.selectTime[lang] || dailyLang.selectTime.en
                      }
                      onChange={(e) =>
                        onFieldChange(e, state, index, "secondTo")
                      }
                      value={state[index]["secondTo"]}
                    />
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <div className="form-group m-0">
                    <div className="checkbox-group">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox-input"
                          onChange={(e) =>
                            onCheckChange(e, state, index, "secondIsClose")
                          }
                          checked={state[index]["secondIsClose"]}
                        />
                        <div className="custom-checkbox-label">
                          {dailyLang.close[lang] || dailyLang.close.en}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
  };
};

export default connect(mapStateToProps, null)(DailyScheduleForm);
