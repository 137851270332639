import React,  { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import slugify from 'slugify';
import { removeFromWishlist } from '../../../actions/wishlist';
import { noImage } from '../../../config/host';
import { Link } from 'react-router-dom';
import history from '../../../config/history';
import { toast } from 'react-toastify';
const SingleWishList = ({ item, removeFromWishlist, lang }) => {
const [activeStatusClass,setActiveStatusClass]=useState('item-image');
useEffect(() => {
  if (item?.active_status==1) {
    setActiveStatusClass("item-image online");
  }else if(item?.active_status==2)
  {
    setActiveStatusClass("item-image offline");
  }
}, [item?.active_status]);
  console.log(item);
  const deleteWishlist = async (e) => {
    e.preventDefault(); 
    
    try {
      const message = await removeFromWishlist(item.consultant_id, item.wishlist_id);
      toast.success(message);
    } catch(err) {
      toast.error(err.message);
    }
  }

  const config = {
    remove: {
      en: 'Remove',
      es: 'Eliminar',
      it: 'Rimuovere'
    }
  }

  return (
    <div className="item">
      <div className={activeStatusClass}>
      <div className="item-image-wrap">
        <img alt={item.profile_name} src={item.profile_image || noImage} 
          onClick={() => history.push(`/${lang}/consultant-details/${item.consultant_id}/${slugify(item.profile_name.toLowerCase(), '-')}`)}
        />
      </div>
      </div>
      <Link className="item-content" 
        to={`consultant-details/${item.consultant_id}/${slugify(item.profile_name.toLowerCase(), '-')}`}
      >
        <div className="item-name">{ item.profile_name }</div>
        <div className="item-role">{ item.interventions }</div>
        <div className="item-language">{ item.language_names }</div>
      </Link>
      <a className="btn-remove" href="#!" onClick={deleteWishlist}>
        <i className="fal fa-minus-circle"></i>
        <span>{ config.remove[lang] || config.remove.en }</span>
      </a>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, { removeFromWishlist })(SingleWishList);
