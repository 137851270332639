import { GET_PAGES_BLOG } from "../../../actions/types";

const blogRecuder = (state = {}, action) => {
  switch(action.type) {
    case GET_PAGES_BLOG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default blogRecuder;