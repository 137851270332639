import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import {
  consultantReject,
  setLastAppointment,
  endAppointment,
  endAppointmentMailSend,
  refundAmount,
} from "../../actions/appointments";
import { updateActiveStatus } from "../../actions/profile";
import hangouts from "../../assets/audio/hangouts.mp3";
import disconnect from "../../assets/audio/disconnect.mp3";
import history from "../../config/history";
import { modalLang } from "../../config/lang";
import { toast } from "react-toastify";
const ConfirmModal = (props) => {
  const [consent, setConsent] = useState(false);
  const [consentPrivacy, setConsentPrivacy] = useState(false);

  const [disableConfirm, setDisableConfirm] = useState(false);
  const [disableCancel, setDisableCancel] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const closeRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const playAudio = () => {
      if (
        props.modalShown &&
        props.lastAppointment?.ids &&
        !props.lastAppointment?.appointment_end_time &&
        props.user?.role === "consultant" &&
        props.lastAppointment?.consultant_join === "2" &&
        !props.lastAppointment?.consultant_join_time &&
        !window.location.pathname.includes("/appointment/")
      ) {
        audioRef.current.muted = false;
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    };

    playAudio();

    // console.log(props.modalShown && !props?.appointment?.appointment_end_time)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.modalShown,
    props.lastAppointment?.appointment_end_time,
    props.user?.role,
    props.lastAppointment?.consultant_join,
  ]);

  useEffect(() => {
    if (
      props.lastAppointment?.consultant_join_time ||
      (props.lastAppointment?.appointment_type === "1" &&
        props.lastAppointment?.appointment_end_time)
    ) {
      audioRef.current.pause();
      closeRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.lastAppointment?.consultant_join_time,
    props.lastAppointment?.appointment_end_time,
  ]);

  const handleConfirm = async () => {
    setDisableCancel(true);

    if (!consent || !consentPrivacy) {
      toast.error(modalLang.yMaTtAC[props.lang] || modalLang.yMaTtAC.en);
      setDisableCancel(false);
      return;
    }

    try {
      if (props.user?.id && props.appointment) {
        closeRef.current.click();

        if (props.appointment.service_type === "1") {
          history.push(
            `/${props.lang}/appointment/${props.appointment.appointment_id}`
          );
        } else {
          const data = {
            user_id: props.user.id,
            consultant_id: props.appointment.consultant_id,
            appointment_id: props.appointment.appointment_id,
            consultant_join: "1",
            client_id: props.appointment.user_id,
            service_type: props.appointment.service_type,
            opentok_sessionId: localStorage.getItem("opentok_sessionId"),
            opentok_token: localStorage.getItem("opentok_token"),
          };

          if (props.appointment.service_type === "2") {
            props.socket.emit("callResponseConsultant", data);
            // history.push(`/${props.lang}/voice?appointment_id=${data.appointment_id}`);
          } else {
            props.socket.emit("callResponseConsultant", data);
            // history.push(`/${props.lang}/video?appointment_id=${data.appointment_id}`);
          }

          props.setModalShown(false);
          props.setLastAppointment({
            ...props.appointment,
            consultant_join: "1",
          });

          setDisableCancel(false);
        }
      }
    } catch (err) {
      setDisableCancel(false);
      console.log(err.message);
    }
  };

  // console.log(props.appointment)

  const handleCancel = async () => {
    setDisableConfirm(true);
    setSpinner(true);

    try {
      if (props.user?.id && props.appointment) {
        await props.consultantReject({
          lang: props.lang,
          appointmentId: props.appointment.appointment_id,
          consultantJoinTime: null,
        });

        props.socket.emit("appintmentDetails", {
          ...props.appointment,
          consultant_join: "3",
          status: "3",
          message: modalLang.cCaTa[props.lang] || modalLang.cCaTa.en,
        });

        // await props.refundAmount({
        // 	appointmentId: props.appointment.appointment_id, userId: props.user.id
        // });

        const data = {
          user_id: props.user.id,
          consultant_id: props.appointment.consultant_id,
          appointment_id: props.appointment.appointment_id,
          consultant_join: "3",
          client_id: props.appointment.user_id,
          service_type: props.appointment.service_type,
          opentok_sessionId: localStorage.getItem("opentok_sessionId"),
          opentok_token: localStorage.getItem("opentok_token"),
        };

        props.socket.emit("callResponseConsultant", data);

        await props.endAppointment(
          {
            lang: props.lang,
            appointmentEndTime: new Date().toISOString(),
            reject: "1",
            appointmentId: props.appointment.appointment_id,
            perMinuteAmount: props.appointment.per_minute_amount,
          },
          null,
          props.appointment.ids
        );
        props.endAppointmentMailSend(
          {
            lang: props.lang,
            appointment_id: props.appointment.appointment_id,
          },
          null,
          props.appointment.ids
        );

        new Audio(disconnect).play();
        audioRef.current.pause();
        closeRef.current.click();
        props.setModalShown(false);
        setSpinner(false);
        props.setLastAppointment({
          ...props.appointment,
          consultant_join: "3",
        });

        setConsent(false);
        setConsentPrivacy(false);
        setDisableConfirm(false);
      }
    } catch (err) {
      setDisableConfirm(false);
      setSpinner(false);
      console.log(err.message);
    }
  };

  const handleRedirectTerm = (e) => {
    e.preventDefault();

    // closeRef.current.click();
    // history.push(`/${props.lang}/terms-conditions`);
  };

  const handleRedirectPrivacy = (e) => {
    e.preventDefault();

    // closeRef.current.click();
    // history.push(`/${props.lang}/privacy-policy`);
  };

  return (
    <div
      className="conversation-modal confirm-modal"
      ref={props.confirmModalRef}
    >
      <div className="modal fade" id="confirm" tabIndex="-1">
        <audio muted loop className="d-none" ref={audioRef}>
          <source src={hangouts} />
        </audio>
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="modal-close d-none"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                style={{ pointerEvents: "none" }}
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
              <div className="subtitle">
                {props.appointment?.service_type === "2"
                  ? modalLang.startAudioConversationWith[props.lang] ||
                    modalLang.startAudioConversationWith.en
                  : props.appointment?.service_type === "3"
                  ? modalLang.startVideoConversationWith[props.lang] ||
                    modalLang.startVideoConversationWith.en
                  : props.appointment?.service_type === "1"
                  ? modalLang.startChatConversationWith[props.lang] ||
                    modalLang.startChatConversationWith.en
                  : modalLang.sCW[props.lang] || modalLang.sCW.en}
                {/* { modalLang.sCW[props.lang] || modalLang.sCW.en } */}
              </div>
              <div className="title">
                {props.appointment?.user_profile_name}
              </div>

              <div className="form-group checkbox-term mt-2 mb-0">
                <div className="checkbox-term-checkbox">
                  <input
                    className="checkbox-term-input"
                    name="checkbox-terms"
                    type="checkbox"
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                  />
                  <div className="checkbox-term-label"></div>
                </div>
                <div className="checkbox-term-text">
                  {modalLang.rA[props.lang] || modalLang.rA.en}{" "}
                  <Link
                    to={`/${props.lang}/terms-conditions`}
                    onClick={handleRedirectTerm}
                  >
                    <u>
                      {modalLang.informedConsent[props.lang] ||
                        modalLang.informedConsent.en}
                    </u>
                  </Link>{" "}
                  {modalLang.tPc[props.lang] || modalLang.tPc.en}
                </div>
              </div>

              <div className="form-group checkbox-term">
                <div className="checkbox-term-checkbox">
                  <input
                    className="checkbox-term-input"
                    name="checkbox-terms"
                    type="checkbox"
                    checked={consentPrivacy}
                    onChange={() => setConsentPrivacy(!consentPrivacy)}
                  />
                  <div className="checkbox-term-label"></div>
                </div>
                <div className="checkbox-term-text">
                  {modalLang.iAgree[props.lang] || modalLang.iAgree.en}{" "}
                  <Link
                    to={`/${props.lang}/privacy-policy`}
                    onClick={handleRedirectPrivacy}
                  >
                    <u>{modalLang.dApP[props.lang] || modalLang.dApP.en}</u>
                  </Link>
                </div>
              </div>

              <div className="communication-mode w-100">
                <div
                  className="communication"
                  style={{ width: "50%", maxWidth: "unset" }}
                >
                  {/* <input className="communication-input" name="communication-mode" type="radio" value=""/> */}
                  <button
                    className="communication-label bg-danger"
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={handleCancel}
                    disabled={disableCancel}
                  >
                    <div className="d-flex align-items-center">
                      <span>
                        {modalLang.cancel[props.lang] || modalLang.cancel.en}
                      </span>
                      {spinner && (
                        <div className="loader-spinner ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
                <div
                  className="communication"
                  style={{ width: "50%", maxWidth: "unset" }}
                >
                  <button
                    className="communication-label"
                    style={{
                      background: "var(--primaryColor)",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={handleConfirm}
                    disabled={disableConfirm}
                  >
                    {modalLang.confirm[props.lang] || modalLang.confirm.en}
                  </button>
                </div>
              </div>

              <div className="form-group form-group-actions d-none">
                <button className="btn-submit" type="button">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    socket: state.socket,
    user: state.auth.user,
    lastAppointment: state.appointments.lastAppointment,
  };
};

export default connect(mapStateToProps, {
  consultantReject,
  updateActiveStatus,
  setLastAppointment,
  endAppointment,
  endAppointmentMailSend,
  refundAmount,
})(ConfirmModal);
