import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { adminSideNavLang } from '../../config/lang';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecDashboard from '../../components/Admin/Sections/SecDashboard';

const Dashboard = ({
  user, nationalities, siteSettings, languages, timezones, dashboard, countries, lang
}) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      !$.isEmptyObject(siteSettings) && languages.length && timezones.length && countries.length;

      if(condition) {
        setLoader(true);
      }

      
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, dashboard, countries, loader ]);

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={ adminSideNavLang.dashboard[lang] || adminSideNavLang.dashboard.en }>
        <SecDashboard />
      </AdminContainer>
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    countries: Object.values(state.cms.countryList),
    // interventions: Object.values(state.cms.interventionSpecialization.interventions),
    // specializations: Object.values(state.cms.interventionSpecialization.specializations),
    // theraputicList: Object.values(state.cms.interventionSpecialization.theraputic),
    // mentalList: Object.values(state.cms.interventionSpecialization.mental),
    // psychologicalList: Object.values(state.cms.interventionSpecialization.psychological),
    // otherList: Object.values(state.cms.interventionSpecialization.others),
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps, null)(Dashboard);