import React from 'react';
import AppointmentReview from './AppointmentReview';

const Reviews = ({ appointment, reviewTab }) => {
  return (
    <div className={`tab-pane fade${reviewTab ? ' show active' : ''}`} id="nav-reviews" role="tabpanel" aria-labelledby="nav-reviews-tab">
      <div className="row">
        <div className="col-12">
          <AppointmentReview id={appointment.consultant_id} appId={appointment?.ids} 
            conId={appointment?.consultant_id} appointment={appointment}
          />
        </div>
      </div>
    </div>
  )
}

export default Reviews
