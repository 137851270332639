import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { langConfig, homeLang } from "../config/lang";
import { checkActiveUser } from "../common/checkActivity";
import { logout } from "../actions/auth";
import { useDispatch } from "react-redux";
const Footer = ({ siteSettings, lang, logout }) => {
  const [returnData, setReturnData] = useState(false);
  const siteSettingsLength = Object.keys(siteSettings).length;

  useEffect(() => {
    window.addEventListener(
      "mouseover",
      () => {
        var returnsData = checkActiveUser("browserCookie");
        if (returnsData) {
          const logoutUser = async () => await logout();
          logoutUser();
        }
      },
      false
    );
    window.addEventListener(
      "load",
      function () {
        const intervals = setInterval(() => {
          var returnsData = checkActiveUser("browserCookie", 2);
          if (returnsData) {
            const logoutUser = async () => await logout();
            logoutUser();
          }
        }, 1000);
      },
      false
    );

    window.addEventListener("keyup", () => {
      var returnsData = checkActiveUser("browserCookie");
      if (returnsData) {
        const logoutUser = async () => await logout();
        logoutUser();
      }
    });
    window.addEventListener("scroll", () => {
      var returnsData = checkActiveUser("browserCookie");
      if (returnsData) {
        const logoutUser = async () => await logout();
        logoutUser();
      }
    });
    window.addEventListener("click", () => {
      var returnsData = checkActiveUser("browserCookie");
      if (returnsData) {
        const logoutUser = async () => await logout();
        logoutUser();
      }
    });
    window.addEventListener("visibilitychange", () => {
      if (document.hidden) {
      } else {
        var returnsData = checkActiveUser("browserCookie");
        if (returnsData) {
          const logoutUser = async () => await logout();
          logoutUser();
        }
      }
    });
  }, [returnData]);

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="footer-content">
              <div className="title" style={{ textTransform: "uppercase" }}>
                {homeLang.aboutUs[lang] || homeLang.aboutUs.en}
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: siteSettings.description }}
              ></div>
            </div>
          </div>
          <div className="col-sm-7 col-md-7 col-lg-4">
            <div className="footer-content">
              <div className="title" style={{ textTransform: "uppercase" }}>
                {homeLang.quickLinks[lang] || homeLang.quickLinks.en}
              </div>
              <div className="footer-links">
                <Link to={`/${lang}`} className="link-item">
                  {homeLang.whatIsProntopsy[lang] ||
                    homeLang.whatIsProntopsy.en}
                </Link>
                <Link to={`/${lang}/faq-consultant`} className="link-item">
                  FAQ's
                </Link>
                <Link to={`/${lang}/privacy-policy`} className="link-item">
                  {langConfig.privacyPolicies[lang] ||
                    langConfig.privacyPolicies.en}
                </Link>
                <Link to={`/${lang}/about`} className="link-item">
                  {homeLang.aboutUs[lang] || homeLang.aboutUs.en}
                </Link>
                <Link to={`/${lang}/terms-conditions`} className="link-item">
                  {langConfig.termsOfServices[lang] ||
                    langConfig.termsOfServices.en}
                </Link>
                <Link to={`/${lang}/contact`} className="link-item">
                  {langConfig.contactUs[lang] || langConfig.contactUs.en}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-5 col-md-5 col-lg-3">
            <div className="footer-content">
              <div className="title">
                {langConfig.contact[lang] || langConfig.contact.en}
              </div>
              <div className="footer-contact mb-4">
                <div className="item">
                  <div className="label">
                    {langConfig.contact[lang] || langConfig.contact.en}
                  </div>
                  <div className="data">
                    <a
                      href={`tel:${
                        siteSettingsLength ? siteSettings.phone : ""
                      }`}
                      className="data-link"
                    >
                      {siteSettingsLength ? siteSettings.phone : ""}
                    </a>
                  </div>
                </div>
                <div className="item">
                  <div className="label">
                    {langConfig.email[lang] || langConfig.email.en}
                  </div>
                  <div className="data">
                    <a
                      href={`mailto:${
                        siteSettingsLength ? siteSettings.email : ""
                      }`}
                      className="data-link"
                    >
                      {siteSettingsLength ? siteSettings.email : ""}
                    </a>
                  </div>
                </div>
              </div>
              <div className="title mb-2">
                {homeLang.followUsOn[lang] || homeLang.followUsOn.en}
              </div>
              <div className="footer-social">
                <a
                  href={siteSettings.facebook_link}
                  className="item"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-fw fa-facebook"></i>
                </a>
                <a
                  href={siteSettings.twitter_link}
                  className="item"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-fw fa-twitter"></i>
                </a>
                <a
                  href={siteSettings.google_link}
                  className="item"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-fw fa-google"></i>
                </a>
                <a
                  href={siteSettings.instagram_link}
                  className="item"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-fw fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          {/* <div className="copyright">&copy; ProntoPsy.com 2021 | All rights & reserved</div> */}
          <div className="copyright">
            {siteSettings && siteSettings.copyright && siteSettingsLength
              ? siteSettings.copyright.replace(
                  /\d{4}/,
                  new Date().getFullYear()
                )
              : ""}
          </div>
          <div className="website">
            {homeLang.websiteBy[lang] || homeLang.websiteBy.en}{" "}
            <a
              href="https://www.symlinktech.com"
              target="_blank"
              rel="noreferrer"
            >
              Symlink Technologies LLP
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    siteSettings: state.cms.siteSettings,
  };
};

export default connect(mapStateToProps, { logout })(Footer);
