import React from 'react';
import { connect } from 'react-redux';
import SingleMessage from '../Children/SingleMessage';
import EmailFooter from '../Children/EmailFooter';

function SecEmailDetails({lang,emailDetails,user,emailId,receiverId,parentId,senderId,...props}) {
  const getSameSenderPrevMsg = (senderId, index) => (
		emailDetails.find((message, i) => (message?.sender_id === senderId && i < index)) || null
  )

  const config = {
    emailDetailsText: {
      en: 'Email Details',
      es: 'Detalles del correo electrónico',
      it: 'Dettagli e-mail'
    },
    tYm: {
      en: 'Type your Message...',
      es: 'Escribe tu mensaje...',
      it: 'Digita il tuo messaggio...'
    }
  }

  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-myaccount-header">
              <div className="lable-text">
                { config.emailDetailsText[lang] || config.emailDetailsText.en }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="message-details">
              <div className="message-body" ref={props.scrollRef}>
                {
                  (emailDetails.length && user?.id) ?
                  emailDetails.map((item, i) => {
                    const self = item?.sender_id?.toString() === user.id ? true : false;
                    
                    if(item && item?.description) {
                      return <SingleMessage key={item.id} email={item} self={self} 
                        prevMsg={getSameSenderPrevMsg(item.sender_id, i)}
                        acPrevMsg={i ? emailDetails[i - 1] : null}
                        nxtMsg={emailDetails[i + 1] || null}
                      />
                    } else {
                      return ''
                    }
                  })
                  : ''
                }
              </div>
              <EmailFooter emailId={emailId} user={user} receiverId={receiverId} 
                parentId={parentId} senderId={senderId} tYm={config.tYm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    lang: state.defaultLang,
    emailDetails: Object.values(state.emailDetails)
  }
}

export default connect(mapStateToProps, null)(SecEmailDetails)
