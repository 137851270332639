import { REGISTER, VERIFY_USER, VERIFY_OTP, LOGIN, LOGOUT, GET_PAYMENT_INFO } from "./types";
import { 
  createUser, 
  profileInfoAsync, 
  verifyOTPAsync, 
  loginAsync,
  forgetPasswordAsync,
  forgetPasswordOTPVerificationAsync,
  resetPasswordAsync,
  logoutAsync,
  socialLoginAsync
} from "../asynchronus/Auth";
import { updateActiveStatusAsync } from "../asynchronus/Profile";
import history from "../config/history";
// import { lang } from "../config/lang";

export const getProfileInfo = async userId => {
  try {
    
    
    const profileInfo = await profileInfoAsync(userId);

    if(profileInfo.status === 200) {
      return profileInfo;
    } else {
      throw new Error(profileInfo.message);
    }
    
  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyUser = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const stateAuth = state.auth;
    const userId = localStorage.getItem('userId') || null;

    if(stateAuth.loggedIn.status || userId) {
      const user = await getProfileInfo(userId);
      
      if(user.status === 200) {
        if(user?.payment_info) {
          dispatch({ type: GET_PAYMENT_INFO, payload: user?.payment_info });
        }

        dispatch({ type: VERIFY_USER, payload: user.data });
      } else {
        localStorage.removeItem('userId');
        history.push(`/${state.defaultLang}/login`);
        throw new Error(user.message);
      }
    }

    return null;

  } catch(err) {
    history.push('/');
    localStorage.removeItem('userId');
    throw new Error(err.message);
  }
}

export const signUp = user => async dispatch => {
  try {

    const userData = await createUser(user);

    if(userData.status === 200) {
      // const userId = userData.data.userId;
      // localStorage.setItem('userId', userId);
      dispatch({ type: REGISTER, payload: true });
      return userData;

    } else {
      throw new Error(userData.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyRegistration = (userId, otp) => async dispatch => {
  try {
    const data = await verifyOTPAsync(userId, otp);

    if(data.status === 200) {
      dispatch({ type: VERIFY_OTP, payload: data.data.userId });
      return data.message;
    } else {
      throw new Error(data.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const login = (email, password, rememberMe = false) => async (dispatch, getState) => {
  try {
    const { socket } = getState();
    const data = await loginAsync(email, password);
    
    if(data.status === 200) {
      const user = await getProfileInfo(data.userId);
      dispatch({ type: LOGIN, payload: user.data });

      if(socket?.io) {
        // const { auth } = getState();
        // const { user } = auth;
        if(user.data?.is_manual === '2' && user.data?.active_status === '2') {
          await updateActiveStatusAsync({ 
            lang: getState().defaultLang, userId: data.userId,
            activeStatus: '1'
          });

          if(user.data?.role === 'consultant') {
            socket.emit('updateConsultantStatus', { userId: user.data.id, activeStatus: '1' });
          }

          dispatch({ type: LOGIN, payload: { ...user.data, active_status: '1', is_manual: '2' } })
        }
      }

      if(user?.payment_info) {
        dispatch({ type: GET_PAYMENT_INFO, payload: user?.payment_info });
      }

      localStorage.setItem('userId', data.userId);

      if(rememberMe) {
        localStorage.setItem('rememberMe', '1');
        localStorage.setItem('userName', user.data.name);
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMeExpiryDate', new Date());
      } else {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('userName');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMeExpiryDate');
      }

      return { message: data.message, user };
    } else {
      dispatch({ type: LOGOUT, payload: true });
      throw new Error(data.message);
    }

  } catch(err) {
    dispatch({ type: LOGOUT, payload: true });
    throw new Error(err.message);
  }
}

export const socialLogin = body => async (dispatch, getState) => {
  try {
    const { socket } = getState();
    const res = await socialLoginAsync(body);
    
    if(res.status === 200) {
      if(res.data && res.data.user_id) {
        const user = await getProfileInfo(res.data.user_id.toString());
        dispatch({ type: LOGIN, payload: user.data });

        if(socket?.io) {
          if(user.data?.is_manual === '2' && user.data?.active_status === '2') {
            await updateActiveStatusAsync({ 
              lang: getState().defaultLang, userId: res.data.user_id,
              activeStatus: '1'
            });
  
            if(user.data?.role === 'consultant') {
              socket.emit('updateConsultantStatus', { userId: user.data.id, activeStatus: '1' });
            }
  
            dispatch({ 
              type: LOGIN, payload: { ...user.data, active_status: '1', is_manual: '2' } 
            });
          }
        }

        if(user?.payment_info) {
          dispatch({ type: GET_PAYMENT_INFO, payload: user?.payment_info });
        }

        localStorage.setItem('userId', res.data.user_id);
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('userName');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMeExpiryDate');
        return { message: res.message, user };
      }
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const forgetPassword = email => async dispatch => {
  try {
    const res = await forgetPasswordAsync(email);

    if(res.status === 200) {
      return res;
    } else {
      throw new Error(res.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const forgetPasswordOTPVerification = (otp, userId) => async dispatch => {
  try {
    const res = await forgetPasswordOTPVerificationAsync(otp, userId);
    
    if(res.status === 200) {
      return res;
    } else {
      throw new Error(res.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const resetPassword = (password, confirmPassword, userId) => async dispatch => {
  try {
    const res = await resetPasswordAsync(password, confirmPassword, userId);

    if(res.status === 200) {
      return res;
    } else {
      throw new Error(res.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const logout = () => async (dispatch, getState) => {
  const { socket, auth } = getState();
  const { user } = auth;

  if(localStorage.getItem('userId')) {
    const defaultLang = getState().defaultLang;

    try {
      if(socket?.io && user?.role === 'consultant') {
        socket.emit('updateConsultantStatus', { userId: user.id, activeStatus: '2' });
      }

      await logoutAsync({
        lang: defaultLang, userId: localStorage.getItem('userId'),
        activeStatus: '2', isManual: '2'
      });
    } catch(err) {
      console.log(err.message);
    }

    dispatch({ type: LOGOUT, payload: true });
    localStorage.removeItem('userId');
    // localStorage.removeItem('userName');
    // localStorage.removeItem('password');
    // localStorage.removeItem('rememberMe');
    
    window.location.href = `/${defaultLang}/login`;
  }
}