import { adminHost, host } from "../../config/host";
import { fetchData } from "../fetch";

export const updateProfileAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/personal-info-update`, user);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateFiscalInfoAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/fiscal-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateMembershipInfoAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/membership-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateInsuranceInfoAsync = async user => {

  try {
    const res = await fetchData('POST', `${adminHost}/insurance-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }

}

export const updateBiographyInfoAsync = async user => {

  try {
    const res = await fetchData('POST', `${adminHost}/biography-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }

}

export const updateLanguageInfoAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/language-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateInterventionInfoAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/intervention-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateAccountInfoAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/account-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const accountPasswordUpdateAsync = async body => {
  try {
    const res = await fetchData('POST', `${adminHost}/account-password-update`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateDailyScheduleInfoAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/dailyschedule-info-update`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateActiveStatusAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/update-status`, user);
    
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getConsultantServicesAsync = async (user, signal = null) => {
  try {
    const res = await fetchData(
      'POST', `${adminHost}/consultant-service-timetable-info`, user, signal
    )
    const data = await res.json();
    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateConsultantServicesAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/service-info-update`, user);
    const data = await res.json();
    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteConsultantServiceAsync = async user => {
  try {
    const res = await fetchData('POST', `${adminHost}/service-info-delete`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addReviewAsync = async user => {
  try {
    const res = await fetchData('POST', `${host}/review/review-add`, user);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getStripeCustomerAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/get-stripe-card`, body, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const closeAccountAsync = async body => {
  try {
    const res = await fetchData('POST', `${adminHost}/close-account`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteStripeAccountAsync = async body => {
  try {
    const res = await fetchData('POST', `${host}/delete-stripe-account`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}