import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { 
  getConsultantDetails, 
  getConsultantReviews, 
  clearConsultant 
} from '../../actions/consultant';
import ConversationModal from '../Modals/ConversationModal';
// import BookAppointmentModal from '../Modals/BookAppointmentModal';
import BookAppointmentModalAlt from '../Modals/BookAppointmentModalAlt';
import SidePanel from './SidePanel';
import DetailsPanel from './DetailsPanel';
import EmailModal from './EmailModal';

const SecConsultantDetails = ({ 
  details, getConsultantDetails, id, getConsultantReviews, reviews, user, lang, setConsultantLoaded
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReviewLoaded, setIsReviewLoaded] = useState(false);

  useEffect(() => {
    const loadDetails = async id => {
      try {
        if(!isLoaded) {
          await getConsultantDetails({ id, lang,userId:localStorage.getItem('userId',null) }); 
          setIsLoaded(true);
          setConsultantLoaded(true);
        }
      } catch(err) {
        console.error(err.message)
        setIsLoaded(true);
        setConsultantLoaded(true);
      }
    }

    loadDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang ]);

  useEffect(() => {
    const loadReviews = async () => {
      try {
        if(!isReviewLoaded) {
          await getConsultantReviews({ userId: id, limit: "2", offset: "0", lang }); 
          setIsReviewLoaded(true);
        }
      } catch(err) {
        console.error(err.message)
        setIsReviewLoaded(true);
      }
    }

    loadReviews(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang ]);
console.log(details)
  return (
    <>
    {/* MODAL STARTS */}
      <ConversationModal consultant={details} lang={lang} user={user} id={id} />
      {/* <BookAppointmentModal /> */}
      <BookAppointmentModalAlt consultant={details} lang={lang} user={user} id={id} />
      <EmailModal user={user} receiverId={id} />
    {/* MODAL ENDS */}

    <section className="sec-consultant-details prefooter-last">
      <div className="container mb-5">
        <div className="consultant-details">
          <SidePanel details={details} id={id} />
          <DetailsPanel isLoaded={isLoaded} details={details} reviews={reviews} id={id} isReviewLoaded={isReviewLoaded} lang={lang} />
        </div>
      </div>
			<div className="prefooter-overlay">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path fill="#2f2f95" fillOpacity="1" d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
				</svg>
			</div>
    </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    details: state.consultants.consultantDetails,
    reviews: Object.values(state.consultants.consultantReviews)
  }
}

export default connect(mapStateToProps, { 
  getConsultantDetails, getConsultantReviews, clearConsultant })
(SecConsultantDetails);