import { GET_BLOG_DETAIL, GET_BLOG_LIST } from "./types";
import { blogListAsync, blogDetailAsync } from "../asynchronus/Blog";

export const getBlogList = (defaultOptions = null, signal = null) => async dispatch => {
  try {

    const blogs = await blogListAsync(defaultOptions, signal);
    dispatch({ type: GET_BLOG_LIST, payload: blogs.data });
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getBlogDetail = (body, signal = null) => async dispatch => {
  try {
    const blog = await blogDetailAsync(body, signal);
    dispatch({ type: GET_BLOG_DETAIL, payload: blog.data });

  } catch(err) {
    throw new Error(err.message);
  }
}