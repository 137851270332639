import { GET_HOME_WHAT } from '../../actions/types';

const homeWhatReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_HOME_WHAT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default homeWhatReducer;