import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { useForm } from "react-hook-form";
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import LogoFacebook from '../../assets/img/logo_facebook.svg';
// import LogoGoogle from '../../assets/img/logo_google.svg';
import { Link } from "react-router-dom";
// import { appId, clientId } from '../../config/social';
import { login } from "../../actions/auth";
import { langConfig, homeLang } from "../../config/lang";
import SocialAuthLogin from "./SocialAuthLogin";
import PrefooterOverlay from "../../prefooterOverlay";
import { numDaysBetween } from "../../helpers";
import SecShowHidePassword from "./SecShowHidePassword";
// import { lang } from '../../config/lang';
import { toast } from "react-toastify";
const SecAuthLogin = ({ history, login, lang, authStatus, user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isPassword, setisPassword] = useState("password");
  const [spinner, setSpinner] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showRememberMe, setShowRememberMe] = useState(false);
  console.log(isPassword);
  useEffect(() => {
    if (
      localStorage.getItem("userName") &&
      localStorage.getItem("password") &&
      localStorage.getItem("email") &&
      localStorage.getItem("rememberMe") &&
      localStorage.getItem("rememberMeExpiryDate")
    ) {
      const expDate = new Date(localStorage.getItem("rememberMeExpiryDate"));
      const dateDiff = numDaysBetween(new Date(), expDate);

      if (dateDiff < 30) {
        setShowRememberMe(true);
        setRememberMe(true);
      }
    }
  }, []);

  const onSubmit = async (data) => {
    setSpinner(true);

    if (!Object.keys(errors).length) {
      try {
        await login(data.email, data.password, rememberMe);
        // localStorage.setItem('lang', result.user.data.default_lang);
        history.push(`/${lang}/dashboard`);
        // $.dialog({ title: 'Success', content: result.message });
        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  const config = {
    welcomeBack: {
      en: "Welcome back",
      es: "Bienvenido de nuevo",
      it: "Ben tornato",
    },
    useAnotherEmail: {
      en: "Use another email?",
      es: "¿Usar otro correo electrónico?",
      it: `Utilizzare un'altra email?`,
    },
    rememberMeText: {
      en: "Remember Me",
      es: "Recuérdame",
      it: "Ricordati di me",
    },
    login: {
      en: "Login",
      es: "Acceso",
      it: "Login",
    },
  };

  return (
    <section className="sec-authentication prefooter-last">
      <div className="authentication-wrap">
        <div className="authentication-content">
          <div className="title">
            <span>{config.login[lang] || config.login.en}</span>
          </div>

          {showRememberMe ? (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                {config.welcomeBack[lang] || config.welcomeBack.en},{" "}
                {localStorage.getItem("userName")}
              </div>
              <a
                href="#!"
                className="d-flex justify-content-center align-items-center mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  setRememberMe(false);
                  setShowRememberMe(false);
                }}
              >
                {config.useAnotherEmail[lang] || config.useAnotherEmail.en}
              </a>
              <div className="form-content mt-3">
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn-submit"
                    disabled={spinner}
                    onClick={() => {
                      onSubmit({
                        email: localStorage.getItem("email"),
                        password: localStorage.getItem("password"),
                      });
                    }}
                  >
                    <i className="fal fa-fw fa-sign-in"></i>
                    <div className="d-flex align-items-center">
                      <span>
                        {langConfig.login[lang] || langConfig.login.en}
                      </span>
                      {spinner && (
                        <div className="loader-spinner ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>

                <SocialAuthLogin lang={lang} />
              </div>
            </>
          ) : (
            <>
              <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <div className="form-input-group">
                    <div className="icon">
                      <i className="fal fa-fw fa-user"></i>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      autoComplete="new-email"
                      placeholder={
                        langConfig.email[lang] || langConfig.email.en
                      }
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-input-group">
                    <div className="icon">
                      <i className="fal fa-fw fa-key"></i>
                    </div>
                    <input
                      type={isPassword}
                      className="form-control"
                      autoComplete="new-password"
                      placeholder={
                        langConfig.password[lang] || langConfig.password.en
                      }
                      {...register("password")}
                    />
                    <SecShowHidePassword
                      setisPassword={setisPassword}
                      isPassword={isPassword}
                    />
                  </div>
                </div>
                <div className="form-options">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-checkbox-input"
                      name="checkbox-01"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <div className="custom-checkbox-label">
                      {config.rememberMeText[lang] || config.rememberMeText.en}
                    </div>
                  </div>
                  <Link
                    to={`/${lang}/forgot-password`}
                    className="btn-fogot-password"
                  >
                    {homeLang.forgotPassword[lang] ||
                      homeLang.forgotPassword.en}
                  </Link>
                </div>
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn-submit"
                    disabled={spinner}
                  >
                    <i className="fal fa-fw fa-sign-in"></i>
                    <div className="d-flex align-items-center">
                      <span>
                        {langConfig.login[lang] || langConfig.login.en}
                      </span>
                      {spinner && (
                        <div className="loader-spinner ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
                <SocialAuthLogin lang={lang} />
              </form>

              <div className="authentication-options">
                {homeLang.dontHaveAnAccount[lang] ||
                  homeLang.dontHaveAnAccount.en}{" "}
                <Link to={`/${lang}/register`}>
                  {homeLang.signupNow[lang] || homeLang.signupNow.en}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    authStatus: state.auth.loggedIn.status,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { login })(SecAuthLogin);
