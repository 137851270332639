import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import SecHomeCount from './Sections/SecHomeCount';

const About = ({ user, homeAbout, lang }) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    if(homeAbout?.description) {
      setDescription(homeAbout.description);
    }
  }, [ homeAbout ]);

  useEffect(() => {
    if(description) {
      const link = description.match(/(<a [^>]*(href="([^>^\\"]*)")[^>]*>)([^<]+)(<\/a>)/gi);

      if(user?.id && link && link[0]) {
        const newDescription = description.replace(link[0], '');
        setDescription(newDescription);
      } else if(!user?.id && link[0]) {
        const newDescription = description.replace(':LANG', lang);
        setDescription(newDescription);
      }
    }
  }, [ description, lang, user ]);
  
  return (
    <section className="sec-about">
      <div className="container">
        { description ?
          <div className="about-wrap">
            <div className="welcome" dangerouslySetInnerHTML={{ __html: description }}></div>
            <SecHomeCount />
          </div>
          : ''
        }
      </div>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    status: state.auth.loggedIn.status,
    user: state.auth.user,
    homeAbout: state.cms.pages.home
  }
}

export default connect(mapStateToProps, null)(About);