import React, { useState } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import TextareaAutosize from 'react-textarea-autosize';
import { setEmailDetail } from '../../../actions/email';
import { toast } from 'react-toastify';
const EmailFooter = ({ 
  emailId, user, receiverId, lang, parentId, senderId, socket, setEmailDetail, tYm
}) => {
  const [description, setDescription] = useState('');

  const sendEmail = async e => {
    e.preventDefault();

    try {
      if(socket?.io) {
        socket.emit('sendEmail', {
          lang, emailId, senderId: user.id, 
          receiverId: receiverId === user.id ? senderId : receiverId, 
          description, parentId, type: '2'
        });

        socket.on('get-last-email', (res) => {
          if(res.sender_id === user.id) {
            setEmailDetail(res.result[0]);
          }
        });
      }

      setDescription('');

      // $.dialog({ title: 'Success', content: message });
    } catch(err) {
      toast.error(err.message);
    }
  }

  return (
    <div className="message-footer">
      <form className="message-form" onSubmit={sendEmail}>
        <div className="input-wrap">
          <TextareaAutosize minRows={2} className="form-control"
            placeholder={ tYm[lang] || tYm.en }
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="input-action">
          <a href="#!" className="btn-action" onClick={sendEmail}>
            <i className="fas fa-fw fa-paper-plane"></i>
          </a>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    socket: state.socket
  }
}

export default connect(mapStateToProps, { setEmailDetail })(EmailFooter);
