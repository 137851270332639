import React, { useEffect } from "react";
import Tooltip  from 'react-tooltip';

const TooltipSec = ({ title }) => {
  return (
    <>
    <button 
    data-class="universal-tooltip"
     data-tip={title}
     data-for="global"
     type="button"
     className="universal-tooltip-icon"
        >
        <Tooltip 
          id="global"
          place='top'
          effect="solid"
          className="custom-color-no-arrow"
          getContent={(dataTip) => {
           return `${dataTip}`;
          }}
        html={true}
        delayHide={100}
        delayUpdate={100}
     />
          <i class="fad fa-info"></i>
        </button>



      {/* <Tooltip
        arrow={15}
        background="#2f2f95"
        border="None"
        color="#fff"
        content={title}
        fadeDuration={0}
        fadeEasing="linear"
        fixed={false}
        fontFamily="inherit"
        fontSize="inherit"
        offset={0}
        padding={10}
        placement="top"
        lineHeight={1}
        radius={5}
        zIndex={1}
      >
        
      </Tooltip> */}
    </>
  );
};

export default TooltipSec;
