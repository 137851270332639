import React, { useState } from 'react';
import ManageAppointment from './manageAppointment';
import Message from './message';

function AppointmentService() {
  const [join, setJoin] = useState(false);

  if(join) return <Message setJoin={setJoin} />

  return <ManageAppointment setJoin={setJoin} />
}

export default AppointmentService;