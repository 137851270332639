import { combineReducers } from "redux";
import homeServicesReducer from "./homeServices";
import homeOnlineServicesReducer from './homeOnlineServices';
import homeInterventionsReducer from "./homeInterventionsReducer";
import homeGettingStartedReducer from "./homeGettingStartedReducer";

import homeCountReducer from "./homeCount";
import homeBannerReducer from "./homeBanners";
import homeWhatReducer from "./homeWhatReducer";

export default combineReducers({
  homeServices: homeServicesReducer,
  homeOnlineServices: homeOnlineServicesReducer,
  homeInterventions: homeInterventionsReducer,
  homeGettingStarted: homeGettingStartedReducer,
  homeCount: homeCountReducer,
  

  banners: homeBannerReducer,
  homeWhat: homeWhatReducer
})