import mapKeys from 'lodash/mapKeys';
import { GET_COUNTRIES } from "../../actions/types";

const countryListReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_COUNTRIES:
      return { ...state, ...mapKeys(action.payload, 'iso2') };
    default:
      return state;
  }
}

export default countryListReducer;