import mapKeys from 'lodash/mapKeys';
import { GET_DAILY_TIMETABLE } from '../../actions/types';

const dailyTimetableReducer = (state = {}, action) =>{
  switch(action.type) {
    case GET_DAILY_TIMETABLE:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default dailyTimetableReducer;