import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { 
  getServices, 
  getInterventionSpecializationList, 
  
  getCountryList,
  getNationalities,
  getTimeZoneList,
  getProfessionalQualificationList
} from '../actions/cms';
import AdminTopNav from './Admin/AdminTopNav';
import AdminSideNav from './Admin/AdminSideNav';
import '../pages/admin/css/intervention.css';
import '../pages/admin/css/daily-schedule.css';
import PrefooterOverlay from '../prefooterOverlay';

const AdminContainer = props => {
  const [sideNavActive, setSideNavActive] = useState(false);

  // Service list
  useEffect(() => {
    const ac = new AbortController();
    const loadSettings = async () => {
      try {
        if(!props.serviceList.length) {
          await props.getServices(ac.signal);
        }
      } catch(err) {
        console.log(err.message);
      } 
    }

    loadSettings();

    // Clean up
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.serviceList.length ]);

  // Country list
  useEffect(() => {
    const ac = new AbortController();
    const loadSettings = async () => {
      try {
        if(!props.countryList.length) {
          await props.getCountryList(ac.signal);
        }
      } catch(err) {
        // console.log(err.message);
      } 
    }

    loadSettings();

    // Clean up
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.countryList.length ]);

  // Prof qua
  useEffect(() => {
    const ac = new AbortController();

    const loadLangDependencies = async () => {
      try {
        if(!props.profQua.length) {
          await props.getProfessionalQualificationList({ lang: props.lang }, ac.signal);
        }
      } catch(err) {
        // console.log(err.message);
      }
    }

    loadLangDependencies();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.lang, props.profQua.length ]);

  // Nationalities
  useEffect(() => {
    const ac = new AbortController();

    const loadLangDependencies = async () => {
      try {
        if(!props.nationalities.length) {
          await props.getNationalities({ lang: props.lang }, ac.signal)
        }
      } catch(err) {
        // console.log(err.message);
      }
    }

    loadLangDependencies();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.lang, props.nationalities.length ]);

  // Intervention
  useEffect(() => {
    const ac = new AbortController();

    const loadLangDependencies = async () => {
      try {
        if(!props.interventionList.length) {
          await props.getInterventionSpecializationList({ lang: props.lang }, ac.signal)
        }
      } catch(err) {
        // console.log(err.message);
      }
    }

    loadLangDependencies();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.lang, props.interventionList.length ]);

  return (
    <section className="sec-dashboard prefooter-last">
      <div className="container container-bg">
        <AdminTopNav title={props.title} setSideNavActive={setSideNavActive} />
        <div className="row position-relative">
          <AdminSideNav sideNavActive={sideNavActive} setSideNavActive={setSideNavActive} />
          { props.children }
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    serviceList: Object.values(state.cms.serviceList),
    nationalities: Object.values(state.cms.nationalities),
    countryList: Object.values(state.cms.countryList),
    interventionList: Object.values(state.cms.interventionSpecialization.interventions),
    profQua: Object.values(state.cms.professionalQualificatonList)
  }
}

export default connect(mapStateToProps, 
{ 
  getServices, 
  getNationalities,
  getCountryList,
  getTimeZoneList,
  getInterventionSpecializationList ,
  getProfessionalQualificationList
})(AdminContainer);