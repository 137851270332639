import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { resetPassword } from '../../actions/auth';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { langConfig, accountLang, homeLang } from '../../config/lang';
import PrefooterOverlay from '../../prefooterOverlay';
import { toast } from 'react-toastify';
import SecShowHidePassword from './SecShowHidePassword'
const SetResetPassword = ({ resetPassword, history, lang }) => {
  const [spinner, setSpinner] = useState(false);
  const location = useLocation();
  const userId = location.state?.userId;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isPassword,setisPassword]=useState('password');
  const [isConfirmPassword,setisConfirmPassword]=useState('password');

  const matchPasswords = (password, confirmPassword) => {
    if(password !== confirmPassword) return "Passwords do not match.";
    return null;
  } 

  const onSubmit = async data => {
    setSpinner(true);

    if(data?.newpassword && data?.confirmpassword) {
      const confirmPasswordErr = matchPasswords(data.newpassword, data.confirmpassword);

      if(confirmPasswordErr) {
        errors.confirmpassword = confirmPasswordErr;
      }
    }

    if(!Object.keys(errors).length && userId) {
      try {
        const res = await resetPassword(data.newpassword, data.confirmpassword, userId);
        history.push(`/${lang}/login`);
        setSpinner(false);
        toast.success(res.message);
      } catch(err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  }


  const config = {
    resetPassword: {
      en: 'Reset Password',
      es: 'Restablecer la contraseña',
      it: 'Resetta la password'
    }
  }

  return (
    <section className="sec-authentication prefooter-last">
      <div className="authentication-wrap">
        <div className="authentication-content">
          <div className="title">
            <span>
              { config.resetPassword[lang] || config.resetPassword.en }
            </span>
          </div>
          <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="form-input-group">
                <div className="icon"><i className="fal fa-fw fa-key"></i></div>
                <input type={isPassword} className="form-control" 
                  placeholder={ langConfig.password[lang] || langConfig.password.en }
                  { ...register('newpassword') }
                  
                />
                <SecShowHidePassword  setisPassword={setisPassword} isPassword ={isPassword}/>
              </div>
            </div>
            <div className="form-group">
              <div className="form-input-group">
                <div className="icon"><i className="fal fa-fw fa-key"></i></div>
                <input type={isConfirmPassword} className="form-control" 
                  placeholder={ 
                    accountLang.confirmPassword[lang] || accountLang.confirmPassword.en 
                  }
                  { ...register('confirmpassword') }
                />
                 <SecShowHidePassword  setisPassword={setisConfirmPassword} isPassword ={isConfirmPassword}/>
              </div>
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn-submit" disabled={spinner}>
                <i className="fal fa-fw fa-sign-in"></i>
                <div className="d-flex align-items-center">
                  <span>{ config.resetPassword[lang] || config.resetPassword.en }</span>
                  {
                    spinner &&
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </button>
            </div>
          </form>
          <div className="authentication-options">
            { homeLang.alreadyHaveAnAccount[lang] || homeLang.alreadyHaveAnAccount.en } <Link to="/login">{ homeLang.signinNow[lang] || homeLang.signinNow.en }</Link>
          </div>
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, { resetPassword })(SetResetPassword);