import React from 'react';
import moment from 'moment';

const SingleTimeSlot = ({ slot, index, timeSlot, setTimeSlot, timezones, user }) => {
  const timezone = timezones.find(zone => zone.id === user?.timezone)?.zone;

  const getOffset = timeString => timeString.slice(-5);

  const convertToTimezone = (currentDate, time, timeZone) => {
    let modifiedTime = time.replace(' AM', '');
    modifiedTime = modifiedTime.replace(' PM', '');

    let utcDateTime = moment(new Date(currentDate + ' ' + modifiedTime)).format();
    utcDateTime = utcDateTime.replace(getOffset(utcDateTime), '00:00');

    const convertedDate = new Date(utcDateTime).toLocaleString('en-US', { timeZone });

    return moment(new Date(convertedDate)).format('hh:mm A')
  }

  return (
    <div className={"slots"+ (slot.available === 2?" booked":"")} onClick={() => setTimeSlot(index)}>
      <input type="radio" className="slots-input" checked={timeSlot === index} 
        onChange={() => null} 
      />
      <div className="slots-range">
        <div className="range">
          { convertToTimezone(slot.date, slot.start, timezone) }
          <span>{ moment(new Date(slot.date)).format('DD MMM YYYY') }</span>
        </div>
        <div className="range">
          { convertToTimezone(slot.date, slot.end, timezone) }
          <span>{ moment(new Date(slot.date)).format('DD MMM YYYY') }</span>
        </div>
      </div>
    </div>
  )
}

export default SingleTimeSlot
