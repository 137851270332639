import React, { useState } from 'react';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import FileUpload from './FileUpload';

const ContentPanel = ({ 
  messages, lang, user, appointmentId, consultantId, appointment, scrollRef, seconds, rcv,
  setAppointmentEnded, setJoin
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [targetFile, setTargetFile] = useState(null);

  return (
    <div className="message-content-panel">
      <Header appointment={appointment} user={user} lang={lang} seconds={seconds} 
        setAppointmentEnded={setAppointmentEnded} setJoin={setJoin}
      />
      <Body messages={messages} lang={lang} user={user} listRef={scrollRef} 
        appointment={appointment} rcv={rcv}
      />
      <Footer lang={lang} user={user} appointmentId={appointmentId} consultantId={consultantId} 
        listRef={scrollRef}
        appointment={appointment}
        file={file} setFile={setFile} setTargetFile={setTargetFile}
        open={open} setOpen={setOpen}
      />
      <FileUpload targetFile={targetFile} setTargetFile={setTargetFile} 
        open={open} setOpen={setOpen} setFile={setFile}
      />
    </div>
  )
}

export default ContentPanel;