import { host } from "../../config/host";
import { fetchData } from "../fetch";

const lang = localStorage.getItem('lang') || 'en';

const howItWorksParams = {lang,limit:"10",offset:0,keyword:"",orderBy:"ordering",order:"ASC"};


export const howItWorksListAsync = async (body = howItWorksParams, signal = null) => {
  try {
    const params = !body ? howItWorksParams : { ...howItWorksParams, ...body };
    const res = await fetchData('POST', `${host}/works/list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

const testimonialListParams = {lang,limit:"10",start:"0",status:"1",orderBy:"",order:""};

export const testimonialListAsync = async (body = testimonialListParams, signal = null) => {
  try {
    const params = !body ? testimonialListParams : { ...testimonialListParams, ...body };
    const res = await fetchData('POST', `${host}/testimonials/list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const faqListAsync = async (body = null, signal = null) => {
  try {
    const defaultParams = {lang, limit:"100",start:"0",status:"1",keyword:"",orderBy:"id",order:"asc" };
    const params = !body ? defaultParams : { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/faqs/list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}