import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import SecAuthLogin from '../components/Auth/SecAuthLogin';

const Login = ({ history, nationalities, siteSettings, languages }) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [languages.length, siteSettings])

  return (
    <LayoutContainer>
      <Loader loaded={loader} />
      <SecAuthLogin history={history} />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages)
  }
}

export default connect(mapStateToProps, null)(Login);