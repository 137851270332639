import React from 'react';
import AccountNav from '../../components/Admin/Children/AccountNav';
import AccountHeading from '../../components/Admin/Children/AccountHeading';

const AccountContainer = props => {
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <AccountNav />
        <AccountHeading title={props.title} />
        { props.children }
      </div>
    </div>
  )
}

export default AccountContainer;