import mapKeys from 'lodash/mapKeys';
import { GET_BANNERS } from '../../actions/types';

const homeBannerReducer = (state = {}, action) => {
  switch(action.type) {
    
    case GET_BANNERS:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default homeBannerReducer;