/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const SectionBanner = ({ banners, search, setSearch, setImgLoader, lang }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [homeBanners, setHomeBanners] = useState(null);

  const [singleBanner, setSingleBanner] = useState(null);
  const manageSearch = (e) => { e.preventDefault(); setSearch(!search); }

  const config = {
    searchConsultants: {
      en: 'Search consultants',
      es: 'Buscar consultores',
      it: 'Ricerca Consulenti'
    }
  }
  
  useEffect(() => {
    const loadBanners = () => {
      if(!isLoaded && banners && banners.length) {
        const findBanners = banners.filter(el => el.page_id === "12");
        setHomeBanners(findBanners);
        setSingleBanner(findBanners[0]);
        setIsLoaded(true);
      }
    }

    loadBanners();
  }, [banners, isLoaded])

  return (
    <section className="sec-banner">
			<div className="banner-overlay">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path fill="#2f2f95" fillOpacity="1" d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
				</svg>
			</div>
      <OwlCarousel className="banner-slider owl-carousel"
        items={1}
        loop={false}
        rewind={true}
        margin={0}
        autoplay={true}
        autoplayTimeout={5000}
        autoplayHoverPause={false}
        lazyLoad={true}

        dots={false}
        nav={false}
        animateOut='fadeOut'
        animateIn='fateIn'
      >
        {
          homeBanners && homeBanners.map(item => {
            return (
              <div className="item" key={item.id}>
                <LazyLoadImage
                  // onLoad={() => setImgLoader(true)} 
                  src={item.image} 
                  alt={item.title} 
                  // className="owl-lazy"
                  afterLoad={() => setImgLoader(true)}
                  // loading="lazy"
                />
              </div>
            )
          })
        }
      </OwlCarousel>
      <div className="container">
        <div className="banner-content">
          <div className="banner-title">{ singleBanner ? singleBanner.title : '' }</div>
          <div className="banner-subtitle" dangerouslySetInnerHTML={{ __html: (singleBanner ? singleBanner.description : '') }}>  
          </div>
          { singleBanner && 
            <div className="banner-actions">
              <a href="#!" className="btn-container" onClick={manageSearch}>
                { config.searchConsultants[lang] || config.searchConsultants.en }
              </a>
            </div>
          }
        </div>
      </div>
    </section>
  );

}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    banners: Object.values(state.home.banners)
  }
}

export default connect(mapStateToProps, null)(SectionBanner);