import React, { useState, useEffect, Fragment } from "react";
import { interventionTooltipLang } from "../../../../../config/lang";
import TooltipSec from "../TooltipSec";
const Psychological = ({
  psychological,
  psychologicalList,
  setPsychological,
  allPsychological,
  search,
  config,
  lang,
}) => {
  const [names, setNames] = useState([]);
  const [disable, setDisabled] = useState(false);
  useEffect(() => {
    if (psychological.length) {
      setNames(getNames(psychological, psychologicalList));
    }
    if (psychological.length > 4) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [psychological, psychologicalList]);

  const manageCheck = (id, psychological) => {
    if (!psychological.length) {
      psychological.push(id);
      setPsychological([...psychological]);
      setNames([...getNames(psychological, psychologicalList)]);
    } else {
      const index = psychological.indexOf(id);

      if (index === -1) {
        psychological.push(id);

        setPsychological([...psychological]);
        setNames([...getNames(psychological, psychologicalList)]);
      } else {
        psychological.splice(index, 1);
        setPsychological([...psychological]);
        setNames([...getNames(psychological, psychologicalList)]);
      }
    }
  };

  const getNames = (ids, array) => {
    let names = [];
    if (ids && array && array.length) {
      array.forEach((item) => {
        item.sub.forEach((el) => {
          if (ids.includes(el.id)) names.push(el.name);
        });
      });

      return names;
    }

    return names;
  };

  const formatNames = (names) => {
    if (names.length) {
      if (names.length > 5) {
        return (
          <span>
            {names.length + ` ${config.selected[lang] || config.selected.en}`}
          </span>
        );
      } else {
        return names.map((el, i) => <span key={i}>{el}</span>);
      }
    }

    return null;
  };

  return (
    <div className="form-group">
      <label className="control-label">
        {config.psychologicalProblems[lang] || config.psychologicalProblems.en}
        <TooltipSec
          title={
            interventionTooltipLang.pP[lang] || interventionTooltipLang.pP.en
          }
        ></TooltipSec>
      </label>
      <div className="select-dropdown dropdown">
        <a
          href="#!"
          className="data-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {!names.length ? config.select[lang] || config.select.en : ""}
          {names.length ? formatNames(names) : ""}
        </a>

        <div className="dropdown-menu">
          <div className="dropdown-search">
            <input
              type="text"
              className="form-control"
              placeholder={config.search[lang] || config.search.en}
              onChange={(e) => search(e.target.value)}
            />
          </div>

          <div className="dropdown-list">
            {allPsychological.length
              ? allPsychological.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <div className="option-group-header">{item.name}</div>
                      {item.sub &&
                        item.sub.map((el) => {
                          return (
                            <div className="item" key={item.id + el.id}>
                              <div className="custom-checkbox" type="checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox-input"
                                  checked={psychological.includes(el.id)}
                                  onChange={() =>
                                    manageCheck(el.id, psychological)
                                  }
                                  disabled={
                                    !psychological.includes(el.id) && disable
                                  }
                                />
                                <label className="custom-checkbox-label">
                                  {el.name}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </Fragment>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Psychological;
