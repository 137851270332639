import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { updateBiographyInfo } from "../../../../actions/profile";

import SaveChanges from "../SaveChanges";
import { toast } from "react-toastify";
import { biographyTooltipLang } from "../../../../config/lang";
import TooltipSec from "./TooltipSec";
const BiographyForm = ({ auth, user, updateBiographyInfo, lang }) => {
  const [spinner, setSpinner] = useState(false);
  const { status } = auth;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const loadDefaults = (user) => {
      setValue("biographyShortDescription", user?.biography_short_description, {
        shouldValidate: true,
      });
      setValue("biographyLongDescription", user?.biography_long_description, {
        shouldValidate: true,
      });
    };

    loadDefaults(user);
  }, [setValue, user]);

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    if (!Object.keys(errors).length && userId && status) {
      try {
        const userData = { ...data, userId, lang };
        const message = await updateBiographyInfo(userData);
        setSpinner(false);
        toast.success(message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  const config = {
    shortDes: {
      en: "Short Description (Max 200 character)",
      es: "Descripción breve (máximo 200 caracteres)",
      it: "Breve descrizione (max 200 caratteri)",
    },
    short: {
      en: "Short Description",
      es: "Breve descripción",
      it: "breve descrizione",
    },
    longDes: {
      en: "Long Description (Max 800 character)",
      es: "Descripción larga (máximo 800 caracteres)",
      it: "Descrizione lunga (massimo 800 caratteri)",
    },
    long: {
      en: "Long Description",
      es: "descripción larga",
      it: "Descrizione lunga",
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="col-md-12">
          {/* Short Description */}
          <div className="form-group">
            <label className="control-label required">
              {config.shortDes[lang] || config.shortDes.en}
              <TooltipSec
                title={
                  biographyTooltipLang.sD[lang] || biographyTooltipLang.sD.en
                }
              ></TooltipSec>
            </label>
            <textarea
              className="form-control ng-pristine ng-valid ng-touched"
              maxLength="200"
              name="biography_short_description"
              placeholder={config.short[lang] || config.short.en}
              {...register("biographyShortDescription")}
            ></textarea>
          </div>
        </div>

        <div className="col-md-12">
          {/* Long Description */}
          <div className="form-group">
            <label className="control-label required">
              {config.longDes[lang] || config.longDes.en}
              <TooltipSec
                title={
                  biographyTooltipLang.lD[lang] || biographyTooltipLang.lD.en
                }
              ></TooltipSec>
            </label>
            <textarea
              className="form-control ng-pristine ng-valid ng-touched"
              maxLength="800"
              name="biography_long_description"
              placeholder={config.long[lang] || config.long.en}
              {...register("biographyLongDescription")}
            ></textarea>
          </div>
        </div>
      </div>

      <SaveChanges spinner={spinner} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { updateBiographyInfo })(BiographyForm);
