import { toast } from "react-toastify";
let base64String = "";

export const isImage = (file, errors = null, setImage, type = "image") => {
  if (file instanceof File && file.name) {
    const reader = new FileReader();
    const fileName = file.name;
    const allowedArray = ["jpg", "jpeg", "png", "gif", "ico", "jfif"];
    const fileType = fileName
      .split(".")
      [fileName.split(".").length - 1].toLowerCase();

    if (type === "image" && !allowedArray.includes(fileType) && errors) {
      errors.image = "File not a valid image.";
      toast.error("File not a valid image.");
    } else {
      if (errors && errors?.image) delete errors.image;
      reader.onload = () => {
        base64String = reader.result;
        const imageBase64Stringsep = base64String;
        setImage(imageBase64Stringsep);
      };
    }

    reader.readAsDataURL(file);
  }
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return 0;
  const k = 1024;
  return Math.trunc(bytes / 1024);
};
export const randomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const extractFileName = (link) => {
  if (link) {
    const linkParts = link.split("/");

    return linkParts.length ? linkParts[linkParts.length - 1] : "";
  }

  return "";
};

export const getExtension = (link) => {
  if (link) {
    const linkParts = link.split("/");

    const fileLink = linkParts.length ? linkParts[linkParts.length - 1] : "";

    if (fileLink) {
      const fileLinkParts = fileLink.split(".");
      return fileLinkParts.length
        ? fileLinkParts[fileLinkParts.length - 1]
        : "";
    }
  }

  return "";
};

// export const getBase64Image = async (img) => {
//   try {
//     const base64String = await imageToBase64(img);
//     console.log(base64String)
//     return base64String;
//   } catch(err) {
//     console.error(err.message);
//   }
// }

export const toHHMMSS = (seconds) => {
  return (
    ("0" + Math.floor(seconds / 3600)).slice(-2) +
    ":" +
    ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
    ":" +
    ("0" + (seconds % 60)).slice(-2)
  );
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
};

export const convertToTimezone = (date, timeZone) => {
  return new Date(date.toLocaleString("en-US", { timeZone }));
};

export const convertIsoTime = () => {
  let iso = new Date().toISOString();
  iso = iso.replace("Z", "");
  return new Date(iso);
};

export const convertFutureIsoTime = () => {
  let iso = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString();
  iso = iso.replace("Z", "");
  return new Date(iso);
};

export const numDaysBetween = (d1, d2) => {
  var diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
export const getSingleName = (lang, array) => {
  if (array && lang) {
    const item = array.find((el) => el.id === lang);
    return item?.name || null;
  }

  return null;
};
