import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { clearEmailDetails, setEmailList, setEmailDetail } from '../../actions/email';
import { adminSideNavLang } from '../../config/lang';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecEmail from '../../components/Admin/Sections/SecEmail';

const Email = ({ 
  user, nationalities, siteSettings, languages, timezones, countries, lang,
  clearEmailDetails, socket, setEmailList, setEmailDetail
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const ac = new AbortController();

    const loadEmails = async () => {
      clearEmailDetails();

      try {
        if(user && user?.id) {
          if(socket?.io) {
            socket.emit('getEmailData', { lang, userId: user.id, role: user.role });

            socket.on('get-email-data', (res) => {
              if(res && res.result && res.result.length) {
                if(res.user_id === user?.id) {
                  setEmailList(res.result);
                }
              }

              setIsLoaded(true);
            });

            // socket.on('get-last-email', (res) => {
            //   if(res.receiver_id === user.id || res.sender_id === user.id) {
            //     socket.emit('getEmailData', { lang, userId: user.id, role: user.role });
            //   }
            // });
          }
        }
      } catch(err) {
        setIsLoaded(true);
        console.error(err.message);
      }
    }

    loadEmails();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.id, socket ]);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      Object.keys(siteSettings).length && languages.length && timezones.length && countries.length
      

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries ]);

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={ adminSideNavLang.email[lang] || adminSideNavLang.email.en }>
        <SecEmail isLoaded={isLoaded} config={adminSideNavLang} lang={lang} />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList)
  }
}

export default connect(mapStateToProps, { clearEmailDetails, setEmailList, setEmailDetail })(Email);