import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import $ from "jquery";
import { contactLang, accountLang } from "../../config/lang";
import { postContact } from "../../actions/otherPages";
import { toast } from "react-toastify";

const SecContactUs = ({ settings, history, lang, contact }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [spinner, setSpinner] = useState(false);

  const onSubmit = async (data) => {
    setSpinner(true);

    if (!Object.keys(errors).length) {
      try {
        const res = await postContact(data);
        setSpinner(false);
        setValue("name", "");
        setValue("phone", "");
        setValue("email", "");
        setValue("subject", "");
        setValue("message", "");
        toast.success(res.message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };
  console.log(contact);
  return (
    <section className="sec-contact-us prefooter-last">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="contact-form">
              {/*  */}
              {contact.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: contact.description }}
                ></div>
              ) : (
                <>
                  {/* <h3>
                { contactLang.getInTouch[lang] || contactLang.getInTouch.en }
              </h3>
              <p>
                { contactLang.manyVariations[lang] || contactLang.manyVariations.en }
              </p> */}
                </>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      placeholder={
                        accountLang.name[lang] || accountLang.name.en
                      }
                      {...register("name")}
                      defaultValue={getValues("name")}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      placeholder={
                        accountLang.phone[lang] || accountLang.phone.en
                      }
                      defaultValue={getValues("phone")}
                      {...register("phone")}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="email"
                      placeholder={
                        accountLang.email[lang] || accountLang.email.en
                      }
                      defaultValue={getValues("email")}
                      {...register("email")}
                    />
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="text"
                      placeholder={
                        accountLang.subject[lang] || accountLang.subject.en
                      }
                      defaultValue={getValues("subject")}
                      {...register("subject")}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      name="massage"
                      cols="30"
                      rows="10"
                      placeholder={
                        accountLang.yourMessage[lang] ||
                        accountLang.yourMessage.en
                      }
                      defaultValue={getValues("message")}
                      {...register("message")}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-form-submit">
                      <div className="d-flex align-items-center">
                        <span>
                          {accountLang.sendMessage[lang] ||
                            accountLang.sendMessage.en}
                        </span>
                        {spinner && (
                          <div className="loader-spinner ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="contact-page-info">
              <h3>
                {accountLang.officeAddress[lang] ||
                  accountLang.officeAddress.en}
              </h3>
              <ul className="contact-info">
                <li>
                  {settings.address && (
                    <>
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {settings.address}
                    </>
                  )}
                </li>
                <li>
                  {settings.email && (
                    <>
                      <i className="fa fa-envelope"></i> {settings.email}{" "}
                    </>
                  )}
                </li>
                <li>
                  {settings.phone && (
                    <>
                      <i className="fa fa-phone"></i> {settings.phone}{" "}
                    </>
                  )}
                </li>
              </ul>
              <h3>{accountLang.followUs[lang] || accountLang.followUs.en}</h3>
              <ul className="social-icon">
                {Object.keys(settings).length ? (
                  <>
                    <li>
                      <a href={settings.facebook_link}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.twitter_link}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.google_link}>
                        <i className="fab fa-google"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.instagram_link}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="prefooter-overlay">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#2f2f95"
            fillOpacity="1"
            d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    settings: state.cms.siteSettings,
    contact: state.cms.pages.contact,
  };
};

export default connect(mapStateToProps, null)(SecContactUs);
