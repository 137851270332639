import { host } from "../config/host";
import { fetchData } from "../asynchronus/fetch";

export const onlineOffline = async (signal = null) => {
  try {
    const userId = localStorage.getItem('userId') || null;
    const res = await fetchData('POST', `${host}/online-offline`, {userId:userId}, signal);
    await res.json();
  } catch(err) {
    console.log(err);
  }
}