import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { langConfig } from '../../config/lang';
import slugify from 'slugify';
// import { lang } from '../../config/lang';

const SingleBlog = ({ blog, lang }) => {
  return (
    <div className="blog-item">
      <div className="item-image">
				<LazyLoadImage src={blog.logo} alt={blog.name} />
				<div className="item-date">
					<i className="fal fa-fw fa-calendar-alt"></i>
					<span>{ blog.publishDate }</span>
				</div>
				<div className="item-overlay">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
						<path fill="#2f2f95" fillOpacity="1" d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
					</svg>
				</div>
			</div>
      <div className="item-content">
        <div className="item-title">{ blog.name }</div>
        <div className="item-text" dangerouslySetInnerHTML={{ __html: blog.short_description }}></div>
				<div className="item-action">
        	<Link to={`/${lang}/blog-details/${blog.id}/${slugify(blog.name.toLowerCase(), '-')}`} className="item-link">
						{ langConfig.readMore[lang] || langConfig.readMore.en }
					</Link>
				</div>
      </div>
    </div>
  )
}

export default SingleBlog
