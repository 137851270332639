import { GET_EMAIL_LIST, DELETE_EMAIL, GET_EMAIL_DETAILS, CLEAR_EMAIL_DETAILS, GET_EMAIL_DETAIL } from "./types";
import { 
  getEmailListAsync, 
  deleteEmailAsync, 
  sendEmailAsync,
  getEmailDetailsAsync
} from "../asynchronus/Emails";

export const getEmailList = (user, signal = null) => async dispatch => {
  try {
    const emails = await getEmailListAsync(user, signal);

    if(emails.status === 200) {
      dispatch({ type: GET_EMAIL_LIST, payload: emails.data });
    } else {
      throw new Error(emails.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const setEmailList = body => dispatch => {
  dispatch({ type: GET_EMAIL_LIST, payload: body });
}

export const deleteEmail = emailDetails => async dispatch => {
  try {
    const email = await deleteEmailAsync(emailDetails);

    if(email.status === 200) {
      dispatch({ type: DELETE_EMAIL, payload: emailDetails.emailId });
      return email.message;
    } else {
      throw new Error(email.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const removeEmail = emailId => dispatch => {
  dispatch({ type: DELETE_EMAIL, payload: emailId });
}

export const sendEmail = (body, signal = null) => async dispatch => {
  try {
    const email = await sendEmailAsync(body, signal);

    if(email.status === 200) {
      const emails = await getEmailListAsync({ lang: body.lang, userId: body.senderId });
      dispatch({ type: GET_EMAIL_LIST, payload: emails.data });
    } else {
      throw new Error(email.message);
    }

    return email.message;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const sendEmailDetails = (body, signal = null) => async dispatch => {
  try {
    const email = await sendEmailAsync(body, signal);

    const emailDetails = await getEmailDetailsAsync({ 
      lang: body.lang, userId: body.senderId, emailId: body.emailId
    });

    dispatch({ type: GET_EMAIL_DETAILS, payload: emailDetails.data });
    
    return email.message;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const setEmailDetail = email => dispatch => {
  dispatch({ type: GET_EMAIL_DETAIL, payload: email });
}

export const getEmailDetails = (body, signal = null) => async dispatch => {
  try {
    const emails = await getEmailDetailsAsync(body, signal);

    if(emails.status === 200) {
      dispatch({ type: GET_EMAIL_DETAILS, payload: emails.data });
    } else {
      throw new Error(emails.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const setEmailDetails = body => dispatch => {
  dispatch({ type: GET_EMAIL_DETAILS, payload: body });
}

export const clearEmailDetails = () => dispatch => {
  dispatch({ type: CLEAR_EMAIL_DETAILS, payload: null });
}