import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setLastAppointment } from "../actions/appointments";
import { convertIsoTime } from "../helpers";
import TopHeader from "../components/Headers/TopHeader";
import BottomHeader from "../components/Headers/BottomHeader";
import ConfirmModal from "../components/Modals/ConfirmModal";
// import { useElementOnScreen } from '../hooks/useElementOnScreen';
import { getAppointments } from "../actions/appointments";
import { modifyAppointments } from "../actions/appointments";
import { useState } from "react";
const Header = ({
  admin,
  lastAppointment,
  getAppointments,
  modalShown,
  setModalShown,
  setLastAppointment,
  user,
}) => {
  const confirmModalInit = useRef(null);
  const confirmModalRef = useRef(null);
  const [updatedAppointment, SetUpdatedAppointment] = useState();
  //const isVisible = useElementOnScreen(confirmModalRef);
  // useEffect(() => {
  //   const ac = new AbortController();
  //   let interval

  //   const loadAppointments = async () => {
  //     if (user && user?.id) {
  //       try {
  //         const data = await getAppointments(
  //           {
  //             userId: user.id,
  //             startDate: "",
  //             endDate: "",
  //             limit: "",
  //             offset: "0",
  //           },
  //           ac.signal
  //         );
  //         SetUpdatedAppointment(data);
  //         return data
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     }
  //   };

  //   loadAppointments().then(data => {
  //     interval = setInterval(() => {
  //       modifyAppointments(
  //         data,
  //         new Date(
  //           new Date(new Date().toISOString()).toLocaleString("en-US", {
  //             timeZone: "UTC",
  //           })
  //         )
  //       );
  //     }, 1000);
  //   }).catch(err => console.log(err))

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.id]);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      modifyAppointments(
        updatedAppointment,
        new Date(
          new Date(new Date().toISOString()).toLocaleString("en-US", {
            timeZone: "UTC",
          })
        )
      );
    }, 5000);
  }, [updatedAppointment]);

  useEffect(() => {
    if (
      lastAppointment?.ids &&
      !lastAppointment?.appointment_end_time &&
      lastAppointment?.consultant_join === "2" &&
      !lastAppointment?.consultant_join_time &&
      !window.location.pathname.includes("/appointment/") &&
      user?.role === "consultant"
    ) {
      if (modalShown) {
        confirmModalInit.current.click();
        console.log("clicked");
        //setModalShown(false);
      }
    } else if (
      lastAppointment?.ids &&
      lastAppointment?.appointment_end_time &&
      new Date(lastAppointment?.appointment_end_time) >= convertIsoTime() &&
      lastAppointment?.consultant_join === "2" &&
      lastAppointment?.show &&
      !lastAppointment?.consultant_join_time &&
      !window.location.pathname.includes("/appointment/") &&
      user?.role === "consultant"
    ) {
      if (!modalShown) {
        setModalShown(true);
      }

      if (modalShown) {
        confirmModalInit.current.click();
        //setModalShown(false);
      }
      setLastAppointment({ ...lastAppointment, show: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalShown,
    user?.role,
    lastAppointment?.ids,
    lastAppointment?.show,
    lastAppointment?.consultant_join_time,
  ]);

  return (
    <>
      <header>
        <TopHeader />
        <BottomHeader admin={admin} />
        <div className="menu-backdrop"></div>
      </header>

      <a
        className="d-none"
        href="#!"
        data-toggle="modal"
        data-target="#confirm"
        data-keyboard="false"
        data-backdrop="static"
        ref={confirmModalInit}
      >
        Email Me
      </a>
      <ConfirmModal
        appointment={lastAppointment}
        modalShown={modalShown}
        setModalShown={setModalShown}
        confirmModalRef={confirmModalRef}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    lastAppointment: state.appointments.lastAppointment,
  };
};

export default connect(mapStateToProps, {
  setLastAppointment,
  getAppointments,
})(Header);
