import { DEFAULT_LANG } from "../../actions/types";

const langReducer = (state = localStorage.getItem('lang') || 'en', action) => {
  switch(action.type) {
    case DEFAULT_LANG:
      return action.payload;
    default:
      return state;
  }
}

export default langReducer;