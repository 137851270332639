import { host } from '../../config/host';
import { fetchData } from '../fetch';

export const getSeoDetailsAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/seo`, body, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    return { status: 400, message: err.message };
  }
}