import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBlogList } from '../../actions/blog';
import { langConfig } from '../../config/lang';
import SingleBlog from '../Blog/SingleBlog';

const Blog = ({ getBlogList, blogs, lang }) => {
  useEffect(() => {
    const ac = new AbortController();

    const loadBlogs = async () => {
      if(!blogs.length) {
        try {
          await getBlogList({ limit: "3", lang }, ac.signal);
        } catch(err) {
          console.log(err.message);
        }
      }
    }

    loadBlogs();

    return () => ac.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, blogs.length ]);

  return (
    <section className="sec-blog prefooter-last">
        <>
      { blogs && blogs.length>0 &&
      <div className="container">
        <div className="section-title">
          <h1 className="title">Blog</h1>
        </div>
        <div className="blog-wrap">
          { blogs && blogs.map(blog => <SingleBlog key={blog.id} blog={blog} lang={lang} />) }
        </div>
        <div className="blog-actions">
          <Link to={`/${lang}/blog`} className="btn-action">
            { langConfig.viewMore[lang] || langConfig.viewMore.en }
          </Link>
        </div>
      </div>
      }     
			<div className="prefooter-overlay">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path fill="#2f2f95" fillOpacity="1" d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
				</svg>
			</div>
      </>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    blogs: Object.values(state.blogs.blogList)
  }
}

export default connect(mapStateToProps, { getBlogList })(Blog);