import mapKeys from 'lodash/mapKeys';
import { GET_THERAPUTIC_LIST } from '../../../actions/types';

const theraputicReducer = (state = {}, action) => {
  switch(action.type) {

    case GET_THERAPUTIC_LIST:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default theraputicReducer;