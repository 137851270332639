import React from 'react';
import { noImage } from '../../../config/host';

const SingleMessage = ({ email, self, acPrevMsg }) => {
  return (
    <div className={`message message-${self ? 'right' : 'left'} ${acPrevMsg && acPrevMsg.sender_id === email.sender_id ? 'mt-0' : ''}`}>
      <div className="message-image">
        <img alt={email.sender_name} src={email.sender_image} 
          onError={(e) => e.target.src=`${noImage}`}
          className={acPrevMsg && acPrevMsg.sender_id === email.sender_id ? ' d-none' : ''}
        />
      </div>
      <div className="message-content">
        <div className={`message-user${acPrevMsg && acPrevMsg.sender_id === email.sender_id ? ' d-none' : ''}`}>
          { email.sender_name }
        </div>
        <div className="message-text-list">
          <div className="message-text-wrap">
            <div className="message-text">{ email.description }</div>
          </div>
        </div>
        <div className="message-date d-none">07:39 AM | Dec 15</div>
      </div>
    </div>
  )
}

export default SingleMessage
