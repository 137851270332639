import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { forgetPasswordOTPVerification } from '../actions/auth';
import LayoutContainer from '../containers/LayoutContainer';
import SecForgotPassword from '../components/Sections/SecForgotPassword';
import SecOTPVerification from '../components/Auth/SecOTPVerification';

const ForgotPassword = ({ history, forgetPasswordOTPVerification }) => {

  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(null);

  return (
    <LayoutContainer>
      {!status && <SecForgotPassword setStatus={setStatus} setUserId={setUserId} 
        setEmail={setEmail}  
      />}
      {status && 
        <SecOTPVerification history={history} id={userId}
          forgetPasswordOTP={forgetPasswordOTPVerification} email={email}
        />
      }
    </LayoutContainer>
  );
}

export default withRouter(connect(null, { forgetPasswordOTPVerification })(ForgotPassword));