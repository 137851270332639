import { host } from "../../config/host";
import { fetchData } from "../fetch";

export const wishlistAsync = async (body = null, signal = null) => {
  try {

    const res = await fetchData('POST', `${host}/wishlist`, 
      { userId: localStorage.getItem('userId'), ...body }, signal
    );

    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addToWishlistAsync = async (consultantId, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/wishlist-add-remove`, 
      { userId: localStorage.getItem('userId'), consultantId }, signal
    );

    
    const data = await res.json();
    
    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}