import React from 'react';

const Banner = ({ banner, mainBanner }) => {
  return (
    <section className="page-banner-alt" 
      style={{ backgroundImage: `url(${banner?.logo})` }}
    >
      <div className="container">
        <div className="banner-content">
          <div className="banner-title">{banner?.name}</div>
          <div className="banner-text" dangerouslySetInnerHTML={{ __html: banner?.description }}></div>
        </div>
      </div>
    </section>
  );
}

export default Banner;