import React from 'react';
import { connect } from 'react-redux';
import { isImage } from '../../helpers';

const FileUpload = ({ targetFile, setTargetFile, setFile, open, setOpen, lang }) => {
  const closeBtn = e => {
    e.preventDefault();

    setOpen(false);
  }

  const handleFileChange = e => {
    e.preventDefault();

    if(e.target.files.length) {
      isImage(e.target.files[0], null, setFile, 'file');
      setTargetFile(e.target.files[0]);
    }
  }

  const removeFile = e => {
    e.preventDefault();

    setFile('');
    setTargetFile(null);
  }

  const config = {
    attachFile: {
      en: 'Attach File',
      es: 'Adjuntar archivo',
      it: 'Allega file'
    },
    remove: {
      en: 'Remove',
      es: 'Eliminar',
      it: 'Rimuovere'
    },
    addFile: {
      en: 'Add File',
      es: 'Agregar archivo',
      it: 'Aggiungi file'
    },
    done: {
      en: 'Done',
      es: 'Hecho',
      it: 'Fatto'
    }
  }

  return (
    <div className={`message-file-upload ${open ? 'active' : ''}`}
      style={{ zIndex: '7' }}
    >
      <div className="file-upload-header">
        <div className="title">
          { config.attachFile[lang] || config.attachFile.en }
        </div>
        <a href="#!" className="btn-close" onClick={closeBtn}>
          <i className="fal fa-fw fa-times"></i>
        </a>
      </div>
      <div className="attached-files">
        {
          targetFile && 
          <div className="file" title={targetFile.name}>
            <div className="file-icon">
              <i className="fad fa-fw fa-swap-opacity fa-file-image"></i>
            </div>
            <div className="file-info">
              <div className="file-name">{ targetFile?.name }</div>
              {/* <div className="file-size">{ targetFile?.size }</div> */}
            </div>
            <div className="file-action" onClick={removeFile}>
              <a href="#!" className="btn-action">
                { config.remove[lang] || config.remove.en }
              </a>
            </div>
          </div>
        }

        {!targetFile && 
          <div className="add-file" title="Add File">
            <input type="file" className="input-file" onChange={handleFileChange} />
            <i className="fal fa-fw fa-plus"></i>
            <span>
              { config.addFile[lang] || config.addFile.en }
            </span>
          </div>
        }
      </div>

      <div className="file-upload-footer">
        <a href="#!" className="btn-upload" onClick={closeBtn}>
          {/* <i className="fal fa-fw fa-cloud-upload"></i> */}
          <span>{ config.done[lang] || config.done.en }</span>
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(FileUpload);