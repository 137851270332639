import mapKeys from 'lodash/mapKeys';
import { GET_NATIONALITIES } from '../../actions/types';

const nationalityReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_NATIONALITIES:
      return { ...state, ...mapKeys(action.payload, 'id') }
    default:
      return state;
  }
}

export default nationalityReducer;
