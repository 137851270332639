import {
  stripeConnectAsync,
  addCardAsync,
  deleteCardAsync,
} from "../asynchronus/payment";
import { getProfileInfo } from "./auth";
import { UPDATE_PROFILE } from "./types";

export const stripeConnect =
  (body, signal = null) =>
  async (dispatch, getState) => {
    try {
      const connect = await stripeConnectAsync(body, signal);

      if (connect.status === 200) {
        const profileInfo = await getProfileInfo(getState().auth.user.id);
        dispatch({ type: UPDATE_PROFILE, payload: profileInfo });
        return connect.data;
      } else {
        throw new Error(connect.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const addCard = (body) => async (dispatch, getState) => {
  try {
    const card = await addCardAsync(body);

    if (card.status === 200) {
      const profileInfo = await getProfileInfo(getState().auth.user.id);
      dispatch({ type: UPDATE_PROFILE, payload: profileInfo });
      return card;
    } else {
      throw new Error(card.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
export const deleteCard = (body) => async (dispatch, getState) => {
  try {
    const card = await deleteCardAsync(body);

    if (card.status === 200) {
      const profileInfo = await getProfileInfo(getState().auth.user.id);
      dispatch({ type: UPDATE_PROFILE, payload: profileInfo });
      return card;
    } else {
      throw new Error(card.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
