import mapKeys from 'lodash/mapKeys';
import { GET_TIMEZONE_LIST } from '../../actions/types';

const timeZoneListReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_TIMEZONE_LIST:
      return { ...state, ...mapKeys(action.payload, 'id') }
    default:
      return state;
  }
}

export default timeZoneListReducer;