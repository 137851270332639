import React from "react";
// import Banner2 from '../../assets/img/banner/banner-02.jpg';

const PageBanner = ({ banner }) => {
  return (
    <section
      className="page-banner"
      style={{ backgroundImage: `url(${banner?.image})` }}
    >
      <div className="container">
        <div className="banner-content">
          <div className="banner-title">{banner?.title}</div>
          <div
            className="banner-subtitle"
            dangerouslySetInnerHTML={{ __html: banner?.description }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
