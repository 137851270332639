import React from "react";
import { OTPublisher } from "opentok-react";
import { noImage } from "../../config/host";
import PrefooterOverlay from "../../prefooterOverlay";
import { langConfig } from "../../config/lang";

const SecManageAppointment = ({
  appointment,
  handleRedirect,
  handleCancel,
  user,
  sessionHelper,
  spinner,
  isConsultantReady,
  socket,
  initComplete,
  lang,
}) => {
  const consultant = appointment?.consultant_id === user?.id;

  let properties = { insertMode: "replace", width: "100%", height: "100%" };
  if (appointment?.service_type !== "3")
    properties = { ...properties, videoSource: null };

  if (appointment?.service_type === "3") {
    properties = {
      ...properties,
      // resolution: '1920x1080'
    };
  }

  const config = {
    readyToJoin: {
      en: "Ready to join?",
      es: "¿Listo para unirte?",
      it: "Pronto a partecipare?",
    },
    noOneElse: {
      en: "No one else is here.",
      es: "No hay nadie más presente",
      it: "Nessun altro è presente",
    },
    isInCall: {
      en: "is in this call.",
      es: "está en esta convocatoria.",
      it: "è in questa chiamata.",
    },
    joinNow: {
      en: "Join now",
      es: "Únete ahora",
      it: "Accedi",
    },
    cancel: {
      en: "Cancel",
      es: "Cancel",
      it: "Cancel",
    },
  };

  return (
    <section className="manageAppointment prefooter-last">
      <div className="container">
        <div className="cont">
          {appointment?.ids && (
            <div
              id="videos"
              className={`videos ${
                appointment?.service_type === "1" ? "d-none" : ""
              }`}
            >
              {appointment &&
                appointment?.service_type !== "1" &&
                sessionHelper && (
                  <OTPublisher
                    properties={properties}
                    session={sessionHelper.session}
                  />
                )}
            </div>
          )}
          {/* <div
            className="confirmBox"
            style={{
              width: appointment?.service_type === "1" ? "100%" : "50%",
            }}
          >
            {appointment?.ids &&
              user?.id &&
              socket?.io &&
              initComplete &&
              ((user?.role === "user" && appointment?.user_join) ||
                (user?.role === "consultant" &&
                  appointment?.consultant_join)) && (
                <h3>{config.readyToJoin[lang] || config.readyToJoin.en}</h3>
              )}
            <div className="d-flex flex-column align-items-center">
              {appointment?.ids &&
                consultant &&
                user?.id &&
                socket?.io &&
                initComplete &&
                ((user?.role === "user" && appointment?.user_join) ||
                  (user?.role === "consultant" &&
                    appointment?.consultant_join)) &&
                (appointment?.user_join !== "1" ? (
                  <p>{config.noOneElse[lang] || config.noOneElse.en}</p>
                ) : (
                  <>
                    <div
                      className="mb-2"
                      style={{ height: "50px", width: "50px" }}
                    >
                      <img
                        src={appointment?.user_image}
                        style={{ height: "100%", maxWidth: "100%" }}
                        alt={appointment?.user_profile_name}
                        onError={(e) => (e.target.src = `${noImage}`)}
                      />
                    </div>
                    <p>
                      {appointment?.user_profile_name}{" "}
                      {config.isInCall[lang] || config.isInCall.en}
                    </p>
                  </>
                ))}

              {appointment?.ids &&
                !consultant &&
                user?.id &&
                socket?.io &&
                initComplete &&
                ((user?.role === "user" && appointment?.user_join) ||
                  (user?.role === "consultant" &&
                    appointment?.consultant_join)) &&
                (appointment?.consultant_join !== "1" ? (
                  <p>{config.noOneElse[lang] || config.noOneElse.en}</p>
                ) : (
                  <>
                    <div
                      className="mb-2"
                      style={{ height: "50px", width: "50px" }}
                    >
                      <img
                        src={appointment?.consultant_image}
                        style={{ height: "100%", maxWidth: "100%" }}
                        alt={appointment?.consultant_profile_name}
                        onError={(e) => (e.target.src = `${noImage}`)}
                      />
                    </div>
                    <p>
                      {appointment?.consultant_profile_name}{" "}
                      {config.isInCall[lang] || config.isInCall.en}
                    </p>
                  </>
                ))}
            </div>

            {appointment?.ids &&
              user?.id &&
              socket?.io &&
              initComplete &&
              ((user?.role === "user" && appointment?.user_join) ||
                (user?.role === "consultant" &&
                  appointment?.consultant_join)) && (
                <div className="btnContainer">
                  <button className="btnConfirm btn" onClick={handleRedirect}>
                    <div className="d-flex align-items-center ">
                      <span>{config.joinNow[lang] || config.joinNow.en}</span>
                      {spinner && (
                        <div className="loader-spinner ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
              )}
            <div className="btnContainer p-3">
              <button className="btnCancel btn" onClick={handleCancel}>
                <div className="d-flex align-items-center ">
                  <span>{config.cancel[lang] || config.cancel.en}</span>
                  {spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div> */}
          {!isConsultantReady ? (
            <>
              <div className="loader-spinner ml-2">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <h5>...{langConfig.loading[lang] || langConfig.loading.en}</h5>{" "}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <PrefooterOverlay />
    </section>
  );
};

export default SecManageAppointment;
