import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getConsultantList } from "../../actions/consultant";
import ConsultantFilter from "./ConsultantFilter";
import ConsultantItem from "./ConsultantItem";
import NoDataFound from "../Admin/Sections/NoDataFound";
import ConversationModal from "../Modals/ConversationModal";
import BookAppointmentModalAlt from "../Modals/BookAppointmentModalAlt";

const Consultant = ({
  consultants,
  getConsultantList,
  nationalities,
  languages,
  countries,
  location,
  setConsultantLoaded,
  lang,
  user,
  details,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [allConsultants, setAllConsultants] = useState([]);
  const [id, setId] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    setConsultantLoaded(false);
    setOrder("");
    setOrderBy("");
    const ac = new AbortController();
    const loadConsultants = async () => {
      const search = location.search.substring(1);
      const allParams = new URLSearchParams(search);
      const allSearchParams = {};

      allParams.forEach((val, i) => {
        const allowedMulti = ["gender", "activeStatus", "modes"];

        if (allowedMulti.includes(i)) {
          if (val) {
            allSearchParams[i] = val.split(",");
          } else {
            allSearchParams[i] = [];
          }
        } else {
          allSearchParams[i] = val;
        }
      });

      try {
        let params = null;

        if (allSearchParams?.search) {
          delete allSearchParams.search;
          params = { ...allSearchParams };
        }
        await getConsultantList({ ...params, lang });
        setIsLoaded(true);
        setConsultantLoaded(true);

        setOrder(params?.order || "ASC");
        setOrderBy(params?.orderBy || "activeStatus");
      } catch (err) {
        console.log(err.message);
      }
    };

    loadConsultants();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, lang]);

  useEffect(() => {
    if (consultants.length) {
      const sortedConsultants = consultants.sort((a, b) => {
        if (orderBy === "name") {
          return order.toLowerCase() === "asc" && a.name > b.name ? 1 : -1;
        }

        return order.toLowerCase() === "asc"
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy];
      });

      setAllConsultants(sortedConsultants);
    } else {
      setAllConsultants([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <ConversationModal
          consultant={details}
          lang={lang}
          user={user}
          id={id}
        />
        <BookAppointmentModalAlt
          consultant={details}
          lang={lang}
          user={user}
          id={id}
        />
      </div>

      <section className="sec-consultant py-4 prefooter-last">
        <div className="container">
          <ConsultantFilter
            nationalities={nationalities}
            languages={languages}
            countries={countries}
          />

          {allConsultants.length ? (
            <div className="consultant-wrap">
              {allConsultants.map((item, i) => {
                return <ConsultantItem key={i} item={item} setId={setId} />;
              })}
            </div>
          ) : isLoaded ? (
            <div className="nodata-wrap">
              <NoDataFound />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="prefooter-overlay">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#2f2f95"
              fillOpacity="1"
              d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    consultants: Object.values(state.consultants.consultantList),
    details: state.consultants.consultantDetails,
  };
};

export default connect(mapStateToProps, { getConsultantList })(Consultant);
