import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import $ from "jquery";
import { stripeConnect } from "../../../actions/payment";
import { deleteStripeAccount } from "../../../actions/profile";
import StripeForm from "./StripeForm";
import StripeCardListItem from "./StripeCardListItem";
import { clientId, apiKey } from "../../../config/stripe";
import { toast } from "react-toastify";

const PaymentInfoForm = ({
  user,
  location,
  stripeConnect,
  lang,
  cards,
  paymentInfo,
  ...props
}) => {
  const [spinner, setSpinner] = useState(false);
  const stripePromise = loadStripe(apiKey);
  useEffect(() => {
    const ac = new AbortController();
    const code = new URLSearchParams(location.search).get("code");
    const state = new URLSearchParams(location.search).get("state");

    const connectToStripe = async () => {
      if (code && state && user?.id) {
        try {
          await stripeConnect({ code, id: state }, ac.signal);
        } catch (err) {
          console.log(err.message);
        }
      }
    };

    connectToStripe();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const handleRedirect = () => {
    if (user?.role === "consultant") {
      const baseUrl = window.location.origin;

      const url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${baseUrl}/${lang}/payment-info&client_id=${clientId}&state=${user.id}&suggested_capabilities[]=card_payments&suggested_capabilities[]=transfers`;

      window.open(url, "_self");
    }
  };

  const deleteStripeAccount = async () => {
    setSpinner(true);

    try {
      const message = await props.deleteStripeAccount({
        consultantId: user.id,
      });
      setSpinner(false);

      toast.success(message);
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };

  const config = {
    connectedSuccessfully: {
      en: "Your Stripe Account has already been connected successfully.",
      es: "Su cuenta de Stripe ya se ha conectado correctamente.",
      it: "Il tuo account Stripe è già stato collegato correttamente.",
    },
    cardAdded: {
      en: "Your card has already been added. If you want to add another card, enter your card details and click the Add Card button.",
      es: "Su tarjeta ya ha sido agregada. Si desea agregar otra tarjeta, ingrese los detalles de su tarjeta y haga clic en el botón Agregar tarjeta.",
      it: `La tua carta è già stata aggiunta. Se desideri aggiungere un'altra carta, inserisci i dettagli della tua carta e fai clic sul pulsante Aggiungi carta.`,
    },
    connectStripe: {
      en: "Connect stripe",
      es: "Conecte la raya",
      it: "Connessione a Stripe",
    },
    deleteStripeAccount: {
      en: "Delete stripe account",
      es: "Eliminar cuenta de banda",
      it: "Elimina account stripe",
    },
    addCard: {
      en: "Add card",
      es: "Agregar tarjeta",
      it: "Aggiungi carta",
    },
    pleaseConnectStripe: {
      en: "Please connect stripe.",
      es: "Por favor, conecte la raya.",
      it: "Si prega di connettersi a Stripe.",
    },
  };
  console.log(cards.length, user?.stripeCardDetails?.length);
  return (
    <>
      <div className="form-group">
        {user?.role === "user" && user?.stripeCardDetails?.length ? (
          <>
            {/* <div className="note-text">{ cards[0].brand } - { cards[0].last4 }</div> */}
            {user?.stripeCardDetails?.length ? (
              <div className="form-group stripe-card-list mt-2">
                {user?.stripeCardDetails?.map((item, i) => {
                  return (
                    <StripeCardListItem
                      key={i}
                      mykey={i}
                      item={item}
                      isDelete={true}
                      user_id={user?.id}
                    />
                  );
                })}
              </div>
            ) : null}
            <div className="note-text" id="user_msg">
              {config.cardAdded[lang] || config.cardAdded.en}
            </div>
          </>
        ) : user?.role === "consultant" ? (
          <div className="note-text" id="user_msg">
            {user?.stripe_account_id ? (
              <>
                {Object.keys(paymentInfo).length > 0 && (
                  <div>
                    {paymentInfo?.external_accounts?.data &&
                      paymentInfo?.external_accounts.data[0].bank_name +
                        " - " +
                        paymentInfo?.external_accounts.data[0].last4}
                  </div>
                )}
                <span>
                  {config.connectedSuccessfully[lang] ||
                    config.connectedSuccessfully.en}
                </span>
                <button
                  className="btn-universal mt-2"
                  placement="top"
                  onClick={deleteStripeAccount}
                >
                  <div className="d-flex align-items-center">
                    <span>
                      {config.deleteStripeAccount[lang] ||
                        config.deleteStripeAccount.en}
                    </span>
                    {spinner && (
                      <div className="loader-spinner ml-2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </button>
              </>
            ) : (
              config.pleaseConnectStripe[lang] || config.pleaseConnectStripe.en
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {stripePromise && user?.role === "user" && (
        <Elements stripe={stripePromise} options={{ locale: lang }}>
          <StripeForm
            config={config}
            lang={lang}
            spinner={spinner}
            setSpinner={setSpinner}
            type={1}
          />
        </Elements>
      )}
      {user?.role === "consultant" && !user?.stripe_account_id && (
        <div className="form-row mt-3">
          <div className="form-group col-md-12 mt-1">
            <div className="lable-text ml-auto">
              <button
                className="btn-universal"
                placement="top"
                onClick={handleRedirect}
              >
                {config.connectStripe[lang] || config.connectStripe.en}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    cards: Object.values(state.profile.cards),
    paymentInfo: state.paymentInfo,
  };
};

export default connect(mapStateToProps, { stripeConnect, deleteStripeAccount })(
  PaymentInfoForm
);
