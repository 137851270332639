import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import $ from "jquery";
import moment from "moment";
import { updateMembershipInfo } from "../../../../actions/profile";
import { memberLang } from "../../../../config/lang";
import SaveChanges from "../SaveChanges";
import { toast } from "react-toastify";
import { memberTooltipLang } from "../../../../config/lang";
import TooltipSec from "./TooltipSec";
const AccountInfoForm = ({
  auth,
  user,
  countries,
  updateMembershipInfo,
  profQua,
  lang,
}) => {
  const [spinner, setSpinner] = useState(false);
  const { status } = auth;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [checked, setChecked] = useState(false);
  const toggle = (value) => !value;

  const converIntToBoolean = (int) => {
    if (int === "1" || int === 1) return true;

    return false;
  };

  useEffect(() => {
    const loadDefaults = (user) => {
      setChecked(converIntToBoolean(user?.checkbox_professional_declaration));
      setValue("memberName", user?.member_name, { shouldValidate: true });
      setValue("memberRegion", user?.member_region, { shouldValidate: true });
      setValue("memberCity", user?.member_city, { shouldValidate: true });
      setValue("memberCountry", user?.member_country, { shouldValidate: true });
      setValue("memberRegno", user?.member_reg_no, { shouldValidate: true });
      setValue(
        "memberDateReg",
        user?.member_date_reg
          ? moment(new Date(user.member_date_reg)).format("DD-MM-Y")
          : "",
        { shouldValidate: true }
      );
    };

    loadDefaults(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    $("#professional_qualification")
      .val(
        getValues("professionalQualification") ||
          user?.professional_qualification
      )
      .trigger("change");

    $("#member_country")
      .val(
        getValues("memberCountry") ||
          countries.find((el) => el.name === user?.member_country)?.id
      )
      .trigger("change");

    $("#professional_qualification").on("change", function (e) {
      setValue("professionalQualification", this.value);
    });

    $("#member_country").on("change", function (e) {
      setValue("memberCountry", this.value);
    });
  });

  useEffect(() => {
    $("#member_date_reg").datetimepicker({
      value: user?.member_date_reg ? new Date(user?.member_date_reg) : "",
      timepicker: false,
      format: "d-m-Y",
      scrollMonth: false,
      onSelectDate: function (e) {
        setValue("memberDateReg", moment(new Date(e)).format("DD-MM-Y"));
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.member_date_reg]);

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    if (!Object.keys(errors).length && userId && status) {
      try {
        const userData = { ...data, userId, lang };
        checked
          ? (userData.checkboxProfessionalDeclaration = "1")
          : (userData.checkboxProfessionalDeclaration = "2");

        const message = await updateMembershipInfo(userData);
        setSpinner(false);
        toast.success(message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="col-md-12">
          {/* Professional Qualification */}
          <div className="form-group" data-select2-id="553">
            <label className="control-label required">
              {memberLang.personalQualification[lang] ||
                memberLang.personalQualification.en}
              <TooltipSec
                title={memberTooltipLang.pQ[lang] || memberTooltipLang.pQ.en}
              ></TooltipSec>
            </label>
            <select
              className="form-control select2-nosearch select2-hidden-accessible"
              id="professional_qualification"
              tabIndex="-1"
              aria-hidden="true"
            >
              <option value="" disabled>
                {memberLang.personalQualification[lang] ||
                  memberLang.personalQualification.en}
              </option>
              {profQua.length
                ? profQua.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          {/* Checkbox professional declaration */}
          <div className="form-group">
            <div className="checkbox-group">
              <div className="custom-checkbox">
                <input
                  className="custom-checkbox-input"
                  name="checkbox-professional_declaration"
                  type="checkbox"
                  checked={checked}
                  {...register("checkboxProfessionalDeclaration")}
                  onChange={() => setChecked(toggle)}
                />
                <div className="custom-checkbox-label">
                  {memberLang.authorization[lang] ||
                    memberLang.authorization.en}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          {/* Membership name */}
          <div className="form-group">
            <label className="control-label required">
              {memberLang.membershipName[lang] || memberLang.membershipName.en}
              <TooltipSec
                title={memberTooltipLang.mN[lang] || memberTooltipLang.mN.en}
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="member_name"
              type="text"
              placeholder={
                memberLang.membershipName[lang] || memberLang.membershipName.en
              }
              defaultValue={status ? user.member_name : ""}
              {...register("memberName")}
            />
          </div>
        </div>

        <div className="col-md-4 col-lg-3">
          {/* Member Country */}
          <div className="form-group" data-select2-id="299">
            <label className="control-label required">
              {memberLang.businessCountry[lang] ||
                memberLang.businessCountry.en}
              <TooltipSec
                title={
                  memberTooltipLang.businessCountry[lang] ||
                  memberTooltipLang.businessCountry.en
                }
              ></TooltipSec>
            </label>
            <select
              className="form-control country select2 select2-hidden-accessible"
              id="member_country"
              tabIndex="-1"
              aria-hidden="true"
            >
              <option value="">
                {memberLang.businessCountry[lang] ||
                  memberLang.businessCountry.en}
              </option>
              {countries &&
                countries.map((country, i) => {
                  return (
                    <option key={i} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-6 col-md-4 col-lg-3">
          {/* Region */}
          <div className="form-group">
            <label className="control-label required">
              {memberLang.region[lang] || memberLang.region.en}
              <TooltipSec
                title={
                  memberTooltipLang.region[lang] || memberTooltipLang.region.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-pristine ng-valid ng-touched"
              id="member_region"
              name="member_region"
              type="text"
              placeholder={memberLang.region[lang] || memberLang.region.en}
              defaultValue={status ? user.member_region : ""}
              {...register("memberRegion")}
            />
          </div>
        </div>

        <div className="col-6 col-md-4 col-lg-3">
          {/* Business City */}
          <div className="form-group">
            <label className="control-label required">
              {memberLang.businessCity[lang] || memberLang.businessCity.en}
              <TooltipSec
                title={
                  memberTooltipLang.city[lang] || memberTooltipLang.city.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-pristine ng-valid ng-touched"
              id="member_city"
              name="member_city"
              type="text"
              placeholder={
                memberLang.businessCity[lang] || memberLang.businessCity.en
              }
              defaultValue={status ? user.member_city : ""}
              {...register("memberCity")}
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6 col-lg-4">
          {/* Member reg no. */}
          <div className="form-group">
            <label className="control-label required">
              {memberLang.membershipRegistrationNumber[lang] ||
                memberLang.membershipRegistrationNumber.en}
              <TooltipSec
                title={memberTooltipLang.mRN[lang] || memberTooltipLang.mRN.en}
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-pristine ng-valid ng-touched"
              name="member_reg_no"
              type="text"
              placeholder={
                memberLang.membershipRegistrationNumber[lang] ||
                memberLang.membershipRegistrationNumber.en
              }
              defaultValue={status ? user.member_reg_no : ""}
              {...register("memberRegno")}
            />
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          {/* Member date of reg */}
          <div className="form-group">
            <label className="control-label required">
              {memberLang.membershipDateRegistration[lang] ||
                memberLang.membershipDateRegistration.en}
              <TooltipSec
                title={memberTooltipLang.mDE[lang] || memberTooltipLang.mDE.en}
              ></TooltipSec>
            </label>
            <input
              className="form-control"
              id="member_date_reg"
              name="member_date_reg"
              type="text"
              placeholder={
                memberLang.membershipDateRegistration[lang] ||
                memberLang.membershipDateRegistration.en
              }
              defaultValue={status ? user.member_date_reg : ""}
              {...register("memberDateReg")}
            />
          </div>
        </div>
      </div>

      <SaveChanges spinner={spinner} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
    countries: Object.values(state.cms.countryList),
    profQua: Object.values(state.cms.professionalQualificatonList),
  };
};

export default connect(mapStateToProps, { updateMembershipInfo })(
  AccountInfoForm
);
