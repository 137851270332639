import { 
  CLEAR_APPOINTMENT_MESSAGES, GET_APPOINTMENT_MESSAGES,
  SEND_APPOINTMENT_MESSAGE
} from "./types";
import { getAppointmentMessagesAsync, sendAppointmentMessageAsync } from "../asynchronus/Message";

export const getAppointmentMessages = (body, signal = null) => async dispatch => {
  try {
    const messages = await getAppointmentMessagesAsync(body, signal);

    if(messages.status === 200) {
      dispatch({ type: GET_APPOINTMENT_MESSAGES, payload: messages.data });
      console.log(messages.data)
      return messages.data;
    } else {
      return messages.data;
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const setMessages = body => dispatch => dispatch({ 
  type: GET_APPOINTMENT_MESSAGES, payload: body
})

export const clearAppointmentMessages = () => dispatch => {
  dispatch({ type: CLEAR_APPOINTMENT_MESSAGES, payload: null });
}

export const sendAppointmentMessage = body => async dispatch => {
  try {
    const message = await sendAppointmentMessageAsync(body);

    if(message.status === 200) {
      // const messages = await getAppointmentMessagesAsync({ 
      //   lang: body.lang, userId: body.senderId, appointmentId: body.appointmentId }, null
      // );
      // dispatch({ type: GET_APPOINTMENT_MESSAGES, payload: messages.data });

      dispatch({ type: SEND_APPOINTMENT_MESSAGE, payload: message.data });
    } else {
      throw new Error(message.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const setMessage = body => dispatch => {
  dispatch({ type: SEND_APPOINTMENT_MESSAGE, payload: body });
}