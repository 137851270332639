import React, { useState, useEffect, useRef } from "react";
import { paymentStatus } from "../../../../helpers/appointment";
import InvoiceModal from "../../../Modals/InvoiceModal";
import ServiceInfo from "./ServiceInfo";
import {
  acNavLangFormal,
  appointmentLang,
  langConfig,
} from "../../../../config/lang";
import { hostUrl } from "../../../../config/host";

const PaymentInfo = ({ appointment, user, lang }) => {
  const invoiceRef = useRef(null);
  const [url, setUrl] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  useEffect(() => {
    if (user?.role === "user") {
      setUrl(
        `${hostUrl}appointments/invoice/${appointment?.appointment_id}?lang=${lang}&type=client`
      );
    } else {
      setUrl(
        `${hostUrl}appointments/consultant-invoice/${appointment?.appointment_id}?lang=${lang}`
      );
      setInvoiceUrl(
        `${hostUrl}appointments/consultant-payment-invoice/${appointment?.appointment_id}?lang=${lang}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role]);
  const getDuration = (startTime, endTime) => {
    const dateDiff = new Date(endTime) - new Date(startTime);
    const duration = Math.ceil(((dateDiff % 86400000) % 3600000) / 60000);

    const pluralize =
      duration > 1
        ? ` ${appointmentLang.minutes[lang] || appointmentLang.minutes.en}`
        : ` ${appointmentLang.minute[lang] || appointmentLang.minute.en}`;

    return duration + pluralize;
  };

  const config = {
    tDt: {
      en: "Total duration time",
      es: "Tiempo de duración total",
      it: "Tempo di durata totale",
    },
    authorizeAmount: {
      en: "Authorize amount",
      es: "cantidad autorizada",
      it: "Autorizza importo",
    },
    payableAmount: {
      en: "Payable Amount",
      es: "Cantidad a pagar",
      it: "Importo pagabile",
    },
    capturedAmount: {
      en: "Captured Amount",
      es: "Cantidad capturada",
      it: "Importo catturato",
    },
    disclaimer: {
      en: "Disclaimer",
      es: "Descargo de responsabilidad",
      it: "Disclaimer",
    },
    disclaimerText: {
      en: "The Consultant call rate is provides for an advance charge for each minute of consulting and for each subsequent fraction of a minute, rounded up to the next unit of time.",
      es: "La tarifa de llamada de Consultor prevé un cargo por adelantado por cada minuto de consulta y por cada fracción de minuto subsiguiente, redondeado a la siguiente unidad de tiempo.",
      it: "La tariffa Chiamata Consulente prevede un addebito anticipato per ogni minuto di consulenza e per ogni successiva frazione di minuto, arrotondata all'unità di tempo successiva.",
    },
    downloadInvoice: {
      en: "Download invoice",
      es: "Descargar factura",
      it: "Scarica fattura",
    },
    downloadSummary: {
      en: "Download summary",
      es: "Descargar resumen",
      it: "Scarica il riassunto",
    },
    cardName: {
      en: "Name",
      es: "Nome",
      it: "Nombre",
    },
    cardIcon: {
      en: "Icon",
      es: "Icono",
      it: "Icona",
    },
    cardNumber: {
      en: "Number",
      es: "Número",
      it: "Número",
    },
    paymentWith: {
      en: "Payment Methods",
      es: "MÉTODO DE PAGO",
      it: "METODO DI PAGAMENTO",
    },
    endWith: {
      en: "Ends With",
      es: "Termina con",
      it: "Termina con",
    },
  };

  return (
    <div
      className="tab-pane fade"
      id="nav-payments"
      role="tabpanel"
      aria-labelledby="nav-payments-tab"
    >
      <div className="row">
        <div className="col-12">
          <div className="appointment-payment">
            <div className="appointment-content" id="invoice_pdf">
              <div className="row">
                <div className="col-lg-6">
                  <div className="appointment-title">
                    {acNavLangFormal.payment[lang] ||
                      acNavLangFormal.payment.en}
                  </div>
                  <div className="row form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {appointmentLang.paymentStatus[lang] ||
                            appointmentLang.paymentStatus.en}
                        </div>
                        <div className="control-data">
                          {paymentStatus[appointment.payment_status]
                            ? appointmentLang[
                                paymentStatus[
                                  appointment.payment_status
                                ].toLowerCase()
                              ][lang] ||
                              appointmentLang[
                                paymentStatus[
                                  appointment.payment_status
                                ].toLowerCase()
                              ].en
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {langConfig.currency[lang] || langConfig.currency.en}
                        </div>
                        <div className="control-data">
                          {appointment.currency}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {langConfig.pPm[lang] || langConfig.pPm.en}
                        </div>
                        <div className="control-data">
                          {appointment.currency} {appointment.per_minute_amount}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {config.tDt[lang] || config.tDt.en}
                        </div>
                        <div className="control-data">
                          {appointment.consultant_join_time
                            ? getDuration(
                                appointment.consultant_join_time,
                                appointment.appointment_end_time
                              )
                            : `0 ${
                                appointmentLang.minutes[lang] ||
                                appointmentLang.minutes.en
                              }`}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {config.authorizeAmount[lang] ||
                            config.authorizeAmount.en}
                        </div>
                        <div className="control-data">
                          {appointment.currency}{" "}
                          {appointment.authorization_amount}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {config.payableAmount[lang] ||
                            config.payableAmount.en}
                        </div>
                        <div className="control-data">
                          {appointment.currency} {appointment.amount}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="control-label">
                          {config.capturedAmount[lang] ||
                            config.capturedAmount.en}
                        </div>
                        <div className="control-data">
                          {appointment.currency} {appointment.amount}
                        </div>
                      </div>
                    </div>
                    {appointment?.authorization_responce?.charges?.data[0]
                      ?.payment_method_details?.card && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="control-label">
                            {config.paymentWith[lang] || config.paymentWith.en}
                          </div>
                          <div className="control-data">
                            <div className="row">
                              <div className="payment-card-img-payment-info">
                                <div className="img-wrap">
                                  <img
                                    src={
                                      require(`../../../../assets/img/cardicon/${appointment.card_name}.png`)
                                        .default
                                    }
                                    alt="icon"
                                  />
                                </div>
                              </div>
                              <div className="end-with">
                                {config.endWith[lang] || config.endWith.en}
                              </div>
                              <div className="last-four">
                                ****
                                {
                                  appointment.authorization_responce.charges
                                    .data[0].payment_method_details.card.last4
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="control-label">
                          {config.disclaimer[lang] || config.disclaimer.en}
                        </div>
                        <div className="control-data">
                          {config.disclaimerText[lang] ||
                            config.disclaimerText.en}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <ServiceInfo appointment={appointment} lang={lang} />
                </div>

                {appointment?.payment_status === "3" && (
                  <>
                    <div className="col-lg-3 mt-2">
                      <button
                        type="button"
                        className="btn-universal d-none"
                        onClick={() => invoiceRef.current.click()}
                      >
                        {config.downloadSummary[lang] ||
                          config.downloadSummary.en}
                      </button>

                      <a
                        href={url}
                        className="btn-universal"
                        target="_blank"
                        rel="noreferrer"
                        data-keyboard="false"
                        data-backdrop="static"
                      >
                        {config.downloadSummary[lang] ||
                          config.downloadSummary.en}
                      </a>
                    </div>
                  </>
                )}
                {appointment?.payment_status === "3" &&
                  user?.role === "consultant" &&
                  appointment?.amount !== "0.00" && (
                    <>
                      <div className="col-lg-3 mt-2">
                        <a
                          href={url}
                          className="btn-universal"
                          target="_blank"
                          rel="noreferrer"
                          data-keyboard="false"
                          data-backdrop="static"
                        >
                          {config.downloadInvoice[lang] ||
                            config.downloadInvoice.en}
                        </a>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
