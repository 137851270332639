import { DASHBOARD } from "./types";
import { getDashboardDataAsync } from "../asynchronus/Dashboard";

export const getDashboardData = (body = null, signal = null) => async dispatch => {
  try {

    const res = await getDashboardDataAsync(body, signal);
    if(res.status === 200) {
      dispatch({ type: DASHBOARD, payload: res.data });
    } else {
      throw new Error(res.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}