import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { getWishlist, addToWishlist } from "../../actions/wishlist";
import ModeItem from "./ModeItem";
import { noImage } from "../../config/host";
import flags from "../../helpers/flags";
import { toast } from "react-toastify";
const SidePanel = ({
  consultantDetails,
  addToWishlist,
  getWishlist,
  wishlist,
  id,
  user,
  nationalities,
  serviceDetails,
  socket,
  lang,
}) => {
  const [detail, setDetail] = useState(null);
  const [scheduleServiceDetails, setScheduleServiceDetails] = useState(null);
  const [activeStatus, setActiveStatus] = useState(detail?.activeStatus);

  useEffect(() => {
    const loadDetail = () => {
      if (
        !detail &&
        consultantDetails &&
        Object.keys(consultantDetails).length
      ) {
        setDetail(consultantDetails);
        setActiveStatus(consultantDetails?.activeStatus);
      }
    };

    const loadWishList = async () => {
      if (localStorage.getItem("userId")) {
        try {
          await getWishlist();
        } catch (err) {
          console.error(err.message);
        }
      }
    };

    loadWishList();
    loadDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantDetails]);

  useEffect(() => {
    if (serviceDetails && serviceDetails.length) {
      const checkService = serviceDetails.find((item) => item.id === "2");
      setScheduleServiceDetails(checkService);
    }
  }, [serviceDetails]);

  useEffect(() => {
    if (socket?.io) {
      socket.on("update-consultant-status", (res) => {
        if (res.userId === id) setActiveStatus(res.activeStatus);
      });
    }
  }, [id, socket]);

  const checkWishlist = (consultantId) => {
    return wishlist.find((el) => el.consultant_id === consultantId);
  };

  const wishlistAdd = async (e, id, wishlist) => {
    try {
      if (localStorage.getItem("userId")) {
        e.preventDefault();
        const message = await addToWishlist(id, wishlist);
        toast.success(message);
      }
    } catch (err) {
      toast.success(err.message);
    }
  };

  const findNationality = (ntnly) => {
    return nationalities.find((el) => el.nationality === ntnly)?.alpha_2_code;
  };

  const getRating = (int) => {
    const rating = parseFloat(int).toFixed(1);

    const percentage = (rating / 5) * 100;

    return percentage.toString();
  };

  const reviewText = {
    en: "Reviews",
    es: "Reseñas",
    it: "Recensioni",
  };

  const emailMe = {
    en: "Email Me",
    es: "Envíeme un correo electrónico",
    it: `Mandami un'email`,
  };

  return (
    <div className="consultant-side-panel">
      <div className="consultant">
        <div
          className={`consultant-image${
            activeStatus === "1"
              ? " online"
              : activeStatus === "2"
              ? " offline"
              : " busy"
          }`}
        >
          {detail && (
            <img
              src={detail.logo}
              alt={detail.name}
              onError={(e) => (e.target.src = `${noImage}`)}
            />
          )}
        </div>
        {detail && <div className="consultant-name">{detail.name}</div>}
        {detail && (
          <div className="consultant-designation">
            {detail.professionalQualification}
          </div>
        )}
        {detail && (
          <div className="consultant-intervention">
            <div className="intervention-data">{detail.interventionName}</div>
          </div>
        )}
        {nationalities.length > 0 &&
          detail?.nationality &&
          Object.keys(flags).length > 0 && (
            <div className="consultant-nationality">
              <div className="nationality-data">
                <div className="nationality-flag">
                  <img
                    alt={detail.nationality}
                    src={flags[findNationality(detail.nationality)]?.default}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
      <div className="consultant-popularity">
        <div className="rating-wrap">
          <div className="rating">
            <div className="back" style={{ width: "100%" }}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <div
              className="front"
              style={{
                width: `${
                  detail?.rating
                    ? getRating(
                        Math.round((+detail.rating + Number.EPSILON) * 10) / 10
                      )
                    : 0
                }%`,
                fontSize: "14px",
              }}
            >
              <i className="fas fa-star" style={{ width: "16px" }}></i>
              <i className="fas fa-star" style={{ width: "16px" }}></i>
              <i className="fas fa-star" style={{ width: "16px" }}></i>
              <i className="fas fa-star" style={{ width: "16px" }}></i>
              <i className="fas fa-star" style={{ width: "16px" }}></i>
            </div>
          </div>

          <div className="average-rating">
            <span>
              {detail?.rating
                ? Math.round((+detail.rating + Number.EPSILON) * 10) / 10
                : "0"}
            </span>
          </div>
        </div>
        {detail && (
          <div className="review-count">
            <div className="count">{detail.review}</div>
            <div className="title">{reviewText[lang] || reviewText.en}</div>
          </div>
        )}
      </div>
      <div className="consultant-status">
        <div
          className={`status${
            activeStatus === "1"
              ? " online"
              : activeStatus === "2"
              ? " offline"
              : " busy"
          }`}
        >
          <div className="icon"></div>
          <div className="text">
            {activeStatus === "1"
              ? "Online"
              : activeStatus === "2"
              ? "Offline"
              : activeStatus === "3"
              ? "Busy"
              : ""}
          </div>
        </div>
      </div>
      <div className="consultant-meta">
        {detail && detail?.activeStatus === "1" && detail.servicePrice && (
          <div className="consultant-rate">
            {detail.serviceCurrency} {detail.servicePrice}
            <small>/min</small>
          </div>
        )}
        {detail &&
          detail?.activeStatus === "2" &&
          scheduleServiceDetails?.currency && (
            <div className="consultant-rate">
              {scheduleServiceDetails?.currency} {scheduleServiceDetails?.price}
              <small>/min</small>
            </div>
          )}
        <div className="consultant-language">
          <div className="language-icon">
            <i className="far fa-fw fa-language"></i>
          </div>
          {detail && <div className="language-name">{detail.language}</div>}
        </div>
      </div>
      {detail && user?.role !== "consultant" ? (
        <ModeItem status={activeStatus} maxWidth={true} />
      ) : (
        ""
      )}
      {user?.role !== "consultant" && detail && (
        <div className="consultant-action justify-content-end mt-0">
          <a
            className="btn-link ml-auto"
            href="#!"
            data-toggle={user?.role === "user" && "modal"}
            data-target="#email_me"
            onClick={(e) => e.preventDefault()}
          >
            {emailMe[lang] || emailMe.en}
          </a>
        </div>
      )}

      {detail && user?.role && user.role === "user" && (
        <a
          className="btn-wishlist"
          href="#!"
          onClick={(e) => wishlistAdd(e, id, wishlist)}
        >
          <i
            className={`${!checkWishlist(id) ? "fal" : "fas"} fa-fw fa-heart`}
          ></i>
        </a>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    wishlist: Object.values(state.wishlist.wishlist),
    user: state.auth.user,
    consultantDetails: state.consultants.consultantDetails.consultant_details,
    socket: state.socket,
    serviceDetails: state.consultants.consultantDetails.service_details,
    nationalities: Object.values(state.cms.nationalities),
  };
};

export default connect(mapStateToProps, { getWishlist, addToWishlist })(
  SidePanel
);
