import { GET_CONSULTANT_TIME_SLOTS, CLEAR_CONSULTANT_TIME_SLOTS } from '../../actions/types';

const timeSlotReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_CONSULTANT_TIME_SLOTS:
      return { ...action.payload };
    case CLEAR_CONSULTANT_TIME_SLOTS:
      return {};
    default:
      return state;
  }
}

export default timeSlotReducer;