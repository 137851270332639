import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { clearConsultant } from "../../../actions/consultant";
import { getWishlist } from "../../../actions/wishlist";
import SingleWishList from "../Children/SingleWishList";
import NoDataFound from "./NoDataFound";

const SecWishList = ({
  getWishlist,
  wishlist,
  clearConsultant,
  setWishlistLoaded,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    clearConsultant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadWishlist = async () => {
      try {
        if (!isLoaded && props.user?.id) {
          await getWishlist(
            { lang: props.lang, userId: props.user.id },
            ac.signal
          );
          setIsLoaded(true);
          setWishlistLoaded(true);
        }
      } catch (err) {
        setIsLoaded(true);
        setWishlistLoaded(true);
        console.error(err.message);
      }
    };

    loadWishlist();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.id]);
  console.log(wishlist?.length);
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-myaccount-header">
              <div className="lable-text">
                {props.config.wishlist[props.lang] || props.config.wishlist.en}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="wishlist">
              {wishlist.length &&
                wishlist.map((item, i) => {
                  return <SingleWishList key={i} item={item} />;
                })}
              {isLoaded && !wishlist.length ? <NoDataFound /> : ""}
            </div>
          </div>
        </div>
        {/* <NoDataFound /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    wishlist: Object.values(state.wishlist.wishlist),
  };
};

export default connect(mapStateToProps, { getWishlist, clearConsultant })(
  SecWishList
);
