import React from 'react';
import { consultantLang } from '../../config/lang';
import NoDataFound from '../Admin/Sections/NoDataFound';

const About = ({ details, isLoaded, lang }) => {
  return (
    <div className="consultant-about">
      <div className="title">
        { consultantLang.biography[lang] || consultantLang.biography.en  }
      </div>
      {(details && details.consultant_details && details.consultant_details.bioLongDescription) ?
        <div className="text" 
          dangerouslySetInnerHTML={{ __html: details.consultant_details.bioLongDescription }}
        >
        </div>
        : isLoaded ? <NoDataFound /> : ''
      }
    </div>
  )
}

export default About;