import { host } from "../../config/host";
import { fetchData } from "../fetch";

export const getDashboardDataAsync = async (body = null, signal = null) => {
  try {

    const defaultParams = { 
      lang: localStorage.getItem('lang') || 'en', 
      userId: localStorage.getItem('userId') 
    }

    const params = !body ? defaultParams : { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/user/profile/dashboard`, params, signal);
    const data = await res.json();
    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}