import React from "react";
import { connect } from "react-redux";
import { addCard } from "../../../actions/payment";
import $ from "jquery";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
const StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleCard = async (e) => {
    e.preventDefault();
    props.setSpinner(true);

    const cardElement = elements.getElement("card");

    const token = await stripe.createToken(cardElement);

    if (props.user?.id) {
      if (token.error) {
        props.setSpinner(false);
        toast.error(token.error.message);
      } else {
        try {
          const cardData = await props.addCard({
            token: token.token.id,
            user_id: props.user.id,
          });
          props.setSpinner(false);
          toast.success(cardData?.message);
          elements.getElement("card").clear();
        } catch (err) {
          props.setSpinner(false);
        }
      }
    }
  };

  return (
    <form onSubmit={handleCard}>
      <CardElement options={{ hidePostalCode: true }} />
      {
        <div className="form-row mt-3">
          <div className="form-group col-md-12 mt-1">
            <div className="lable-text ml-auto">
              <button
                className="btn-universal"
                placement="top"
                type="submit"
                disabled={props.spinner}
              >
                <div className="d-flex align-items-center">
                  <span>
                    {props.config.addCard[props.lang] ||
                      props.config.addCard.en}
                  </span>
                  {props.spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      }
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { addCard })(StripeForm);
