import { combineReducers } from "redux";
import inteventionReducer from "./interventionReducer";
import specializationReducer from "./specializationReducer";
import theraputicReducer from "./theraputicReducer";
import mentalReducer from "./mentalReducer";

import psychologicalReducer from "./psychologicalReducer";
import otherReducer from "./otherReducer";

export default combineReducers({
  interventions: inteventionReducer,
  specializations: specializationReducer,
  theraputic: theraputicReducer,
  mental: mentalReducer,
  psychological: psychologicalReducer,
  others: otherReducer
})