import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getConsultantReviews } from '../../../actions/consultant';
import OverallRating from '../../Consultant/OverallRating';
import SingleReview from '../../Consultant/SingleReview';
import NoDataFound from './NoDataFound';

const SecReview = ({ getConsultantReviews, reviews, user, setReviewLoaded, config, lang }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstRating, setFirstRating] = useState(null);

  useEffect(() => {
    const loadReviews = async () => {
      if(!isLoaded && user?.role && user?.role === 'consultant') {
        try {
          await getConsultantReviews({ userId: user.id });
          setIsLoaded(true);
          setReviewLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          setReviewLoaded(true);
          console.error(err.message);
        }
      }
    }

    const loadFirstReview = () => {
      if(!firstRating && reviews) setFirstRating(reviews[0]);
    }

    loadReviews();
    
    loadFirstReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRating, getConsultantReviews, isLoaded, reviews, user.id, user?.role]);

  useEffect(() => {
    if(reviews.length && firstRating?.id) {
      let reliableAndTrustworthy = 0;
      let communication = 0;
      let empathyAndFlexibility = 0;

      reviews.forEach(review => {
        reliableAndTrustworthy += parseInt(review.reliable_and_trustworthy);
        communication += parseInt(review.communication);
        empathyAndFlexibility += parseInt(review.empathy_and_flexibility);
      });

      reliableAndTrustworthy = reliableAndTrustworthy / reviews.length;
      communication = communication / reviews.length;
      empathyAndFlexibility = empathyAndFlexibility / reviews.length;

      setFirstRating({  
        ...firstRating,
        reliable_and_trustworthy: `${reliableAndTrustworthy}`,
        communication: `${communication}`,
        empathy_and_flexibility: `${empathyAndFlexibility}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ reviews.length, firstRating?.id ]);

  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-12">
            <div className="consultant-reviews">
              <div className="title">{ config.reviews[lang] || config.reviews.en }</div>
              
              { firstRating && <OverallRating rating={firstRating} all={true} /> }

              { (reviews && reviews.length) ?
                <div className="review-list">
                  
                  { reviews.map(item => <SingleReview key={item.id} review={item} 

                    admin={true}
                  />) }
                </div>
                : isLoaded ? <NoDataFound /> : ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    user: state.auth.user,
    reviews: Object.values(state.consultants.consultantReviews).sort((a, b) => (
      new Date(b.date) - new Date(a.date)
    ))
  }
}

export default connect(mapStateToProps, { getConsultantReviews })(SecReview);