import React, { 
    useState, 
    // useEffect, 
    useRef 
} from 'react';
import { connect } from 'react-redux';
//import $ from 'jquery';
// import { Link, StaticRouter } from 'react-router-dom';
import { modalLang } from '../../config/lang';

const ClientReviewModal = props => {    
    const [disableConfirm, setDisableConfirm] = useState(false);
    //  const [spinner, setSpinner] = useState(false);
    const closeRef = useRef(null);

    const handleConfirm = async () => {
		setDisableConfirm(false);
        setDisableConfirm(false);
        closeRef.current.click();
    }
    return (
        <div className={`conversation-modal ${props.isShow}`} ref={props.ClientReviewModalRef}>
          <div className="modal fade" id="clientReview" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
               <div className="modal-body">
                    <button type="button" className="modal-close d-none" data-dismiss="modal" aria-label="Close" ref={closeRef} style={{ pointerEvents: 'none' }} >
                    <i className="fal fa-fw fa-times"></i>
                    </button>
                    <div className="title">
                        {  modalLang.attention[props.lang] || modalLang.attention.en}
                    </div>   
                    <div className="subtitle">                       
                        {
                            props.user?.role === 'user' ? 
                            modalLang.clientReviewContent[props.lang] || 
                            modalLang.clientReviewContent.en : 

                            props.user?.role === 'consultant' ? 

                            modalLang.consultantReviewContent[props.lang] || 
                            modalLang.consultantReviewContent.en :
                            
                            modalLang.defaultReview[props.lang] || 
                            modalLang.defaultReview.en
                        }
                    </div>                             
    
                    <div className="communication-mode w-100">                       
                        <div className="communication" style={{ width: '100%', maxWidth: 'unset' }}>
                            <button className="communication-label" style={{ background: 'var(--primaryColor)', color: '#fff', cursor: 'pointer' }} 
                            onClick={handleConfirm}
                            disabled={disableConfirm}
                            >
                            { modalLang.oksure[props.lang] || modalLang.oksure.en }
                            </button>
                        </div>
                    </div>    
                    <div className="form-group form-group-actions d-none">
                        <button className="btn-submit" type="button">Continue</button>
                    </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      )

}
const mapStateToProps = state => {
	return {
		lang: state.defaultLang,
		socket: state.socket,
		user: state.auth.user,
		lastAppointment: state.appointments.lastAppointment,
        // setReviewTab : state.setReviewTab,
        // isShow : state.isShow
	}
}

export default connect(mapStateToProps, null)(ClientReviewModal)

