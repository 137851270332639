import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getWishlist } from '../../actions/wishlist';
import { getConsultantListForIntervention } from '../../actions/consultant';
// import InterventionDetailSingle from './InterventionDetailSingle';
import PrefooterOverlay from '../../prefooterOverlay';
// import NoDataFound from '../Admin/Sections/NoDataFound';
import ConversationModal from '../Modals/ConversationModal';
import BookAppointmentModalAlt from '../Modals/BookAppointmentModalAlt';
import ConsultantItem from '../Consultant/ConsultantItem';

const SecInterventionDetail = ({ intervention, isLoaded, lang, user, details, getWishlist, setIntervention }) => {
  const [id, setId] = useState(null);
  const [consultants, setConsutants] = useState([]);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const ac = new AbortController();

    const loadWishlist = async () => {
      try {
        await getWishlist({ userId: user.id }, ac.signal);
      } catch(err) {
        console.error(err.message);
      }
    }

    if(user?.id) {
      loadWishlist();
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.id ]);

  useEffect(() => {
    const ac = new AbortController();

    const loadConsultants = async () => {
      try {
        const consultantData = await getConsultantListForIntervention({
          activeStatus: [],
          currency: "",
          gender: [],
          intervention: intervention.id,
          keyword: "",
          lang: lang,
          language: "",
          limit: limit,
          maxPrice: "",
          minPrice: "",
          modes: [],
          nationality: "",
          offset:offset,
          order: "",
          orderBy: "",
          specializationsMentalId: "",
          specializationsOtherId: "",
          specializationsPsychologicalId: "",
          specializationsTherapeuticId: ""
        }, ac.signal);

        setConsutants(consultantData);
      } catch(err) {
        console.error(err.message);
      }
    }

    if(intervention?.id) {
      loadConsultants();
    }

    setIntervention(intervention);

    return () => ac.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ intervention?.id ]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ConversationModal consultant={details} lang={lang} user={user} id={id} />
        <BookAppointmentModalAlt consultant={details} lang={lang} user={user} id={id} 
        />
      </div>
     

      <section className="sec-consultant prefooter-last">
        <div className="container">
          <div className="consultant-wrap">
            {
              consultants.length > 0 &&
              consultants.map(consultant => <ConsultantItem 
                key={consultant.id} 
                item={consultant} 
                setId={setId}
                lang={lang}
              />)
            }
          </div>
        </div>
        <PrefooterOverlay />
      </section>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    intervention: state.home.homeInterventions[ownProps.id],
    details: state.consultants.consultantDetails
  }
}

export default connect(mapStateToProps, { getWishlist })(SecInterventionDetail);