import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import InterventionForm from '../Children/Forms/InterventionForm';

const SecInterventionInfo = ({ history, config, lang }) => {

  return (
    <AccountContainer 
      title={
        config.interventionSpecialization[lang] || 
        config.interventionSpecialization.en
      }
    >
      <InterventionForm history={history} />
    </AccountContainer>
  );
}

export default SecInterventionInfo;