import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import {
  getConsultantServices,
  updateConsultantServices,
} from "../../../../actions/profile";
// import { lang } from '../../../../config/lang';
import ServiceFormSec from "./ServiceInfo/ServiceFormSec";
import AddMore from "./ServiceInfo/AddMore";
import SaveChanges from "../SaveChanges";
import { toast } from "react-toastify";
const AccountInfoForm = ({
  user,
  services,
  countries,
  getConsultantServices,
  allConsultantServices,
  updateConsultantServices,
  history,
  setServiceLoaded,
  lang,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState("");
  const [spinner, setSpinner] = useState(false);
  // const { status } = auth;
  const [isLoaded, setIsLoaded] = useState(false);
  const [serviceCount, setServiceCount] = useState(1);

  // Initialize dependencies
  const [firstService, setFirstService] = useState({
    serviceId: "1",
    duration: user.schedule_duration,
  });
  const [secondService, setSecondService] = useState({});

  // ServiceId
  const [firstServiceId, setFirstServiceId] = useState("");
  const [secondServiceId, setSecondServiceId] = useState("");

  // Duration
  const [firstDuration, setFirstDuration] = useState("");
  const [secondDuration, setSecondDuration] = useState("");

  //console.log(firstServiceId)

  // Currency
  const [firstCurrency, setFirstCurrency] = useState("");
  const [secondCurrency, setSecondCurrency] = useState("");

  useEffect(() => {
    const loadServices = async () => {
      if (!isLoaded && user && user?.id) {
        try {
          await getConsultantServices({ lang, userId: user?.id });
          setIsLoaded(true);
          setServiceLoaded(true);
        } catch (err) {
          setIsLoaded(true);
          setServiceLoaded(true);
          console.error(err.message);
        }
      }
    };

    loadServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, user]);

  useEffect(() => {
    if (allConsultantServices.length) {
      if (allConsultantServices[0]) {
        setFirstService({
          ...firstService,
          id: allConsultantServices[0].id,
          serviceId: getService(allConsultantServices[0].name, services)?.id,
          duration: user?.schedule_duration,
          price: allConsultantServices[0].price,
          currency: allConsultantServices[0].currency,
          mode: allConsultantServices[0].mode,
        });
        //setFirstStatus(true);
      }

      if (allConsultantServices[1]) {
        setSecondService({
          ...secondService,
          id: allConsultantServices[1].id,
          serviceId: getService(allConsultantServices[1].name, services)?.id,
          duration: user?.schedule_duration,
          price: allConsultantServices[1].price,
          currency: allConsultantServices[1].currency,
          mode: allConsultantServices[1].mode,
        });
        //setSecondStatus(true);
      }

      setServiceCount(allConsultantServices.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allConsultantServices.length, user]);

  const getService = (name, services) => {
    return services.find((el) => el.name === name);
  };

  const renderServiceForms = () => {
    if (isLoaded) {
      return new Array(serviceCount).fill().map((_, i) => {
        return (
          <ServiceFormSec
            key={i}
            services={services}
            index={i}
            allServices={allConsultantServices}
            custom={allConsultantServices.length ? true : false}
            firstService={firstService}
            setFirstService={setFirstService}
            secondService={secondService}
            setSecondService={setSecondService}
            countries={countries}
            deleteServiceForm={deleteServiceForm}
            user={user}
            history={history}
            serviceCount={serviceCount}
            firstCurrency={firstCurrency}
            setFirstCurrency={setFirstCurrency}
            secondCurrency={secondCurrency}
            setSecondCurrency={setSecondCurrency}
            firstServiceId={firstServiceId}
            firstDuration={firstDuration}
            setFirstServiceId={setFirstServiceId}
            setFirstDuration={setFirstDuration}
            secondServiceId={secondServiceId}
            secondDuration={secondDuration}
            setSecondServiceId={setSecondServiceId}
            setSecondDuration={setSecondDuration}
            lang={lang}
          />
        );
      });
    }
  };

  const handleAddMore = () => {
    if (serviceCount < 2) {
      setServiceCount(serviceCount + 1);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const id = [];
    firstService?.id ? id.push(firstService.id) : id.push("");
    if (secondService?.id) id.push(secondService?.id);
    if (secondService.serviceId && !secondService?.id) id.push("");

    const serviceId = [];
    firstServiceId ? serviceId.push(firstServiceId) : serviceId.push("");
    if (secondServiceId) serviceId.push(secondServiceId);

    const duration = [];
    firstDuration ? duration.push(firstDuration) : duration.push("");
    if (secondDuration) duration.push(secondDuration);

    const mode = [];
    firstService?.mode ? mode.push(firstService.mode) : mode.push("");
    if (secondService?.mode) mode.push(secondService.mode);
    if (secondService?.serviceId && !secondService?.mode) mode.push("");

    const price = [];
    firstService?.price ? price.push(firstService.price) : price.push("");
    if (secondService?.price) price.push(secondService.price);
    if (secondService?.serviceId && !secondService?.price) price.push("");

    const currency = [];
    firstCurrency ? currency.push(firstCurrency) : currency.push("");
    if (secondCurrency) currency.push(secondCurrency);
    if (secondService?.serviceId && !secondCurrency) currency.push("");

    try {
      const message = await updateConsultantServices({
        userId: user?.id,
        id,
        serviceId,
        duration,
        mode,
        price,
        currency,
        lang,
      });

      setSpinner(false);

      toast.success(message);
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };

  const deleteServiceForm = (index) => {
    if (index === 1) {
      setServiceCount(serviceCount - 1);
      setSecondService({});
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {renderServiceForms()}

      {isLoaded && serviceCount === 1 && (
        <AddMore handleAddMore={handleAddMore} />
      )}
      <SaveChanges spinner={spinner} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
    services: state.cms.serviceList,
    countries: Object.values(state.cms.countryList),
    allConsultantServices: Object.values(state.profile.consultantServices),
  };
};

export default connect(mapStateToProps, {
  getConsultantServices,
  updateConsultantServices,
})(AccountInfoForm);
