import { combineReducers } from "redux";
import profileInfoReducer from "./profileInfoReducer";
import consultantServicesReducer from "./consultantServiceReducer";
import dailyTimetableReducer from "./dailyTimeTable";
import stripeCustomerReducer from "./stripeCustomerReducer";

export default combineReducers({
  profileInfo: profileInfoReducer,
  consultantServices: consultantServicesReducer,
  dailyTimetable: dailyTimetableReducer,
  cards: stripeCustomerReducer
});