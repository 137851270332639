import { GET_SOCKET } from "../actions/types";

const socketReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_SOCKET:
      return action.payload;
    default:
      return state;
  }
}

export default socketReducer;