import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getAppointment } from "../../actions/appointments";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { clearConsultantReview } from "../../actions/consultant";
import Loader from "../../Loader";
import LayoutContainer from "../../containers/LayoutContainer";
import AdminContainer from "../../containers/AdminContainer";
import AppointmentDetailsContailer from "../../containers/Admin/AppointmentDetailsContailer";
import AppointmentDetailsNav from "../../components/Admin/AppointmentDetailsNav";
import SecAppointmentDetails from "../../components/Admin/Sections/SecAppointmentDetails";
import NoDataFound from "../../components/Admin/Sections/NoDataFound";
import ClientReviewModal from "../../components/Modals/ClientReviewModal";
import InvoiceModal from "../../components/Modals/InvoiceModal";

const AppointmentDetails = ({
  lang,
  clearConsultantReview,
  user,
  nationalities,
  siteSettings,
  languages,
  timezones,
  countries,
  location,
  getAppointment,
  appointments,
}) => {
  const params = useParams();
  const id = params.id;

  const [isShow, setIsShow] = useState("d-none");
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [, setReviewTab] = useState(false);
  const buttonRef = useRef(null);
  const [appointment, setAppointment] = useState(null);

  // useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition =
        user?.id &&
        nationalities.length &&
        !$.isEmptyObject(siteSettings) &&
        languages.length &&
        timezones.length &&
        countries.length &&
        isLoaded;

      if (condition) {
        // setLoader(true);
      }
    };

    handleLoader();
  }, [
    user,
    nationalities,
    siteSettings,
    languages,
    timezones,
    loader,
    countries,
    isLoaded,
  ]);

  const getDurationInNumber = (startTime, endTime) => {
    const dateDiff = new Date(endTime) - new Date(startTime);
    const duration = Math.ceil(((dateDiff % 86400000) % 3600000) / 60000);
    return duration;
  };
  const reviewTabRef = useRef(null);
  const getParam = new URLSearchParams(location.search).get("review_tab");

  useEffect(() => {
    clearConsultantReview();
    buttonRef.current.click();
    if (getParam) {
      setReviewTab(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      user?.role === "user" &&
      appointments.consultant_join_time &&
      appointments.appointment_end_time &&
      appointments.duration_in_min >= 1 &&
      getParam
    ) {
      setIsShow("");
    }
  }, [user, appointments, getDurationInNumber, setIsShow, getParam]);
  useEffect(() => {
    if (
      user?.role === "consultant" &&
      appointments.consultant_join_time &&
      appointments.appointment_end_time &&
      getParam
    ) {
      setIsShow("");
    }
  }, [user, appointments, setIsShow, getParam]);

  useEffect(() => {
    const ac = new AbortController();

    const loadAppointment = async () => {
      try {
        if (!isLoaded && user && user.id) {
          const newAppointment = await getAppointment(
            { lang, appointmentId: id, userId: user.id },
            ac.signal
          );
          setAppointment(newAppointment);
          if (newAppointment) setLoader(true);
          else setLoader(false);
        }
      } catch (err) {
        setLoader(true);
        console.error(err.message);
      }
    };

    loadAppointment();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);
  return (
    <>
      {appointment && (
        <div style={{ position: "relative" }}>
          <InvoiceModal appointment={appointment} user={user} lang={lang} />
        </div>
      )}
      <LayoutContainer admin={true}>
        <Loader loaded={loader} />
        <button
          className="d-none"
          ref={buttonRef}
          data-target="#clientReview"
          data-toggle="modal"
          data-keyboard="false"
          data-backdrop="static"
        ></button>
        <ClientReviewModal setReviewTab={setReviewTab} isShow={isShow} />

        {appointment ? (
          <>
            <AdminContainer title="Appointment Details">
              <AppointmentDetailsContailer>
                <AppointmentDetailsNav
                  reviewTabRef={reviewTabRef}
                  lang={lang}
                  reviewTab={getParam}
                  setReviewTab={setReviewTab}
                />
                <SecAppointmentDetails
                  id={id}
                  setAppointmentLoaded={setIsLoaded}
                  reviewTab={getParam}
                />
              </AppointmentDetailsContailer>
            </AdminContainer>
          </>
        ) : (
          <NoDataFound />
        )}
      </LayoutContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    appointments: state.appointments.lastAppointment,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
  };
};

export default connect(mapStateToProps, {
  clearConsultantReview,
  getAppointment,
})(AppointmentDetails);
