import React from "react";
import BusinessInfo from "./BusinessInfo";
import ServiceInfo from "./ServiceInfo";
import AddressInfo from "./AddressInfo";
import BirthInfo from "./BirthInfo";
import InsuranceInfo from "./InsuranceInfo";
import MembershipInfo from "./MembershipInfo";
import FiscalInfo from "./FiscalInfo";
import TermsAndConditionInfo from "./TermsAndConditionInfo";
import { accountLang } from "../../../../config/lang";

const Details = ({ user, appointment, countries, lang, reviewTab }) => {
  const config = {
    otherInfo: {
      en: "Other Information",
      es: "Servicio de información",
      it: "Informazioni sul servizio",
    },
  };
  console.log(user?.role);
  return (
    <div
      className={`tab-pane fade ${!reviewTab ? "show active" : ""}`}
      id="nav-details"
      role="tabpanel"
      aria-labelledby="nav-details-tab"
    >
      <div className="row">
        <div className="col-12">
          <div className="appointment-data">
            <div className="appointment-header">
              <div className="appointment-header-data">
                {user?.role === "user"
                  ? `${appointment.consultant_profile_name}`
                  : `${appointment.user_profile_name}`}
              </div>
            </div>

            <div className="appointment-content">
              <div className="row">
                <div className="col-lg-6">
                  <BusinessInfo
                    user={user}
                    appointment={appointment}
                    lang={lang}
                  />
                </div>

                {/* <AddressInfo user={user} appointment={appointment} countries={countries} 
                  lang={lang} /> */}

                {/* <BirthInfo user={user} appointment={appointment} countries={countries} 
                  lang={lang}
                /> */}

                <div className="col-lg-6">
                  {user.role === "user" && (
                    <MembershipInfo appointment={appointment} lang={lang} />
                  )}
                </div>
                <div className="col-lg-6">
                  <FiscalInfo
                    user={user}
                    appointment={appointment}
                    countries={countries}
                    lang={lang}
                  />
                </div>
                <div className="col-md-6">
                  <ServiceInfo appointment={appointment} lang={lang} />
                </div>

                {user.role === "user" && (
                  <div className="col-md-6">
                    <InsuranceInfo
                      user={user}
                      appointment={appointment}
                      lang={lang}
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <div className="appointment-title">
                    {config.otherInfo[lang] || config.otherInfo.en}
                  </div>
                  <div className="row form-row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="control-label control-label-note">
                          {accountLang.note[lang] || accountLang.note.en}
                        </div>
                        <div className="control-data control-data-note">
                          {user && user.role === "consultant"
                            ? appointment?.consultant_note
                            : appointment?.user_note}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <TermsAndConditionInfo lang={lang} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
