import React from 'react';

const Banner = ({ banner }) => {
  return (
    <section className="page-banner" 
      style={{ backgroundImage: `url(${banner?.image})` }}
    >
      <div className="container">
        <div className="banner-content">
          <div className="banner-title">{banner?.title}</div>
          <div
            className="banner-subtitle"
            dangerouslySetInnerHTML={{ __html: banner?.description }}
          ></div>
        </div>
      </div>
    </section>
  );
}

export default Banner;