import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setSiteDefaultLang } from '../actions/lang';
import { Route, withRouter } from 'react-router-dom';
import { allowedLangs } from '../config/lang';
import { onlineOffline } from '../middlewares/onlineOffline';

function HomeRoute({ path, component: Component, auth, setSiteDefaultLang, ...restOfProps }) {
  useEffect(() => {
    const handleOnlineOffline = async () => {
      try {
        await onlineOffline();
      } catch(err) {
        console.log(err.message);
      }
    }

    handleOnlineOffline();
  }, [ restOfProps.location.pathname ]);

  useEffect(() => {
    if(!allowedLangs.includes(restOfProps.computedMatch.params.lang)) {
      localStorage.setItem('lang', 'en')
      setSiteDefaultLang('en')
    } else {
      localStorage.setItem('lang', restOfProps.computedMatch.params.lang)
      setSiteDefaultLang(restOfProps.computedMatch.params.lang)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ restOfProps.computedMatch.params.lang ]);

  return (
    <Route 
      path={path}
      { ...restOfProps }
      render={(props) => <Component { ...props } />}
    />
  )
}

export default withRouter(connect(null, { setSiteDefaultLang })(HomeRoute));
