import { GET_SITE_SETTINGS } from '../../actions/types';

const siteSettingsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_SITE_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default siteSettingsReducer;