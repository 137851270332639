import React from 'react';
import { extractFileName, getExtension } from '../../helpers';
import { noImage } from '../../config/host';
import { hostUrl } from '../../config/host';

const SingleMessage = ({ message, user, appointment, prevMsg, nxtMsg, acPrevMsg }) => {
  const sameUser = parseInt(user?.id) === parseInt(message.sender_id);
  const consultant = parseInt(message.sender_id) === parseInt(appointment?.consultant_id);

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "ico", "jfif"];
  const docExtensions = ['doc', 'docx'];
  const pdfExtension = 'pdf';

  const getActualFile = file => {
    if(file) {
      const regex = /:"(.*)"/;
      const match = file.match(regex);
  
      return match[1] ? hostUrl + match[1] : '';
    }  
    
    return '';
  }

  return (
    <div 
      className={`comment${sameUser ? ' right' : ''} ${(prevMsg && prevMsg.sender_id === message.sender_id) && 'p-0'} ${(nxtMsg && nxtMsg.sender_id === message.sender_id) && 'pb-0'}`}
    >
      <div className="img-container" 
        style={{ border: acPrevMsg && acPrevMsg.sender_id === message.sender_id ? 'none' : '1px solid rgba(0,0,0,0.1)' }}
      >
        <img 
          src={consultant ? appointment?.consultant_image : appointment?.user_image} 
          alt={consultant ? appointment?.consultant_name : appointment?.user_profile_name}
          onError={(e) => e.target.src=`${noImage}`}
          className={acPrevMsg && acPrevMsg.sender_id === message.sender_id ? 'd-none' : ''}
        />
      </div>
      <div className="comment-content">
        <div className="comment-header">
          <div 
            className={`user-name ${(acPrevMsg && acPrevMsg.sender_id === message.sender_id) && 'd-none'}`}
          >
            {consultant ? appointment?.consultant_profile_name : appointment?.user_profile_name}
          </div>
          <div className="comment-time d-none">18:56 Hrs</div>
        </div>
        <div className="comment-body">
          <div className="comment-text-wrap">
            {message.comment && <div className="comment-text"
              style={{ background: 'var(--primaryColor)', color: '#fff' }}
            >
              { decodeURIComponent(message.comment) }
            </div>}
          </div>
          {/* <div className="comment-text-wrap">
            <div className="comment-text">Sed non mauris fringilla, pulvinar eros et, blandit metus.</div>
          </div> */}
        </div>
        { message.files &&
          <div className="comment-footer">
            <div className={`comment-files justify-content-${sameUser ? 'end' : 'start'}`}>
              <div className="file " style={{ background: 'var(--primaryColor)' }}>
                <div className="file-icon" style={{ color: '#fff' }}>
                  {
                    imageExtensions.includes(getExtension(getActualFile(message.files))) ? 
                    <i className="fad fa-fw fa-swap-opacity fa-file-image"></i> :
                    docExtensions.includes(getExtension(getActualFile(message.files))) ?
                    <i class="fas fa-file-word"></i> :
                    pdfExtension === getExtension(getActualFile(message.files)) ?
                    <i className="fad fa-fw fa-swap-opacity fa-file-pdf"></i>
                    : <i class="far fa-file"></i>
                  }
                </div>

                <div className="file-info">
                  <div className="file-name" style={{ color: '#fff' }}>
                    { extractFileName(getActualFile(message.files)) }
                  </div>
                </div>

                <div className="file-action" 
                  style={{ 
                    color: '#fff', borderTop: '1px solid rgba(255,255,255,0.2)',
                    padding: '0.7rem 0', display: 'flex'
                  }}
                >
                  <a href={getActualFile(message.files)} target="_blank" rel="noreferrer"
                    style={{ margin: '0 auto', color: '#fff' }}
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
            {/* <!--<div className="seen-by-list">
              <div className="seen-by" title="Michael R. Reynolds">
                <img src={Team2} alt=""/>
              </div>
            </div>--> */}
          </div>
        }
      </div>
    </div>
  )
}

export default SingleMessage
