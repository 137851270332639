import React from 'react';
import moment from 'moment';
import { acNavLangFormal, memberLang } from '../../../../config/lang';

const MembershipInfo = ({ appointment, lang }) => {
  return (
    <>
      <div className="appointment-title">
        { acNavLangFormal.member[lang] || acNavLangFormal.member.en }
      </div>
      <div className="row form-row mb-3">
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { memberLang.businessCity[lang] || memberLang.businessCity.en }
            </div>
            <div className="control-data">
              { appointment.consultant_membership_city }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { memberLang.membershipName[lang] || memberLang.membershipName.en }
            </div>
            <div className="control-data">
              { appointment.consultant_membership_name }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { memberLang.region[lang] || memberLang.region.en }
            </div>
            <div className="control-data">
            { appointment.consultant_membership_region }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { 
                memberLang.membershipRegistrationNumber[lang] || 
                memberLang.membershipRegistrationNumber.en 
              }
            </div>
            <div className="control-data">
              { appointment.consultant_membership_reg_no }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { 
                memberLang.membershipDateRegistration[lang] || 
                memberLang.membershipDateRegistration.en 
              }
            </div>
            <div className="control-data">
              { moment(appointment.consultant_membership_date_reg).format('YYYY-MM-DD') }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MembershipInfo
