import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getBlogDetail } from '../actions/blog';
import { withRouter } from 'react-router';
// import $ from 'jquery';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import SecBlogDetail from '../components/Sections/SecBlogDetail';

const BlogDetail = ({ match, nationalities, siteSettings, languages, lang, getBlogDetail }) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);

  useEffect(() => {
		const ac = new AbortController();
    setIsLoaded(false);

    const loadDetail = async (id) => {
      try {
        await getBlogDetail({ id, lang }, ac.signal);
        setIsLoaded(true);
      } catch(err) {
        console.error(err.message);
      }
    }

    loadDetail(match.params.id);
		
		return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ match.params.id, lang ]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && imgLoader && isLoaded) {
        setLoader(true)
      } else {
        setLoader(false);
      }
    }

    handleLoader();
  }, [loader, imgLoader, languages, nationalities, siteSettings, isLoaded])

  return (
    <LayoutContainer>
      <Loader loaded={loader} />
      <SecBlogDetail params={match.params} setImgLoader={setImgLoader} />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    blogs: Object.values(state.blogs.blogList)
  }
}

export default withRouter(connect(mapStateToProps, { getBlogDetail })(BlogDetail));