import { GET_USER, VERIFY_USER, REGISTER, LOGIN, VERIFY_OTP } from "../../actions/types";

const authReducer = (state = { status: false, pending: false }, action) => {
  switch(action.type) {
    case REGISTER:

      return { ...state, pending: true };
    case VERIFY_OTP:
      return { ...state, pending: false };
    case LOGIN:
      return { ...state, status: true, pending: false };
    case GET_USER:
      return { ...state, status: true };
    case VERIFY_USER:
      return { ...state, status: true };
    default:
      return state;
  }
}

export default authReducer;