import React from 'react';
import { connect } from 'react-redux';
import { dailyLang } from '../../../config/lang';
import DailyScheduleForm from './Forms/DailyScheduleForm';
import SaveChanges from './SaveChanges';

const DailyScheduleFormSec = ({ 
  state, setState, onSubmit, intervalDuration, setIntervalDuration, scheduleDuration, currentDate,
  lang, spinner
}) => {
  return (
    <form onSubmit={onSubmit}>
      {
        Object.values(state).map((item, i) => {
          return <DailyScheduleForm key={i} index={i} item={item} state={state} 
            setState={setState} currentDate={currentDate}
          />
        })
      }

      <div className="form-row">
        <div className="col-6 col-md-3">
          <div className="form-group">
            <label className="control-label">
              { dailyLang.restTimeInterval[lang] || dailyLang.restTimeInterval.en }
            </label>
            <input type="number" className="form-control" 
              placeholder={ dailyLang.sd[lang] || dailyLang.sd.en } 
              value={intervalDuration} min={0}
              onChange={(e) => setIntervalDuration(e.target.value)}
            />
          </div>
        </div>

        <div className="col-6 col-md-3">
          <div className="form-group">
            <label className="control-label">
              { dailyLang.scheduleDuration[lang] || dailyLang.scheduleDuration.en }
            </label>
            <input type="text" className="form-control" 
              placeholder={ dailyLang.sd[lang] || dailyLang.sd.en } 
              defaultValue={scheduleDuration}
              readOnly
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
        </div>
      </div>

      <SaveChanges spinner={spinner} />
    </form>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(DailyScheduleFormSec);
