import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPages } from '../actions/cms';
import { GET_PAGES_BLOG } from '../actions/types';
// import { lang } from '../config/lang';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import PageBanner from '../components/Blog/PageBanner';
import BlogPage from '../components/Blog/BlogPage';

const Blog = ({ getPages, blog, banners, siteSettings, languages, lang }) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    const loadBlogPage = async () => {
      if(!blog?.id) {
        try {
          await getPages({ lang, slug: 'blog' }, GET_PAGES_BLOG);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.error(err.message);
        }
      }
    }

    loadBlogPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, blog?.id ]);

  useEffect(() => {
    const loadBanner = () => {
      if(!banner && blog && banners) {
        const blogBanner = banners.find(el => el.page_id === blog.id);
        setBanner(blogBanner);
      } 
    }

    loadBanner();
  }, [banner, banners, blog])

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && (blog?.id || isLoaded)) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [isLoaded, blog?.id, languages.length, siteSettings]);

  return (
    <LayoutContainer
      seoTitle={blog?.seo?.title}
      seoDescription={blog?.seo?.description}
      seoKeywords={blog?.seo?.keywords} 
    >
      <Loader loaded={loader} />
      <PageBanner banner={banner} />
      <BlogPage />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    blog: state.cms.pages.blog,
    banners: Object.values(state.home.banners),
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    blogs: Object.values(state.blogs.blogList)
  }
}

export default connect(mapStateToProps, { getPages })(Blog);