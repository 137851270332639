import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import $ from "jquery";
import {
  getAppointmentMessages,
  clearAppointmentMessages,
  setMessages,
  setMessage,
} from "../actions/messages";
import {
  endAppointment,
  getAppointment,
  removeInprogressAppointment,
  removeUpcomingAppointment,
  endAppointmentMailSend,
  setLastAppointment,
  consultantJoin,
} from "../actions/appointments";
import { getConsultantDetails, clearConsultant } from "../actions/consultant";
import { updateActiveStatus } from "../actions/profile";
import Loader from "../Loader";
import Battery from "react-device-battery";
import LayoutContainer from "../containers/LayoutContainer";
import SecMessages from "../components/Message/SecMessages";
import SecAudioVideo from "../components/Message/SecAudioVideo";
import history from "../config/history";
import disconnect from "../assets/audio/disconnect.mp3";
import { convertIsoTime } from "../helpers";
import { toast } from "react-toastify";
import { langConfig } from "../config/lang";
const Message = (props) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [type, setType] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [appointmentEnded, setAppointmentEnded] = useState(false);
  const scrollRef = useRef(null);
  const params = useParams();
  const [battery, setBattery] = useState({ level: 100, charging: false });
  const [batteryPercentage, setBatteryPercentage] = useState(100);
  const appointmentId = params.id;
  const rcv = "true";

  // Init
  useEffect(() => {
    props.clearAppointmentMessages();

    if (
      typeof scrollRef?.current?.scrollTop === "number" &&
      typeof scrollRef.current?.scrollHeight === "number"
    ) {
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle type
  useEffect(() => {
    const handleType = () => {
      if (appointment && appointment.appointment_type === "1") {
        setType("direct");
      } else if (appointment && appointment?.appointment_type === "2") {
        setType("schedule");
      }
    };

    handleType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.appointment_type]);

  // Set last appointment
  useEffect(() => {
    if (appointment?.ids) {
      if (
        // props.user?.id && props.user?.role === 'consultant' &&
        appointment?.consultant_join === "3"
      ) {
        setLastAppointment(appointment);

        if (appointment?.message) {
          toast.success(appointment.message);
        }

        history.push(
          `/${props.lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lang, appointment, props.user?.id]);

  // Set timer
  useEffect(() => {
    const joinTime = new Date().toISOString();

    const setTimer = async () => {
      try {
        if (
          appointment?.consultant_join === "1" &&
          appointment?.user_join === "1" &&
          !appointment?.consultant_join_time
        ) {
          setAppointment({
            ...appointment,
            consultant_join: "1",
            consultant_join_time: joinTime,
          });

          await props.consultantJoin({
            lang: props.lang,
            appointmentId: appointment.appointment_id,
            consultantJoin: "1",
            consultantJoinTime: joinTime,
          });

          let modifiedJoinTime = joinTime.replace("Z", "");
          modifiedJoinTime = `${new Date(modifiedJoinTime).getFullYear()}-${
            new Date(modifiedJoinTime).getMonth() + 1
          }-${new Date(modifiedJoinTime).getDate()} ${new Date(
            modifiedJoinTime
          ).getHours()}:${new Date(modifiedJoinTime).getMinutes()}:${new Date(
            modifiedJoinTime
          ).getSeconds()}`;

          if (props.socket?.io) {
            props.socket.emit("appintmentDetails", {
              ...appointment,
              consultant_join: "1",
              consultant_join_time: modifiedJoinTime,
            });
          }
        }
      } catch (err) {
        setAppointment({
          ...appointment,
          consultant_join: "1",
          consultant_join_time: joinTime,
        });
        console.log(err.message);
      }
    };

    if (props.user?.role === "consultant") setTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.socket,
    props.lang,
    props.user?.role,
    appointment?.consultant_join,
    appointment?.consultant_join_time,
    appointment?.user_join,
  ]);

  // Handle timer
  useEffect(() => {
    const handleTimer = () => {
      if (
        appointment &&
        appointment?.consultant_join_time &&
        !appointmentEnded &&
        appointment?.user_join === "1" &&
        appointment?.consultant_join === "1"
      ) {
        const currentTime = convertIsoTime().getTime();

        const consultantJoinTime = new Date(
          appointment?.consultant_join_time
        ).getTime();

        let calculatedSeconds = Math.round(
          (currentTime - consultantJoinTime) / 1000
        );

        if (calculatedSeconds < 0) {
          calculatedSeconds = 0;
        }

        setSeconds(calculatedSeconds);
      }
    };

    const interval = setInterval(() => handleTimer(), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointmentEnded,
    appointment?.consultant_join_time,
    appointment?.consultant_join,
    appointment?.user_join,
  ]);

  // Auto redirect
  useEffect(() => {
    const autoRedirectExec = async () => {
      if (props.user?.role === "consultant") {
        // setAppointmentEnded(true);

        await props.updateActiveStatus({
          lang: props.lang,
          userId: props.user?.id,
          activeStatus: "1",
        });
      } else if (appointment?.consultant_id) {
        props.socket.emit("updateConsultantStatus", {
          userId: appointment.consultant_id,
          activeStatus: "1",
          isManual: "2",
        });
      }

      props.removeInprogressAppointment(appointment.ids);
      props.removeUpcomingAppointment(appointment.ids);

      localStorage.removeItem("opentok_sessionId");
      localStorage.removeItem("opentok_token");

      if (appointment?.success_message && !appointmentEnded) {
        toast.success(appointment.success_message);
      }
    };

    const handleAutoRedirect = async () => {
      if (
        appointment &&
        type &&
        type === "direct" &&
        appointment?.appointment_end_time
      ) {
        try {
          await autoRedirectExec();
        } catch (err) {
          await autoRedirectExec();
        }

        history.push(
          `/${props.lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`
        );
      } else if (
        appointment &&
        type &&
        type === "schedule" &&
        appointment?.appointment_end_time
      ) {
        if (new Date(appointment.appointment_end_time) <= convertIsoTime()) {
          try {
            await autoRedirectExec();
          } catch (err) {
            await autoRedirectExec();
          }

          history.push(
            `/${props.lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`
          );
        }
      } else if (appointment && appointment?.status === "3") {
        try {
          await autoRedirectExec();
        } catch (err) {
          await autoRedirectExec();
        }

        history.push(
          `/${props.lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`
        );
      }
    };

    handleAutoRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.lang,
    props.user?.id,
    props.user?.role,
    type,
    appointment?.status,
    appointment?.appointment_end_time,
  ]);

  // Load consultant details
  useEffect(() => {
    const loadConsultantDetails = async () => {
      clearConsultant();
      try {
        if (appointment && appointment?.consultant_id) {
          await props.getConsultantDetails({
            id: appointment.consultant_id,
            lang: props.lang,
          });
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    loadConsultantDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lang, appointment?.consultant_id]);

  // Load messages
  useEffect(() => {
    const loadMessages = async () => {
      if (props.user?.id && appointment?.appointment_id && props.socket?.io) {
        try {
          // await props.getAppointmentMessages({
          //   lang: props.lang, userId: props.user.id, appointmentId: appointment?.appointment_id
          // });

          if (props.socket?.io && appointment?.appointment_id) {
            props.socket.emit("getMessageList", {
              appointment_id: appointment.appointment_id,
              session_user_id: props.user.id,
              is_scroll_up: "",
            });
          }

          setIsLoaded(true);
        } catch (err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      }
    };

    loadMessages();

    if (props.socket?.io) {
      props.socket.on("get-message-list", (res) => {
        props.setMessages(res.result);
      });

      props.socket.on("get-last-message", (res) => {
        if (
          res.result[0] &&
          res.appointment_id === appointment?.appointment_id
        ) {
          props.setMessage(res.result[0]);
        }
      });
    }

    //const interval = setInterval(() => loadMessages(), 60000);

    //return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointment?.appointment_id,
    props.socket,
    props.lang,
    props.user?.id,
    props.messages.length,
  ]);

  // Load appointment
  useEffect(() => {
    const ac = new AbortController();
    const { socket } = props;

    const loadAppointment = async () => {
      try {
        const appointmentDetails = await props.getAppointment(
          { lang: props.lang, appointmentId },
          ac.signal
        );

        setAppointment(appointmentDetails);

        if (socket?.io) {
          socket.emit("appintmentDetails", appointmentDetails);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    // const interval = setInterval(() => loadAppointment(), 1000);

    loadAppointment();

    if (socket?.io) {
      socket.on("appointment-details", async (res) => {
        if (res.appointment_id === appointment?.appointment_id) {
          if (res.staus === "3" && res.message) {
            toast.success(res.message);
          }

          setAppointment({ ...res });
          if (props.user?.role === "consultant") props.setLastAppointment(res);
        }
      });

      window.onbeforeunload = () => {
        socket.emit("browserClosed", {
          appointmentId: appointment?.appointment_id,
          user_id: props.user?.id,
          last_active: convertIsoTime(),
        });
      };
    }

    return () => {
      ac.abort();
      // clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointment?.appointment_end_time,
    props.user?.role,
    props.lang,
    props.socket,
  ]);

  // Handle appointment service
  useEffect(() => {
    const handleAppointmentService = () => {
      if (
        appointment?.service_type &&
        appointment?.service_type !== "1" &&
        props.socket?.io &&
        props.user?.id
      ) {
        props.socket.emit("userConsultantJoin", {
          user_join: "1",
          consultant_join: appointment.consultant_join,
        });
      }
    };

    handleAppointmentService();
  }, [
    props.user?.id,
    props.socket,
    appointment?.consultant_join,
    appointment?.service_type,
  ]);

  // Handle loader
  useEffect(() => {
    if (
      Object.keys(props.siteSettings).length &&
      props.languages.length &&
      props.user?.id &&
      isLoaded
    ) {
      setLoader(true);
    }
  }, [
    props.nationalities,
    props.siteSettings,
    props.languages,
    props.user?.id,
    isLoaded,
  ]);

  // Auto scroll
  useEffect(() => {
    if (
      typeof scrollRef?.current?.scrollTop === "number" &&
      typeof scrollRef?.current?.scrollHeight === "number"
    ) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef, props.messages.length]);

  // End appointment
  useEffect(() => {
    const ac = new AbortController();
    const directConditon =
      type &&
      type === "direct" &&
      seconds >= 3600 &&
      appointment &&
      props.consultant?.consultant_details;

    const timezone = props.timezones[props.user?.timezone]?.zone;

    const scheduleCondition =
      timezone &&
      type &&
      type === "schedule" &&
      appointment &&
      appointment?.appointment_end_time &&
      props.consultant?.consultant_details &&
      new Date(appointment?.appointment_end_time) <= convertIsoTime();

    const endAppointmentExec = async (reject = "2") => {
      try {
        if (!appointmentEnded) {
          setAppointmentEnded(true);

          const endTime = new Date().toISOString();

          await props.endAppointment(
            {
              lang: props.lang,
              appointmentEndTime: endTime,
              appointmentId: appointment.appointment_id,
              perMinuteAmount:
                props.consultant.consultant_details?.servicePrice,
              reject,
            },
            ac.signal,
            appointment.ids
          );
          props.endAppointmentMailSend(
            {
              lang: props.lang,
              appointment_id: appointment.appointment_id,
            },
            null,
            appointment.ids
          );
          if (props.socket?.io) {
            props.socket.emit("appintmentDetails", {
              ...appointment,
              appointment_end_time: endTime,
              ended: true,
            });
            props.socket.emit("endAppointment", { ...appointment });
          }

          if (props.user?.role === "consultant") {
            await props.updateActiveStatus({
              lang: props.lang,
              userId: props.user.id,
              activeStatus: "1",
            });
          } else if (appointment?.consultant_id) {
            props.socket.emit("updateConsultantStatus", {
              userId: appointment.consultant_id,
              activeStatus: "1",
              isManual: "2",
            });
          }

          localStorage.removeItem("opentok_sessionId");
          localStorage.removeItem("opentok_token");

          new Audio(disconnect).play();
          props.clearAppointmentMessages();
          history.push(
            `/${props.lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`
          );
        }
      } catch (err) {
        setAppointmentEnded(false);
        console.log(err.message);
        // $.dialog({ title: 'Failure', content: err.message });
      }
    };

    const endOngoingAppointment = async () => {
      if (
        directConditon ||
        (appointment && appointment?.consultant_join === "3")
      ) {
        try {
          await endAppointmentExec();
        } catch (err) {
          await endAppointmentExec("1");
        }
      } else if (
        scheduleCondition ||
        (appointment && appointment?.consultant_join === "3")
      ) {
        try {
          await endAppointmentExec();
        } catch (err) {
          await endAppointmentExec("1");
        }
      }
    };

    endOngoingAppointment();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.socket,
    props.user?.id,
    props.user?.role,
    props.lang,
    type,
    appointment?.consultant_join,
    appointment?.appointment_end_time,
    props.consultant,
    seconds,
    appointment,
  ]);
  useEffect(() => {
    const ac = new AbortController();
    const endAppointmentExec = async (reject = "2") => {
      try {
        if (!appointmentEnded) {
          setAppointmentEnded(true);
          const endTime = new Date().toISOString();
          await props.endAppointment(
            {
              lang: props.lang,
              appointmentEndTime: endTime,
              appointmentId: appointment.appointment_id,
              perMinuteAmount:
                props.consultant.consultant_details?.servicePrice,
              reject,
            },
            ac.signal,
            appointment.ids
          );
          props.endAppointmentMailSend(
            {
              lang: props.lang,
              appointment_id: appointment.appointment_id,
            },
            null,
            appointment.ids
          );
          if (props.socket?.io) {
            props.socket.emit("appintmentDetails", {
              ...appointment,
              appointment_end_time: endTime,
              ended: true,
            });
            props.socket.emit("endAppointment", { ...appointment });
          }

          if (props.user?.role === "consultant") {
            await props.updateActiveStatus({
              lang: props.lang,
              userId: props.user.id,
              activeStatus: "1",
            });
          } else if (appointment?.consultant_id) {
            props.socket.emit("updateConsultantStatus", {
              userId: appointment.consultant_id,
              activeStatus: "1",
              isManual: "2",
            });
          }

          localStorage.removeItem("opentok_sessionId");
          localStorage.removeItem("opentok_token");
          new Audio(disconnect).play();
          props.clearAppointmentMessages();

          history.push(
            `/${props.lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`
          );
        }
      } catch (err) {
        console.log(err);
        setAppointmentEnded(false);
      }
    };
    const endOngoingAppointment = async () => {
      await endAppointmentExec();
    };
    if (batteryPercentage) {
      if (batteryPercentage < 11) {
        toast.error(
          langConfig.lowBattery[props.lang] || langConfig.lowBattery.en
        );
      }
      if (batteryPercentage < 7) {
        endOngoingAppointment();
      }
    }

    return () => ac.abort();
  }, [
    props.socket,
    props.user?.id,
    props.user?.role,
    props.lang,
    type,
    appointment?.consultant_join,
    appointment?.appointment_end_time,
    props.consultant,
    seconds,
    batteryPercentage,
  ]);

  return (
    <>
      {appointment && appointment?.service_type === "1" ? (
        <LayoutContainer>
          <Loader loaded={loader} />
          <SecMessages
            messages={props.messages}
            lang={props.lang}
            user={props.user}
            appointmentId={appointmentId}
            consultantId={appointment?.consultant_id}
            appointment={appointment}
            scrollRef={scrollRef}
            seconds={seconds}
            rcv={rcv}
            setAppointmentEnded={setAppointmentEnded}
            setJoin={props.setJoin}
            socket={props.socket}
          />
        </LayoutContainer>
      ) : (
        <>
          {appointment && appointment?.service_type !== "1" && (
            <>
              <Loader loaded={loader} />
              <SecAudioVideo
                messages={props.messages}
                lang={props.lang}
                user={props.user}
                appointmentId={appointmentId}
                consultantId={appointment?.consultant_id}
                appointment={appointment}
                scrollRef={scrollRef}
                seconds={seconds}
                rcv={rcv}
                setAppointmentEnded={setAppointmentEnded}
                setJoin={props.setJoin}
              />
            </>
          )}
        </>
      )}
      <Battery
        onChange={(battery) => {
          setBatteryPercentage(battery);
        }}
        render={({ battery }) => <p></p>}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    socket: state.socket,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    messages: Object.values(state.appointmentMessages),
    timezones: Object.values(state.cms.timeZoneList),
    consultant: state.consultants.consultantDetails,
  };
};

export default connect(mapStateToProps, {
  getAppointment,
  endAppointmentMailSend,
  clearConsultant,
  getConsultantDetails,
  getAppointmentMessages,
  clearAppointmentMessages,
  endAppointment,
  removeInprogressAppointment,
  setLastAppointment,
  updateActiveStatus,
  consultantJoin,
  removeUpcomingAppointment,
  setMessages,
  setMessage,
})(Message);
