import React from "react";
import { Link } from "react-router-dom";
import { modalLang } from "../../config/lang";
import { getKeyByValue, getSingleName } from "../../helpers/index";

const ProfileCompleteCheckModal = ({ setShown, lang, shown, redirectUrl }) => {
  const close = () => {
    setShown(false);
  };

  const redirectRoute = {
    myaccount: "myaccount",
    fiscalInfo: "fiscal-info",
    paymentInfo: "payment-info",
    memberInfo: "member-info",
    insuranceInfo: "insurance-info",
    biographyInfo: "biography-info",
    interventionSpecialization: "intervention-specialization",
    serviceInfo: "service-info",
    dailySchedule: "daily-schedule",
  };
  const completefiscalInfo = [
    { id: "en", name: "Complete Billing Info" },
    { id: "es", name: "Información de facturación completa" },
    { id: "it", name: "Informazioni di fatturazione complete" },
  ];
  const completemyaccount = [
    { id: "en", name: "Complete Personal Info" },
    { id: "es", name: "Información personal completa" },
    { id: "it", name: "Informazioni personali complete" },
  ];
  const completememberInfo = [
    { id: "en", name: "Complete Member Info" },
    { id: "es", name: "Información completa del miembro" },
    { id: "it", name: "Completa le informazioni sui membri" },
  ];
  const completeserviceInfo = [
    { id: "en", name: "Complete Service Info" },
    { id: "es", name: "Información completa del servicio" },
    { id: "it", name: "Informazioni di servizio complete" },
  ];
  const completedailySchedule = [
    { id: "en", name: "Complete Daily Schedule Info" },
    { id: "es", name: "Información completa del horario diario" },
    { id: "it", name: "Informazioni complete sul programma giornaliero" },
  ];
  const completeinterventionSpecialization = [
    { id: "en", name: "Complete Intervention & Specialization Info" },
    {
      id: "es",
      name: "Información completa de intervención y especialización",
    },
    {
      id: "it",
      name: "Informazioni complete sull intervento e sulla specializzazione",
    },
  ];
  const completeinsuranceInfo = [
    { id: "en", name: "Complete Insurance Info" },
    { id: "es", name: "Información completa del seguro" },
    { id: "it", name: "Informazioni complete sull assicurazione" },
  ];
  const completebiographyInfo = [
    { id: "en", name: "Complete Biography Info" },
    { id: "es", name: "Información Biográfica Completa" },
    { id: "it", name: "Informazioni complete sulla biografia" },
  ];
  const completepaymentInfo = [
    { id: "en", name: "Complete Payment Info" },
    { id: "es", name: "Información de pago completa" },
    { id: "it", name: "Informazioni di pagamento non complete" },
  ];

  return (
    <div className="conversation-modal">
      <div
        className={`modal fade${shown ? " show" : ""}`}
        id="endAppointment"
        tabIndex="-1"
        style={{
          display: `${shown ? "block" : "none"}`,
          paddingRight: `${shown ? "17px" : "none"}`,
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="title text-center mb-0"
                style={{ fontSize: "25px" }}
              >
                {modalLang.confirmationAlert[lang] ||
                  modalLang.confirmationAlert.en}
              </div>
              <div className="py-3 text-center">
                {redirectRoute && redirectUrl
                  ? getSingleName(
                      lang,
                      eval(
                        "complete" + getKeyByValue(redirectRoute, redirectUrl)
                      )
                    )
                  : modalLang.completeProfile[lang]}
              </div>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4"></div>
                </div>
              </div>

              <div
                className="communication-mode w-100"
                style={{ justifyContent: "center" }}
              >
                <div
                  className="communication"
                  style={{ width: "50%", maxWidth: "unset", display: "flex" }}
                >
                  <Link
                    to={`/${lang}/${redirectUrl}`}
                    className="communication-label bg-danger"
                    type="button"
                    style={{
                      color: "#fff",
                      cursor: "pointer",
                      borderRadius: "6px",
                      background: "linear-gradient(135deg,#fd2fb2,#f9bd44)",
                    }}
                    onClick={close}
                  >
                    {modalLang.oksure[lang] || modalLang.oksure.en}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompleteCheckModal;
