import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import LanguageDropdown from './LanguageDropdown';
import { updateLanguageInfo } from '../../../../actions/profile';
import { langConfig } from '../../../../config/lang';

import SaveChanges from '../SaveChanges';
import { toast } from 'react-toastify';
const AccountInfoForm = ({ auth, user, defLang, updateLanguageInfo }) => {
  const [spinner, setSpinner] = useState(false);
  const { status } = auth;
  const [lang, setLang] = useState(null);
  const [langName, setLangName] = useState(null);
  const langs = [
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Spanish' },
    { id: 'it', name: 'Italian' }
  ]

  useEffect(() => {
    if(user?.default_lang) {
      setLang(user?.default_lang || 'en');
      setLangName(langs.find(lang => lang.id === user?.default_lang)?.name || 'English');
    } else {
      setLang('en');
      setLangName('English');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.default_lang ]);

  useEffect(() => {
    manageLangs(user?.default_lang, lang, setLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.default_lang ]);

  const manageLangs = (value, target, callback) => {
    
    if(value && !target) { callback(value) }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');
    setSpinner(true);

    if(userId && status) {
      try {
        const userData = { language: lang, userId, lang: defLang };
        const message = await updateLanguageInfo(userData);
        setSpinner(false);
        toast.success(message);
       
      } catch(err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  }

  return (
    
    <form onSubmit={onSubmit}>
      <div className="form-row">
        <div className="col-md-12">
          <LanguageDropdown 
            lang={defLang}
            selectedLang={lang}
            langs={langs}
            setLang={setLang}
            langName={langName}
            setLangName={setLangName}
          />
        </div>
      </div>
      <SaveChanges spinner={spinner} />
    </form>
  )
}

const mapStateToProps = state => {
  return {
    defLang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { updateLanguageInfo })(AccountInfoForm);