import React from 'react';
import { noImage } from '../../../config/host';

const SingleNote = ({ note }) => {
  return (
    <div className="email">
      <div className="e-image">
        <img 
          src={note.user_image}
          alt={note.user_name} 
          onError={(e) => e.target.src=`${noImage}`} 
        />
      </div>
      <div className="e-content">
        <div className="e-name">{ note.user_name }</div>
        <div className="e-text">{ note.note }</div>
      </div>
    </div>
  )
}

export default SingleNote
