import React from 'react';
import { Link } from 'react-router-dom';
// import { jsVoid } from '../../config/jsVoid';

const ServiceItem = ({ service }) => {
  return (
    <Link to="/" className="item" onClick={(e) => e.preventDefault()}>
      <div className="icon">
        <i className={"fal fa-fw " + service.icon}></i>
      </div>
      <div className="item-title">{ service.name }</div>
      <div className="item-text" dangerouslySetInnerHTML={{ __html: service.description }}>
      </div>
    </Link>
  )
}

export default ServiceItem;