import React from 'react';
import SingleNote from '../Children/SingleNote';
import NoteModal from '../Children/Modals/NoteModal';

const SecNoteList = ({ notes, lang, id, consultantId, config }) => {
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-myaccount-header">
              <div className="lable-text">
                { config.clientNotes[lang] || config.clientNotes.en }
              </div>
              <button className="btn btn-primary" data-toggle="modal" data-target="#noteModal"
                style={{ cursor: 'pointer !important' }}
              >
                { config.add[lang] || config.add.en }
              </button>
            </div>

            <div style={{ position: 'relative' }}>
              <NoteModal userId={id} consultantId={consultantId} lang={lang} 
                config={config}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="emails-list">
              {
                notes.length ? 
                notes.map(item => <SingleNote key={item.id} note={item} />)
                : ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecNoteList
