import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { consultantLang } from '../../config/lang';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecInterventionInfo from '../../components/Admin/Sections/SecInterventionInfo';


const InterventionInfo = ({ 
  history, user, nationalities, siteSettings, languages, timezones, countries,
  interventions, theraputicList, mentalList, psychologicalList, otherList, lang
}) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      Object.keys(siteSettings).length && languages.length && timezones.length && countries.length
      && interventions.length && theraputicList.length && mentalList.length 
      && psychologicalList.length && otherList.length;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, 
  [user, nationalities, siteSettings, languages, timezones, loader, countries, interventions, theraputicList, mentalList, psychologicalList, otherList]);

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer 
        title={
          consultantLang.interventionSpecialization[lang] || 
          consultantLang.interventionSpecialization.en
        }
      >
        <SecInterventionInfo history={history} config={consultantLang} lang={lang} />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    interventions: Object.values(state.cms.interventionSpecialization.interventions),
    specializations: Object.values(state.cms.interventionSpecialization.specializations),
    theraputicList: Object.values(state.cms.interventionSpecialization.theraputic),
    mentalList: Object.values(state.cms.interventionSpecialization.mental),
    psychologicalList: Object.values(state.cms.interventionSpecialization.psychological),
    otherList: Object.values(state.cms.interventionSpecialization.others)
  }
}

export default connect(mapStateToProps, null)(InterventionInfo);