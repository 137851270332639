import React from "react";
import { accountLang, fiscalLang } from "../../../../config/lang";

const FiscalInfo = ({ user, appointment, countries, lang }) => {
  const config = {
    fiscalInformation: {
      en: "Fiscal Information",
      es: "Información Fiscal",
      it: "Informazioni fiscali",
    },
  };

  return (
    <>
      <div className="appointment-title">
        {config.fiscalInformation[lang] || config.fiscalInformation.en}
      </div>
      <div className="row form-row mb-3">
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label ">
              {fiscalLang.businessAddress[lang] ||
                fiscalLang.businessAddress.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_address}`
                : `${appointment.user_fiscal_address}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label ">
              {fiscalLang.businessCity[lang] || fiscalLang.businessCity.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_city}`
                : `${appointment.user_fiscal_city}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label ">
              {fiscalLang.fiscalCode[lang] || fiscalLang.fiscalCode.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_code}`
                : `${appointment.user_fiscal_code}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label ">
              {fiscalLang.pec[lang] || fiscalLang.pec.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_pec}`
                : `${appointment.user_fiscal_pec}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {fiscalLang.businessProvince[lang] ||
                fiscalLang.businessProvince.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_province}`
                : `${appointment.user_fiscal_province}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {accountLang.personalEmail[lang] || accountLang.personalEmail.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_personal_email}`
                : `${appointment.personal_email}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {fiscalLang.sdi[lang] || fiscalLang.sdi.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_sdi || ""}`
                : `${appointment.user_fiscal_sdi || ""}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {fiscalLang.tIc[lang] || fiscalLang.tIc.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_vatno || ""}`
                : `${appointment.user_fiscal_vatno || ""}`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {fiscalLang.businessCountry[lang] ||
                fiscalLang.businessCountry.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${
                    countries.find(
                      (el) => el.id === appointment.consultant_fiscal_country
                    )?.name
                  }`
                : `${
                    countries.find(
                      (el) => el.id === appointment.user_fiscal_country
                    )?.name
                  }`}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {accountLang.zipCode[lang] || accountLang.zipCode.en}
            </div>
            <div className="control-data">
              {user.role === "user"
                ? `${appointment.consultant_fiscal_zipcode}`
                : `${appointment.user_fiscal_zipcode}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiscalInfo;
