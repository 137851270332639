import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { updateInterventionInfo } from "../../../../actions/profile";
import Intervention from "./InterventionAndSpecialization/Intervention";
import Theraputic from "./InterventionAndSpecialization/Theraputic";

import Mental from "./InterventionAndSpecialization/Mental";
import Psychological from "./InterventionAndSpecialization/Psychological";
import Other from "./InterventionAndSpecialization/Other";
import { interventionLang } from "../../../../config/lang";
import SaveChanges from "../SaveChanges";
import { toast } from "react-toastify";

const AccountInfoForm = ({
  auth,
  user,
  interventions,
  theraputicList,
  mentalList,
  psychologicalList,
  otherList,
  updateInterventionInfo,
  lang,
}) => {
  const [spinner, setSpinner] = useState(false);
  const { status } = auth;
  const [allInterventions, setAllInterventions] = useState([]);
  const [allTheraputic, setAllTheraputic] = useState([]);
  const [allOther, setAllOther] = useState([]);
  const [allMental, setAllMental] = useState([]);
  const [allPsychological, setAllPsychological] = useState([]);

  const [intervention, setIntervention] = useState(null);
  const [theraputic, setTheraputic] = useState([]);
  const [mental, setMental] = useState([]);
  const [psychological, setPsychological] = useState([]);
  const [other, setOther] = useState([]);

  // Interventions
  useEffect(() => {
    if (interventions.length) {
      setAllInterventions([...interventions]);
    }
  }, [interventions]);

  // Theraputic
  useEffect(() => {
    if (theraputicList.length) setAllTheraputic([...theraputicList]);
  }, [theraputicList]);

  // Others
  useEffect(() => {
    if (otherList.length) setAllOther([...otherList]);
  }, [otherList]);

  // Mental
  useEffect(() => {
    if (mentalList.length) setAllMental([...mentalList]);
  }, [mentalList]);

  // Psychological
  useEffect(() => {
    if (psychologicalList.length) setAllPsychological([...psychologicalList]);
  }, [psychologicalList]);

  const searchInterventions = (keyword) => {
    const searchInterventionList = interventions.filter((el) => {
      return el.name.toLowerCase().includes(keyword.toLowerCase());
    });

    keyword
      ? setAllInterventions([...searchInterventionList])
      : setAllInterventions([...interventions]);
  };

  const searchTheraputic = (keyword) => {
    const searchTheraputicList = theraputicList.filter((el) => {
      return el.name.toLowerCase().includes(keyword.toLowerCase());
    });

    keyword
      ? setAllTheraputic([...searchTheraputicList])
      : setAllInterventions([...theraputic]);
  };

  const searchOther = (keyword) => {
    const searchOthers = otherList.filter((el) => {
      return el.name.toLowerCase().includes(keyword.toLowerCase());
    });

    keyword ? setAllOther([...searchOthers]) : setAllOther([...otherList]);
  };

  const searchMental = (keyword) => {
    const searchMentalList = mentalList.map((el) => {
      return {
        ...el,
        sub: el.sub.filter((si) => {
          return si?.name
            ? si.name.toLowerCase().includes(keyword.toLowerCase())
            : [];
        }),
      };
    });

    return keyword
      ? setAllMental([...searchMentalList])
      : setAllMental([...mentalList]);
  };

  const searchPsyChological = (keyword) => {
    const searchPsychologicalList = psychologicalList.map((el) => {
      return {
        ...el,
        sub: el.sub.filter((si) => {
          return si?.name
            ? si.name.toLowerCase().includes(keyword.toLowerCase())
            : [];
        }),
      };
    });

    return keyword
      ? setAllPsychological([...searchPsychologicalList])
      : setAllPsychological([...psychologicalList]);
  };

  useEffect(() => {
    manageInterventions(user?.intervention_ids, intervention, setIntervention);
    manageValues(
      user?.specialization_therapeutic_ids,
      theraputic,
      setTheraputic
    );
    manageValues(user?.specialization_mental_ids, mental, setMental);
    manageValues(
      user?.specialization_psycho_ids,
      psychological,
      setPsychological
    );
    manageValues(user?.specialization_others_ids, other, setOther);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.intervention_ids,
    user?.specialization_therapeutic_ids,
    user?.specialization_mental_ids,
    user?.specialization_psycho_ids,
    user?.specialization_others_ids,
  ]);

  const manageInterventions = (value, target, callback) => {
    if (value && !target) {
      callback(value);
    }
  };

  const manageValues = (value, target, callback) => {
    if (value && !target.length) {
      const newValue = value.split(",");
      callback([...target, ...newValue]);
    }
  };

  const getSingleName = (id, array) => {
    if (interventions && id) {
      const item = interventions.find((el) => el.id === id);

      return item?.name || null;
    }

    return null;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    const data = {
      //
      userId: user?.id,
      lang,
      interventionId: intervention && intervention.length ? intervention : [""],
      specializationTherapeuticId:
        theraputic && theraputic.length ? theraputic : [""],
      specializationMmentalId: mental && mental.length ? mental : [""],
      specializationPsychoId:
        psychological && psychological.length ? psychological : [""],
      specializationOthersId: other && other.length ? other : [""],
    };

    if (status && userId) {
      try {
        const message = await updateInterventionInfo(data);
        setSpinner(false);
        toast.success(message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-row">
        <div className="col-md-6">
          {/* Intervention area */}
          <Intervention
            interventions={allInterventions}
            intervention={intervention}
            getName={getSingleName}
            setIntervention={setIntervention}
            search={searchInterventions}
            lang={lang}
            config={interventionLang}
          />
        </div>

        <div className="col-md-6">
          {/* Theraputic Research */}
          <Theraputic
            theraputic={theraputic}
            theraputicList={theraputicList}
            setTheraputic={setTheraputic}
            allTheraputic={allTheraputic}
            search={searchTheraputic}
            lang={lang}
            config={interventionLang}
          />
        </div>

        <div className="col-md-6">
          {/* Mental and Neuropsychological Disorders */}
          <Mental
            mental={mental}
            mentalList={mentalList}
            setMental={setMental}
            allMental={allMental}
            search={searchMental}
            lang={lang}
            config={interventionLang}
          />
        </div>

        <div className="col-md-6">
          {/* Psychological Problems */}
          <Psychological
            psychological={psychological}
            psychologicalList={psychologicalList}
            allPsychological={allPsychological}
            setPsychological={setPsychological}
            search={searchPsyChological}
            lang={lang}
            config={interventionLang}
          />
        </div>

        <div className="col-md-12">
          {/* Other Topics */}
          <Other
            other={other}
            otherList={otherList}
            allOther={allOther}
            setOther={setOther}
            search={searchOther}
            lang={lang}
            config={interventionLang}
          />
        </div>
      </div>
      <SaveChanges spinner={spinner} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
    interventions: Object.values(
      state.cms.interventionSpecialization.interventions
    ),
    specializations: Object.values(
      state.cms.interventionSpecialization.specializations
    ),
    theraputicList: Object.values(
      state.cms.interventionSpecialization.theraputic
    ),
    mentalList: Object.values(state.cms.interventionSpecialization.mental),
    psychologicalList: Object.values(
      state.cms.interventionSpecialization.psychological
    ),
    otherList: Object.values(state.cms.interventionSpecialization.others),
  };
};

export default connect(mapStateToProps, { updateInterventionInfo })(
  AccountInfoForm
);
