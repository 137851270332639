import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getConsultantReviews } from '../../../../actions/consultant';
import OverallRating from '../../../Consultant/OverallRating';
import SingleReview from '../../../Consultant/SingleReview';
import AppointmentReviewForm from './AppointmentReviewForm';
import NoDataFound from '../../Sections/NoDataFound';

const AppointmentReview = ({ id, reviews, getConsultantReviews, user, appId, conId, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstRating, setFirstRating] = useState(null);
  const [comm, setComm] = useState(0);
  const [access, setAccess] = useState(0);
  const [skill, setSkill] = useState(0);

  useEffect(() => {
    const loadReviews = async () => {
      if(!isLoaded && id) {
        try {
          await getConsultantReviews({ 
            userId: id, details: true, appointmentId: appId 
          })
          setIsLoaded(true);
        } catch(err) {
          console.error(err.message);
          setIsLoaded(true);
        }
      }
    }
    
    loadReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ firstRating, reviews, id ]);

  useEffect(() => {
    if(props.appointment?.ids) {
      setFirstRating(props.appointment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.appointment?.ids ]);

  const getDurationInNumber = (startTime, endTime) => {
    const dateDiff = new Date(endTime) - new Date(startTime);
    const duration = Math.ceil(((dateDiff % 86400000) % 3600000) / 60000);
    return duration;
  }

  const appointmentStable = props.appointment?.consultant_join_time && ( 
    getDurationInNumber(
      props.appointment.consultant_join_time, 
      props.appointment.appointment_end_time
    ) > 1
  );

  const nonOverallVisibleCond = user?.role === 'consultant' && !props.appointment?.rating;

  return (
    <div className="consultant-reviews">
      {(props.appointment && !nonOverallVisibleCond
        // &&  (props.appointment.reviews_status === '1')
      ) && 
        <OverallRating 
        // rating={user?.role === 'user' ? firstRating : reviews.length ? reviews[0] : null} 
          rating={firstRating}
          app={user?.role === 'user' ? true : false} 
          reviewable={props.appointment?.rating && user?.role === 'user' ? true : false}
          comm={comm} setComm={setComm}
          access={access} setAccess={setAccess}
          skill={skill} setSkill={setSkill}
          appointment={props.appointment}
      /> }
      { (
          reviews && 
          user?.role === 'consultant' && 
          firstRating && 
          firstRating?.rating &&
          appointmentStable
        ) &&
        // <div className="review-list">
        //   { reviews.map(item => 
        //     item.appointment_id === appId &&  reviews.length && <SingleReview key={item.id} review={item} 
        //     admin={true} app={true}
        //   />) }
        // </div>
        <div className="review-list">
          <SingleReview review={firstRating} admin={true} app={true} />
        </div>
      }
      {
        (
          firstRating && 
          user?.role === 'user' && 
          firstRating?.rating &&
          appointmentStable
        ) &&
        <div className="review-list">
          <SingleReview review={firstRating} admin={true} app={true} />
        </div>
      }
      {
        (user?.role === 'user' && !props.appointment?.rating) &&
        <div className="mt-4">
          <AppointmentReviewForm 
            comm={comm} access={access} skill={skill} appId={appId}
            conId={conId}
            appointment={props.appointment}
          />
        </div>
      }
      
      { 
        (
          // !reviews.find(review => review.appointment_id === appId) &&
          (!firstRating &&
          isLoaded && 
          user?.role !== 'user') || nonOverallVisibleCond 
        ) && <NoDataFound /> 
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    reviews: Object.values(state.consultants.consultantReviews)
  }
}

export default connect(mapStateToProps, { getConsultantReviews })(AppointmentReview);
