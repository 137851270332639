import { combineReducers } from "redux";
import nationalityReducer from "./nationalityReducer";
import siteSettingsReducer from "./siteSettingsReducer";
import pagesReducer from "./pagesReducer";
import languagesReducer from "./languagesReducer";

import countryListReducer from "./countryListReducer";
import faqCategoryListReducer from "./faqCategoryListReducer";
import timeZoneListReducer from "./timeZoneListReducer";
import servicesListReducer from "./servicesList";
import interventionSpecialization from "./InterventionSpecialization";
import professionalQualificationReducer from "./professionalQualificationReducer";
import notificationReducer from "./notificatonsReducer";

export default combineReducers({
  nationalities: nationalityReducer,
  siteSettings: siteSettingsReducer,
  pages: pagesReducer,
  languages: languagesReducer,
  countryList: countryListReducer,
  faqCategoryList: faqCategoryListReducer,
  timeZoneList: timeZoneListReducer,
  serviceList: servicesListReducer,
  interventionSpecialization,
  professionalQualificatonList: professionalQualificationReducer,
  notifications: notificationReducer,
});
