import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { logout } from "../../actions/auth";
import { adminSideNavLang } from "../../config/lang";
import ActiveStatus from "./ActiveStatus";

const AdminSideNav = ({
  dashboard,
  logout,
  user,
  lang,
  sideNavActive,
  setSideNavActive,
}) => {
  const pathname = window.location.pathname;
  const [appointmentNavLink, setAppointmentNavLink] = useState(
    "/up-coming-appointments"
  );
  useEffect(() => {
    if (
      dashboard &&
      dashboard.upcomingAppointments &&
      dashboard.upcomingAppointments == "0"
    ) {
      setAppointmentNavLink("/appointment-list");
    }
  }, [dashboard]);
  console.log(dashboard);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
  };

  return (
    <div className="col-md-3 col-lg-2">
      <div className={`side-bar${sideNavActive ? " active" : ""}`}>
        <ul>
          <li className={pathname === `/${lang}/dashboard` ? "active" : ""}>
            <Link to={`/${lang}/dashboard`}>
              <i className="fas fa-fw fa-tachometer-alt-fast"></i>
              <span>
                {adminSideNavLang.dashboard[lang] ||
                  adminSideNavLang.dashboard.en}
              </span>
            </Link>
          </li>
          <li className={pathname === `/${lang}/myaccount` ? "active" : ""}>
            <Link to={`/${lang}/myaccount`}>
              <i className="fas fa-fw fa-user"></i>
              <span>
                {adminSideNavLang.myProfile[lang] ||
                  adminSideNavLang.myProfile.en}
              </span>
            </Link>
          </li>
          {user?.role && user.role === "user" && (
            <li className={pathname === `/${lang}/wishlist` ? "active" : ""}>
              <Link to={`/${lang}/wishlist`}>
                <i className="fas fa-fw fa-heart"></i>
                <span>
                  {adminSideNavLang.myFavourite[lang] ||
                    adminSideNavLang.myFavourite.en}
                </span>
              </Link>
            </li>
          )}
          {user?.role && user.role === "consultant" && (
            <li>
              <Link
                to={`/${lang}/consultant-details/${user.id}/${slugify(
                  user?.name.toLowerCase(),
                  "-"
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-fw fa-user-tie"></i>
                <span>
                  {adminSideNavLang.viewOnline[lang] ||
                    adminSideNavLang.viewOnline.en}
                </span>
              </Link>
            </li>
          )}
          <li
            className={
              pathname === `/${lang}/appointment-list` ||
              pathname === `/${lang}/in-progress-appointment` ||
              pathname === `/${lang}/up-coming-appointments`
                ? "active"
                : ""
            }
          >
            <Link to={`/${lang}${appointmentNavLink}`}>
              <i className="fas fa-fw fa-list-ul"></i>
              <span>
                {adminSideNavLang.appointments[lang] ||
                  adminSideNavLang.appointments.en}
              </span>
            </Link>
          </li>
          {user?.role && user.role === "consultant" && (
            <li className={pathname === `/${lang}/my-clients` ? "active" : ""}>
              <Link to={`/${lang}/my-clients`}>
                <i className="fas fa-fw fa-users"></i>
                <span>
                  {adminSideNavLang.myClients[lang] ||
                    adminSideNavLang.myClients.en}
                </span>
              </Link>
            </li>
          )}
          <li className={pathname === `/${lang}/email` ? "active" : ""}>
            <Link to={`/${lang}/email`}>
              <i className="fas fa-fw fa-envelope"></i>
              <span>
                {adminSideNavLang.email[lang] || adminSideNavLang.email.en}
              </span>
            </Link>
          </li>
          {user?.role && user.role === "consultant" && (
            <li className={pathname === `/${lang}/my-reviews` ? "active" : ""}>
              <Link to={`/${lang}/my-reviews`}>
                <i className="fas fa-fw fa-stars"></i>
                <span>
                  {adminSideNavLang.myReviews[lang] ||
                    adminSideNavLang.myReviews.en}
                </span>
              </Link>
            </li>
          )}
          <li>
            <Link to={`/${lang}/logout`} onClick={(e) => handleLogout(e)}>
              <i className="fas fa-fw fa-sign-out"></i>
              <span>
                {adminSideNavLang.logout[lang] || adminSideNavLang.logout.en}
              </span>
            </Link>
          </li>

          {user?.role && user.role === "consultant" && (
            <ActiveStatus status={user.active_status} userId={user.id} />
          )}
        </ul>
      </div>
      <div
        className={`sidebar-overlay${sideNavActive ? " active" : ""}`}
        onClick={() => setSideNavActive(!sideNavActive)}
      ></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
    lang: state.defaultLang,
    dashboard: state.dashboard,
  };
};

export default connect(mapStateToProps, { logout })(AdminSideNav);
