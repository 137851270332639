import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { createSession } from "opentok-react";
import { useParams } from "react-router-dom";
import { updateActiveStatus } from "../actions/profile";

import {
  getAppointment,
  consultantJoin,
  consultantLeave,
  setLastAppointment,
  userJoinLeave,
  removeInprogressAppointment,
  removeUpcomingAppointment,
  endAppointment,
  consultantReject,
} from "../actions/appointments";
import { createASession } from "../asynchronus/Appointment";
import { API_KEY, API_SECRET } from "../config/opektok";
import { convertIsoTime } from "../helpers";
import history from "../config/history";
import Loader from "../Loader";
import { modalLang } from "../config/lang";
import LayoutContainer from "../containers/LayoutContainer";
import SecManageAppointment from "../components/Message/SecManageAppointment";
import { toast } from "react-toastify";
const ManageAppointment = (props) => {
  const [loader, setLoader] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [token, setToken] = useState(null);
  const [type, setType] = useState("");
  const [sessionHelper, setSessionHelper] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [isConsultantReady, setIsConsultantReady] = useState(false);
  const [pageReady, setPageReady] = useState(false);

  const params = useParams();

  //Auto logout
  useEffect(() => {
    const timer = setInterval(() => {
      if (appointment) {
        props.socket.emit("getAppointmentDetails", {
          ...appointment,
        });
      }
    }, 1000);
  }, [props.socket, appointment]);
  useEffect(() => {
    if (props.socket?.io) {
      props.socket.on("get-appointment-details", (res) => {
        if (res) {
          if (res.result?.status != 3) {
            if (
              res.result?.consultant_join !== 1 ||
              res.result?.user_join !== 1
            ) {
              var currentTime = convertIsoTime().getTime();

              var appointment_starttime = new Date(
                res.result?.appointment_start_time
              ).getTime();

              var calculatedSeconds = Math.round(
                (currentTime - appointment_starttime) / 1000
              );

              if (calculatedSeconds > 240) {
                if (
                  props.user?.role === "consultant" &&
                  props.user?.id &&
                  appointment
                ) {
                  props.socket.emit("updateConsultantStatus", {
                    userId: res.result?.consultant_id,
                    activeStatus: "1",
                  });
                }
                var data = {
                  user_id: props.user.id,
                  consultant_id: res.result?.consultant_id,
                  appointment_id: res.result?.appointment_id,
                  client_id: res.result?.user_id,
                  service_type: res.result?.service_type,
                  opentok_sessionId: localStorage.getItem("opentok_sessionId"),
                  opentok_token: localStorage.getItem("opentok_token"),
                };

                props.socket.emit("callAutoDisconnect", data);
                const handleAutoDisconnect = async () => {
                  await props.endAppointment(
                    {
                      lang: props.lang,
                      appointmentEndTime: new Date().toISOString(),
                      reject: "1",
                      appointmentId: res.result?.appointment_id,
                      perMinuteAmount: res.result?.per_minute_amount,
                    },
                    null,
                    res.result?.id
                  );
                };
                handleAutoDisconnect();
              }
            }
          }
        }
      });
    }
  }, [appointment, props.socket, props]);
  // useEffect(() => {

  //   if (appointment?.appointment_start_time) {
  //     var currentTime = convertIsoTime().getTime();

  //     var consultantJoinTime = new Date(
  //       appointment?.appointment_start_time
  //     ).getTime();

  //     let calculatedSeconds = Math.round(
  //       (currentTime - consultantJoinTime) / 1000
  //     );
  //     if (
  //       appointment?.user_join !== "1" &&
  //       appointment?.consultant_join !== "1" &&
  //       calculatedSeconds > 3000
  //     )
  //     {
  //     var interval = setInterval(function () {
  //       // if(appointment){

  //       //   props.socket.emit("getAppointmentDetails", {
  //       //     ...appointment
  //       //   });
  //       //   props.socket.on('get-appointment-details', (res) => {

  //       //     setAppointmentDetails(res)
  //       // })
  //       // }

  //       if (appointment?.appointment_start_time && appointmentDetails == 1) {
  //         // if( props.lastAppointment && props.lastAppointment.status==1){

  //         if (
  //           appointment?.user_join !== "1" &&
  //           appointment?.consultant_join !== "1" &&
  //           calculatedSeconds > 3000
  //         ) {
  //           props.socket.emit("getAppointmentDetails", {
  //             ...appointment,
  //           });
  //           props.socket.emit("updateConsultantStatus", {
  //             userId: appointment.consultant_id,
  //             activeStatus: "1",
  //             role: props.user?.role,
  //           });
  //           props.socket.emit("appintmentDetails", {
  //             ...appointment,
  //             status: "3",

  //             message:
  //               modalLang.appointmentClosed[props.lang] ||
  //               modalLang.appointmentClosed.en,
  //           });
  //           var data = {
  //             user_id: props.user.id,
  //             consultant_id: appointment.consultant_id,
  //             appointment_id: appointment.appointment_id,
  //             client_id: appointment.user_id,
  //             service_type: appointment.service_type,
  //             opentok_sessionId: localStorage.getItem("opentok_sessionId"),
  //             opentok_token: localStorage.getItem("opentok_token"),
  //           };
  //           props.socket.emit("callAutoDisconnect", data);
  //           const handleAutoDisconnect = async () => {
  //             await props.endAppointment(
  //               {
  //                 lang: props.lang,
  //                 appointmentEndTime: new Date().toISOString(),
  //                 reject: "1",
  //                 appointmentId: appointment.appointment_id,
  //                 perMinuteAmount: appointment.per_minute_amount,
  //               },
  //               null,
  //               appointment.ids
  //             );
  //           };
  //           handleAutoDisconnect();
  //         }

  //         // }
  //       }
  //     }, 6000);
  //     // return () => {
  //     //   clearInterval(interval);
  //     // };
  //     }
  //   }
  // }, [appointment, props.socket, props.lastAppointment, props.user]);

  //Init
  useEffect(() => {
    const ac = new AbortController();

    const handleLeave = async () => {
      try {
        // if(props.user?.role === 'consultant' && appointment && props.socket?.io) {
        //   if(appointment?.consultant_join === '1') {
        //     await props.consultantLeave({
        //       lang: props.lang, appointmentId: appointment.appointment_id,
        //       consultantJoin: '2', consultantJoinTime: appointment.consultant_join_time || null
        //     }, );
        //   }

        //   if(props.socket?.io) {
        //     props.socket.emit('appintmentDetails', { ...appointment, consultant_join: '2' });
        //   }
        // }

        if (props.user?.role === "user" && appointment) {
          if (appointment.user_join === "1") {
            await props.userJoinLeave(
              {
                appointmentId: appointment?.appointment_id,
                userJoin: "2",
              },
              ac.signal
            );

            if (props.socket?.io) {
              props.socket.emit("appintmentDetails", {
                ...appointment,
                user_join: "2",
              });
            }
          }
        }

        setInitComplete(true);
      } catch (err) {
        setInitComplete(true);
        console.log(err.message);
      }
    };

    handleLeave();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.lang,
    props.user?.role,
    appointment?.consultant_join,
    appointment?.user_join,
    props.socket,
  ]);

  // Browser load
  useEffect(() => {
    if (props.socket?.io && initComplete) {
      props.socket.emit("browserLoaded", {
        appointmentId: appointment?.appointment_id,
        user_id: props.user?.id,
        last_active: convertIsoTime(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initComplete, props.user?.id, appointment?.ids, props.socket]);

  // Handle type
  useEffect(() => {
    const handleType = () => {
      if (appointment && appointment.appointment_type === "1") {
        setType("direct");
      } else if (appointment && appointment?.appointment_type === "2") {
        setType("schedule");
      }
    };

    handleType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.appointment_type]);

  // Auto redirect
  useEffect(() => {
    const autoRedirectExec = async () => {
      if (props.user?.role === "consultant") {
        await props.updateActiveStatus({
          lang: props.lang,
          userId: props.user?.id,
          activeStatus: "1",
        });
      }

      props.removeInprogressAppointment(appointment.ids);
      props.removeUpcomingAppointment(appointment.ids);

      localStorage.removeItem("opentok_sessionId");
      localStorage.removeItem("opentok_token");

      if (appointment?.message) {
        toast.success(appointment.message);
      }
    };

    const handleAutoRedirect = async () => {
      if (
        appointment &&
        type &&
        type === "direct" &&
        appointment?.appointment_end_time
      ) {
        try {
          await autoRedirectExec();
        } catch (err) {
          await autoRedirectExec();
        }

        history.push(
          `/${props.lang}/appointment-details/${appointment?.appointment_id}`
        );
      } else if (
        appointment &&
        type &&
        type === "schedule" &&
        appointment?.appointment_end_time
      ) {
        if (new Date(appointment.appointment_end_time) <= convertIsoTime()) {
          try {
            await autoRedirectExec();
          } catch (err) {
            await autoRedirectExec();
          }

          history.push(
            `/${props.lang}/appointment-details/${appointment?.appointment_id}`
          );
        }
      } else if (appointment && appointment?.status === "3") {
        try {
          await autoRedirectExec();
        } catch (err) {
          await autoRedirectExec();
        }

        history.push(
          `/${props.lang}/appointment-details/${appointment?.appointment_id}`
        );
      } else if (appointment && appointment?.consultant_join === "3") {
        try {
          await autoRedirectExec();
        } catch (err) {
          await autoRedirectExec();
        }

        history.push(
          `/${props.lang}/appointment-details/${appointment?.appointment_id}`
        );
      }
    };

    handleAutoRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.lang,
    props.user?.id,
    props.user?.role,
    type,
    appointment?.status,
    appointment?.appointment_end_time,
    appointment?.consultant_join,
    props.socket,
  ]);

  // Auto redirect unauthorized users
  useEffect(() => {
    if (props.user?.id && appointment?.ids) {
      if (
        props.user.id === appointment.user_id ||
        props.user.id === appointment.consultant_id
      ) {
      } else {
        history.push(
          `/${props.lang}/consultant-details/${appointment?.consultant_id}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.ids, props.user?.id]);

  // Load appointment
  useEffect(() => {
    const ac = new AbortController();

    const loadAppointment = async () => {
      if (params.id) {
        try {
          const appointmentData = await props.getAppointment(
            {
              lang: props.lang,
              appointmentId: params.id,
            },
            ac.signal
          );
          setAppointment(appointmentData);
          props.setLastAppointment(appointmentData);
        } catch (err) {
          console.log(err.message);
        }
      }
    };

    loadAppointment();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lang]);

  // Handle session
  useEffect(() => {
    const ac = new AbortController();

    const loadSession = async () => {
      if (
        appointment?.service_type &&
        appointment?.service_type !== "1" &&
        !sessionId &&
        !token
      ) {
        try {
          const sessionData = await createASession(
            API_KEY,
            API_SECRET,
            ac.signal
          );
          setSessionId(sessionData.sessionId);
          setToken(sessionData.token);

          setSessionHelper(
            createSession({
              apiKey: API_KEY,
              sessionId: sessionData.sessionId,
              token: sessionData.token,
            })
          );
        } catch (err) {
          console.log(err.message);
        }
      }
    };

    loadSession();

    return () => {
      ac.abort();
      if (sessionHelper) sessionHelper.disconnect();
    };
  }, [appointment?.service_type, sessionId, token, sessionHelper]);

  // Handle loader
  useEffect(() => {
    if (Object.keys(props.siteSettings).length && props.languages.length)
      setLoader(true);
  }, [props.lang, props.siteSettings, props.languages.length]);

  useEffect(() => {
    const { socket } = props;
    if (socket?.io) {
      socket.on("appointment-details", (res) => {
        if (
          (res.consultant_join === "3" || res.user_join === "3") &&
          res.staus === "3" &&
          res.message
        ) {
          toast.success(res.message);
        }

        if (
          res?.user_id === props.user?.id ||
          res?.consultant_id === props.user?.id
        ) {
          setAppointment(res);
          props.setLastAppointment(res);
        }
      });

      socket.on("call-response-consultant", (res) => {
        if (props.user?.id === res?.client_id && res.consultant_join === "3") {
          if (sessionHelper) sessionHelper.disconnect();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appointment?.ids, props.user?.id, props.socket]);

  // Set join/leave

  useEffect(() => {
    if (props.user?.id && appointment?.ids) setPageReady(true);
    if (props.user?.role === "user" && appointment?.user_join === "1") {
      props.setJoin(true);
    }

    if (
      props.user?.role === "consultant" &&
      appointment?.consultant_join === "1"
    ) {
      props.setJoin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.user?.role,
    appointment?.consultant_join,
    appointment?.user_join,
    props.setJoin,
  ]);

  useEffect(() => {
    const { socket } = props;

    if (props.user?.role === "user" && props.appointment?.user_join !== "1") {
      window.onbeforeunload = () => {
        socket.emit("browserClosed", {
          appointmentId: appointment?.appointment_id,
          user_id: props.user?.id,
          last_active: convertIsoTime(),
        });
      };
    }

    if (
      props.user?.role === "consultant" &&
      props.appointment?.consultant_join !== "1" &&
      props.appointment?.consultant_join !== "3"
    ) {
      window.onbeforeunload = () => {
        socket.emit("browserClosed", {
          appointmentId: appointment?.appointment_id,
          user_id: props.user?.id,
          last_active: convertIsoTime(),
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.socket,
    props.user?.role,
    props.appointment?.consultant_join,
    props.appointment?.user_join,
    appointment,
  ]);

  useEffect(() => {
    const handleRedirect1 = async () => {
      setSpinner(true);
      try {
        if (
          props.user?.role === "consultant" &&
          props.socket?.io &&
          appointment
        ) {
          if (props.user?.active_status !== "3") {
            await props.updateActiveStatus({
              lang: props.lang,
              userId: props.user.id,
              activeStatus: "3",
              isManual: "2",
            });
          }

          if (appointment.consultant_join !== "1") {
            await props.consultantJoin({
              lang: props.lang,
              appointmentId: appointment.appointment_id,
              consultantJoin: "1",
              consultantJoinTime:
                appointment.consultant_join_time || new Date().toISOString(),
            });

            if (props.socket?.io) {
              props.socket.emit("appintmentDetails", {
                ...appointment,
                consultant_join: "1",
                consultant_join_time:
                  appointment.consultant_join_time || new Date().toISOString(),
              });

              props.socket.emit("updateConsultantStatus", {
                userId: appointment.consultant_id,
                activeStatus: "3",
                isManual: "2",
              });
            }
          }
          setIsConsultantReady(true);
        }

        if (props.user?.role === "user" && appointment) {
          if (appointment.user_join !== "1") {
            await props.userJoinLeave({
              appointmentId: appointment?.appointment_id,
              userJoin: "1",
            });

            if (props.socket?.io) {
              props.socket.emit("appintmentDetails", {
                ...appointment,
                user_join: "1",
                consultant_join_time: appointment.consultant_join_time || null,
              });
            }
          }
          setIsConsultantReady(true);
        }

        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        console.log(err);
        console.log(err.message);
      }

      props.setJoin(true);
    };
    if (appointment) {
      handleRedirect1();
    }
  }, [appointment]);

  const handleCancel = async () => {
    setSpinner(true);

    try {
      if (props.user?.role === "consultant" && props.user?.id && appointment) {
        await props.consultantReject({
          lang: props.lang,
          appointmentId: appointment.appointment_id,
          consultantJoinTime: null,
        });

        props.socket.emit("appintmentDetails", {
          ...appointment,
          consultant_join: "3",
          status: "3",
          message: modalLang.cCaTa[props.lang] || modalLang.cCaTa.en,
        });
        props.socket.emit("updateConsultantStatus", {
          userId: appointment.consultant_id,
          activeStatus: "1",
        });
        const data = {
          user_id: props.user.id,
          consultant_id: appointment.consultant_id,
          appointment_id: appointment.appointment_id,
          consultant_join: "3",
          client_id: appointment.user_id,
          service_type: appointment.service_type,
          opentok_sessionId: localStorage.getItem("opentok_sessionId"),
          opentok_token: localStorage.getItem("opentok_token"),
        };
        props.socket.emit("callResponseConsultant", data);
        await props.endAppointment(
          {
            lang: props.lang,
            appointmentEndTime: new Date().toISOString(),
            reject: "1",
            appointmentId: appointment.appointment_id,
            perMinuteAmount: appointment.per_minute_amount,
          },
          null,
          appointment.ids
        );
        props.endAppointmentMailSend(
          {
            lang: props.lang,
            appointment_id: appointment.appointment_id,
          },
          null,
          appointment.ids
        );
        setSpinner(false);
      } else if (props.user?.role === "user" && props.user?.id && appointment) {
        props.socket.emit("appintmentDetails", {
          ...appointment,
          user_join: "3",
          status: "3",
          message: modalLang.cUsTa[props.lang] || modalLang.cUsTa.en,
        });
        props.socket.emit("updateConsultantStatus", {
          userId: appointment.consultant_id,
          activeStatus: "1",
        });
        await props.userJoinLeave({
          appointmentId: appointment?.appointment_id,
          userJoin: "3",
        });

        const data = {
          user_id: props.user.id,
          consultant_id: appointment.consultant_id,
          appointment_id: appointment.appointment_id,
          user_join: "3",
          client_id: appointment.user_id,
          service_type: appointment.service_type,
          opentok_sessionId: localStorage.getItem("opentok_sessionId"),
          opentok_token: localStorage.getItem("opentok_token"),
        };
        props.socket.emit("callResponseUser", data);
        await props.endAppointment(
          {
            lang: props.lang,
            appointmentEndTime: new Date().toISOString(),
            reject: "1",
            appointmentId: appointment.appointment_id,
            perMinuteAmount: appointment.per_minute_amount,
          },
          null,
          appointment.ids
        );
        props.endAppointmentMailSend(
          {
            lang: props.lang,
            appointment_id: appointment.appointment_id,
          },
          null,
          appointment.ids
        );

        setSpinner(false);
      }
    } catch (err) {
      setSpinner(false);
      console.log(err.message);
    }
  };

  const handleRedirect = async () => {
    setSpinner(true);
    try {
      if (
        props.user?.role === "consultant" &&
        props.socket?.io &&
        appointment
      ) {
        if (props.user?.active_status !== "3") {
          await props.updateActiveStatus({
            lang: props.lang,
            userId: props.user.id,
            activeStatus: "3",
            isManual: "2",
          });
        }

        if (appointment.consultant_join !== "1") {
          await props.consultantJoin({
            lang: props.lang,
            appointmentId: appointment.appointment_id,
            consultantJoin: "1",
            consultantJoinTime:
              appointment.consultant_join_time || new Date().toISOString(),
          });

          if (props.socket?.io) {
            props.socket.emit("appintmentDetails", {
              ...appointment,
              consultant_join: "1",
              consultant_join_time:
                appointment.consultant_join_time || new Date().toISOString(),
            });

            props.socket.emit("updateConsultantStatus", {
              userId: appointment.consultant_id,
              activeStatus: "3",
              isManual: "2",
            });
          }
        }
      }

      if (props.user?.role === "user" && appointment) {
        if (appointment.user_join !== "1") {
          await props.userJoinLeave({
            appointmentId: appointment?.appointment_id,
            userJoin: "1",
          });

          if (props.socket?.io) {
            props.socket.emit("appintmentDetails", {
              ...appointment,
              user_join: "1",
              consultant_join_time: appointment.consultant_join_time || null,
            });
          }
        }
      }

      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      console.log(err);
      console.log(err.message);
    }

    props.setJoin(true);
  };
  if (!pageReady) return <></>;
  return (
    <LayoutContainer>
      <Loader loaded={loader} />
      <SecManageAppointment
        appointment={appointment}
        handleRedirect={handleRedirect}
        handleCancel={handleCancel}
        user={props.user}
        sessionHelper={sessionHelper}
        spinner={spinner}
        isConsultantReady={isConsultantReady}
        socket={props.socket}
        initComplete={initComplete}
        lang={props.lang}
      />
    </LayoutContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket,
    siteSettings: state.cms.siteSettings,
    lastAppointment: state.appointments.lastAppointment,
    languages: Object.values(state.cms.languages),
  };
};

export default connect(mapStateToProps, {
  getAppointment,
  consultantJoin,
  consultantLeave,
  setLastAppointment,
  updateActiveStatus,
  userJoinLeave,
  removeInprogressAppointment,
  removeUpcomingAppointment,
  endAppointment,
  consultantReject,
})(ManageAppointment);
