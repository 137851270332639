import React, { useState, useEffect } from "react";
import { interventionTooltipLang } from "../../../../../config/lang";
import TooltipSec from "../TooltipSec";
const Theraputic = ({
  theraputic,
  theraputicList,
  setTheraputic,
  allTheraputic,
  search,
  lang,
  config,
}) => {
  const [names, setNames] = useState([]);
  const [disable, setDisabled] = useState(false);

  useEffect(() => {
    if (theraputic.length) {
      setNames(getNames(theraputic, theraputicList));
    }
    if (theraputic.length > 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [theraputic, theraputicList]);

  const manageCheck = (id, theraputic) => {
    if (!theraputic.length) {
      theraputic.push(id);
      setTheraputic([...theraputic]);
      setNames([...getNames(theraputic, theraputicList)]);
    } else {
      const index = theraputic.indexOf(id);

      if (index === -1) {
        theraputic.push(id);
        setTheraputic([...theraputic]);
        setNames([...getNames(theraputic, theraputicList)]);
      } else {
        theraputic.splice(index, 1);
        setTheraputic([...theraputic]);
        setNames([...getNames(theraputic, theraputicList)]);
      }
    }
  };

  const getNames = (ids, array) => {
    let names = [];
    if (ids && array) {
      array.forEach((el) => {
        if (ids.includes(el.id)) names.push(el.name);
      });
      return names;
    }

    return names;
  };

  const formatNames = (names) => {
    if (names.length) {
      if (names.length > 4) {
        return (
          <span>
            {names.length + ` ${config.selected[lang] || config.selected.en}`}
          </span>
        );
      } else {
        return names.map((el, i) => <span key={i}>{el}</span>);
      }
    }

    return null;
  };

  return (
    <div className="form-group">
      <label className="control-label">
        {config.theraputicApproach[lang] || config.theraputicApproach.en}
        <TooltipSec
          title={
            interventionTooltipLang.tA[lang] || interventionTooltipLang.tA.en
          }
        ></TooltipSec>
      </label>
      <div className="select-dropdown dropdown">
        <a
          href="#!"
          className="data-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {!names.length ? config.select[lang] || config.select.en : ""}
          {names.length ? formatNames(names) : ""}
        </a>

        <div className="dropdown-menu">
          <div className="dropdown-search">
            <input
              type="text"
              className="form-control"
              placeholder={config.search[lang] || config.search.en}
              onChange={(e) => search(e.target.value)}
            />
          </div>

          <div className="dropdown-list">
            {allTheraputic.length
              ? allTheraputic.map((el) => {
                  return (
                    <div className="item" key={el.id}>
                      <div className="custom-checkbox" type="checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox-input"
                          checked={theraputic.includes(el.id)}
                          onChange={() => manageCheck(el.id, theraputic)}
                          disabled={!theraputic.includes(el.id) && disable}
                        />
                        <label className="custom-checkbox-label">
                          {el.name}
                        </label>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theraputic;
