import React, { useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { addReview } from "../../../../actions/profile";
import history from "../../../../config/history";
import { toast } from "react-toastify";
const AppointmentReviewForm = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [personalExperience, setPersonalExperience] = useState("");
  const [personalnoteforConsultant, setPersonalnoteforConsultant] =
    useState("");
  const [commentforprontopsyStaff, setCommentforprontopsyStaff] = useState("");

  const handleSubmit = async () => {
    setSpinner(true);

    try {
      const message = await props.addReview({
        lang: props.lang,
        userId: props.user?.id,
        appointmentId: props.appId,
        consultantId: props.conId,
        reliableandTrustworthy: props.skill ? props.skill.toString() : null,
        communication: props.comm ? props.comm.toString() : null,
        empathyandFlexibility: props.access ? props.access.toString() : null,
        personalExperience,
        commentforprontopsyStaff,
      });

      setSpinner(false);
      toast.success(message);
      history.push(`/${props.lang}/appointment-list`);
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };

  const textConfig = {
    personalExperience: {
      en: "Personal Experience (Max 400 character)",
      es: "Experiencia personal (máximo 800 caracteres)",
      it: "Esperienza personale (massimo 800 caratteri)",
    },
    noteForPsychologist: {
      en: "Note for Psychologist ",
      es: "Nota para el psicólogo",
      it: "Nota per lo Psicologo",
    },
    noteForProntopsy: {
      en: "Note for Prontopsy (Max 400 character)",
      es: "Nota para Prontopsy (máximo 800 caracteres)",
      it: "Nota per Prontopsy (massimo 800 caratteri)",
    },
    submit: {
      en: "Submit",
      es: "Entregar",
      it: "Invia",
    },
  };

  const getDurationInNumber = (startTime, endTime) => {
    const dateDiff = new Date(endTime) - new Date(startTime);
    const duration = Math.ceil(((dateDiff % 86400000) % 3600000) / 60000);
    return duration;
  };

  return (
    <>
      {props.appointment.consultant_join_time &&
      getDurationInNumber(
        props.appointment.consultant_join_time,
        props.appointment.appointment_end_time
      ) > 1 ? (
        <>
          {" "}
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">
                  {textConfig.personalExperience[props.lang] ||
                    textConfig.personalExperience.en}
                </label>
                <textarea
                  className="form-control"
                  id="personal_experience"
                  name="personal_experience"
                  placeholder={
                    textConfig.personalExperience[props.lang] ||
                    textConfig.personalExperience.en
                  }
                  onChange={(e) =>
                    setPersonalExperience(e.target.value.slice(0, 400))
                  }
                  value={personalExperience}
                ></textarea>
              </div>
            </div>
          </div>
          {/* <div className="form-row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="control-label">
              { textConfig.noteForPsychologist[props.lang] || textConfig.noteForPsychologist.en }
            </label>
            <textarea 
              className="form-control" 
              id="personal_note_for_consultant" 
              name="personal_note_for_consultant" 
              placeholder={ 
                textConfig.noteForPsychologist[props.lang] || textConfig.noteForPsychologist.en 
              }
              onChange={(e) => setPersonalnoteforConsultant(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div> */}
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">
                  {textConfig.noteForProntopsy[props.lang] ||
                    textConfig.noteForProntopsy.en}
                </label>
                <textarea
                  className="form-control"
                  id="comment_for_prontopsy_staff"
                  name="comment_for_prontopsy_staff"
                  placeholder={
                    textConfig.noteForProntopsy[props.lang] ||
                    textConfig.noteForProntopsy.en
                  }
                  onChange={(e) =>
                    setCommentforprontopsyStaff(e.target.value.slice(0, 400))
                  }
                  value={commentforprontopsyStaff}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sec-myaccount-header border-0 mt-3">
                <div className="lable-text">
                  <button
                    className="btn-universal"
                    onClick={handleSubmit}
                    disabled={spinner}
                  >
                    <div className="d-flex align-items-center">
                      <span>
                        {textConfig.submit[props.lang] || textConfig.submit.en}
                      </span>
                      {spinner && (
                        <div className="loader-spinner ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { addReview })(AppointmentReviewForm);
