import {
  GET_NATIONALITIES,
  GET_SITE_SETTINGS,
  GET_LANGUAGES,
  GET_COUNTRIES,
  GET_TIMEZONE_LIST,
  GET_PAGES_TERM_CONDITION,
  GET_SERVICES_LIST,
  GET_INTERVENTION_LIST,
  GET_SPECIALIZATION_LIST,
  GET_THERAPUTIC_LIST,
  GET_MENTAL_LIST,
  GET_PSYCHOLOGICAL_LIST,
  GET_OTHER_LIST,
  GET_PROFESSIONAL_QUALIFICATION_LIST,
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_FAQ_CATEGORY,
} from "./types";
import {
  nationalityListAsync,
  siteSettingsAsync,
  languagesAsync,
  countryListAsync,
  faqCategoryListAsync,
  timeZoneListAsync,
  getPagesAsync,
  getServicesInfoAsync,
  getInterventionSpecializationListAsync,
  getProfessionalQualificationListAsync,
} from "../asynchronus/CMS";
import { getSeoDetailsAsync } from "../asynchronus/SEO";

export const getNationalities =
  (body = null, signal = null) =>
  async (dispatch) => {
    try {
      const nationalities = await nationalityListAsync(body, signal);
      dispatch({ type: GET_NATIONALITIES, payload: nationalities.data });
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getSiteSettings = () => async (dispatch, getState) => {
  try {
    const cacheSettings = localStorage.getItem("siteSettings");
    const defaultLang = getState().defaultLang;

    if (!cacheSettings) {
      const settings = await siteSettingsAsync(defaultLang);
      localStorage.setItem("siteSettings", JSON.stringify(settings.data));
      dispatch({ type: GET_SITE_SETTINGS, payload: settings.data });
    } else {
      dispatch({ type: GET_SITE_SETTINGS, payload: JSON.parse(cacheSettings) });

      const settings = await siteSettingsAsync(defaultLang);
      localStorage.setItem("siteSettings", JSON.stringify(settings.data));

      if (JSON.parse(cacheSettings) !== settings.data) {
        dispatch({ type: GET_SITE_SETTINGS, payload: settings.data });
      }
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getPages =
  (body = null, reducer = null, signal = null) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const lang = state.defaultLang;

      const res = await getPagesAsync(body, signal);
      const seoRes = await getSeoDetailsAsync(
        {
          lang,
          pageId: res.data.id,
        },
        signal
      );

      // console.log(seoRes)

      if (res.status === 200) {
        if (seoRes.status === 200) {
          dispatch({
            type: reducer || GET_PAGES_TERM_CONDITION,
            payload: { ...res.data, seo: { ...seoRes.data } },
          });
        } else {
          dispatch({
            type: reducer || GET_PAGES_TERM_CONDITION,
            payload: res.data,
          });
        }
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getLanguages = () => async (dispatch, getState) => {
  try {
    const cacheLanguages = localStorage.getItem("languages");
    const defaultLang = getState().defaultLang;

    if (!cacheLanguages) {
      const languages = await languagesAsync(defaultLang);
      localStorage.setItem("languages", JSON.stringify(languages.data));
      dispatch({ type: GET_LANGUAGES, payload: languages.data });
    } else {
      dispatch({ type: GET_LANGUAGES, payload: JSON.parse(cacheLanguages) });

      const languages = await languagesAsync(defaultLang);
      localStorage.setItem("languages", JSON.stringify(languages.data));

      if (JSON.parse(cacheLanguages) !== languages.data) {
        dispatch({ type: GET_LANGUAGES, payload: languages.data });
      }
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getCountryList =
  (signal = null) =>
  async (dispatch) => {
    try {
      const countryList = await countryListAsync(signal);
      dispatch({ type: GET_COUNTRIES, payload: countryList.data });
    } catch (err) {
      throw new Error(err.message);
    }
  };
export const getfaqCategoryList =
  (body = null, signal = null) =>
  async (dispatch) => {
    try {
      const faqCategoryList = await faqCategoryListAsync(body, signal);
      dispatch({ type: GET_FAQ_CATEGORY, payload: faqCategoryList.data });
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getTimeZoneList =
  (signal = null) =>
  async (dispatch) => {
    try {
      const cacheTimezones = localStorage.getItem("timeZoneList");

      if (!cacheTimezones) {
        const timeZoneList = await timeZoneListAsync(signal);
        localStorage.setItem("timeZoneList", JSON.stringify(timeZoneList.data));
        dispatch({ type: GET_TIMEZONE_LIST, payload: timeZoneList.data });
      } else {
        dispatch({
          type: GET_TIMEZONE_LIST,
          payload: JSON.parse(cacheTimezones),
        });

        const timeZoneList = await timeZoneListAsync(signal);
        localStorage.setItem("timeZoneList", JSON.stringify(timeZoneList.data));

        if (JSON.parse(cacheTimezones) !== timeZoneList.data) {
          dispatch({ type: GET_TIMEZONE_LIST, payload: timeZoneList.data });
        }
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getServices =
  (body = null, signal = null) =>
  async (dispatch) => {
    try {
      const res = await getServicesInfoAsync(body, signal);
      dispatch({ type: GET_SERVICES_LIST, payload: res.data });
    } catch (err) {
      throw new Error(err.nessage);
    }
  };

export const getInterventionSpecializationList =
  (body = null, signal = null) =>
  async (dispatch) => {
    try {
      const res = await getInterventionSpecializationListAsync(body, signal);

      if (res.status === 200) {
        const intervetion = res.data.intervetion;
        const specialization = res.data.specializations;

        const theraputic = specialization.filter((el) => el.type === "1");
        const mental = specialization.filter((el) => el.type === "2");
        const psychological = specialization.filter((el) => el.type === "3");
        const other = specialization.filter((el) => el.type === "4");

        dispatch({ type: GET_INTERVENTION_LIST, payload: intervetion });
        dispatch({ type: GET_SPECIALIZATION_LIST, payload: specialization });

        dispatch({ type: GET_THERAPUTIC_LIST, payload: theraputic });
        dispatch({ type: GET_MENTAL_LIST, payload: mental });
        dispatch({ type: GET_PSYCHOLOGICAL_LIST, payload: psychological });
        dispatch({ type: GET_OTHER_LIST, payload: other });
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getProfessionalQualificationList =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const profQua = await getProfessionalQualificationListAsync(body, signal);

      if (profQua.status === 200) {
        dispatch({
          type: GET_PROFESSIONAL_QUALIFICATION_LIST,
          payload: profQua.data,
        });
      } else {
        throw new Error(profQua.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const setNotifications = (notifications) => (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS, payload: notifications });
};

export const deleteNotification = (notificationId) => (dispatch, getState) => {
  const state = getState();
  const { socket, auth } = state;
  const { user } = auth;

  if (socket?.io && user?.id) {
    socket.emit("clearNotification", {
      user_id: user.id,
      id: notificationId,
    });
  }

  dispatch({ type: DELETE_NOTIFICATION, payload: notificationId });
};
