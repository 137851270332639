import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import Banner from '../containers/Banner';
import SecTermCondition from '../components/Sections/SecTermCondition';

const TermCondition = ({ termCondition, banners, siteSettings, languages }) => {
  const [loader, setLoader] = useState(false);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const loadBanner = () => {
      if(!banner && termCondition && banners) {
        const termConditionBanner = banners.find(el => el.page_id === termCondition.id);
        setBanner(termConditionBanner);
      } 
    }

    loadBanner();
  }, [banner, banners, termCondition]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && banner) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [banner, languages.length, siteSettings])

  return (
    <LayoutContainer
      seoTitle={termCondition?.seo?.title}
      seoDescription={termCondition?.seo?.description}
      seoKeywords={termCondition?.seo?.keywords}
    >
      <Loader loaded={loader} />
      <Banner banner={banner} />
      <SecTermCondition />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    termCondition: state.cms.pages.termCondition,
    banners: Object.values(state.home.banners),
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages)
  }
}

export default connect(mapStateToProps, null)(TermCondition);