import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { acNavLang, consultantLang } from '../../../config/lang';

const AccountNav = ({ status, user, lang }) => {
  const pathName = window.location.pathname;
  
  // const checkUser = Object.keys(user).length;
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card-nav">
          <nav className="nav nav-pills nav-fill" style={{ flexWrap: 'wrap' }}>
            <Link 
              className={`nav-item nav-link${pathName === `/${lang}/myaccount` ? ' active' : ''}`} 
              to={`/${lang}/myaccount`}
            >
              { acNavLang.personal[lang] || acNavLang.personal.en }
            </Link>
            
            <Link 
              className={`nav-item nav-link${pathName === `/${lang}/fiscal-info` ? ' active' : ''}`}  
              to={`/${lang}/fiscal-info`}
            >
              { acNavLang.billing[lang] || acNavLang.billing.en }
            </Link>
            
            <Link 
              className={`nav-item nav-link${pathName === `/${lang}/payment-info` ? ' active' : ''}`}  
              to={`/${lang}/payment-info`}
            >
              { acNavLang.payment[lang] || acNavLang.payment.en }
            </Link>
            
            <Link className={`nav-item nav-link${pathName === `/${lang}/account-info` ? ' active' : ''}`}  to={`/${lang}/account-info`}>
              { acNavLang.account[lang] || acNavLang.account.en }
            </Link>

            {
              user?.role === 'user' &&
              <Link 
                className=
                {`nav-item nav-link${pathName === `/${lang}/language-info` ? ' active' : ''}`}  
                to={`/${lang}/language-info`}
              >
                { acNavLang.language[lang] || acNavLang.language.en }
              </Link>
            }

            {user?.role === 'consultant' && 
              <>
                <Link 
                  className={`nav-item nav-link${pathName === `/${lang}/member-info` ? ' active' : ''}`}  
                  to={`/${lang}/member-info`}
                >
                  { acNavLang.member[lang] || acNavLang.member.en }
                </Link>

                <Link 
                  className={`nav-item nav-link${pathName === `/${lang}/insurance-info` ? ' active' : ''}`}  
                  to={`/${lang}/insurance-info`}
                >
                  { acNavLang.insurance[lang] || acNavLang.insurance.en }
                </Link>

                <Link 
                  className=
                  {`nav-item nav-link${pathName === `/${lang}/biography-info` ? ' active' : ''}`}  
                  to={`/${lang}/biography-info`}>
                  { consultantLang.biography[lang] || consultantLang.biography.en }
                </Link>

                <Link 
                  className=
                  {`nav-item nav-link${pathName === `/${lang}/intervention-specialization` ? ' active' : ''}`}  
                  to={`/${lang}/intervention-specialization`}
                >
                  { 
                    consultantLang.interventionSpecialization[lang] || 
                    consultantLang.interventionSpecialization.en 
                  }
                </Link>

                <Link 
                  className=
                  {`nav-item nav-link${pathName === `/${lang}/language-info` ? ' active' : ''}`}  
                  to={`/${lang}/language-info`}
                >
                  { acNavLang.language[lang] || acNavLang.language.en }
                </Link>

                <Link 
                  className=
                  {`nav-item nav-link${pathName === `/${lang}/service-info` ? ' active' : ''}`}  
                  to={`/${lang}/service-info`}
                >
                  { consultantLang.serviceOffered[lang] || consultantLang.serviceOffered.en }
                </Link>

                <Link 
                  className=
                  {`nav-item nav-link${pathName === `/${lang}/daily-schedule` ? ' active' : ''}`}  
                  to={`/${lang}/daily-schedule`}
                >
                  { acNavLang.daily[lang] || acNavLang.daily.en }
                </Link>
              </>
            }
          </nav>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    status: state.auth.loggedIn.status,
    user: state.auth.user
  }
}

export default connect(mapStateToProps, null)(AccountNav);