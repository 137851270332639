import React, { useState, useEffect } from "react";
import { interventionTooltipLang } from "../../../../../config/lang";
import TooltipSec from "../TooltipSec";

const Other = ({
  other,
  otherList,
  setOther,
  allOther,
  search,
  config,
  lang,
}) => {
  const [names, setNames] = useState([]);
  const [disable, setDisabled] = useState(false);
  useEffect(() => {
    if (other.length) {
      setNames(getNames(other, otherList));
    }
    if (other.length > 4) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [other, otherList]);

  const manageCheck = (id, other) => {
    if (!other.length) {
      other.push(id);
      setOther([...other]);
      setNames([...getNames(other, otherList)]);
    } else {
      const index = other.indexOf(id);

      if (index === -1) {
        other.push(id);

        setOther([...other]);
        setNames([...getNames(other, otherList)]);
      } else {
        other.splice(index, 1);
        setOther([...other]);
        setNames([...getNames(other, otherList)]);
      }
    }
  };

  const getNames = (ids, array) => {
    let names = [];
    if (ids && array) {
      array.forEach((el) => {
        if (ids.includes(el.id)) names.push(el.name);
      });
      return names;
    }

    return names;
  };

  const formatNames = (names) => {
    if (names.length) {
      if (names.length > 5) {
        return (
          <span>
            {names.length + ` ${config.selected[lang] || config.selected.en}`}
          </span>
        );
      } else {
        return names.map((el, i) => <span key={i}>{el}</span>);
      }
    }

    return null;
  };

  return (
    <div className="form-group">
      <label className="control-label">
        {config.otherTopics[lang] || config.otherTopics.en}
        <TooltipSec
          title={
            interventionTooltipLang.oP[lang] || interventionTooltipLang.oP.en
          }
        ></TooltipSec>
      </label>
      <div className="select-dropdown dropdown">
        <a
          href="#!"
          className="data-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {!names.length ? config.select[lang] || config.select.en : ""}
          {names.length ? formatNames(names) : ""}
        </a>

        <div className="dropdown-menu">
          <div className="dropdown-search">
            <input
              type="text"
              className="form-control"
              placeholder={config.search[lang] || config.search.en}
              onChange={(e) => search(e.target.value)}
            />
          </div>

          <div className="dropdown-list">
            {allOther.length
              ? allOther.map((el) => {
                  return (
                    <div className="item" key={el.id}>
                      <div className="custom-checkbox" type="checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox-input"
                          checked={other.includes(el.id)}
                          onChange={() => manageCheck(el.id, other)}
                          disabled={!other.includes(el.id) && disable}
                        />
                        <label className="custom-checkbox-label">
                          {el.name}
                        </label>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Other;
