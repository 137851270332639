import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Loader from '../../Loader';
import { getNoteList } from '../../actions/note';
// import { lang } from '../../config/lang';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecNoteList from '../../components/Admin/Sections/SecNoteList';

const NoteList = ({ 
  location, getNoteList, user, notes, nationalities, siteSettings, languages, timezones, countries, lang
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const id = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    const loadNotes = async () => {
      if(!isLoaded && user?.id) {
        try {
          await getNoteList({ lang, userId: id, consultantId: user?.id });
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.error(err.message);
        }
      }
    }

    loadNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user ]);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      !$.isEmptyObject(siteSettings) && languages.length && timezones.length && countries.length
      && isLoaded;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries, isLoaded ]);

  const config = {
    clientNotes: {
      en: 'Client Notes',
      es: 'Notas del cliente',
      it: 'Note del cliente'
    },
    add: {
      en: 'Add',
      es: 'Agregar',
      it: 'Aggiungere'
    },
    addNote: {
      en: 'Add Note',
      es: 'Añadir la nota',
      it: 'Aggiungi nota'
    },
    note: {
      en: 'Note',
      es: 'Nota',
      it: 'Nota'
    },
    save: {
      en: 'Save',
      es: 'Ahorrar',
      it: 'Salva'
    }
  }

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={config.clientNotes[lang] || config.clientNotes.en}>
        <SecNoteList notes={notes} lang={lang} id={id} consultantId={user?.id} 
          config={config}
        />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    notes: Object.values(state.notes)
  }
}

export default connect(mapStateToProps, { getNoteList })(NoteList);