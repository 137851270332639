import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import $ from "jquery";
import {
  updateAccountInfo,
  accountPasswordUpdate,
} from "../../../actions/profile";
import { langConfig, accountLang } from "../../../config/lang";
import SaveChanges from "./SaveChanges";
import SaveAccountChanges from "./SaveAccountChanges";
import { toast } from "react-toastify";
import SecShowHidePassword from "../../Auth/SecShowHidePassword";
const AccountInfoForm = ({
  auth,
  user,
  updateAccountInfo,
  accountPasswordUpdate,
  socket,
  lang,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const { status } = auth;
  const [promo, setPromo] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [passwordSpinner, setPasswordSpinner] = useState(false);
  const [isOldPassword, setisOldPassword] = useState("password");
  const [isPassword, setisPassword] = useState("password");
  const [isConfirmPassword, setisConfirmPassword] = useState("password");

  useEffect(() => {
    const loadDefaultEmail = (user) =>
      setValue("email", user?.email, { shouldValidate: true });

    if (user?.promotional_email_status === "1") setPromo(true);

    loadDefaultEmail(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    $("#profile_status").val(user?.active_status).trigger("change");
    $("#profile_status").on("change", function (e) {
      setValue("activeStatus", this.value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.active_status]);

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    if (!Object.keys(errors).length && userId && status) {
      try {
        const userData = {
          userId,
          lang,
          email: getValues("email"),
          activeStatus: user.active_status,
          promotionalEmailStatus: getValues("promotionalEmailStatus")
            ? "1"
            : "2",
        };

        if (user.role === "consultant") {
          userData.activeStatus =
            getValues("activeStatus") || user?.active_status;

          if (socket?.io) {
            socket.emit("updateConsultantStatus", {
              userId,
              activeStatus: userData?.activeStatus,
            });
          }
        }

        const message = await updateAccountInfo(userData);
        setSpinner(false);
        toast.success(message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  const onPasswordSubmit = async (data) => {
    const userId = user?.id;
    setPasswordSpinner(true);

    const userData = {
      userId,
      lang,
      password: getValues("password"),
      confirmPassword: getValues("confirmPassword"),
      newPassword: getValues("newPassword"),
    };

    try {
      const message = await accountPasswordUpdate(userData);
      setPasswordSpinner(false);
      toast.success(message);
    } catch (err) {
      setPasswordSpinner(false);
      toast.error(err.message);
    }
  };

  const dWaPE = {
    en: "I DO NOT want to receive promotional emails",
    es: "NO quiero recibir correos promocionales",
    it: "NON voglio ricevere email promozionali",
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row form-row">
          <div
            className={`col-md-${user?.role === "consultant" ? "12" : "12"} ${
              !user?.id ? "d-none" : ""
            }`}
          >
            {/* Email */}
            <div className="form-group">
              <label className="control-label required">
                {langConfig.email[lang] || langConfig.email.en}
              </label>
              <input
                className="form-control ng-untouched ng-pristine ng-valid"
                name="email"
                type="email"
                placeholder={langConfig.email[lang] || langConfig.email.en}
                defaultValue={status ? user.email : ""}
                {...register("email")}
              />
            </div>
          </div>

          <div className={`col-md-6 d-none`}>
            {/* Status */}
            <div className="form-group">
              <label className="control-label">
                {langConfig.status[lang] || langConfig.status.en}
              </label>
              <select
                id="profile_status"
                className="form-control select2-nosearch select2-hidden-accessible"
              >
                <option value="1">Online</option>
                <option value="2">Offline</option>
                <option value="3" disabled>
                  Busy
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-md-6">
            <div className="form-group">
              <div className="checkbox-group">
                <div className="custom-checkbox">
                  {/* Promotional email status */}
                  <input
                    className="custom-checkbox-input"
                    name="checkbox-promotional_email_status"
                    type="checkbox"
                    checked={promo}
                    onClick={() => setPromo(!promo)}
                    {...register("promotionalEmailStatus")}
                  />
                  <div className="custom-checkbox-label">
                    {dWaPE[lang] || dWaPE.en}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {user?.id && (
          <SaveChanges noMargin={true} smallTop={true} spinner={spinner} />
        )}
      </form>

      <form onSubmit={handleSubmit(onPasswordSubmit)}>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="sec-myaccount-header">
              <div className="lable-text">
                {langConfig.changePassword[lang] ||
                  langConfig.changePassword.en}
              </div>
            </div>
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-4">
            {/* Old password */}
            <div className="form-group">
              <div className="form-input-group">
                <label className="control-label required">
                  {accountLang.oldPassword[lang] || accountLang.oldPassword.en}
                </label>
                <div className="d-flex onpage-input">
                  <input
                    className="form-control ng-valid ng-dirty ng-touched"
                    name="old_password"
                    type={isOldPassword}
                    placeholder={
                      accountLang.oldPassword[lang] ||
                      accountLang.oldPassword.en
                    }
                    {...register("password")}
                  />
                  <SecShowHidePassword
                    setisPassword={setisOldPassword}
                    isPassword={isOldPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-4">
            {/* New password */}
            <div _ngcontent-serverapp-c9="" className="form-group">
              <div className="form-input-group">
                <label className="control-label required">
                  {accountLang.newPassword[lang] || accountLang.newPassword.en}
                </label>
                <div className="d-flex onpage-input">
                  <input
                    className="form-control ng-untouched ng-pristine ng-valid"
                    name="new_password"
                    type={isPassword}
                    placeholder={
                      accountLang.newPassword[lang] ||
                      accountLang.newPassword.en
                    }
                    {...register("newPassword")}
                  />
                  <SecShowHidePassword
                    setisPassword={setisPassword}
                    isPassword={isPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-4">
            {/* Confirm password */}
            <div className="form-group">
              <div className="form-input-group">
                <label className="control-label required">
                  {accountLang.confirmPassword[lang] ||
                    accountLang.confirmPassword.en}
                </label>
                <div className="d-flex onpage-input">
                  <input
                    className="form-control ng-untouched ng-pristine ng-valid"
                    name="confirm_password"
                    type={isConfirmPassword}
                    placeholder={
                      accountLang.confirmPassword[lang] ||
                      accountLang.confirmPassword.en
                    }
                    {...register("confirmPassword")}
                  />
                  <SecShowHidePassword
                    setisPassword={setisConfirmPassword}
                    isPassword={isConfirmPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <SaveAccountChanges
          userId={user?.id}
          lang={lang}
          spinner={passwordSpinner}
        />
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    socket: state.socket,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  updateAccountInfo,
  accountPasswordUpdate,
})(AccountInfoForm);
