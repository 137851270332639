import React from 'react';
import { connect } from 'react-redux';
import SingleMessage from './SingleMessage';

const Body = ({ messages, user, listRef, appointment, rcv, socket }) => {
	const getSameSenderPrevMsg = (senderId, index) => {
		return messages.find((message, i) => (message.sender_id === senderId && i < index)) || null;
	}

  return (
    <div className="message-body">
			<div className="d-flex">
				<div 
					className={`comment-list${appointment?.service_type && appointment?.service_type !== '1' ? ' d-none' : ''}`} 
					ref={listRef}
					style={{
						position: 'absolute',
						display: 'flex',
						flexDirection: 'column',
						bottom: '0',
						width: (appointment?.service_type && appointment?.service_type !== '1') ? '50%' : '100%',
						left: (appointment?.service_type && appointment?.service_type !== '1') ? 'unset' : '0',
						right: (appointment?.service_type && appointment?.service_type !== '1') ? '0' : 'unset'
					}}
				>
					{
						messages.length ?
						messages.map((item, i) => (
							<SingleMessage key={item.id} message={item} user={user} 
								appointment={appointment}
								prevMsg={getSameSenderPrevMsg(item.sender_id, i)}
								acPrevMsg={i ? messages[i - 1] : null}
								nxtMsg={messages[i + 1] || null}
							/>
						))
						: ''
					}
				</div>
			</div>
		</div>
  )
}

const mapStateToProps = state => {
	return {
		socket: state.socket
	}
}

export default connect(mapStateToProps, null)(Body);