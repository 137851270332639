import { GET_LAST_APPOINTMENT, SET_LAST_APPOINTMENT, LOGOUT } from "../../actions/types";

const lastAppointmentReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_LAST_APPOINTMENT:
      return action.payload;
    case SET_LAST_APPOINTMENT:
      return action.payload;
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default lastAppointmentReducer;