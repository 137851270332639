import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPages } from '../actions/cms';
import { GET_PAGES_PRIVACY_POLICY } from '../actions/types';
// import { lang } from '../config/lang';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import Banner from '../containers/Banner';
import SecPrivacyPolicy from '../components/Sections/SecPrivacyPolicy';

const PrivacyPolicy = ({ 
  getPages, privacyPolicy, banners, siteSettings, languages, lang
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    const loadPrivacyPage = async () => {
      if(!privacyPolicy?.id) {
        try {
          await getPages({ lang, slug: 'privacy-policies' }, GET_PAGES_PRIVACY_POLICY);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.error(err.message);
        }
      }
    }

    loadPrivacyPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, privacyPolicy?.id ]);

  useEffect(() => {
    const loadBanner = () => {
      if(!banner && privacyPolicy && banners) {
        const privacyPolicyBanner = banners.find(el => el.page_id === privacyPolicy.id);
        setBanner(privacyPolicyBanner);
      } 
    }

    loadBanner();
  }, [banner, banners, privacyPolicy]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && (privacyPolicy?.id || isLoaded)) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [isLoaded, privacyPolicy?.id, languages, siteSettings])

  return (
    <LayoutContainer
      seoTitle={privacyPolicy?.seo?.title}
      seoDescription={privacyPolicy?.seo?.description}
      seoKeywords={privacyPolicy?.seo?.keywords}
    >
      <Loader loaded={loader} />
      <Banner banner={banner} />
      <SecPrivacyPolicy privacyPolicy={privacyPolicy} />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    privacyPolicy: state.cms.pages.privacyPolicy,
    banners: Object.values(state.home.banners),
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages)
  }
}

export default connect(mapStateToProps, { getPages })(PrivacyPolicy);