import { host } from "../../config/host";
import { fetchData } from "../fetch";
// import { lang } from "../../config/lang";

export const nationalityListAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem("lang") || "en";
    const res = await fetchData(
      "POST",
      `${host}/nationalitieslist`,
      { lang, ...body },
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
export const faqCategoryListAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem("lang") || "en";
    const res = await fetchData(
      "POST",
      `${host}/faq-category/list`,
      { lang, ...body },
      signal
    );
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const siteSettingsAsync = async (lang = null) => {
  try {
    let url = `${host}/settings`;

    if (lang) {
      url = `${host}/settings?lang=${lang}`;
    }

    const res = await fetchData("GET", url, null);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const languagesAsync = async (lang = null) => {
  try {
    let url = `${host}/languages`;

    if (lang) {
      url = `${host}/languages?lang=${lang}`;
    }

    const res = await fetchData("GET", url, null);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const countryListAsync = async (signal = null) => {
  try {
    const res = await fetchData("GET", `${host}/countrieslist`, null, signal);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const timeZoneListAsync = async (signal = null) => {
  try {
    const res = await fetchData("GET", `${host}/timezoneslist`, null, signal);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getPagesAsync = async (body = null, signal = null) => {
  try {
    //const lang = localStorage.getItem('lang') || 'en';
    const defaultParams = { slug: "terms-service" };
    const params = !body ? defaultParams : { ...defaultParams, ...body };
    const res = await fetchData("POST", `${host}/pages`, params, signal);
    const data = await res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getServicesInfoAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem("lang") || "en";
    const params = !body ? { lang } : { ...body, lang };
    const res = await fetchData("POST", `${host}/serviceslist`, params, signal);
    const data = await res.json();
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getInterventionSpecializationListAsync = async (
  body = null,
  signal = null
) => {
  try {
    const defaultParams = { lang: localStorage.getItem("lang") || "en" };
    const params = !body ? defaultParams : body;
    // console.log(params)
    const res = await fetchData(
      "POST",
      `${host}/intervention-specialization/list`,
      params,
      signal
    );
    const data = await res.json();
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getProfessionalQualificationListAsync = async (
  body,
  signal = null
) => {
  try {
    const res = await fetchData(
      "POST",
      `${host}/professional_qualifications/list`,
      body,
      signal
    );
    const data = res.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
