// Socket
export const GET_SOCKET = "GET_SOCKET";
export const GET_SESSION_CREDS = "GET_SESSION_CREDS";
export const SET_SESSION_CREDS = "SET_SESSION_CREDS";

// Home
export const GET_HOME_SERVICES = "GET_HOME_SERVICES";
export const GET_HOME_ONLINE_SERVICES = "GET_HOME_ONLINE_SERVICES";
export const GET_HOME_INTERVENTIONS = "GET_HOME_INTERVENTIONS";
export const GET_HOME_GETTING_STARTED = "GET_HOME_GETTING_STARTED";

export const GET_HOME_COUNT = "GET_HOME_COUNT";
export const GET_BANNERS = "GET_BANNERS";
export const GET_HOME_WHAT = "GET_HOME_WHAT";

// Others
export const HOW_IT_WORKS_LIST = "HOW_IT_WORKS_LIST";
export const GET_TESTIMONIAL_LIST = "GET_TESTIMONIAL_LIST";
export const GET_FAQ_LIST = "GET_FAQ_LIST";

// CMS
export const GET_SITE_SETTINGS = "GET_SITE_SETTINGS";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_NATIONALITIES = "GET_NATIONALITIES";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_TIMEZONE_LIST = "GET_TIMEZONE_LIST";
export const GET_PROFESSIONAL_QUALIFICATION_LIST =
  "GET_PROFESSIONAL_QUALIFICATION_LIST";

export const GET_PAGES = "GET_PAGES";
export const GET_PAGES_TERM_CONDITION = "GET_PAGES_TERM_CONDITION";

export const GET_PAGES_HOME = "GET_PAGES_HOME";
export const GET_PAGES_ABOUT = "GET_PAGES_ABOUT";
export const GET_PAGES_CONTACT = "GET_PAGES_CONTACT";
export const GET_PAGES_HOW_IT_WORKS = "GET_PAGES_HOW_IT_WORKS";
export const GET_PAGES_BLOG = "GET_PAGES_BLOG";
export const GET_PAGES_PRIVACY_POLICY = "GET_PAGES_PRIVACY_POLICY";
export const GET_PAGES_INTERVENTIONS = "GET_PAGES_INTERVENTIONS";
export const GET_PAGES_FAQ = "GET_PAGES_FAQ";
export const GET_PAGES_CONSULTANT = "GET_PAGES_CONSULTANT";

export const GET_SERVICES_LIST = "GET_SERVICES_LIST";
export const GET_INTERVENTION_LIST = "GET_INTERVENTION_LIST";
export const GET_SPECIALIZATION_LIST = "GET_SPECIALIZATION_LIST";
export const GET_THERAPUTIC_LIST = "GET_THERAPUTIC_LIST";
export const GET_MENTAL_LIST = "GET_MENTAL_LIST";
export const GET_PSYCHOLOGICAL_LIST = "GET_PSYCHOLOGICAL_LIST";

export const GET_CONSULTANT_SERVICES = "GET_CONSULTANT_SERVICES";
export const DELETE_CONSULTANT_SERVICE = "DELETE_CONSULTANT_SERVICE";
export const UPDATE_CONSULTANT_ACCOUNT_INFO = "UPDATE_CONSULTANT_ACCOUNT_INFO";
export const GET_DAILY_TIMETABLE = "GET_DAILY_TIMETABLE";

export const GET_OTHER_LIST = "GET_OTHER_LIST";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

// Blog
export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_BLOG_DETAIL = "GET_BLOG_DETAIL";

// Conssultants

export const GET_CONSULTANT_LIST = "GET_CONSULTANT_LIST";

export const GET_CONSULTANT_DETAILS = "GET_CONSULTANT_DETAILS";
export const CLEAR_CONSULTANT_DETAILS = "CLEAR_CONSULTANT_DETAILS";
export const GET_CONSULTANT_REVIEWS = "GET_CONSULTANT_REVIEWS";
export const CLEAR_CONSULTANT_REVIEWS = "CLEAR_CONSULTANT_REVIEWS";
export const GET_CONSULTANT_TIME_SLOTS = "GET_CONSULTANT_TIME_SLOTS";
export const CLEAR_CONSULTANT_TIME_SLOTS = "CLEAR_CONSULTANT_TIME_SLOTS";

// Auth
export const REGISTER = "REGISTER";

export const LOGIN = "LOGIN";
export const GET_USER = "GET_USER";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_OTP = "VERIFY_OTP";

export const LOGOUT = "LOGOUT";

// Profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";
export const UPDATE_FISCAL_INFO = "UPDATE_FISCAL_INFO";
export const UPDATE_MEMBERSHIP_INFO = "UPDATE_MEMBERSHIP_INFO";
export const UPDATE_INSURANCE_INFO = "UPDATE_INSURANCE_INFO";

export const UPDATE_BIOGRAPHY_INFO = "UPDATE_BIOGRAPHY_INFO";
export const UPDATE_LANGUAGE_INFO = "UPDATE_LANGUAGE_INFO";
export const UPDATE_INTERVENTION_INFO = "UPDATE_INTERVENTION_INFO";

export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
export const UPDATE_DAILY_SCHEDULE_INFO = "UPDATE_DAILY_SCHEDULE_INFO";
export const GET_WISHLIST = "GET_WISHLIST";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";
export const DASHBOARD = "DASHBOARD";
export const GET_STRIPE_CUSTOMER = "GET_STRIPE_CUSTOMER";

// Appointments
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_PAST_APPOINTMENTS = "GET_PAST_APPOINTMENTS";
export const GET_MODIFIED_PAST_APPOINTMENTS = "GET_MODIFIED_PAST_APPOINTMENTS";
export const GET_PAST_APPOINTMENT_COUNT = "GET_PAST_APPOINTMENT_COUNT";
export const GET_PAST_APPOINTMENT = "GET_PAST_APPOINTMENT";
export const GET_INPROGRESS_APPOINTMENTS = "GET_INPROGRESS_APPOINTMENTS";
export const GET_MODIFIED_INPROGRESS_APPOINTMENTS =
  "GET_MODIFIED_INPROGRESS_APPOINTMENTS";
export const GET_INPROGRESS_APPOINTMENT_COUNT =
  "GET_INPROGRESS_APPOINTMENT_COUNT";
export const GET_INPROGRESS_APPOINTMENT = "GET_INPROGRESS_APPOINTMENT";
export const GET_UPCOMING_APPOINTMENTS = "GET_UPCOMING_APPOINTMENTS";
export const GET_MODIFIED_UPCOMING_APPOINTMENTS =
  "GET_MODIFIED_UPCOMING_APPOINTMENTS";
export const GET_UPCOMING_APPOINTMENT_COUNT = "GET_UPCOMING_APPOINTMENT_COUNT";
export const DECREASE_UPCOMING_APPOINTMENT_COUNT =
  "DECREASE_UPCOMING_APPOINTMENT_COUNT";
export const GET_UPCOMING_APPOINTMENT = "GET_UPCOMING_APPOINTMENT";
export const REMOVE_INPROGRESS_APPOINTMENT = "REMOVE_INPROGRESS_APPOINTMENT";
export const REMOVE_UPCOMING_APPOINTMENT = "REMOVE_UPCOMING_APPOINTMENT";
export const GET_LAST_APPOINTMENT = "GET_LAST_APPOINTMENT";
export const SET_LAST_APPOINTMENT = "SET_LAST_APPOINTMENT";
export const REMOVE_APPOINTMENT = "REMOVE_APPOINTMENT";
export const GET_APPOINTMENT_NOTE = "GET_APPOINTMENT_NOTE";

// Emails
export const GET_EMAIL_LIST = "GET_EMAIL_LIST";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const GET_EMAIL_DETAILS = "GET_EMAIL_DETAILS";
export const GET_EMAIL_DETAIL = "GET_EMAIL_DETAIL";
export const CLEAR_EMAIL_DETAILS = "CLEAR_EMAIL_DETAILS";

// Clients
export const GET_CLIENTS = "GET_CLIENTS";

// Notes
export const GET_NOTE_LIST = "GET_NOTE_LIST";

// Review
export const ADD_REVIEW = "ADD_REVIEW";

// Lang
export const DEFAULT_LANG = "DEFAULT_LANG";
//Category
export const GET_FAQ_CATEGORY = "GET_FAQ_CATEGORY";

// Messages
export const GET_APPOINTMENT_MESSAGES = "GET_APPOINTMENT_MESSAGES";
export const CLEAR_APPOINTMENT_MESSAGES = "CLEAR_APPOINTMENT_MESSAGES";
export const SEND_APPOINTMENT_MESSAGE = "SEND_APPOINTMENT_MESSAGE";

// Block user list
export const GET_BLOCKED_USERS = "GET_BLOCKED_USERS";
export const BLOCK_USER = "BLOCK_USER";
export const UNBLOCK_USER = "UNBLOCK_USER";

// Session
export const SET_SESSION = "SET_SESSION";
