import mapKeys from 'lodash/mapKeys';
import { GET_EMAIL_DETAILS, GET_EMAIL_DETAIL, CLEAR_EMAIL_DETAILS } from '../../actions/types';

const emailDetailsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_EMAIL_DETAILS:
      return { ...state, ...mapKeys(action.payload, 'id') };
    case GET_EMAIL_DETAIL:
      return { ...state, [action.payload.id]: action.payload };
    case CLEAR_EMAIL_DETAILS:
      return {};
    default:
      return state;
  }
}

export default emailDetailsReducer;