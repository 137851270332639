import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { updateDailyScheduleInfo } from '../../../actions/profile';
import { scheduleState } from '../../../config/dailySchedule';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import DailyScheduleFormSec from '../Children/DailyScheduleFormSec';
import moment from 'moment';
import { toast } from 'react-toastify';
const SecDailySchedule = ({
  history, updateDailyScheduleInfo, user, dailyTimetable, profile, timezones, ...props
}) => {
  const { lang } = props;
  const [spinner, setSpinner] = useState(false);
  const [state, setState] = useState({ ...scheduleState });
  const [intervalDuration, setIntervalDuration] = useState(0);
  const [scheduleDuration, setScheduleDuration] = useState(user.schedule_duration);
  const [currentDate,] = useState(new Date().toDateString());
  const [timezone, setTimezone] = useState('');

  useEffect(() => {
    if(timezones.length && user?.timezone) setTimezone(timezones[user?.timezone]?.zone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ timezones.length, user?.timezone ]);

  const ucwords = str => {
    return str.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
  }

  const formatIsoString = (currentDate, convertTime) => {
    // const convertedTime = convertToTimezone(currentDate, convertTime, timezone);
    // const selectedDate = new Date(currentDate + ' ' + convertTime).toISOString();
    // const selectedDateConverted = new Date(selectedDate).toLocaleString('en-US', { timeZone: timezone });

    // console.log(selectedDate, selectedDateConverted)

    const isoString = new Date(currentDate + ' ' + convertTime).toISOString();
    const hours = new Date(isoString).getUTCHours();
    const minutes = new Date(isoString).getUTCMinutes();

    return `${hours}:${minutes}`;
  }

  const getOffset = timeString => timeString.slice(-5);

  const convertToTimezone = (currentDate, time, timeZone) => {
    let utcDateTime = moment(new Date(currentDate + ' ' + time)).format();
    utcDateTime = utcDateTime.replace(getOffset(utcDateTime), '00:00');

    const convertedDate = new Date(utcDateTime).toLocaleString('en-US', { timeZone });

    let hours = new Date(convertedDate).getHours();
    hours = ('0' + hours).slice(-2);
    
    let minutes = new Date(convertedDate).getMinutes();
    minutes = ('0' + minutes).slice(-2);

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    if(dailyTimetable.length && timezone) {
      //console.log(dailyTimetable);
      const vState = { ...scheduleState };
      dailyTimetable.sort((a, b) => a.day_number - b.day_number).forEach((item, i) => {
       // console.log(item.first_from);
        if(vState && vState[i]) {
          vState[i]['id'] = item.id;
          vState[i]['dayName'] = ucwords(item.day_name);
          vState[i]['dayNumber'] = item.day_number;
          vState[i]['firstFrom'] = item.first_from ? 
          item.first_from : '';
          vState[i]['firstTo'] = item.first_to ? 
          item.first_to : '';
          vState[i]['firstIsClose'] = item.first_is_close !== '1' ? true : false;
          vState[i]['secondFrom'] = item.second_from ? 
          item.second_from : '';
          vState[i]['secondTo'] = item.second_to ? 
          item.second_to : '';
          vState[i]['secondIsClose'] = item.second_is_close !== '1' ? true : false;
        }
      })

      setState(vState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ timezone, dailyTimetable.length, props.isLoaded ]);

  useEffect(() => {
    if(profile?.id) {
      setIntervalDuration(profile.interval_duration);
      setScheduleDuration(profile.schedule_duration);
    }
  }, [profile]);

  const convertAbsToTime = numberValue => {
    if(numberValue) {
      if(isNaN(Math.abs(numberValue))) return numberValue;

      let absValue = parseInt(numberValue);
  
      if(absValue.toString().length < 2) {
       //console.log(`0${absValue.toString()}:00`)
        return `0${absValue.toString()}:00`
      }
  
     // console.log(`${absValue.toString()}:00`)
      return `${absValue.toString()}:00`;
    }

    return '';
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    const id = [];
    const firstFrom = []; 
    const firstTo = [];
    const firstIsClose = [];
    const secondFrom = [];
    const secondTo = [];
    const secondIsClose = [];
    const dayName = [];
    const dayNumber = [];

    Object.values(state).forEach(item => {
      item?.id ? id.push(item.id) : id.push('');
      firstFrom.push(item.firstFrom ? convertAbsToTime(item.firstFrom) : '');

      firstTo.push(item.firstTo ? convertAbsToTime(item.firstTo) : '');

      secondFrom.push(item.secondFrom ? convertAbsToTime(item.secondFrom) : '');

      secondTo.push(item.secondTo ? convertAbsToTime(item.secondTo) : '');

      dayName.push(item.dayName.toLowerCase());
      dayNumber.push(item.dayNumber);

      item.firstIsClose ? firstIsClose.push('2') : firstIsClose.push('1');
      item.secondIsClose ? secondIsClose.push('2') : secondIsClose.push('1')
    });

    if(user?.id) {
      try {
        const message = await updateDailyScheduleInfo({
          lang, userId: user.id, dayName, dayNumber, firstFrom, firstTo, firstIsClose,
          secondFrom, secondTo, secondIsClose, intervalDuration: `${intervalDuration}`, scheduleDuration, id
        });

        props.setIsLoaded(!props.isLoaded);
        setSpinner(false);

        toast.success(message);
        history.push(`/${lang}/daily-schedule`);
      } catch(err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  }

  return (
    <AccountContainer title={props.config.daily[props.lang] || props.config.daily.en}>
      <DailyScheduleFormSec state={state} setState={setState} 
        onSubmit={onSubmit}
        intervalDuration={intervalDuration}
        setIntervalDuration={setIntervalDuration}
        scheduleDuration={scheduleDuration}
        spinner={spinner}
      />
    </AccountContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    timezones: Object.values(state.cms.timeZoneList)
  }
}

export default connect(mapStateToProps, { updateDailyScheduleInfo })(SecDailySchedule)
