import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { addAppointmentNode } from "../../../../actions/appointments";
import { toast } from "react-toastify";
const AppointmentNoteModal = ({
  lang,
  user,
  appointmentId,
  addAppointmentNode,
  config,
  appointmentNote,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [note, setNote] = useState("");
  const [userType, setUserType] = useState(1);
  const closeRef = useRef(null);
  useEffect(() => {
    if (user.role == "consultant") setUserType(2);
  }, [user]);
  // useEffect(() => {
  //   if (appointmentNote) setNote(appointmentNote);
  //   else setNote("");
  // }, [appointmentNote]);
  const add = async () => {
    setSpinner(true);

    try {
      const message = await addAppointmentNode({
        lang,
        appointmentId,
        note,
        type: userType,
      });
      setNote("");
      setSpinner(false);
      toast.success(message);
      closeRef.current.click();
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };

  return (
    <div
      className="modal fade conversation-modal"
      id="appointmentNoteModal"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header appointment-modal-header">
            <h5 className="modal-title">
              {config.addNote[lang] || config.addNote.en}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              ref={closeRef}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="form-input-group">
                <textarea
                  className="form-control ng-pristine ng-valid ng-touched"
                  type="text"
                  value={note}
                  placeholder={config.note[lang] || config.note.en}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer appointment-footer-header">
            <button
              className="btn-universal"
              type="button"
              ref={closeRef}
              data-dismiss="modal"
            >
              <div className="d-flex align-items-center">
                <span>{config.cancel[lang] || config.cancel.en}</span>
                {spinner && (
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </button>

            <button
              className="btn-universal"
              type="button"
              onClick={add}
              disabled={spinner}
            >
              <div className="d-flex align-items-center">
                <span>{config.save[lang] || config.save.en}</span>
                {spinner && (
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { addAppointmentNode })(AppointmentNoteModal);
