import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import "fix-date";
import {
  setLastAppointment,
  deleteAppointment,
  modifyAppointments,
} from "../../../actions/appointments";
import moment from "moment";
import { Link } from "react-router-dom";
import { status, paymentStatus } from "../../../helpers/appointment";
import history from "../../../config/history";
import { appointmentLang } from "../../../config/lang";
import { toast } from "react-toastify";

const ListCard = ({
  item,
  user,
  lang,
  timezones,
  setLastAppointment,
  deleteAppointment,
  isShowDetails,
  setAppointmentId,
  setAppointmentNote,
  appointmentNote,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [detailsLink, setDetailsLink] = useState(false);
  const [current, setCurrent] = useState(
    new Date(
      new Date(new Date().toISOString()).toLocaleString("en-US", {
        timeZone: "UTC",
      })
    )
  );
  const timezone = timezones.find((item) => item.id === user?.timezone);
  useEffect(() => {
    if (isShowDetails) {
      if (item.payment_status == 3) {
        setDetailsLink(true);
      } else {
        setDetailsLink(false);
      }
    } else {
      setDetailsLink(true);
    }
  }, [isShowDetails, item]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(
        new Date(
          new Date(new Date().toISOString()).toLocaleString("en-US", {
            timeZone: "UTC",
          })
        )
      );
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openNoteModal = (e) => {
    setAppointmentId(e.appointment_id);
    setAppointmentNote(
      user && user.role === "consultant" ? e.consultant_note : e.user_note
    );
  };
  const handleConsultantRedirect = (e) => {
    e.preventDefault();

    if (item.appointment_type === "1") {
      history.push(`/${lang}/appointment/${item.appointment_id}`);
    } else {
      if (user?.role === "consultant" && item.consultant_join !== "1") {
        if (!item.appointment_end_time) {
          setLastAppointment(item);
        } else {
          setSpinner(true);

          if (new Date(item.appointment_end_time) >= current) {
            setLastAppointment({ ...item, show: true });
            setSpinner(false);
          }
        }
      } else {
        history.push(`/${lang}/appointment/${item.appointment_id}`);
      }
    }
  };

  const deleteAnAppointment = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteAppointment(item.ids);
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const getType = (serviceType) => {
    if (serviceType === "1") {
      return `/${lang}/message?appointment_id=${item.appointment_id}`;
    } else if (serviceType === "2") {
      return `/${lang}/voice?appointment_id=${item.appointment_id}&rcv=true`;
    }

    return `/${lang}/video?appointment_id=${item.appointment_id}&rcv=true`;
  };

  return (
    <div className="list-card">
      <>
        <Link
          to={`/${lang}/appointment-details/${item.appointment_id}`}
          className="date-time-wrapper"
        >
          <div className="sec-date-time">
            <span className="date-cont">
              {moment(
                String(
                  new Date(
                    `${item.appointment_start_time.replace(" ", "T")}`
                  ).toLocaleString("en-US", {
                    timeZone: timezone?.zone,
                  })
                )
              ).format("D")}
            </span>
            <span className="time-month">
              {moment(
                String(
                  new Date(
                    `${item.appointment_start_time.replace(" ", "T")}`
                  ).toLocaleString("en-US", {
                    timeZone: timezone?.zone,
                  })
                )
              ).format("MMM")}
            </span>
            <span className="time-cont">
              {moment(
                String(
                  new Date(`${item.appointment_start_time} UTC`).toLocaleString(
                    "en-US",
                    { timeZone: timezone?.zone }
                  )
                )
              )
                //.utcOffset(timezone.offset)
                .format("HH")}
              :
              {moment(
                String(
                  new Date(`${item.appointment_start_time} UTC`).toLocaleString(
                    "en-US",
                    { timeZone: timezone?.zone }
                  )
                )
              )
                //.utcOffset(timezone.offset)
                .format("mm")}
              {item.appointment_end_time && "-"}
              {item.appointment_end_time && (
                <span>
                  {moment(
                    String(
                      new Date(
                        `${item.appointment_end_time} UTC`
                      ).toLocaleString("en-US", { timeZone: timezone?.zone })
                    )
                  ).format("HH")}
                  :
                  {moment(
                    String(
                      new Date(
                        `${item.appointment_end_time} UTC`
                      ).toLocaleString("en-US", { timeZone: timezone?.zone })
                    )
                  ).format("mm")}
                </span>
              )}
            </span>
          </div>
        </Link>

        <Link
          to={`/${lang}/appointment-details/${item.appointment_id}`}
          className="list-title"
        >
          {user?.role === "user"
            ? !item.appointment_end_time ||
              new Date(item.appointment_end_time) > current
              ? `${appointmentLang.yHaWF[lang] || appointmentLang.yHaWF.en} ${
                  item.consultant_profile_name
                }`
              : `${appointmentLang.yHaW[lang] || appointmentLang.yHaW.en} ${
                  item.consultant_profile_name
                }`
            : !item.appointment_end_time ||
              new Date(item.appointment_end_time) > current
            ? `${appointmentLang.yHaWF[lang] || appointmentLang.yHaWF.en} ${
                item.user_profile_name
              }`
            : `${appointmentLang.yHaW[lang] || appointmentLang.yHaW.en} ${
                item.user_profile_name
              }`}
          <span className="list-amount">
            {appointmentLang.paymentAmount[lang] ||
              appointmentLang.paymentAmount.en}
            : {item.currency} {item.amount}
          </span>
          <span className="list-payment-status">
            {appointmentLang.paymentStatus[lang] ||
              appointmentLang.paymentStatus.en}
            : {paymentStatus[item.payment_status]}
          </span>
          {item.appointment_id && (
            <span className="list-payment-status">
              {appointmentLang.aI[lang] || appointmentLang.aI.en}:{" "}
              {item.appointment_id}
            </span>
          )}
        </Link>
      </>

      <div className="actionWraperBtn">
        {/* <div className=""> */}
        {item?.appointment_end_time &&
          new Date(new Date().toISOString()) <
            new Date(item.appointment_end_time) && (
            <a
              className="btn-action ml-auto universal-delete-btn"
              href="#!"
              onClick={deleteAnAppointment}
            >
              <i className="far fa-trash-alt"></i>
            </a>
          )}

        {/* </div> */}

        <div className="action-wrapper">
          <div
            className="btn-action status"
            data-toggle="modal"
            data-target="#appointmentNoteModal"
            onClick={() => openNoteModal(item)}
          >
            <i class="far fa-sticky-note mr-2"></i>
            {appointmentLang.addNote[lang] || appointmentLang.addNote.en}
          </div>

          <span className="status status-active">
            {appointmentLang[status[item.status].toLowerCase()][lang] ||
              appointmentLang[status[item.status].toLowerCase()].en}
          </span>

          {item.status === "3" && user?.role === "user" ? (
            <div className="other-actions d-none">
              <Link
                className="btn-action"
                to={`/${lang}/add-review?appointment_id=${item.ids}&consultant_id=${item.consultant_id}`}
              >
                Add Review
              </Link>
            </div>
          ) : !item.appointment_end_time &&
            item.consultant_join !== "3" &&
            item.status !== "3" ? (
            <div className="other-actions">
              <a
                className="btn-action btn-join"
                onClick={handleConsultantRedirect}
                href={getType(item.service_type)}
              >
                Join
              </a>
            </div>
          ) : item.appointment_end_time &&
            new Date(item.appointment_start_time) <= current &&
            new Date(item.appointment_end_time) > current &&
            item.consultant_join !== "3" &&
            item.status !== "3" ? (
            <div className="other-actions">
              <a
                className="btn-action"
                onClick={handleConsultantRedirect}
                href={getType(item.service_type)}
              >
                <div className="d-flex align-items-center">
                  <span className="status status-active">Join</span>
                  {spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </a>
            </div>
          ) : (
            ""
          )}
          {/* ---- */}

          {/* ---- */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    timezones: Object.values(state.cms.timeZoneList),
    pastAppointments: Object.values(state.appointments.pastAppointments),
    inprogressAppointments: Object.values(
      state.appointments.inprogressAppointments
    ),
    upcomingAppointments: Object.values(
      state.appointments.upcomingAppointments
    ),
  };
};

export default connect(mapStateToProps, {
  setLastAppointment,
  deleteAppointment,
  modifyAppointments,
})(ListCard);
