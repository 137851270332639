import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { sendEmail } from "../../actions/email";
import { toast } from "react-toastify";

const EmailModal = ({ sendEmail, user, receiverId, socket, lang }) => {
  const [description, setDescription] = useState("");
  const [spinner, setSpinner] = useState(false);

  const buttonRef = useRef(null);

  const sendAnEmail = async () => {
    setSpinner(true);

    try {
      const message = await sendEmail({
        lang,
        description,
        senderId: user.id,
        receiverId,
        type: "1",
        emailId: "0",
        parentId: "0",
      });

      // socket.emit('sendEmail', {
      //   lang, description, senderId: user.id, receiverId, type: '1', emailId: '0',
      //   parentId: '0'
      // });

      setDescription("");
      setSpinner(false);
      buttonRef.current.click();
      toast.success(message);
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };

  const emailMeText = {
    en: "Email Me",
    es: "Envíeme un correo electrónico",
    it: `Mandami un'email`,
  };

  const enterText = {
    en: "Enter Text",
    es: "Ingrese texto",
    it: "Inserire il testo",
  };

  const send = {
    en: "Send",
    es: "Enviar",
    it: "Spedire",
  };

  return (
    <div className="modal fade" id="email_me" role="dialog" tabIndex="-1">
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {emailMeText[lang] || emailMeText.en}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              ref={buttonRef}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <textarea
                autoComplete="off"
                className="form-control"
                name="email_text"
                placeholder={enterText[lang] || enterText.en}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group mb-0 mt-3">
              <button
                className="btn btn-universal email"
                onClick={sendAnEmail}
                disabled={spinner}
              >
                <div className="d-flex align-items-center">
                  <span>{send[lang] || send.en}</span>
                  {spinner && (
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    socket: state.socket,
  };
};

export default connect(mapStateToProps, { sendEmail })(EmailModal);
