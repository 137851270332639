import { host, adminHost } from "../../config/host";
import { fetchData } from "../fetch";

export const createUser = async user => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const params = { ...user, lang };
  
    const res = await fetchData('POST', `${host}/signup`, params);
    const data = await res.json();
  
    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const profileInfoAsync = async userId => {
  try {
    const res = await fetchData('POST', `${host}/user/profile-info`, { userId });
    const data = await res.json();

    return data;
    
  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyOTPAsync = async (userId, otp) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const params = { lang, userId, otp };
    const res = await fetchData('POST', `${host}/email-verification`, params);
    const data = await res.json();
  
    return data;
    
  } catch(err) {
    throw new Error(err.message);
  }
}

export const loginAsync = async (email, password) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const params = { lang, email, password };
    const res = await fetchData('POST', `${host}/login`, params);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const socialLoginAsync = async body => {
  try {
    const res = await fetchData('POST', `${host}/social-login`, body);
    const data = await res.json();
    
    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const forgetPasswordAsync = async email => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const params = { lang, email };
    const res = await fetchData('POST', `${host}/forget-password`, params);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const forgetPasswordOTPVerificationAsync = async (otp, userId) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const params = { lang, otp, userId };
    const res = await fetchData('POST', `${host}/check-otp`, params);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const resetPasswordAsync = async (newpassword, confirmpassword, userId) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const params = { lang, newpassword, confirmpassword, userId };
    const res = await fetchData('POST', `${host}/reset-password`, params);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const logoutAsync = async body => {
  try {
    const res = await fetchData('POST', `${adminHost}/update-status`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}