import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import { getTestimonialList } from '../../actions/otherPages';
// import { loadList } from '../../helpers/loadHelper';

import TestimonialItem from './TestimonialItem';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TestimonialsBG from '../../assets/img/banner/banner-02.jpg';

const Testimonials = ({ list, getTestimonialList, lang }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();
    
    const loadTestimonials = async () => {
      try {
        if(!list.length) {
          await getTestimonialList({ lang }, ac.signal)
          setIsLoaded(true);
        } else {
          setIsLoaded(true);
        }
      } catch(err) {
        setIsLoaded(true);
        console.log(err.message);
      }
    }

    loadTestimonials();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, list.length ]); 

  const config = {
    testimonials: {
      en: 'Testimonials',
      es: 'Testimonios',
      it: 'Testimonianze'
    }
  }

  return (
    <section className="sec-testimonial">
			<div className="testimonial-overlay" style={{ backgroundImage: `url(${TestimonialsBG})` }}></div>
      <div className="container">
        <div className="section-title title-alt">
          <h1 className="title">{ config.testimonials[lang] || config.testimonials.en }</h1>
        </div>
        <OwlCarousel className="testimonial-slider owl-carousel" items={1}  loop margin={5} dots={true} nav center={false} rewind={1} autoplay={false}
          navText={['<i class="fal fa-arrow-circle-left"></i>', '<i class="fal fa-arrow-circle-right"></i>']}
          lazyLoad={true}
          responsive={{0:{items:1},550:{items:1},768:{items:1},1000:{items:1}}}
        >
          {
            (isLoaded && list) ? 
            list.map(item => <TestimonialItem key={item.id} item={item} />)
            : ''
          }
        </OwlCarousel>
      </div>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    list: Object.values(state.others.testimonialList)
  }
}

export default connect(mapStateToProps, { getTestimonialList })(Testimonials);