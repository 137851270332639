import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { addCard } from '../../../actions/payment';
import $ from 'jquery';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
const StripeFormOnAppointmentBooking = props => {
  const stripe = useStripe();
  const elements = useElements();
  const handleChange =  async (event) => {
    if(props.isNewCard==1){
      if(event.complete)
      {
        const cardElement = elements.getElement('card');
        const token = await stripe.createToken(cardElement);
        props.setToken(token.token.id);
      }else{
        props.setToken(null)
      }
    }
  };
  return (
    <form >
      <CardElement  onChange={handleChange}  options={{ hidePostalCode: true}} />
    </form>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { addCard })(StripeFormOnAppointmentBooking);
