import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import LanguageForm from '../Children/Forms/LanguageForm';

const SecLanguageInfo = ({ history, config, lang }) => {

  return (
    <AccountContainer title={config.language[lang] || config.language.en}>
      <LanguageForm history={history} />
    </AccountContainer>
  );
}

export default SecLanguageInfo;