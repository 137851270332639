import { 
  GET_USER, 
  VERIFY_USER, 
  LOGIN, 
  UPDATE_PROFILE, 

  UPDATE_FISCAL_INFO,
  UPDATE_MEMBERSHIP_INFO,
  UPDATE_INSURANCE_INFO,
  UPDATE_BIOGRAPHY_INFO,
  UPDATE_LANGUAGE_INFO,
  UPDATE_INTERVENTION_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_DAILY_SCHEDULE_INFO,
  LOGOUT
} from "../../actions/types";

const userReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_USER:
      return { ...state, ...action.payload };
    case LOGIN:
      return { ...state, ...action.payload };
    case VERIFY_USER:
      return { ...state, ...action.payload };
    case UPDATE_PROFILE:
      return { ...state, ...action.payload.data };
    case UPDATE_FISCAL_INFO:
        return { ...state, ...action.payload.data };
    case UPDATE_MEMBERSHIP_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_INSURANCE_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_BIOGRAPHY_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_LANGUAGE_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_INTERVENTION_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_ACCOUNT_INFO:
      return { ...state, ...action.payload.data };
    case UPDATE_DAILY_SCHEDULE_INFO:
      return { ...state, ...action.payload.data };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default userReducer;