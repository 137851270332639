import { GET_INPROGRESS_APPOINTMENT_COUNT } from "../../../actions/types";

const inProgressAppointmentCountReducer = (state = 0, action) => {
  switch(action.type) {
    case GET_INPROGRESS_APPOINTMENT_COUNT:
      return action.payload;
    default:
      return state;
  }
}

export default inProgressAppointmentCountReducer;