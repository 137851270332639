import { GET_PAGES_CONSULTANT } from "../../../actions/types";

const consultantRecuder = (state = {}, action) => {
  switch(action.type) {
    case GET_PAGES_CONSULTANT:
      return { ...action.payload };
    default:
      return state;
  }
}

export default consultantRecuder;