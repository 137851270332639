import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { 
  getNationalities, 
  getInterventionSpecializationList, 
  getCountryList 
} from '../actions/cms';
import { withRouter } from 'react-router';
import { getPages } from '../actions/cms';
import { getWishlist } from '../actions/wishlist';
import { clearConsultant } from '../actions/consultant';
import { getHomeInterventionsList } from '../actions/home';
import { GET_PAGES_CONSULTANT } from '../actions/types';
// import { lang } from '../config/lang';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import PageBanner from '../components/Consultant/PageBanner';

import ConsultantItems from '../components/Consultant/Consultant';

const Consultant = ({ 
  getWishlist, getPages, consultant, banners, nationalities, siteSettings, languages,
  clearConsultant, countries, location, lang, getNationalities, getInterventionSpecializationList,
  getCountryList, user
}) => {
  const [loader, setLoader] = useState(false);
  const [isPageLoaded, setPageIsLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState(false);
  const [consultantLoaded, setConsultantLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadWishList = async () => {
      if(!isLoaded && user?.id) {
        try {
          await getWishlist({ userId: user.id }, ac.signal);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          // console.error(err.message);
        }
      }
    }

    loadWishList();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.id ]);

  useEffect(() => {
    const loadConsultantPage = async () => {
      if(!isPageLoaded) {
        try {
          clearConsultant();
          await getPages({ lang, slug: 'find-a-psychologist' }, GET_PAGES_CONSULTANT);
          setPageIsLoaded(false);
        } catch(err) {
          console.error(err.message);
        }
      }
    }

    loadConsultantPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang ]);

  useEffect(() => {
    const loadBanner = () => {
      if(!banner && consultant && banners) {
        const consultantBanner = banners.find(el => el.page_id === consultant.id);
        setBanner(consultantBanner);
      } 
    }

    loadBanner();
  }, [banner, banners, consultant]);

  useEffect(() => {
    const ac = new AbortController();

    const loadCountryList = async () => {
      try {
        await getCountryList(ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    loadCountryList();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadDependencies = async () => {
      try {
        await getNationalities({ lang });
        await getInterventionSpecializationList({ lang }, ac.signal)
      } catch(err) {
        // console.log(err.message);
      }
    }

    loadDependencies();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang ]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && banner && consultantLoaded
      ) {
        setLoader(true);
      } else {
        setLoader(false);
      }
    }

    handleLoader();
  }, [banner, languages.length, siteSettings, consultantLoaded]);

  return (
    <LayoutContainer
      seoTitle={consultant?.seo?.title}
      seoDescription={consultant?.seo?.description}
      seoKeywords={consultant?.seo?.keywords}
    >
      <Loader loaded={loader} />
      <PageBanner banner={banner} />
      <ConsultantItems nationalities={nationalities} languages={languages} countries={countries}
        location={location}
        setConsultantLoaded={setConsultantLoaded}
      />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    consultant: state.cms.pages.consultants,
    banners: Object.values(state.home.banners),
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    consultants: Object.values(state.consultants.consultantList),
  }
}

export default withRouter(connect(mapStateToProps, { getWishlist, getPages, clearConsultant, getHomeInterventionsList, getCountryList, getInterventionSpecializationList, getNationalities })(Consultant));