import mapKeys from 'lodash/mapKeys';
import { GET_PSYCHOLOGICAL_LIST } from '../../../actions/types';

const psychologicalReducer = (state = {}, action) => {
  switch(action.type) {

    case GET_PSYCHOLOGICAL_LIST:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default psychologicalReducer;