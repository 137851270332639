import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import BiographyForm from '../Children/Forms/BiographyForm';

const SecBiographyInfo = ({ history, config, lang }) => {

  return (
    <AccountContainer title={config.biography[lang] || config.biography.en}>
      <BiographyForm history={history} />
    </AccountContainer>
  );
}

export default SecBiographyInfo;