import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { GET_BLOCKED_USERS, BLOCK_USER, UNBLOCK_USER, LOGOUT } from '../../actions/types';

const blockedUserReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_BLOCKED_USERS:
      return { ...state, ...mapKeys(action.payload, 'id') };
    case BLOCK_USER:
      return { ...state, [action.payload.id]: action.payload };
    case UNBLOCK_USER:
      const user = Object.values(state).find(user => user.user_id === action.payload);
      return omit(state, user.id);
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default blockedUserReducer;