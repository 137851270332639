import React, { useEffect, useState } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { updateProfile } from "../../../actions/profile";
import { hostUrl } from "../../../config/host";
import { isImage, formatBytes } from "../../../helpers";
import { acNavLangFormal, errorLang } from "../../../config/lang";
import AccountContainer from "../../../containers/Admin/AccountContainer";
import AccountInfo from "../Children/AccountInfo";
import AddressAndBirthInfo from "../Children/AddressAndBirthInfo";
import SaveChanges from "../Children/SaveChanges";
import moment from "moment";
import { toast } from "react-toastify";

const SecMyAccount = ({
  auth,
  user,
  countries,
  timezones,
  updateProfile,
  nationalities,
  lang,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [image, setImage] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const imageChange = (e) => {
    const fileSize = formatBytes(e.target.files[0]?.size);
    if (fileSize < 500) {
      var validImage = isImage(e.target.files[0], errors, setImage);
      if (validImage) {
        setValue("image", validImage, {
          shouldValidate: true,
        });
      }
    } else {
      toast.error(errorLang.imageSizeLimit[lang]);
    }
  };
  useEffect(() => {
    const setFormValues = () => {
      setValue("firstname", user?.firstname, { shouldValidate: true });
      setValue("lastname", user?.lastname, { shouldValidate: true });
      setValue("phoneCode", user?.phone_code, { shouldValidate: true });
      setValue("phone", user?.phone, { shouldValidate: true });
      setValue(
        "personalDob",
        user?.dob ? moment(new Date(user?.dob)).format("DD-MM-Y") : "",
        { shouldValidate: true }
      );

      // Residence
      setValue("personalAddress", user?.personal_address, {
        shouldValidate: true,
      });
      setValue("personalCity", user?.personal_city, { shouldValidate: true });
      setValue("personalProvince", user?.personal_province, {
        shouldValidate: true,
      });
      setValue("personalZipcode", user?.personal_zipcode, {
        shouldValidate: true,
      });

      // Birth
      setValue("birthCity", user?.birth_city, { shouldValidate: true });
      setValue("birthProvince", user?.birth_province, { shouldValidate: true });
    };
    setFormValues();
  }, [user, setValue]);

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    if (!Object.keys(errors).length && userId && auth.status) {
      try {
        const userData = { ...data, userId, lang };

        if (image) {
          userData.image = image;
        } else {
          if (user?.image) {
            const previousImage = user.image.replace(hostUrl, "");
            userData.previousImage = previousImage;
          }
        }

        const message = await updateProfile(userData);
        setSpinner(false);
        toast.success(message);
        setImage(null);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  return (
    <AccountContainer
      title={acNavLangFormal.personal[lang] || acNavLangFormal.personal.en}
    >
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart-form-data">
        <AccountInfo
          auth={auth}
          user={user}
          register={register}
          errors={errors}
          countries={countries}
          timezones={timezones}
          image={image}
          imageChange={imageChange}
          nationalities={nationalities}
          setValue={setValue}
          lang={lang}
          getValues={getValues}
        />
        <AddressAndBirthInfo
          auth={auth}
          user={user}
          register={register}
          errors={errors}
          countries={countries}
          setValue={setValue}
          config={acNavLangFormal}
          lang={lang}
          getValues={getValues}
        />
        <SaveChanges auth={auth} user={user} spinner={spinner} />
      </form>
    </AccountContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    timezones: Object.values(state.cms.timeZoneList),
  };
};

export default connect(mapStateToProps, { updateProfile })(SecMyAccount);
