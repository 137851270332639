import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { allowedLangs } from '../config/lang';
import { onlineOffline } from '../middlewares/onlineOffline';

const ProtectedRoute = ({ path, component: Component, auth, lang, ...restOfProps }) => {

  const isAuthenticated = localStorage.getItem('userId') || auth?.status;
  // const isAuthenticated = auth.status;

  useEffect(() => {
    const handleOnlineOffline = async () => {
      try {
        await onlineOffline();
      } catch(err) {
        console.log(err.message);
      }
    }

    handleOnlineOffline();
  }, [ restOfProps.location.pathname ]);

  useEffect(() => {
    if(!allowedLangs.includes(restOfProps.computedMatch.params.lang)) {
      localStorage.setItem('lang', 'en')
    } else {
      localStorage.setItem('lang', restOfProps.computedMatch.params.lang)
    }

  }, [ restOfProps.computedMatch.params.lang ]);

  return (
    <Route 
      path={path}
      { ...restOfProps }
      render={(props) =>
       isAuthenticated ? <Component { ...props } /> : <Redirect to={`/${lang}/login`} />
      }
    />
  )
}

// function ProtectedRoute2({ component: Component, ...restOfProps }) {
//   const isAuthenticated = localStorage.getItem("isAuthenticated");
//   console.log("this", isAuthenticated);

//   return (
//     <Route
//       {...restOfProps}
//       render={(props) =>
//         isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
//       }
//     />
//   );
// }

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user
  }
}

export default withRouter(connect(mapStateToProps, null)(ProtectedRoute));