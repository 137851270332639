import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { updateFiscalInfo } from "../../../actions/profile";
import {
  fiscalLang,
  accountLang,
  billingTooltipLang,
  personalTooltipLang,
} from "../../../config/lang";
import SaveChanges from "../Children/SaveChanges";
import { toast } from "react-toastify";
import TooltipSec from "./Forms/TooltipSec";
const FiscalInfoForm = ({
  auth,
  user,
  countries,
  updateFiscalInfo,
  history,
  lang,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [role, setRole] = useState("user");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const { status } = auth;

  useEffect(() => {
    const loadDefaultValues = (user) => {
      setValue("fiscalBusinessName", user?.fiscal_business_name, {
        shouldValidate: true,
      });
      setValue("fiscalCode", user?.fiscal_code, { shouldValidate: true });
      setValue("fiscalCountry", user?.fiscal_country, { shouldValidate: true });
      setValue("fiscalProvince", user?.fiscal_province, {
        shouldValidate: true,
      });
      setValue("fiscalCity", user?.fiscal_city, { shouldValidate: true });
      setValue("fiscalZipcode", user?.fiscal_zipcode, { shouldValidate: true });
      setValue("fiscalAddress", user?.fiscal_address, { shouldValidate: true });
      setValue("fiscalVatno", user?.fiscal_vatno, { shouldValidate: true });
      setValue("fiscalPec", user?.fiscal_pec, { shouldValidate: true });
      setValue("personalEmail", user?.personal_email, { shouldValidate: true });
      setValue("fiscalSdi", user?.fiscal_sdi, { shouldValidate: true });
    };
    setRole(user?.role);

    loadDefaultValues(user);
  }, [setValue, user]);

  useEffect(() => {
    $("#fiscal_country")
      .val(getValues("fiscalCountry") || user?.fiscal_country)
      .trigger("change");

    $("#fiscal_country").on("change", function (e) {
      setValue("fiscalCountry", this.value);
    });
  });

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    if (!Object.keys(errors).length && userId) {
      try {
        const userData = { ...data, lang };
        userData.userId = userId;
        const message = await updateFiscalInfo(userData);

        setSpinner(false);
        toast.success(message);
        history.push(`/${lang}/fiscal-info`);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="col-md-6 col-lg-3">
          {/* Fiscal Business Name */}
          <div className="form-group">
            <label className="control-label">
              {accountLang.name[lang] || accountLang.name.en}
              <TooltipSec
                title={
                  personalTooltipLang.name[lang] || personalTooltipLang.name.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine"
              name=""
              type="text"
              readOnly
              placeholder={accountLang.name[lang] || accountLang.name.en}
              defaultValue={status ? user.firstname : ""}
            />
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          {/* Fiscal Business Name */}
          <div className="form-group">
            <label className="control-label">
              {accountLang.surname[lang] || accountLang.surname.en}
              <TooltipSec
                title={
                  personalTooltipLang.surname[lang] ||
                  personalTooltipLang.surname.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine "
              name=""
              type="text"
              readOnly
              placeholder={accountLang.surname[lang] || accountLang.surname.en}
              defaultValue={status ? user.lastname : ""}
            />
          </div>
        </div>
        <div className="col-md-6 d-none">
          {/* Fiscal Business Name */}
          <div className="form-group">
            <label className="control-label">
              {fiscalLang.businessName[lang] || fiscalLang.businessName.en}
              <TooltipSec
                title={
                  personalTooltipLang.surname[lang] ||
                  personalTooltipLang.surname.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_business_name"
              type="text"
              placeholder={
                fiscalLang.businessName[lang] || fiscalLang.businessName.en
              }
              defaultValue={status ? user.fiscal_business_name : ""}
              {...register("fiscalBusinessName")}
            />
          </div>
        </div>
        <div className="col-md-6">
          {/* Fiscal Code */}
          <div className="form-group">
            <label className="control-label required">
              {fiscalLang.fiscalCode[lang] || fiscalLang.fiscalCode.en}
              <TooltipSec
                title={billingTooltipLang.fC[lang] || billingTooltipLang.fC.en}
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_code"
              type="text"
              placeholder={
                fiscalLang.fiscalCode[lang] || fiscalLang.fiscalCode.en
              }
              defaultValue={status ? user.fiscal_code : ""}
              {...register("fiscalCode")}
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal Country */}
          <div className="form-group">
            <label className="control-label required">
              {fiscalLang.businessCountry[lang] ||
                fiscalLang.businessCountry.en}
              <TooltipSec
                title={
                  billingTooltipLang.bCountry[lang] ||
                  billingTooltipLang.bCountry.en
                }
              ></TooltipSec>
            </label>
            <select
              className="form-control select2 country select2-hidden-accessible"
              id="fiscal_country"
              tabIndex="-1"
              aria-hidden="true"
            >
              <option value="">
                {fiscalLang.businessCountry[lang] ||
                  fiscalLang.businessCountry.en}
              </option>
              {countries &&
                countries.map((country, i) => {
                  return (
                    <option key={i} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal Province */}
          <div className="form-group">
            <label className="control-label required">
              {fiscalLang.businessProvince[lang] ||
                fiscalLang.businessProvince.en}
              <TooltipSec
                title={billingTooltipLang.bP[lang] || billingTooltipLang.bP.en}
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              id="fiscal_province"
              name="fiscal_province"
              type="text"
              placeholder={
                fiscalLang.businessProvince[lang] ||
                fiscalLang.businessProvince.en
              }
              defaultValue={status ? user.fiscal_province : ""}
              {...register("fiscalProvince")}
            />
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal City */}
          <div className="form-group">
            <label className="control-label required">
              {fiscalLang.businessCity[lang] || fiscalLang.businessCity.en}
              <TooltipSec
                title={
                  billingTooltipLang.bCity[lang] || billingTooltipLang.bCity.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              id="fiscal_city"
              name="fiscal_city"
              type="text"
              placeholder={
                fiscalLang.businessCity[lang] || fiscalLang.businessCity.en
              }
              defaultValue={status ? user.fiscal_city : ""}
              {...register("fiscalCity")}
            />
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal Zipcode */}
          <div className="form-group">
            <label className="control-label required">
              {accountLang.zipCode[lang] || accountLang.zipCode.en}
              <TooltipSec
                title={
                  billingTooltipLang.bZipCode[lang] ||
                  billingTooltipLang.bZipCode.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_zipcode"
              type="text"
              placeholder={accountLang.zipCode[lang] || accountLang.zipCode.en}
              defaultValue={status ? user.fiscal_zipcode : ""}
              {...register("fiscalZipcode")}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6 col-lg-3">
          <div className="form-group">
            {/* Fiscal Address */}
            <label className="control-label required">
              {fiscalLang.businessAddress[lang] ||
                fiscalLang.businessAddress.en}
              <TooltipSec
                title={
                  billingTooltipLang.bAddress[lang] ||
                  billingTooltipLang.bAddress.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_address"
              type="text"
              placeholder={
                fiscalLang.businessAddress[lang] ||
                fiscalLang.businessAddress.en
              }
              defaultValue={status ? user.fiscal_address : ""}
              {...register("fiscalAddress")}
            />
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal vatno */}
          <div className="form-group">
            <label
              className={"control-label " + (role === "user" ? "" : "required")}
            >
              {fiscalLang.tIc[lang] || fiscalLang.tIc.en}
              <TooltipSec
                title={
                  billingTooltipLang.tIC[lang] || billingTooltipLang.tIC.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_vatno"
              type="text"
              placeholder={fiscalLang.tIc[lang] || fiscalLang.tIc.en}
              defaultValue={status ? user.fiscal_vatno : ""}
              {...register("fiscalVatno")}
            />
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal Pec */}
          <div className="form-group">
            <label className="control-label">
              {fiscalLang.pec[lang] || fiscalLang.pec.en}
              <TooltipSec
                title={
                  billingTooltipLang.pEC[lang] || billingTooltipLang.pEC.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_pec"
              type="text"
              placeholder={fiscalLang.pec[lang] || fiscalLang.pec.en}
              defaultValue={status ? user.fiscal_pec : ""}
              {...register("fiscalPec")}
            />
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Personal email */}
          <div className="form-group">
            <label className="control-label required">
              {accountLang.personalEmail[lang] || accountLang.personalEmail.en}
              <TooltipSec
                title={
                  billingTooltipLang.personalEmail[lang] ||
                  billingTooltipLang.personalEmail.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="personal_email"
              type="email"
              placeholder={
                accountLang.personalEmail[lang] || accountLang.personalEmail.en
              }
              defaultValue={status ? user.personal_email : ""}
              {...register("personalEmail")}
            />
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3">
          {/* Fiscal Sdi */}
          <div className="form-group">
            <label className="control-label">
              {fiscalLang.sdi[lang] || fiscalLang.sdi.en}
              <TooltipSec
                title={
                  billingTooltipLang.sDI[lang] || billingTooltipLang.sDI.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-untouched ng-pristine ng-valid"
              name="fiscal_sdi"
              type="text"
              placeholder={fiscalLang.sdi[lang] || fiscalLang.sdi.en}
              defaultValue={status ? user.fiscal_sdi : ""}
              {...register("fiscalSdi")}
            />
          </div>
        </div>
      </div>

      <SaveChanges spinner={spinner} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
    countries: Object.values(state.cms.countryList),
  };
};

export default connect(mapStateToProps, { updateFiscalInfo })(FiscalInfoForm);
