import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import MemberInfoForm from '../Children/Forms/MemberInfoForm';


const SecMemberInfo = ({ history, config, lang }) => {
  return (
    <AccountContainer title={config.member[lang] || config.member.en}>
      <MemberInfoForm history={history} />
      {/* <SaveAccountChanges /> */}
    </AccountContainer>
  );
}

export default SecMemberInfo;