export const API_KEY = "47879001";
//47527901 11/09/2022
//"47431141"; latest
//"47411761";
//47604431  26/03/2024
export const API_SECRET = "1e71483f664e4e1bf048e43b76b81412f924bd24";
//c5e640f7f5810f5386d570039e44b9989026d783 11/09/2022
//"76382b1f2c5656a596476726324a30c6f56b0507"; latest
// "2acd7237a92c20c24fe7ed3cd6fd5885fb27a4ab";
//fd9bf1736ed975ddc9480a591bfe582e8d9fe7ef 26/03/2024
export const sessionId =
  "2_MX40Nzg3OTAwMX5-MTcxMTQzNDI3NTkyMn53bWt2NXlkVTlPQmVNVjNkTUJmekpudmt-fn4";
export const token =
  "T1==cGFydG5lcl9pZD00NzQxMTc2MSZzaWc9NGNmY2UwMTg0MjM0OTVmOWRjMmQyOWM5YzMwMjYxMTY2ZWY2MWIxZjpzZXNzaW9uX2lkPTFfTVg0ME56UXhNVGMyTVg1LU1UWTBNakUxTlRnME1EZ3dNMzV5Tm5aaFNWaE1TakozWTNwemRITnhNMmxVY2l0NFlrWi1mZyZjcmVhdGVfdGltZT0xNjQyMTU1ODQxJm5vbmNlPTAuMDYzOTAzODE4MjY0OTg4OTImcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY0MjI0MjI0MSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==";
