import mapKeys from "lodash/mapKeys";
import { GET_FAQ_CATEGORY } from "../../actions/types";

const faqCategoryListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FAQ_CATEGORY:
      return { ...state, ...mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default faqCategoryListReducer;
