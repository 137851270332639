import { host } from "../../config/host";
import { fetchData } from "../fetch";

const lang = localStorage.getItem('lang') || 'en';

export const consultantListAsync = async (body = null, signal = null) => {
  try {

    const defaultParams = {lang, limit:"10",offset:"0",nationality:"",language:"",gender:"",intervention:"" ,activeStatus:"", specializationsMentalId:"",specializationsTherapeuticId:"",specializationsOtherId:"",specializationsPsychologicalId:"",minPrice:"",maxPrice:"",currency:"",orderBy:"",order:"ASC"};

    const params = !body ? defaultParams : { ...defaultParams, ...body };
    // console.log(params, body)

    const res = await fetchData('POST', `${host}/consultant/list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const consultantDetailsAsync = async (body) => {
  try {
    
    const defaultParams = { lang: localStorage.getItem('lang') || "en" };
    const params = { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/consultant/details`, params);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  
  }
}

export const consultantReviewsAsync = async (body) => {
  try {
    const defaultParams = { lang: localStorage.getItem('lang') || 'en' };
    let params = !body ? defaultParams : { ...defaultParams, ...body };

    const uri = body.details ? `${host}/review/details` : `${host}/review/list`;

    if(body.details) {
      params = { 
        ...defaultParams, 
        appointmentId: body.appointmentId 
      };
    }
    
    const res = await fetchData('POST', uri, params);
    const data = await res.json();
    return data;

  } catch(err) {
    console.log('body', body)
    throw new Error(err.message);
  }
}

export const getTimeSlotAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/consultant/time-slot`, body, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}