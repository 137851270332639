import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import FiscalInfoForm from '../Children/FiscalInfoForm';
// import SaveChanges from '../Children/SaveChanges';

const SecFiscalInfo = ({ history, config, lang }) => {
  return (
    <AccountContainer title={config.billing[lang] || config.billing.en}>
      <FiscalInfoForm history={history}  />
      {/* <SaveChanges /> */}
    </AccountContainer>
  );
}

export default SecFiscalInfo;