import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getHomeServicesList } from '../../actions/home';
import ServiceItem from './ServiceItem';


const Service = ({ getHomeServicesList, services, lang }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadServices = async () => {
      if(!services.length) {
        try {
          await getHomeServicesList({ lang }, ac.signal);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      } else {
        setIsLoaded(true);
      }
    }

    loadServices();

    return () => ac.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, services.length ]);

  const config = {
    servicesProvided: {
      en: 'SERVICES PROVIDED',
      es: 'SERVICIOS PRESTADOS',
      it: 'SERVIZI OFFERTI'
    },
    servicesProvidedSubHeading: {
      en: 'These services are provided by our team of highly qualified and experienced psychologists and therapists. They are available online, at your convenience, to provide you with the support you need to improve your mental health and well-being',
      es: 'Estos servicios son proporcionados por nuestro equipo de psicólogos y terapeutas altamente calificados y experimentados. Están disponibles en línea, a su conveniencia, para brindarle el apoyo que necesita para mejorar su salud mental y bienestar.',
      it: 'Questi servizi sono forniti dal nostro team di psicologi e terapisti altamente qualificati ed esperti. Sono disponibili online, a tuo piacimento, per fornirti il ​​supporto di cui hai bisogno per migliorare la tua salute mentale e il tuo benessere'
    }
  }

  return (
    <section className="sec-service">
      <div className="container">
        <div className="section-title col-md-9 mx-auto col-12">
          <h1 className="title">{ config.servicesProvided[lang] || config.servicesProvided.en }</h1>
          <h4 className="sub-title">{ config.servicesProvidedSubHeading[lang] || config.servicesProvidedSubHeading.en }</h4>
        </div>
        <div className="service-wrap">
          {
            (services && isLoaded) ?  
              services.map(service => <ServiceItem key={service.id} service={service} />)
            : ''
          }
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    services: Object.values(state.home.homeServices)
  }
}

export default connect(mapStateToProps, { getHomeServicesList })(Service);