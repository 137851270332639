import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import PaymentInfoForm from '../Children/PaymentInfoForm';

const SecPaymentInfo = ({ location, config, lang }) => {
  return (
    <AccountContainer title={config.payment[lang] || config.payment.en}>
      <PaymentInfoForm location={location} />
    </AccountContainer>
  );
}

export default SecPaymentInfo;