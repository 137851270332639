import { GET_PAST_APPOINTMENT_COUNT } from "../../../actions/types";

const pastAppointmentCountReducer = (state = 0, action) => {
  switch(action.type) {
    case GET_PAST_APPOINTMENT_COUNT:
      return action.payload;
    default:
      return state;
  }
}

export default pastAppointmentCountReducer;