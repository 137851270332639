import mapKeys from 'lodash/mapKeys';
import { GET_BLOG_LIST, GET_BLOG_DETAIL } from "../../actions/types";

const blogListReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_BLOG_LIST:
      return { ...mapKeys(action.payload, 'id') };
    case GET_BLOG_DETAIL:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
}

export default blogListReducer;