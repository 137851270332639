import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const CallToAction = ({ auth, lang, siteSettings }) => {
  const [action, setAction] = useState('');

  useEffect(() => {
    if(siteSettings?.register_psychology) setAction(siteSettings.register_psychology);
  }, [ siteSettings?.register_psychology ]);

  useEffect(() => {
    if(action) {
      const link = action.match(/(<a [^>]*(href="([^>^\\"]*)")[^>]*>)([^<]+)(<\/a>)/gi);

      if(auth.status && link && link[0]) {
        const newAction = action.replace(link[0], '');
        setAction(newAction);
      } else if(!auth.status && link && link[0]) {
        const newAction = action.replace(':LANG', lang);
        setAction(newAction);
      }
    }
  }, [ auth.status, lang, action ]);

  return (
    <section className="sec-calltoaction">
      {action && 
        <div className="container" dangerouslySetInnerHTML={{ __html: action }}>
        </div>
      }
    </section>
  );
}

const mapStateToProps = state => {
  return {

    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    siteSettings: state.cms.siteSettings
  }
}

export default connect(mapStateToProps, null)(CallToAction);