import React, { useState } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { addReview } from '../../../actions/profile';
// import { lang } from '../../../config/lang';
import history from '../../../config/history';
import Communication from '../Children/Review/Communication';
import Access from '../Children/Review/Access';
import Skill from '../Children/Review/Skill';
import { toast } from 'react-toastify';
const SecAddReview = ({ addReview, id, user, consultantId, lang }) => {
  const [communication, setCommunication] = useState(0);
  const [access, setAccess] = useState(0);
  const [skill, setSkill] = useState(0);
  const [personalExperience, setPersonalExperience] = useState('');
  const [personalnoteforConsultant, setPersonalnoteforConsultant] = useState('');
  const [commentforprontopsyStaff, setCommentforprontopsyStaff] = useState('');

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const message = await addReview({ 
        lang, 
        userId: user?.id, 
        appointmentId: id, 
        reliableandTrustworthy: skill.toString(),
        communication: communication.toString(), 
        empathyandFlexibility: access.toString(),
        consultantId,
        personalExperience, commentforprontopsyStaff
      });
      toast.success(message);
      history.push(`/${lang}/appointment-list`);
      
    } catch(err) {
      toast.error(err.message);
    }
  } 

  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <form onSubmit={onSubmit}>
        <div className="card-container">
          <div className="form-row">
            <Communication rating={communication} setRating={setCommunication} />
            <Access rating={access} setRating={setAccess} />
            <Skill rating={skill} setRating={setSkill} />
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Personal Experience</label>
                <textarea className="form-control" 
                  id="personal_experience" 
                  name="personal_experience" 
                  placeholder="Personal Experience"
                  onChange={(e) => setPersonalExperience(e.target.value)}
                ></textarea>
                </div>
            </div>
          </div>
          {/* <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Note for Psychologist</label>
                <textarea 
                  className="form-control" 
                  id="personal_note_for_consultant" 
                  name="personal_note_for_consultant" 
                  placeholder="Note for Psychologist"
                  onChange={(e) => setPersonalnoteforConsultant(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div> */}
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Note for Prontopsy</label>
                <textarea className="form-control" 
                  id="comment_for_prontopsy_staff" 
                  name="comment_for_prontopsy_staff" 
                  placeholder="Note for Prontopsy"
                  onChange={(e) => setCommentforprontopsyStaff(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sec-myaccount-header border-0 mt-3">
                <div className="lable-text ml-auto">
                  <button className="btn-universal">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, { addReview })(SecAddReview);