import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getHomeOnlineServicesList } from '../../actions/home';
import OnlineService from './OnlineService';
import PrefooterOverlay from '../../prefooterOverlay';

const Widget = ({ getHomeOnlineServicesList, onlineServices, lang }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadOnlineServices = async () => {
      if(!onlineServices.length) {
        try {
          await getHomeOnlineServicesList({ lang }, ac.signal);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      } else {
        setIsLoaded(true);
      }
    }

    loadOnlineServices();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, onlineServices.length ]);

  const config = {
    onlineIsBetter: {
      en: 'ONLINE IS BETTER',
      es: 'EN LÍNEA ES MEJOR',
      it: 'ONLINE È MEGLIO'
    }
  }

  return (
    <section 
      className={`sec-widget ${window.location.pathname.includes('about') && 'prefooter-last'}`}
    >
      <div className="container">
        <div className="section-title">
          <h1 className="title">
            { config.onlineIsBetter[lang] || config.onlineIsBetter.en }
          </h1>
        </div>
        <div className="widget-wrap">
          {
            (isLoaded && onlineServices) ? 
              onlineServices.map(item => <OnlineService key={item.id} item={item} />)
            : ''
          }
        </div>
      </div>
      {
        window.location.pathname.includes('about') &&
        <PrefooterOverlay />
      }
    </section>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    onlineServices: Object.values(state.home.homeOnlineServices)
  }
}

export default connect(mapStateToProps, { getHomeOnlineServicesList })(Widget);