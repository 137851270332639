import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setSiteDefaultLang } from "../../actions/lang";
import {
  getInProgressAppointments,
  getAppointments,
} from "../../actions/appointments";
import { lang } from "../../config/lang";
// import history from '../../config/history';
import { appointmentLang } from "../../config/lang";
const TopHeader = ({
  siteSettings,
  languages,
  setSiteDefaultLang,
  siteDefaultLang,
  getInProgressAppointments,
  getAppointments,
  user,
  appointments,
  lang,
}) => {
  const [defaultLang, setDefaultLang] = useState(null);
  const pathname = window.location.pathname;
  useEffect(() => {
    if (siteDefaultLang && languages.length) {
      const defaultLanguage = languages.find(
        (el) => el.lang === siteDefaultLang
      );
      setDefaultLang(defaultLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDefaultLang, languages]);

  useEffect(() => {
    const loadDefaultLanguage = () => {
      if (languages.length) {
        const defaultLanguage = languages.find(
          (el) => el.lang === localStorage.getItem("lang")
        );
        // setDefaultLang(defaultLanguage);
        setSiteDefaultLang(defaultLanguage.lang);
      }
    };

    loadDefaultLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, lang]);

  const handleLangChange = (e, lang) => {
    e.preventDefault();

    localStorage.setItem("lang", lang);

    let currentLang = pathname.split("/");
    currentLang[1] = lang;
    // setDefaultLang(lang);
    window.location.href = currentLang.join("/") + window.location.search;
    // history.push(currentLang.join('/') + window.location.search);
  };

  useEffect(() => {
    const ac = new AbortController();

    const loadAppointments = async () => {
      if (user && user?.id) {
        console.log(user);

        try {
          // await getAppointments({ userId: user.id, startDate: "", endDate: "", limit: '', offset: '0' }, ac.signal);

          const data = await getAppointments(
            {
              userId: user.id,
              limit: "1",
              offset: "0",
              lastId: "0",
            },
            ac.signal
          );
          // console.log(appointments);
        } catch (err) {
          console.error(err.message);
        }
      }
    };

    loadAppointments();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  console.log(appointments);
  return (
    <div className="header-top-wrap">
      <div className="container">
        <div className="header-top-content">
          <div className="contact">
            <a href={`mailto:${siteSettings?.email}`} className="item">
              <i className="fas fa-fw fa-envelope"></i>
              <span>{siteSettings?.email}</span>
            </a>
            <a href={`tel:${siteSettings?.phone}`} className="item">
              <i className="fas fa-fw fa-phone fa-flip-horizontal"></i>
              <span>{siteSettings?.phone}</span>
            </a>
          </div>

          {user && appointments.length > 0 && (
            <div className="contact chat-join" style={{ color: "snow" }}>
              {appointmentLang.yHaWF[lang] || appointmentLang.yHaWF.en}{" "}
              {user.role === "user"
                ? appointments[0]?.consultant_profile_name
                : appointments[0]?.user_profile_name}{" "}
              &nbsp;
              <Link
                className="btn-join"
                to={`/${lang}/appointment/${appointments[0]?.appointment_id}`}
                style={{ color: "snow" }}
              >
                {" "}
                Join Now
              </Link>
            </div>
          )}

          <div className="social">
            <a
              href={siteSettings.facebook_link}
              className="item"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-fw fa-facebook"></i>
            </a>
            <a
              href={siteSettings.twitter_link}
              className="item"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-fw fa-twitter"></i>
            </a>
            <a
              href={siteSettings.google_link}
              className="item"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-fw fa-google"></i>
            </a>
            <a
              href={siteSettings.instagram_link}
              className="item"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-fw fa-instagram"></i>
            </a>
          </div>
          <div className="language dropdown">
            {defaultLang && (
              <a href="#!" className="dropdown-toggle" data-toggle="dropdown">
                <span className="flag">
                  <img src={defaultLang.logo} alt={defaultLang.name} />
                </span>
                <span className="language-name">{defaultLang.name}</span>
              </a>
            )}
            <div className="dropdown-menu">
              {languages && languages.length
                ? languages.map((el) => {
                    return (
                      <a
                        href="#!"
                        className="dropdown-item"
                        key={el.id}
                        onClick={(e) => handleLangChange(e, el.lang)}
                      >
                        <span className="flag">
                          <img src={el.logo} alt={el.name} />
                        </span>
                        <span className="language-name">{el.name}</span>
                      </a>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    siteSettings: state.cms.siteSettings,
    siteDefaultLang: state.defaultLang,
    languages: Object.values(state.cms.languages),
    lang: state.defaultLang,
    appointments: Object.values(state.appointments.inprogressAppointments).sort(
      (a, b) => {
        if (a?.appointment_start_time) {
          return (
            new Date(a?.appointment_start_time) -
            new Date(b?.appointment_start_time)
          );
        } else {
          return a?.ids - b?.ids;
        }
      }
    ),
  };
};

export default connect(mapStateToProps, {
  setSiteDefaultLang,
  getInProgressAppointments,
  getAppointments,
})(TopHeader);
