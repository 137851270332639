import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getHomeCount } from '../../../actions/home';

const SecHomeCount = ({ getHomeCount, homeCount,socket, lang }) => {

  // const [isLoaded, setIsLoaded] = useState(false);
  const [activeConsultant, setActiveConsultant] = useState();
  useEffect(() => {
    const ac = new AbortController();

    const loadHomeCount = async () => {
      if(!Object.keys(homeCount).length) {
        try {
          await getHomeCount(ac.signal);
        } catch(err) {
          console.log(err.message)
        }
      }
    }

    loadHomeCount();

    return () => ac.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ Object.keys(homeCount).length ]);
  useEffect(() => {
    if(socket?.io){
    socket.emit('getOnlineConsultantList', {
    });
      socket.on('get-online-consultant-list', (res) => {
        setActiveConsultant(res.count);
      })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socket]);
  const config = {
    consultants: {
      en: 'Consultants',
      es: 'Consultores',
      it: 'Consulenti'
    },
    areaOfIntervention: {
      en: 'Areas of intervention',
      es: 'Áreas de intervención',
      it: 'Aree di intervento'
    },
    consultingCompleted: {
      en: 'Consultings completed',
      es: 'Consultas finalizadas',
      it: 'Consulenze completate',
    },
    satisfiedCustomers: {
      en: 'Satisfied customers',
      es: 'Clientes satisfechos',
      it: 'Clienti soddisfatti'
    }
  }

  return (
    <div className="counter">
      <div className="item">
        <div className="item-count">{ activeConsultant?activeConsultant:0 }</div>
        <div className="item-title">
          { config.consultants[lang] || config.consultants.en }
        </div>
      </div>
      <div className="item">
        <div className="item-count">{ homeCount.intervention }</div>
        <div className="item-title">
          { config.areaOfIntervention[lang] || config.areaOfIntervention.en }
        </div>
      </div>
      <div className="item">
        <div className="item-count">{ homeCount.appointment }</div>
        <div className="item-title">
          { config.consultingCompleted[lang] || config.consultingCompleted.en }
        </div>
      </div>
      <div className="item">
        <div className="item-count">{ homeCount.user }</div>
        <div className="item-title">
          { config.satisfiedCustomers[lang] || config.satisfiedCustomers.en }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    socket: state.socket,
    homeCount: state.home.homeCount
    
  }
}

export default connect(mapStateToProps, { getHomeCount })(SecHomeCount);
