import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { adminSideNavLang } from '../../config/lang';
import { getClientList, getBlockedUserList } from '../../actions/appointments';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecMyClients from '../../components/Admin/Sections/SecMyClients';

const MyClients = ({ 
  user, clients, getClientList, countries, nationalities, siteSettings, languages, timezones,
  getBlockedUserList, lang
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadMyClients = async () => {
      if(!isLoaded && user && user?.id) {
        try {
          await getClientList(
            { userId: user.id, startDate: '', endDate: '', client: '1' }, ac.signal
          );
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      }
    }

    loadMyClients();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user ]);

  useEffect(() => {
    const ac = new AbortController();

    const loadBlockedUsers = async () => {
      try {
        if(user?.id) {
          await getBlockedUserList({ lang, consultantId: user.id }, ac.signal);
        }
      } catch(err) {
        console.log(err.message);
      }
    }

    loadBlockedUsers();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user, lang ]);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      !$.isEmptyObject(siteSettings) && languages.length && timezones.length && countries.length
      && isLoaded;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries, isLoaded ])

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={adminSideNavLang.myClients[lang] || adminSideNavLang.myClients.en}>
        <SecMyClients clients={clients} countries={countries} user={user}
          config={adminSideNavLang} lang={lang} isLoaded={isLoaded}
        />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    countries: Object.values(state.cms.countryList),
    clients: Object.values(state.clients)
  }
}

export default connect(mapStateToProps, { getClientList, getBlockedUserList })(MyClients);
