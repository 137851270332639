import React from 'react';
import { connect } from 'react-redux';
import { consultantLang, dailyLang, langConfig } from '../../../../config/lang';
import { modes } from '../../../../helpers/appointment';
import moment from 'moment';
const ServiceInfo = ({ appointment, services, user, timezones, lang }) => {
  const timezone = timezones.find(item => item.id === user?.timezone);

  const config = {
    serviceInformation: {
      en: 'Service Information',
      es: 'Servicio de información',
      it: 'Informazioni sul servizio'
    },
    dateOfAppointment: {
      en: 'Date of Appointment',
      es: 'Fecha de nombramiento',
      it: `Data dell'appuntamento`
    },
    consultantJoinTime: {
      en: 'Consultant Join Time',
      es: 'Consultor unirse tiempo',
      it: 'Consulente Join Time'
    },
    callDuration: {
      en: 'Call Duration',
      es: 'Duración de la llamada',
      it: 'Durata della chiamata'
    }
  }

  return (
    <>
      <div className="appointment-title">
        { config.serviceInformation[lang] || config.serviceInformation.en }
      </div>
      <div className="row form-row">
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { consultantLang.serviceOffered[lang] || consultantLang.serviceOffered.en }
            </div>
            <div className="control-data">
              { services[appointment.appointment_type - 1]?.name }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { langConfig.mode[lang] || langConfig.mode.en }
            </div>
            <div className="control-data">
              { 
                modes[appointment.service_type] ? 
                langConfig[modes[appointment.service_type].toLowerCase()][lang] : 
                ''
              }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { config.dateOfAppointment[lang] || config.dateOfAppointment.en }
            </div>
            <div className="control-data">
{ moment(new Date( new Date(appointment.appointment_start_time + ' UTC').toLocaleString(
                "en-US", {timeZone: timezone?.zone}
              ))).format('dddd')},  { moment(new Date( new Date(appointment.appointment_start_time + ' UTC').toLocaleString(
                "en-US", {timeZone: timezone?.zone}
              ))).format('DD-MM-YYYY')}
              </div>
          </div>
        </div>
      {appointment?.consultant_join_time &&  <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { config.callDuration[lang] || config.callDuration.en }
            </div>
            <div className="control-data">
              { 
                appointment?.consultant_join_time ? 
                appointment?.duration: ''
              }
            </div>
          </div>
        </div>}
      </div>
      <div className="row form-row mb-3">
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { dailyLang.startTime[lang] || dailyLang.startTime.en }
            </div>
            <div className="control-data">
            { 
                appointment?.consultant_join_time ? 
                 moment(new Date( new Date(appointment.consultant_join_time + ' UTC').toLocaleString(
                  "en-US", {timeZone: timezone?.zone}
                ))).format('hh:mm:ss A')
                :  moment(new Date( new Date(appointment.appointment_start_time + ' UTC').toLocaleString(
                    "en-US", {timeZone: timezone?.zone}
                  ))).format('hh:mm:ss A')
              }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { dailyLang.endTime[lang] || dailyLang.endTime.en }
            </div>
            <div className="control-data">
              { 
                appointment?.appointment_end_time ? 
              moment(new Date( new Date(appointment.appointment_end_time + ' UTC').toLocaleString(
                "en-US", {timeZone: timezone?.zone}
              ))).format('hh:mm:ss A')
                : ''
              }
            </div>
          </div>
        </div>

    
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    timezones: Object.values(state.cms.timeZoneList),
    services: Object.values(state.cms.serviceList)
  }
}

export default connect(mapStateToProps, null)(ServiceInfo)
