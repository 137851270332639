import { GET_NOTE_LIST } from "./types";
import { getNoteListAsync, addNoteAsync } from "../asynchronus/Notes";

export const getNoteList = body => async dispatch => {
  try {
    const noteList = await getNoteListAsync(body);

    if(noteList.status === 200) {
      dispatch({ type: GET_NOTE_LIST, payload: noteList.data });
    } else {
      throw new Error(noteList.message);
    }
  } catch(err) { 
    throw new Error(err.message);
  }
}

export const addNote = body => async dispatch => {
  try {
    const note = await addNoteAsync(body);

    if(note.status === 200) {
      const notes = await getNoteListAsync({ 
        lang: body.lang, userId: body.userId, consultantId: body.consultantId 
      });

      dispatch({ type: GET_NOTE_LIST, payload: notes.data });
      
      return note.message;
    } else {
      throw new Error(note.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}