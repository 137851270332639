import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { GET_CONSULTANT_SERVICES, DELETE_CONSULTANT_SERVICE } from '../../actions/types';

const consultantServicesReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_CONSULTANT_SERVICES:
      return { ...state, ...mapKeys(action.payload, 'id') };
    case DELETE_CONSULTANT_SERVICE:
      return omit(state, action.payload);
    default:
      return state;
  }
}

export default consultantServicesReducer;