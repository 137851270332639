import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appointmentLang } from '../../../config/lang';

const AppointmentNav = ({ lang }) => {
  const pathname = window.location.pathname;

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card-nav">
          <nav className="nav nav-pills nav-fill">
            <Link 
              className=
              {`nav-item nav-link${pathname === `/${lang}/appointment-list` ? ' active' : ''}`} 
              to={`/${lang}/appointment-list`}
              // onClick={() => setNavStatus('past')}
            >
              { appointmentLang.pastAppointments[lang] || appointmentLang.pastAppointments.en }
            </Link>
            <Link 
              className={`nav-item nav-link${pathname === `/${lang}/in-progress-appointment` ? ' active' : ''}`} 
              to={`/${lang}/in-progress-appointment`}
              // onClick={() => setNavStatus('present')}
            >
              { 
                appointmentLang.inProgressAppointment[lang] || 
                appointmentLang.inProgressAppointment.en 
              }
            </Link>
            <Link 
              className=
              {`nav-item nav-link${pathname === `/${lang}/up-coming-appointments` ? ' active' : ''}`} 
              to={`/${lang}/up-coming-appointments`}
              // onClick={() => setNavStatus('future')}
            >
              { 
                appointmentLang.upcomingAppointments[lang] || 
                appointmentLang.upcomingAppointments.en 
              }
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(AppointmentNav);