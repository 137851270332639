import React, { useState, useEffect } from "react";
import { languageTooltipLang } from "../../../../config/lang";
import TooltipSec from "./TooltipSec";
const LanguageDropdown = ({
  lang,
  langs,
  setLang,
  selectedLang,
  langName,
  setLangName,
}) => {
  const [allLangs, setAllLangs] = useState([]);

  const getSingleName = (id, array) => {
    if (array && id) {
      const item = array.find((el) => el.id === selectedLang);
      return item?.name || null;
    }

    return null;
  };
  const langsen = [
    { id: "en", name: "English" },
    { id: "es", name: "Spanish" },
    { id: "it", name: "Italian" },
  ];
  const langsit = [
    { id: "en", name: "Inglese" },
    { id: "es", name: "Spagnolo" },
    { id: "it", name: "Italiano" },
  ];
  const langses = [
    { id: "en", name: "Inglés" },
    { id: "es", name: "Español" },
    { id: "it", name: "Italiano" },
  ];

  useEffect(() => {
    if (langs.length) {
      setAllLangs([...eval("langs" + lang)]);
    }
  }, [langs]);

  // const searchLangs = keyword => {
  //   const searchLangs = langs.filter(el => el.name.toLowerCase().includes(keyword.toLowerCase()));
  //   keyword ? setAllLangs([ ...searchLangs ]) : setAllLangs([ ...langs ]);
  // }

  const config = {
    languages: {
      en: "Languages",
      es: "Idiomas",
      it: "Lingue",
    },
  };
  return (
    <div className="form-group">
      <label className="control-label required">
        {config.languages[lang] || config.languages.en}
        <TooltipSec
          title={
            languageTooltipLang.languages[lang] ||
            languageTooltipLang.languages.en
          }
        ></TooltipSec>
      </label>
      <div className="select-dropdown dropdown">
        <a
          href="#!"
          className="data-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {!lang && "Select"}
          {getSingleName(lang, eval("langs" + lang)) ? (
            <span>{getSingleName(lang, eval("langs" + lang))}</span>
          ) : (
            ""
          )}
        </a>

        <div className="dropdown-menu intervention">
          <div className="dropdown-search">
            {/* <input className="form-control" type="text" placeholder="Search..." 
              onChange={(e) => searchLangs(e.target.value)}
            /> */}
          </div>

          <div className="dropdown-list">
            {allLangs.length
              ? allLangs.map((el) => {
                  return (
                    <div className="item" key={el.id}>
                      <div className="custom-radio intervention">
                        <input
                          type="radio"
                          className="custom-radio-input"
                          checked={el.id === selectedLang}
                          onChange={() => {
                            setLang(el.id);
                          }}
                        />
                        <label className="custom-radio-label">{el.name}</label>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageDropdown;
