import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import PrefooterOverlay from '../../prefooterOverlay';
import { langConfig, homeLang } from '../../config/lang';

const SecAuthRegister = ({ history, setStatus, setUserId, timezones, lang, setEmail, countries }) => {
  return (
    <section className="sec-authentication prefooter-last">
      <div className="authentication-wrap">
        <div className="authentication-content">
          <div className="title">
            <span>{ langConfig.clientSignup[lang] || langConfig.clientSignup.en }</span>
          </div>
          <SignUpForm history={history} setStatus={setStatus} setUserId={setUserId} 
            timezones={timezones} setEmail={setEmail} countries={countries}
          />
          <div className="authentication-options">
            { homeLang.alreadyHaveAnAccount[lang] || homeLang.alreadyHaveAnAccount.en } <Link to={`/${lang}/login`}>{ homeLang.signinNow[lang] || homeLang.signinNow.en }</Link>
          </div>
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(SecAuthRegister);