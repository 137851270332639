import React from 'react';
import AppointmentNav from '../../components/Admin/Children/AppointmentNav';

const AppointmentContainer = props => {
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <AppointmentNav />
        { props.children }
      </div>
    </div>
  )
}

export default AppointmentContainer;