import { prontospyAuth } from "../config/headers"

export const fetchData = async (method, host, body, signal = null) => {
  return (
    await fetch(host, {
      method,
      headers: new Headers({
        'Content-Type': "application/json",
        'Accept': 'application/json',
        'Authorization': prontospyAuth
      }),
      body: (method !== 'GET') ? JSON.stringify(body) : null,
      signal: signal ? signal : null
    })
  )
}