import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import $ from 'jquery';
import { homeBannerList } from '../actions/home';
import { getAppointments, getAppointment, setLastAppointment } from '../actions/appointments';
import { convertIsoTime } from '../helpers';
import { seoLang } from '../config/lang';
import Header from './Header';
import CallToAction from '../components/Home/CallToAction';
import Footer from '../components/Footer';

const LayoutContainer = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    $('.select2').select2();
    $('.select2-nosearch').select2({ minimumResultsForSearch: -1 });

    $('header .btn-menu').on('click', function(){
      $('header .navigation-menu').addClass('active');
      $('header .menu-backdrop').addClass('active');
    });
    
    $('header .menu-backdrop').on('click', function(){
      $('header .navigation-menu').removeClass('active');
      $(this).removeClass('active');
    }); 
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadBanners = async () => {
      try {
        if(!isLoaded && !props.banners.length && !props.admin) {
          await props.homeBannerList({ lang: props.lang }, ac.signal);
          setIsLoaded(true);
        }
      } catch(err) {
        console.log(err.message);
      }
    }

    loadBanners();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.lang ]);

  useEffect(() => {
    //const ac = new AbortController();
    const { user, socket } = props;
    
    if(socket?.io && user?.id) {
      socket.on('check-consultant', (res) => {
        if(res && res.consultant_id === user.id) {
          // console.log(res)
          setAppointment(res);
        }
      });

      socket.on('appointment-details', (res) => {
        if(res && res.consultant_id === user.id) {
          // console.log(res)
          setAppointment(res);
        }
      })
    }

    // return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.user?.active_status, props.user?.id, appointment?.ids, props.socket  ]);

  useEffect(() => {
    const ac = new AbortController();

    const loadLastAppointment = async () => {
      try {
        if(props.user?.id && !appointment && !props.lastAppointment?.ids
          && props.user?.role === 'consultant'
        ) {
          const appointments = await props.getAppointments({
            userId: props.user.id, startDate: '', endDate: '', limit: '1', offset: '0'
          }, ac.signal, true);

          if(appointments && appointments.length) {
            setAppointment(appointments[0]);
          }
        }
      } catch(err) {
        console.log(err.message);
      }
    }

    loadLastAppointment();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.user?.id, appointment?.ids, props.lastAppointment?.ids ]);

  useEffect(() => {
    if(appointment?.ids && appointment?.consultant_id === props.user?.id) {
      if(
        !appointment.appointment_end_time && 
        appointment.consultant_join === '2' &&
        appointment.status !== '3'
      ) {
        if(!modalShown) setModalShown(true);
      } else if(
        appointment.appointment_end_time && 
        appointment.appointment_type !== '1' &&
        new Date(appointment.appointment_end_time) >= convertIsoTime() &&
        appointment.consultant_join === '2' &&
        appointment.status !== '3'
      ) {
        if(!modalShown) setModalShown(true);
      } else {
        if(modalShown) setModalShown(false);
      }
    }

    if(props.user?.id && props.user?.role === 'consultant' && props.user?.active_status === '1' 
      && !window.location.pathname.includes('/appointment/') && appointment && !modalShown
      && appointment?.consultant_id === props.user?.id
    ) {
      props.setLastAppointment(appointment);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ appointment, props.user?.role, props.user?.active_status, modalShown ]);

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: props.lang || 'en' }}
      >
        <title>
          { props.seoTitle || (seoLang.title[props.lang] || seoLang.title.en) }
          </title>
        <meta 
          name="description" 
          content= { props.seoDescription || (seoLang.title[props.lang] || seoLang.title.en) }
        />
        <meta name="keywords" content={ props.seoKeywords || 'prontopsy' } />
      </Helmet>

      <Header admin={props.admin || null} modalShown={modalShown} setModalShown={setModalShown} />

        { props.children }

      <CallToAction />
      <Footer />
    </>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket,
    banners: Object.values(state.home.banners),
    lastAppointment: state.appointments.lastAppointment
  }
}

export default connect(
  mapStateToProps, 
  { homeBannerList, getAppointment, setLastAppointment, getAppointments }
)(LayoutContainer);