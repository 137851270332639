import { host } from "../../config/host";
import { fetchData } from "../fetch";

const lang = localStorage.getItem('lang') || 'en';

export const blogListAsync = async (defaultOptions = null, signal = null) => {
  try {
    
    const defaultParams = {
      lang, limit:"10",offset:"0",keyword:"",orderBy:"id",order:"DESC"
    };
    const params = defaultOptions ? { ...defaultParams, ...defaultOptions } : defaultParams;
    const res = await fetchData('POST', `${host}/blogs/list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const blogDetailAsync = async (body, signal = null) => {
  try {
    const defaultParams = { lang };
    const params = { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/blogs/details`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}