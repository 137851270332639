import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getHomeInterventionsList } from "../actions/home";
import { getPages } from "../actions/cms";
import { GET_PAGES_INTERVENTIONS } from "../actions/types";
// import { lang } from '../config/lang';
import Loader from "../Loader";
import LayoutContainer from "../containers/LayoutContainer";
// import Banner from '../containers/Banner';
import BannerBig from "../containers/BannerBig";
// import SecInterventions from '../components/Sections/SecInterventions';
import SecInterventionDetail from "../components/InterventionArea/SecInterventionDetail";

const InterventionTemplate = ({
  getPages,
  interventions,
  banners,
  siteSettings,
  languages,
  interventionList,
  getHomeInterventionsList,
  lang,
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInterventionLoaded, setIsInterventionLoaded] = useState(false);
  const [banner, setBanner] = useState(false);
  const [intervention, setIntervention] = useState(null);

  const params = useParams();

  useEffect(() => {
    const loadInterventionsPage = async () => {
      if (!interventions?.id) {
        try {
          await getPages(
            { lang, slug: "interventions" },
            GET_PAGES_INTERVENTIONS
          );
          setIsLoaded(true);
        } catch (err) {
          setIsLoaded(true);
          console.error(err.message);
        }
      }
    };

    loadInterventionsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, interventions?.id]);

  useEffect(() => {
    const ac = new AbortController();

    const loadInterventionList = async () => {
      try {
        if (!interventionList.length) {
          await getHomeInterventionsList({ lang }, ac.signal);
          setIsInterventionLoaded(true);
        }
      } catch (err) {
        setIsInterventionLoaded(true);
        console.error(err.message);
      }
    };

    loadInterventionList();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, interventionList.length]);

  useEffect(() => {
    const loadBanner = () => {
      if (!banner && interventions && banners) {
        const interventionsBanner = banners.find(
          (el) => el.page_id === interventions.id
        );
        setBanner(interventionsBanner);
      }
    };

    loadBanner();
  }, [banner, banners, interventions]);

  useEffect(() => {
    const handleLoader = () => {
      if (
        Object.keys(siteSettings).length &&
        languages.length &&
        (interventions?.id || isLoaded) &&
        (interventionList.length || isInterventionLoaded)
      ) {
        setLoader(true);
      }
    };

    handleLoader();
  }, [
    interventionList.length,
    isLoaded,
    languages.length,
    siteSettings,
    isInterventionLoaded,
    interventions?.id,
  ]);
 console.log(interventionList+"HI")
  return (
    <LayoutContainer>
      <Loader loaded={loader} />
      <BannerBig banner={intervention} mainBanner={banner} />
      <SecInterventionDetail
        interventions={interventionList}
        isLoaded={isLoaded}
        lang={lang}
        id={params.id}
        setIntervention={setIntervention}
      />
    </LayoutContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    interventionList: Object.values(state.home.homeInterventions),
    interventions: state.cms.pages.interventionList,
    banners: Object.values(state.home.banners),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
  };
};

export default connect(mapStateToProps, { getPages, getHomeInterventionsList })(
  InterventionTemplate
);
