import { GET_CONSULTANT_DETAILS, CLEAR_CONSULTANT_DETAILS } from '../../actions/types';

const consultantDetailsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_CONSULTANT_DETAILS:
      return { ...state, ...action.payload };
    case CLEAR_CONSULTANT_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export default consultantDetailsReducer;