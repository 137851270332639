import { 
  GET_CONSULTANT_LIST, 
  GET_CONSULTANT_DETAILS, 
  GET_CONSULTANT_REVIEWS,
  CLEAR_CONSULTANT_DETAILS,
  CLEAR_CONSULTANT_REVIEWS,
  GET_CONSULTANT_TIME_SLOTS,
  CLEAR_CONSULTANT_TIME_SLOTS
} from "./types";

import { 
  consultantListAsync, 
  consultantDetailsAsync, 
  consultantReviewsAsync,
  getTimeSlotAsync
} from "../asynchronus/Consultant";

export const getConsultantList = (body = null, signal = null) => async dispatch => {
  try {

    const consultants = await consultantListAsync(body, signal);
    
    if(consultants.status === 200) {
      dispatch({ type: GET_CONSULTANT_LIST, payload: consultants.data });
    } else {
      dispatch({ type: GET_CONSULTANT_LIST, payload: {} });
    }

  } catch(err) {
    dispatch({ type: GET_CONSULTANT_LIST, payload: {} });
    throw new Error(err.message);
  }
}

export const getConsultantListForIntervention = async (body = null, signal = null) => {
  try {
    const consultants = await consultantListAsync(body, signal);
    
    if(consultants.status === 200) {
      return consultants.data;
      //dispatch({ type: GET_CONSULTANT_LIST, payload: consultants.data });
    } else {
      throw new Error(consultants.message);
      // dispatch({ type: GET_CONSULTANT_LIST, payload: {} });
    }

  } catch(err) {
    // dispatch({ type: GET_CONSULTANT_LIST, payload: {} });
    throw new Error(err.message);
  }
}

export const getConsultantDetails = body => async dispatch => {
  try {
    const detail = await consultantDetailsAsync(body);
    if(detail.status === 200) {
      dispatch({ type: GET_CONSULTANT_DETAILS, payload: detail.data });
    } else {
      throw new Error(detail.message)
    }
  } catch(err) {
    throw new Error(err.message);
  }

}

export const getConsultantReviews = body => async dispatch => {
  try {
    // delete body.userId
    const reviews = await consultantReviewsAsync(body);
    if(reviews.status === 200) {
      let data = reviews.data;

      if(!Array.isArray(data)) {
        data = [data];
      }

      // console.log('data', data)

      dispatch({ 
        type: GET_CONSULTANT_REVIEWS, 
        payload: reviews.data
      });
    
    } else {
      // console.log(body)
      throw new Error(reviews?.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const clearConsultant = () => dispatch => {
  dispatch({ type: CLEAR_CONSULTANT_DETAILS, payload: {} });
  dispatch({ type: CLEAR_CONSULTANT_REVIEWS, payload: {} });
  dispatch({ type: CLEAR_CONSULTANT_TIME_SLOTS, payload: {} });
}

export const clearConsultantReview = () => dispatch => {
  dispatch({ type: CLEAR_CONSULTANT_REVIEWS, payload: {} });
}

export const getTimeSlots = (body, signal = null) => async dispatch => {
  try {
    const timeSlots = await getTimeSlotAsync(body, signal);

    if(timeSlots.status === 200) {
      dispatch({ type: GET_CONSULTANT_TIME_SLOTS, payload: timeSlots.data });
    } else {
      throw new Error(timeSlots.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const clearTimeSlots = () => dispatch => {
  dispatch({ type: CLEAR_CONSULTANT_TIME_SLOTS, payload: {} });
}