import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from '../../Loader';
import { acNavLangFormal } from '../../config/lang';
import { getStripeCustomer } from '../../actions/profile';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecPaymentInfo from '../../components/Admin/Sections/SecPaymentInfo';

const PaymentInfo = ({ location, user, getStripeCustomer, siteSettings, languages, lang }) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && Object.keys(siteSettings).length && languages.length

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, siteSettings, languages ]);

  useEffect(() => {
    const ac = new AbortController();

    const loadStripeCustomer = async userId => {
      try {
        await getStripeCustomer({ user_id: userId }, ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    if(user?.id && user?.role === 'user') loadStripeCustomer(user?.id);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.id, user?.role ]);

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={acNavLangFormal.payment[lang] || acNavLangFormal.payment.en}>
        <SecPaymentInfo location={location} config={acNavLangFormal} lang={lang} />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
  }
}

export default connect(mapStateToProps, { getStripeCustomer })(PaymentInfo);