import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import TextareaAutosize from 'react-textarea-autosize';
import Picker from 'emoji-picker-react';
import { sendAppointmentMessage } from '../../actions/messages';
import { isImage } from '../../helpers';
import { chatLang } from '../../config/lang';
import { toast } from 'react-toastify';
const Footer = ({ 
  lang, user, appointmentId, consultantId, listRef, appointment, 
  file, setFile, setTargetFile, open, setOpen, socket
}) => {
  const [comment, setComment] = useState('');
  const [showEmojiContainer, setShowEmojiContainer] = useState(false);
  const fileRef = useRef(null);
  
  const sendMessage = async e => {
    e.preventDefault();
    setShowEmojiContainer(false);

    try {
      console.log(file)
      if(socket?.io) {
        socket.emit('sendMessage', {
          lang, senderId: user.id, appointment_id: appointmentId, 
          comment: encodeURIComponent(comment),
          receiverId: user?.id === appointment?.consultant_id ? appointment?.user_id : consultantId,
          msg_files: file ? [file] : []
        })
      }

      listRef.current.scrollTop = listRef.current.scrollHeight;
      setComment('');
      setFile('');
      setTargetFile(null);
    } catch(err) {
      toast.error(err.message);
    }
  }

  const onEmojiClick = (e, emoji) => {
    // console.log(emoji)
    setComment(comment + emoji.emoji);
  }

  const handleFileChange = e => {
    e.preventDefault();

    if(e.target.files.length) {
      isImage(e.target.files[0], null, setFile, 'file');
      setTargetFile(e.target.files[0]);
    }
  }

  return (
    <div className={`message-footer`}>
      <input type="file" name="file" ref={fileRef} hidden 
        onChange={handleFileChange}
      />

      <a href="#!" className="btn-attachment" 
        onClick={(e) => { e.preventDefault(); setOpen(!open) }}
      >
        <i className="far fa-fw fa-paperclip"></i>
      </a>

      <div className="message-input">
        <TextareaAutosize className="form-control" rows="1" 
          value={comment} onChange={(e) => setComment(e.target.value)} placeholder={chatLang.enter_message[lang]}
        />

        <div className="emojiInit" onClick={() => setShowEmojiContainer(!showEmojiContainer)}>
          <i className="far fa-smile-beam"></i>
        </div>

        {showEmojiContainer && 
          <div className="emojiBox">
            <Picker pickerStyle={{ width: '100%', height: '100%' }} onEmojiClick={onEmojiClick} />
          </div>
        }
      </div>

      <a href="#!" className="btn-send" onClick={sendMessage}>
        <i className="fas fa-fw fa-arrow-alt-right"></i>
      </a>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    socket: state.socket
  }
}

export default connect(mapStateToProps, { sendAppointmentMessage })(Footer);