import React from 'react';

const AccountHeading = ({ title }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="sec-myaccount-header">
          <div className="lable-text">{ title }</div>
        </div>
      </div>
    </div>
  )
}

export default AccountHeading;