import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import {
  langConfig,
  interventionLang,
  filterLang,
  homeLang,
} from "../../config/lang";
import history from "../../config/history";
// import { lang } from '../../config/lang';

const ConsultantFilter = ({
  nationalities,
  languages,
  interventions,
  theraputicList,
  mentalList,
  psychologicalList,
  otherList,
  countries,
  lang,
}) => {
  const [active, setActive] = useState(false);

  const [state, setState] = useState({
    keyword: "",
    gender: [],
    activeStatus: [],
    modes: [],
  });

  const [nationality, setNationality] = useState("");
  const [language, setLanguage] = useState("");
  const [intervention, setIntervention] = useState("");
  const [theraputic, setTheraputic] = useState("");
  const [mental, setMental] = useState("");
  const [psychological, setPsychological] = useState("");
  const [other, setOther] = useState("");
  const [order, setOrder] = useState("");
  const [orderby, setOrderby] = useState("");
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    setActive(true);
    $(".consultant-filter .filter-content-wrap").slideDown(300);
  }, []);

  // Nationality
  useEffect(() => {
    $("#nationality").val(nationality).trigger("change");
    $("#nationality").on("change", function (e) {
      setNationality(e.target.value);
    });
  }, [nationality]);

  // Language
  useEffect(() => {
    $("#language").val(language).trigger("change");
    $("#language").on("change", function (e) {
      setLanguage(e.target.value);
    });
  }, [language]);

  // Intervention
  useEffect(() => {
    $("#intervention").val(intervention).trigger("change");
    $("#intervention").on("change", function (e) {
      setIntervention(e.target.value);
    });
  }, [intervention]);

  // Theraputic
  useEffect(() => {
    $("#theraputic").val(theraputic).trigger("change");
    $("#theraputic").on("change", function (e) {
      setTheraputic(e.target.value);
    });
  }, [theraputic]);

  // Mental
  useEffect(() => {
    $("#mental").val(mental).trigger("change");
    $("#mental").on("change", function (e) {
      setMental(e.target.value);
    });
  }, [mental]);

  // Psychological
  useEffect(() => {
    $("#psychological").val(psychological).trigger("change");
    $("#psychological").on("change", function (e) {
      setPsychological(e.target.value);
    });
  }, [psychological]);

  // Other
  useEffect(() => {
    $("#other").val(other).trigger("change");
    $("#other").on("change", function (e) {
      setOther(e.target.value);
    });
  }, [other]);

  useEffect(() => {
    $("#sortby").on("change", function (e) {
      const sortOrder = JSON.parse(e.target.value);
      setOrderby(sortOrder?.order);
      setOrder(sortOrder?.orderBy);
    });
  }, [orderby]);

  useEffect(() => {
    $("#currency").val(currency).trigger("change");
    $("#currency").on("change", function (e) {
      setCurrency(e.target.value);
    });
  }, [currency]);

  const handleMultiState = (value, key) => {
    const currentList = state[key];

    if (state[key].includes(value)) {
      setState({
        ...state,
        [key]: currentList.filter((item) => item !== value),
      });
    } else {
      setState({ ...state, [key]: [...state[key], value] });
    }
  };

  const manageActive = (e) => {
    e.preventDefault();
    setActive(!active);
    $(".consultant-filter .filter-content-wrap").slideToggle(300);
  };

  const manageClear = (e) => {
    e.preventDefault();
    setActive(!active);
    $(".consultant-filter .filter-content-wrap").slideUp(300);
    $("#sortby").val('{"order": "", "orderBy": ""}').trigger("change");
    setOrder("");
    setOrderby("");
    clearState();
    // window.location.reload();
    history.push(`/${lang}/consultant`);
  };

  const convertToUrl = (params) => {
    let url = "";

    for (let i in params) {
      if (url !== "") {
        url += "&";
      }

      url += i + "=" + encodeURIComponent(params[i]);
    }

    return url;
  };

  const clearState = () => {
    setState({ keyword: "", gender: [], activeStatus: [], modes: [] });
    setNationality("");
    setLanguage("");
    setIntervention("");
    setTheraputic("");
    setMental("");
    setPsychological("");
    setOther("");
    setOrder("");
    setOrderby("");
    setCurrency("");
  };

  const onSubmit = () => {
    const allParams = {
      search: true,
      ...state,
      nationality,
      language,
      intervention,
      specializationsTherapeuticId: theraputic,
      specializationsMentalId: mental,
      specializationsPsychologicalId: psychological,
      specializationsOtherId: other,
      orderBy: orderby,
      currency,
      order,
    };

    const params = convertToUrl(allParams);
    history.push(`/${lang}/consultant?${params}`);
  };

  return (
    <div className={`consultant-filter`}>
      <div className="consultant-filter-actions">
        <a
          href="#!"
          className={`btn-filter${active ? " active" : ""}`}
          onClick={manageActive}
        >
          <i className="far fa-fw fa-filter"></i>
          <div className="title">
            {langConfig.filter[lang] || langConfig.filter.en}
          </div>
        </a>
      </div>
      <div className="filter-content-wrap">
        <a
          href="#!"
          className="filter-join"
          onClick={manageClear}
          style={{ cursor: "pointer" }}
        >
          {langConfig.clearAll[lang] || langConfig.clearAll.en}
        </a>
        <div className="row form-row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="control-label">
                {langConfig.search[lang] || langConfig.search.en}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={
                  homeLang.searchBannerPlaceholder[lang] ||
                  homeLang.searchBannerPlaceholder.en
                }
                onChange={(e) =>
                  setState({ ...state, keyword: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label">
                {langConfig.nationality[lang] || langConfig.nationality.en}
              </label>
              <select
                defaultValue=""
                className="form-control select2"
                id="nationality"
              >
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {nationalities.length
                  ? nationalities.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.nationality}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label">
                {langConfig.language[lang] || langConfig.language.en}
              </label>
              <select className="form-control select2-nosearch" id="language">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {languages.length
                  ? languages.map((item) => {
                      // console.log('lang item', item)
                      return (
                        <option key={item.id} value={item.lang}>
                          {item.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label">
                {homeLang.areaOfIntervention[lang] ||
                  homeLang.areaOfIntervention.en}
              </label>
              <select className="form-control select2" id="intervention">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {interventions.length
                  ? interventions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label">
                {langConfig.gender[lang] || langConfig.gender.en}
              </label>
              <div className="checkbox-group">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="checkbox-01"
                    className="custom-checkbox-input"
                    value="1"
                    checked={state.gender.includes("1")}
                    onChange={(e) => handleMultiState(e.target.value, "gender")}
                  />
                  <div className="custom-checkbox-label">
                    {langConfig.male[lang] || langConfig.male.en}
                  </div>
                </div>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="checkbox-01"
                    className="custom-checkbox-input"
                    value="2"
                    checked={state.gender.includes("2")}
                    onChange={(e) => handleMultiState(e.target.value, "gender")}
                  />
                  <div className="custom-checkbox-label">
                    {langConfig.female[lang] || langConfig.female.en}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label">
                {langConfig.onlineStatus[lang] || langConfig.onlineStatus.en}
              </label>
              <div className="checkbox-group">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="checkbox-02"
                    className="custom-checkbox-input"
                    value="1"
                    checked={state.activeStatus.includes("1")}
                    onChange={(e) =>
                      handleMultiState(e.target.value, "activeStatus")
                    }
                  />
                  <div className="custom-checkbox-label">Online</div>
                </div>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="checkbox-02"
                    className="custom-checkbox-input"
                    value="2"
                    checked={state.activeStatus.includes("2")}
                    onChange={(e) =>
                      handleMultiState(e.target.value, "activeStatus")
                    }
                  />
                  <div className="custom-checkbox-label">Offline</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label">
                {langConfig.mode[lang] || langConfig.mode.en}
              </label>
              <div className="checkbox-group">
                <div className="custom-checkbox" id="mode_checkbox">
                  <input
                    className="custom-checkbox-input"
                    name="checkbox-02"
                    type="checkbox"
                    value="1"
                    checked={state.modes.includes("1")}
                    onChange={(e) => handleMultiState(e.target.value, "modes")}
                  />
                  <div className="custom-checkbox-label">
                    {langConfig.chat[lang] || langConfig.chat.en}
                  </div>
                </div>
                <div className="custom-checkbox ml-3">
                  <input
                    className="custom-checkbox-input"
                    name="checkbox-02"
                    type="checkbox"
                    value="2"
                    checked={state.modes.includes("2")}
                    onChange={(e) => handleMultiState(e.target.value, "modes")}
                  />
                  <div className="custom-checkbox-label">
                    {langConfig.voice[lang] || langConfig.voice.en}
                  </div>
                </div>
                <div className="custom-checkbox ml-3">
                  <input
                    className="custom-checkbox-input"
                    name="checkbox-02"
                    type="checkbox"
                    value="3"
                    checked={state.modes.includes("3")}
                    onChange={(e) => handleMultiState(e.target.value, "modes")}
                  />
                  <div className="custom-checkbox-label">
                    {langConfig.video[lang] || langConfig.video.en}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label">
                {interventionLang.theraputicApproach[lang] ||
                  interventionLang.theraputicApproach.en}
              </label>
              <select className="form-control select2" id="theraputic">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {theraputicList.length
                  ? theraputicList.map((item) => {
                      return item.name ? (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ) : (
                        ""
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label">
                {interventionLang.mentalApproach[lang] ||
                  interventionLang.mentalApproach.en}
              </label>
              <select className="form-control select2" id="mental">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {mentalList.length
                  ? mentalList.map((item) => {
                      return (
                        <optgroup key={item.id} label={item.name}>
                          {item.sub.length
                            ? item.sub.map((subItem) => {
                                return (
                                  <option key={subItem.id} value={subItem.id}>
                                    {subItem.name}
                                  </option>
                                );
                              })
                            : ""}
                        </optgroup>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label">
                {interventionLang.psychologicalProblems[lang] ||
                  interventionLang.psychologicalProblems.en}
              </label>
              <select className="form-control select2" id="psychological">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {psychologicalList.length
                  ? psychologicalList.map((item) => {
                      return (
                        <optgroup key={item.id} label={item.name}>
                          {item.sub.length
                            ? item.sub.map((subItem) => {
                                return (
                                  <option key={subItem.id} value={subItem.id}>
                                    {subItem.name}
                                  </option>
                                );
                              })
                            : ""}
                        </optgroup>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label">
                {interventionLang.otherTopics[lang] ||
                  interventionLang.otherTopics.en}
              </label>
              <select className="form-control select2" id="other">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {otherList.length
                  ? otherList.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label">
                {filterLang.soryBy[lang] || filterLang.soryBy.en}
              </label>
              <select className="form-control select2" id="sortby">
                <option value='{"order": "", "orderBy": ""}'>
                  {filterLang.soryBy[lang] || filterLang.soryBy.en}
                </option>
                <option value='{"order": "name", "orderBy": "ASC"}'>
                  {filterLang.nameAZ[lang] || filterLang.nameAZ.en}
                </option>
                <option value='{"order": "name", "orderBy": "DESC"}'>
                  {filterLang.nameZA[lang] || filterLang.nameZA.en}
                </option>
                <option value='{"order": "price", "orderBy": "ASC"}'>
                  {filterLang.priceLH[lang] || filterLang.priceLH.en}
                </option>
                <option value='{"order": "price", "orderBy": "DESC"}'>
                  {filterLang.priceHL[lang] || filterLang.priceHL.en}
                </option>
                <option value='{"order": "rating", "orderBy": "ASC"}'>
                  {filterLang.ratingLH[lang] || filterLang.ratingLH.en}
                </option>
                <option value='{"order": "rating", "orderBy": "DESC"}'>
                  {filterLang.ratingHL[lang] || filterLang.ratingHL.en}
                </option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label">
                {filterLang.currency[lang] || filterLang.currency.en}
              </label>
              <select className="form-control select2" id="currency">
                <option value="">
                  {langConfig.any[lang] || langConfig.any.en}
                </option>
                {countries.length
                  ? countries.map((item) => {
                      return (
                        <option key={item.id} value={item.currency}>
                          {item.currency}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mt-4 mb-0">
              <button
                className="btn-universal"
                placement="top"
                onClick={onSubmit}
              >
                {langConfig.search[lang] || langConfig.search.en}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    interventions: Object.values(
      state.cms.interventionSpecialization.interventions
    ),
    specializations: Object.values(
      state.cms.interventionSpecialization.specializations
    ),
    theraputicList: Object.values(
      state.cms.interventionSpecialization.theraputic
    ),
    mentalList: Object.values(state.cms.interventionSpecialization.mental),
    psychologicalList: Object.values(
      state.cms.interventionSpecialization.psychological
    ),
    otherList: Object.values(state.cms.interventionSpecialization.others),
  };
};

export default connect(mapStateToProps, null)(ConsultantFilter);
