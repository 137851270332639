import React from 'react';
import SingleClient from '../Children/SingleClient';
import NoDataFound from './NoDataFound';

const SecMyClients = ({ clients, countries, user, config, lang, isLoaded }) => {
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-myaccount-header">
              <div className="lable-text">
                { config.myClients[lang] || config.myClients.en }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="emails-list">
              { (clients && clients.length && countries.length) ?
                clients.map(client => {
                  return (
                    <SingleClient key={client.ids} client={client} countries={countries} 
                      user={user}
                    />
                  )
                })
                : isLoaded ? <NoDataFound /> : ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecMyClients
