export const allowedLangs = ["en", "es", "it"];

const getLang = () => {
  const lang = localStorage.getItem("lang");

  if (lang && allowedLangs.includes(lang)) {
    return lang;
  }

  return "en";
};

export const lang = getLang();

export const langConfig = {
  loading: {
    en: "Loading",
    es: "Cargando",
    it: "Caricamento",
  },
  viewAll: {
    en: "View all",
    es: "Ver todo",
    it: "Mostra tutto",
  },
  lowBattery: {
    en: "Battery is low",
    es: "La batería está baja",
    it: "La batteria è quasi scarica",
  },
  wantToDelete: {
    en: "Are you sure you want to delete?",
    es: "Estás segura de que quieres eliminar?",
    it: "Sei sicuro di voler eliminare?",
  },
  viewMore: {
    en: "View more",
    es: "Ver más",
    it: "SCOPRI DI PIÙ",
  },
  readMore: {
    en: "Read article",
    es: "Lee el artículo",
    it: `Leggi l'articolo`,
  },
  login: {
    en: "Login",
    es: "Acceder",
    it: "Accedi",
  },
  signup: {
    en: "Sign up",
    es: "Regístrate",
    it: "Registrati",
  },
  clientSignup: {
    en: "Client Sign up",
    es: "Registro de cliente",
    it: "Registrazione cliente",
  },
  consultantSignup: {
    en: "Consultant Sign up",
    es: "Consultor Registrarse",
    it: "Consulente Iscriviti",
  },
  privacyPolicies: {
    en: "Privacy policies",
    es: "Políticas De Privacidad",
    it: "Norme Sulla Privacy",
  },
  termsOfServices: {
    en: "Terms of services",
    es: "Términos De Servicio",
    it: "Termini Di Servizio",
  },
  contact: {
    en: "Contact",
    es: "Contáctenos",
    it: "Contattaci",
  },
  contactUs: {
    en: "Contact us",
    es: "Contacta con nosotros",
    it: "Contattaci",
  },
  email: {
    en: "Email",
    es: "Correo electrónico",
    it: "E-mail",
  },
  password: {
    en: "Password",
    es: "Contraseña",
    it: "Password",
  },
  search: {
    en: "Search",
    es: "Buscar",
    it: "Ricerca",
  },
  nationality: {
    en: "Nationality",
    es: "Nacionalidad",
    it: "Nazionalità",
  },
  language: {
    en: "Language",
    es: "Idioma",
    it: "Lingua",
  },
  any: {
    en: "Any",
    es: "Alguna",
    it: "Qualunque",
  },
  gender: {
    en: "Gender",
    es: "Género",
    it: "Genere",
  },
  status: {
    en: "Status",
    es: "Estado",
    it: "Stato",
  },
  mode: {
    en: "Mode",
    es: "Modo",
    it: "Modalità",
  },
  male: {
    en: "Male",
    es: "Masculino",
    it: "Maschio",
  },
  female: {
    en: "Female",
    es: "Hembra",
    it: "Femmina",
  },
  chat: {
    en: "Chat",
    es: "Chat",
    it: "Chat",
  },
  voice: {
    en: "Voice",
    es: "Voz",
    it: "Voce",
  },
  video: {
    en: "Video",
    es: "Video",
    it: "Video",
  },
  filter: {
    en: "Filter",
    es: "Filtrar",
    it: "Filtro",
  },
  onlineStatus: {
    en: "Online status",
    es: "Estado en línea",
    it: "Stato online",
  },
  clearAll: {
    en: "Clear all",
    es: "Limpiar Todo",
    it: "Cancella Tutto",
  },
  termCondition: {
    en: "Terms and Conditons",
    es: "Términos y Condiciones",
    it: "Termini e condizioni",
  },
  privacyPoliciy: {
    en: "Data and Privacy Policy",
    es: "Política de datos y privacidad.",
    it: "Informativa sui dati e sulla privacy.",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
    it: "Continua",
  },
  changePassword: {
    en: "Change Password",
    es: "Cambia la contraseña",
    it: "Cambiare la password",
  },
  count: {
    en: "Count",
    es: "Contar",
    it: "Contare",
  },
  currency: {
    en: "Currency",
    es: "Moneda",
    it: "Valuta",
  },
  service: {
    en: "Service",
    es: "Servicio",
    it: "Servizio",
  },
  pPm: {
    en: "Price/Minutes",
    es: "Precio/Minutos",
    it: "Prezzo/Minuti",
  },
  select: {
    en: "Select",
    es: "Seleccione",
    it: "Selezionare",
  },
  price: {
    en: "Price",
    es: "Precio",
    it: "Prezzo",
  },
  success: {
    en: "Success",
    es: "Éxito",
    it: "Successo",
  },
  failure: {
    en: "Failure",
    es: "Falla",
    it: "Fallimento",
  },
  maxCapture: {
    en: "Maximum amount captured: ",
    es: "Cantidad máxima capturada:",
    it: "Importo massimo catturato:",
  },
};
export const cardConfig = {
  addCard: {
    en: "Add card first",
    es: "Agregar tarjeta primero",
    it: "Aggiungi prima la carta",
  },
  addNewCard: {
    en: "Add new card",
    es: "Agregar nueva tarjeta",
    it: "Aggiungi nuova carta",
  },
};

export const homeLang = {
  whatIsProntopsy: {
    en: "What is Prontopsy?",
    es: "¿Qué es Prontopsy?",
    it: `Che cos'è Prontopsy?`,
  },
  findAPsychologist: {
    en: "Find a Psychologist",
    es: "Busca un Psicólogo",
    it: "Trova uno Psicologo",
  },
  areaOfIntervention: {
    en: "Area of Intervention",
    es: "ÁMBITOS DE INTERVENCIÓN",
    it: "AMBITI DI INTERVENTO",
  },
  howItWorks: {
    en: "How it works",
    es: "Cómo Funciona",
    it: "Come Funziona",
  },
  myAccount: {
    en: "My Account",
    es: "Mi cuenta",
    it: "Il mio account",
  },
  aboutUs: {
    en: "About us",
    es: "Sobre nosotros",
    it: "Su di noi",
  },
  quickLinks: {
    en: "Quick links",
    es: "Link rápidos",
    it: "Links rapidi",
  },
  followUsOn: {
    en: "Follow us on",
    es: "Siga Con Nosotros",
    it: "Seguici Su",
  },
  websiteBy: {
    en: "Website by",
    es: "Sitio web por",
    it: "Sito web di",
  },
  searchBannerPlaceholder: {
    en: "Search Psychologist name, word or phrase",
    es: "Buscar el nombre del psicólogo, una palabra o una frase",
    it: "Cerca il nome dello psicologo, una parola o una frase",
  },
  rememberBe: {
    en: "Remember me",
    es: "Recuérdame",
    it: "Ricordami La Password",
  },
  forgotPassword: {
    en: "Forgot Passoword",
    es: "Se Te Olvidó Tu Contraseña",
    it: "Password Dimenticata",
  },
  dontHaveAnAccount: {
    en: "Dont have an account?",
    es: "¿No Tienes Una Cuenta?",
    it: "Non Hai Un Account? ",
  },
  signupNow: {
    en: "Signup Now",
    es: "Regístrate ahora",
    it: "Iscriviti ora",
  },
  alreadyHaveAnAccount: {
    en: "Already have an account?",
    es: "¿Ya Tienes Una Cuenta?",
    it: "Hai Già Un Account?",
  },
  signinNow: {
    en: "Signin now",
    es: "Regístrate Ahora",
    it: "Registrati Ora",
  },
};

export const interventionLang = {
  interventionArea: {
    en: "Intervention Area",
    es: "Área De Intervención",
    it: "Area Di Intervento",
  },
  theraputicApproach: {
    en: "Theraputic approach",
    es: "Enfoque Terapéutico",
    it: "Approccio Terapeutico",
  },
  theraputicResearch: {
    en: "Theraputic Research",
    es: "Enfoque Terapéutico",
    it: "Approccio Terapeutico",
  },
  mentalApproach: {
    en: "Mental and Neuropsychological disorders",
    es: "Trastornos Mentales Y Neuropsicológicos Tratados",
    it: "Disturbi Mentali E Neuropsicologici Trattati",
  },
  psychologicalProblems: {
    en: "Psychological problems",
    es: "Problemas Psicológicos Tratados",
    it: "Problematiche Psicologiche Trattate",
  },
  otherTopics: {
    en: "Other topics",
    es: "Otros Temas Tratados",
    it: "Altre Tematiche Trattate",
  },
  search: {
    en: "Search...",
    es: "Buscar...",
    it: "Ricerca...",
  },
  select: {
    en: "Select",
    es: "Seleccione",
    it: "Selezionare",
  },
  selected: {
    en: "Selected",
    es: "Seleccionado",
    it: "Selezionato",
  },
};

export const personalTooltipLang = {
  name: {
    en: "",
    es: "",
    it: "",
  },
  surname: {
    en: "",
    es: "",
    it: "",
  },
  gender: {
    en: "",
    es: "",
    it: "",
  },
  phoneCode: {
    en: "",
    es: "",
    it: "",
  },
  phone: {
    en: "",
    es: "",
    it: "",
  },
  nationality: {
    en: "",
    es: "",
    it: "",
  },
  timezone: {
    en: "",
    es: "",
    it: "",
  },
  dOB: {
    en: "",
    es: "",
    it: "",
  },
  countryOB: {
    en: "",
    es: "",
    it: "",
  },
  pOB: {
    en: "",
    es: "",
    it: "",
  },
  cityOB: {
    en: "",
    es: "",
    it: "",
  },
  aOR: {
    en: "",
    es: "",
    it: "",
  },
  countryOR: {
    en: "",
    es: "",
    it: "",
  },
  pOR: {
    en: "",
    es: "",
    it: "",
  },
  cityOR: {
    en: "",
    es: "",
    it: "",
  },
  zipCode: {
    en: "",
    es: "",
    it: "",
  },
};
export const billingTooltipLang = {
  fC: {
    en: "",
    es: "",
    it: "",
  },
  bCountry: {
    en: "",
    es: "",
    it: "",
  },
  bP: {
    en: "",
    es: "",
    it: "",
  },
  bCity: {
    en: "",
    es: "",
    it: "",
  },
  bAddress: {
    en: "",
    es: "",
    it: "",
  },
  tIC: {
    en: "",
    es: "",
    it: "",
  },
  pEC: {
    en: "",
    es: "",
    it: "",
  },
  personalEmail: {
    en: "",
    es: "",
    it: "",
  },
  sDI: {
    en: "",
    es: "",
    it: "",
  },
  bZipCode: {
    en: "",
    es: "",
    it: "",
  },
};
export const accountTooltipLang = {
  email: {
    en: "",
    es: "",
    it: "",
  },
  oP: {
    en: "",
    es: "",
    it: "",
  },
  nP: {
    en: "",
    es: "",
    it: "",
  },
  cP: {
    en: "",
    es: "",
    it: "",
  },
};
export const languageTooltipLang = {
  languages: {
    en: "",
    es: "",
    it: "",
  },
};
export const memberTooltipLang = {
  pQ: {
    en: "",
    es: "",
    it: "",
  },
  mN: {
    en: "",
    es: "",
    it: "",
  },
  businessCountry: {
    en: "",
    es: "",
    it: "",
  },
  region: {
    en: "",
    es: "",
    it: "",
  },
  city: {
    en: "",
    es: "",
    it: "",
  },
  mRN: {
    en: "",
    es: "",
    it: "",
  },
  mDE: {
    en: "",
    es: "",
    it: "",
  },
};
export const insuranceTooltipLang = {
  iCN: {
    en: "",
    es: "",
    it: "",
  },
  iN: {
    en: "",
    es: "",
    it: "",
  },
  iED: {
    en: "",
    es: "",
    it: "",
  },
};
export const biographyTooltipLang = {
  sD: {
    en: "",
    es: "",
    it: "",
  },
  lD: {
    en: "",
    es: "",
    it: "",
  },
};
export const interventionTooltipLang = {
  iVA: {
    en: "",
    es: "",
    it: "",
  },
  tA: {
    en: "",
    es: "",
    it: "",
  },
  mAND: {
    en: "",
    es: "",
    it: "",
  },
  pP: {
    en: "",
    es: "",
    it: "",
  },
  oP: {
    en: "",
    es: "",
    it: "",
  },
};
export const serviceofferedTooltipLang = {
  service: {
    en: "",
    es: "",
    it: "",
  },
  mode: {
    en: "",
    es: "",
    it: "",
  },
  currency: {
    en: "",
    es: "",
    it: "",
  },
  pricePerMinuite: {
    en: "",
    es: "",
    it: "",
  },
};
export const dailyscheduleTooltipLang = {
  startTime: {
    en: "Start Time",
    es: "",
    it: "",
  },
  endTime: {
    en: "End Time",
    es: "",
    it: "",
  },
  restInterval: {
    en: "",
    es: "",
    it: "",
  },
  scheduleDuration: {
    en: "",
    es: "",
    it: "",
  },
};

export const termsAndConditionLang = {
  firstCheckboxContent: {
    en: "I have read and accept the Informed Consent to the Psychological Consult.",
    es: "He leído y acepto el Consentimiento Informado para la Consulta Psicológica",
    it: "Ho letto e accetto il Consenso Informato al Consulto Psicologico",
  },
  secondCheckboxContent: {
    en: "I have read, understood and agree to the Prontopsy Data and Privacy Policy",
    es: "He leído, entendido y acepto la Política de privacidad y datos de Prontopsy",
    it: "Ho letto, compreso e accetto l`Informativa sui dati e sulla privacy di Prontopsy",
  },
};

export const filterLang = {
  soryBy: {
    en: "Sort by",
    es: "Ordenar por",
    it: "Ordina per",
  },
  currency: {
    en: "Currency",
    es: "Moneda",
    it: "Valuta",
  },
  nameAZ: {
    en: "Name (A - Z)",
    es: "Nombre (A - Z)",
    it: "Nome (A - Z)",
  },
  nameZA: {
    en: "Name (Z - A)",
    es: "Nombre (Z - A)",
    it: "Nome (Z - A)",
  },
  priceLH: {
    en: "Price (Low - High)",
    es: "Precio (Bajo - Alto)",
    it: "Presso (Basso - Alto)",
  },
  priceHL: {
    en: "Price (High - Low)",
    es: "Precio (Alto - Bajo)",
    it: "Presso (Alto - Basso)",
  },
  ratingLH: {
    en: "Rating (Low - High)",
    es: "Calificación (Bajo - Alto)",
    it: "Rating (Basso - Alto)",
  },
  ratingHL: {
    en: "Rating (High - Low)",
    es: "Calificación (Alto - Bajo)",
    it: "Rating (Alto - Basso)",
  },
};

export const accountLang = {
  name: {
    en: "Name",
    es: "Nombre",
    it: "Nome",
  },
  surname: {
    en: "Surname",
    es: "Apellido",
    it: "Cognome",
  },
  secondSurname: {
    en: "Second Surname",
    es: "Segundo Apellido",
    it: "Secondo Cognome",
  },
  contact: {
    en: "Contact",
    es: "Contacto",
    it: "Contatto",
  },
  telephoneCode: {
    en: "Telephone Code",
    es: "Código telefónico",
    it: "Codice del telefono",
  },
  telephone: {
    en: "Telephone",
    es: "Teléfono",
    it: "Telefono",
  },
  dateOfBirth: {
    en: "Date of Birth",
    es: "Fecha De Nacimiento",
    it: "Data Di Nascita",
  },
  selectPhoneCode: {
    en: "Select Phonecode",
    es: "Seleccionar código de teléfono",
    it: "Seleziona Codice telefono",
  },
  selectTimezone: {
    en: "Select Timezone",
    es: "Selecciona La Zona Horaria",
    it: "Seleziona Il Fuso Orario",
  },
  confirmPassword: {
    en: "Confirm Password",
    es: "Confirmar contraseña",
    it: "Conferma password",
  },
  iAmAPsychologist: {
    en: "I am a Psychologist",
    es: "Soy Un Psicólogo",
    it: "Sono Uno Psicologo",
  },
  iAmAClient: {
    en: "I am a Client",
    es: "Soy Un Cliente",
    it: "Sono Un Cliente",
  },
  agree: {
    en: "I have read, understood and agree to the Prontopsy",
    es: "He leído, entendido y estoy de acuerdo con ProntoPsy",
    it: "Ho letto, compreso e accetto il ProntoPsy",
  },
  addressOfResidence: {
    en: "Address of Residence",
    es: "Dirección de residencia",
    it: "Indirizzo di residenza",
  },
  countryOfResidence: {
    en: "Country of Residence",
    es: "País de residencia",
    it: "Paese di residenza",
  },
  provinceOfResidence: {
    en: "Province of Residence",
    es: "Provincia de residencia",
    it: "Provincia Di Residenza",
  },
  cityOfResidence: {
    en: "City of Residence",
    es: "Ciudad de residencia",
    it: "Città di residenza",
  },
  zipCode: {
    en: "Zip Code",
    es: "Código postal",
    it: "Cap",
  },
  countryOfBirth: {
    en: "Country of Birth",
    es: "País de nacimiento",
    it: "Paese di nascita",
  },
  provinceOfBirth: {
    en: "Province of Birth",
    es: "Lugar de nacimiento",
    it: "Provincia Di Nascita",
  },
  cityOfBirth: {
    en: "City of Birth",
    es: "Ciudad de nacimiento",
    it: "Città di nascita",
  },
  personalEmail: {
    en: "Personal Email",
    es: "Email personal",
    it: "E-mail personale",
  },
  oldPassword: {
    en: "Old Password",
    es: "Contraseña anterior",
    it: "vecchia password",
  },
  newPassword: {
    en: "New Password",
    es: "Nueva contraseña",
    it: "nuova password",
  },
  phone: {
    en: "Phone",
    es: "Teléfono",
    it: "Telefono",
  },
  subject: {
    en: "Subject",
    es: "Sujeto",
    it: "Materia",
  },
  yourMessage: {
    en: "Your Message",
    es: "Tu mensaje",
    it: "Il tuo messaggio",
  },
  sendMessage: {
    en: "Send Message",
    es: "Enviar mensaje",
    it: "Invia messaggio",
  },
  officeAddress: {
    en: "Office Address",
    es: "Dirección de la oficina",
    it: `Indirizzo dell'ufficio`,
  },
  followUs: {
    en: "Follow Us",
    es: "Síganos",
    it: "Seguici",
  },
  email: { en: "Email", es: "Correo electrónico", it: "E-mail" },
  note: { en: "Note", es: "Nota", it: "Nota" },
};

export const consultantLang = {
  biography: {
    en: "Biography",
    es: "Biografía",
    it: "Biografia",
  },

  interventionSpecialization: {
    en: "Intervention & Specialization Info",
    es: "Información de intervención y especialización",
    it: `Informazioni sull'intervento e sulla specializzazione`,
  },
  serviceOffered: {
    en: "Service Offered",
    es: "Servicio Ofrecido",
    it: "Servizio Offerto",
  },
  reviews: {
    en: "Reviews",
    es: "Reseñas",
    it: "Recensioni",
  },
  skillAndKnowledge: {
    en: "Skill and knowledge",
    es: "Habilidad y Conocimiento",
    it: "Abilità e Conoscenza",
  },
  cordiality: {
    en: "Cordiality and communication",
    es: "Cordialidad y Comunicación",
    it: "Cordialità e Comunicazione",
  },
  internetAccess: {
    en: "Internet access",
    es: "Acceso a Internet",
    it: "Accesso ad Internet",
  },
  serviceName: {
    en: "Service name",
    es: "Nombre Del Servicio",
    it: "Tipologia Di Servizio",
  },
  cost: {
    en: "Cost",
    es: "Costo",
    it: "Costo",
  },
  duration: {
    en: "Duration",
    es: "Duración",
    it: "Durata",
  },
  bookAnInterview: {
    en: "Book an interview",
    es: "Reserve una entrevista",
    it: "Prenota un colloquio",
  },
  immeadiateConsultation: {
    en: "Immeadiate consultation",
    es: "Consulta inmediata",
    it: "Consulenza immediata",
  },
  startConversationWith: {
    en: "Start conversation with",
    es: "Iniciar conversación con",
    it: "Inizia la conversazione con",
  },
  startAudioConversationWith: {
    en: "Start AUDIO conversation with",
    es: "Iniciar conversación de AUDIO con",
    it: "Avvia una conversazione AUDIO con",
  },
  startVideoConversationWith: {
    en: "Start VIDEO conversation with",
    es: "Iniciar conversación de VIDEO con",
    it: "Avvia una conversazione VIDEO con",
  },
  startChatConversationWith: {
    en: "Start CHAT conversation with",
    es: "Iniciar conversación de CHAT con",
    it: "Avvia conversazione CHAT con",
  },
  bookAnInterviewWith: {
    en: "Book an interview with",
    es: "Reserve una entrevista con",
    it: "Prenota un colloquio con",
  },
  availableTimeSlots: {
    en: "Available time slots",
    es: "Horarios disponibles",
    it: "Fasce orarie disponibili",
  },
  availableTimeSlotsAccording: {
    en: "Available Time Slots according",
    es: "Horarios disponibles según",
    it: "Fasce orarie disponibili secondo",
  },
};

export const adminSideNavLang = {
  dashboard: { en: "Dashboard", es: "Tablero", it: "Pannello di controllo" },
  myAccount: { en: "My Account", es: "Mi cuenta", it: "Il mio account" },
  myProfile: { en: "My Profile", es: "Mi perfil", it: "Il mio profilo" },
  myFavourite: {
    en: "My Favourite",
    es: "Mi favorita",
    it: "I Miei Preferiti",
  },
  appointments: { en: "Appointments", es: "Citas", it: "Appuntamenti" },
  email: { en: "Email", es: "Correo electrónico", it: "E-mail" },
  logout: { en: "Logout", es: "Cerrar sesión", it: "Disconnettersi" },
  viewOnline: {
    en: "View Online",
    es: "Ver online",
    it: "Visualizza in linea",
  },
  myPublicProfile: {
    en: "My Public Profile",
    es: "Mi perfil público",
    it: "Il mio profilo pubblico",
  },
  myClients: { en: "My Clients", es: "Mis clientes", it: "I miei clienti" },
  myReviews: { en: "My Reviews", es: "Reseñas", it: "Recensioni" },
  wishlist: {
    en: "Wishlist",
    es: "Lista De Favoritos",
    it: "Lista Dei Preferiti",
  },
};

export const appointmentLang = {
  pastAppointments: {
    en: "Past Appointments",
    es: "Citas Anteriores",
    it: "Appuntamenti Passati",
  },
  inProgressAppointment: {
    en: "In Progress Appointment",
    es: "Cita en curso",
    it: "Appuntamento in corso",
  },
  upcomingAppointments: {
    en: "Upcoming Appointments",
    es: "Próximas citas",
    it: "Prossimi Appuntamenti",
  },
  yHaW: {
    en: "You had appointment with",
    es: "Tuviste Una Cita Con",
    it: "Hai Avuto Un Appuntamento Con",
  },
  yHaWF: {
    en: "You have an appointment with",
    es: "Tienes una cita con",
    it: "Hai un appuntamento con",
  },
  paymentAmount: {
    en: "Payment Amount",
    es: "Monto del pago",
    it: "Importo del pagamento",
  },
  paymentStatus: {
    en: "Payment Status",
    es: "Estado de pago",
    it: "Stato del pagamento",
  },
  aI: {
    en: "Appointment ID",
    es: "ID de cita",
    it: "ID appuntamento",
  },
  closed: {
    en: "Closed",
    es: "Cerrado",
    it: "Chiuso",
  },
  cancel: {
    en: "Cancel",
    es: "Cancelar",
    it: "Annulla",
  },
  active: {
    en: "Active",
    es: "Activo",
    it: "Attivo",
  },
  iP: {
    en: "In Progress",
    es: "En curso",
    it: "In corso",
  },
  captured: {
    en: "Captured",
    es: "Capturado",
    it: "Catturato",
  },
  authorized: {
    en: "Authorized",
    es: "Autorizado",
    it: "Autorizzato",
  },
  pending: {
    en: "Pending",
    es: "Pendiente",
    it: "In attesa di",
  },
  "authorization failed": {
    en: "Authorization failed",
    es: "Autorización fallida",
    it: "Autorizzazione fallita",
  },
  "payment released": {
    en: "Payment released",
    es: "Pago liberado",
    it: "Pagamento sbloccato",
  },
  "capture failed": {
    en: "Capture failed",
    es: "Captura fallida",
    it: "Cattura fallita",
  },
  minute: {
    en: "minute",
    es: "minuto",
    it: "minuto",
  },
  minutes: {
    en: "minutes",
    es: "minutos",
    it: "minuti",
  },
  addNote: {
    en: "Add note",
    es: "Añadir la nota",
    it: "Aggiungi nota",
  },
};

export const acNavLang = {
  personal: {
    en: "Personal Info",
    es: "Datos Personales",
    it: "Dati Personali",
  },
  billing: {
    en: "Billing Info",
    es: "Datos De Facturación",
    it: "Dati Di Fatturazione",
  },
  payment: {
    en: "Payment Info",
    es: "Datos De Pago",
    it: "Dati Sul Pagamento",
  },
  account: { en: "Account Info", es: "Datos De cuenta", it: "Dati Account" },
  member: {
    en: "Member Info",
    es: "Datos De Membresía",
    it: "Ordine Di Appartenenza",
  },
  insurance: {
    en: "Insurance Info",
    es: "Datos Del Seguro",
    it: "Dati Assicurativi",
  },
  language: { en: "Language Info", es: "Idioma", it: "Lingua" },
  daily: { en: "Daily Schedule", es: "Disponibilidad", it: "Disponibilità" },
  address: {
    en: "Address Information",
    es: "Datos del Domicilio",
    it: "Informazioni sulla Residenza",
  },
  birth: {
    en: "Birth Information",
    es: "Información de nacimiento",
    it: "Informazioni sulla nascita",
  },
};
export const errorLang = {
  imageSizeLimit: {
    en: "File must be less than 500kb",
    es: "La imagen debe tener menos de 500kb",
    it: "L'immagine deve essere inferiore a 500kb",
  },
};

export const acNavLangFormal = {
  personal: {
    en: "Personal Information",
    es: "Datos Personales",
    it: "Dati Personali",
  },
  card: {
    en: "Card Information",
    es: "Información de la tarjeta",
    it: "Informazioni sulla carta",
  },
  billing: {
    en: "Billing Information",
    es: "Datos De Facturación",
    it: "Dati Di Fatturazione",
  },
  payment: {
    en: "Payment Information",
    es: "Datos De Pago",
    it: "Dati Sul Pagamento",
  },
  account: {
    en: "Account Information",
    es: "Datos De cuenta",
    it: "Dati Account",
  },
  member: {
    en: "Membership Information",
    es: "Información de membresía",
    it: "Informazioni sull`iscrizione",
  },
  insurance: {
    en: "Insurance Information",
    es: "Datos Del Seguro",
    it: "Dati Assicurativi",
  },
  biography: { en: "Biography Information", es: "Biografía", it: "Biografia" },
  language: { en: "Language Information", es: "Idioma", it: "Lingua" },
  daily: { en: "Daily Schedule", es: "Disponibilidad", it: "Disponibilità" },
  address: {
    en: "Address Information",
    es: "Datos del Domicilio",
    it: "Informazioni sulla Residenza",
  },
  birth: {
    en: "Birth Information",
    es: "Información de nacimiento",
    it: "Informazioni sulla nascita",
  },
};

export const fiscalLang = {
  businessName: {
    en: "Business Name",
    es: "Nombre del Negocio",
    it: "Nome commerciale",
  },
  fiscalCode: {
    en: "Fiscal Code",
    es: "Código Fiscal",
    it: "Codice fiscale",
  },
  businessCountry: {
    en: "Business Country",
    es: "País Comerial",
    it: "Paese Di Attività",
  },
  businessProvince: {
    en: "Business Province",
    es: "Provincias Comercial",
    it: "Provincia Di Attività",
  },
  businessCity: {
    en: "Business City",
    es: "Ciudad Comercial",
    it: "Città Di Attività",
  },
  businessAddress: {
    en: "Business Address",
    es: "Dirección De Negocios",
    it: "Indirizzo Sede Di Lavoro",
  },
  tIc: {
    en: "Tax Identification Code",
    es: "Código De Identificación Fiscal",
    it: "Partita Iva",
  },
  pec: {
    en: "PEC",
    es: "PEC",
    it: "PEC",
  },
  sdi: {
    en: "SDI",
    es: "IDE",
    it: "SDI",
  },
};

export const memberLang = {
  personalQualification: {
    en: "Personal Qualification",
    es: "Cualificación Profesional",
    it: "Qualifica Professionale",
  },
  authorization: {
    en: "I Am Authorized To Exercise The Above Professions Which Is Selected.",
    es: "Estoy Autorizado Para Ejercer Las Profesiones Antes Mencionadas Que Se Seleccionen.",
    it: "Sono Autorizzato Ad Esercitare Le Professioni Sopra Selezionate.",
  },
  membershipName: {
    en: "Membership Name",
    es: "Institución De Pertenencia",
    it: "Ordine Professionale Di Appartenenza",
  },
  businessCountry: {
    en: "Country",
    es: "País",
    it: "Nazoine",
  },
  region: {
    en: "Region",
    es: "Región",
    it: "Regione",
  },
  businessCity: {
    en: "City",
    es: "Ciudad",
    it: "Città",
  },
  membershipRegistrationNumber: {
    en: "Membership Registration No.",
    es: "Número De Registro De Membresía",
    it: "Numero Di Iscrizione",
  },
  membershipDateRegistration: {
    en: "Membership Date Registration",
    es: "Fecha De Membresía De Registro",
    it: "Data Di Iscrizione",
  },
};

export const insuranceLang = {
  iCn: {
    en: "Insurance Company Name",
    es: "Datos Del Seguro",
    it: "Nome Della Compagnia Di Assicurazioni",
  },
  insuranceNumber: {
    en: "Insurance Number",
    es: "Numero De Seguro",
    it: "Numero Di Polizza",
  },
  iEd: {
    en: "Insurance Expiration Date",
    es: "Fecha de vencimiento del seguro",
    it: `Data di scadenza dell'assicurazione`,
  },
  dHI: {
    en: "I do not have insurance",
    es: "No tengo seguro",
    it: `Non Ho L' Assicurazione`,
  },
};

export const dailyLang = {
  firstHalf: {
    en: "First half",
    es: "Primera mitad",
    it: "Prima metà",
  },
  secondHalf: {
    en: "Second half",
    es: "segunda mitad",
    it: "Seconda metà",
  },
  startTime: {
    en: "Start time",
    es: "Hora de apertura",
    it: "Ora di apertura",
  },
  endTime: {
    en: "End time",
    es: "Hora de cierre",
    it: "Ora di chiusura",
  },
  selectTime: {
    en: "Select Time",
    es: "Seleccionar hora",
    it: "Seleziona Ora",
  },
  close: {
    en: "Close",
    es: "Cerrado",
    it: "Chiuso",
  },
  restTimeInterval: {
    en: "Rest Time Interval(Minutes)",
    es: "Intervalo de tiempo de descanso (minutos)",
    it: "Intervallo di riposo (minuti)",
  },
  scheduleDuration: {
    en: "Schedule Duration(Minutes)",
    es: "Duración del programa (minutos)",
    it: "Durata del programma (minuti)",
  },
  sd: {
    en: "Schedule Duration",
    es: "Horario Duración",
    it: "Durata del programma",
  },
  monday: {
    en: "Monday",
    es: "lunes",
    it: "Lunedi",
  },
  tuesday: {
    en: "Tuesday",
    es: "martes",
    it: "Martedì",
  },
  wednesday: {
    en: "Wednesday",
    es: "miércoles",
    it: "mercoledì",
  },
  thursday: {
    en: "Thursday",
    es: "jueves",
    it: "giovedì",
  },
  friday: {
    en: "Friday",
    es: "viernes",
    it: "venerdì",
  },
  saturday: {
    en: "Saturday",
    es: "sábado",
    it: "Sabato",
  },
  sunday: {
    en: "Sunday",
    es: "domingo",
    it: "domenica",
  },
};

export const modalLang = {
  rA: {
    en: "I have read and accept the",
    es: "He leído y acepto la",
    it: "Ho letto e accetto il",
  },

  iAgree: {
    en: "I have read, understood and agree to the Prontopsy",
    es: "He leído, entendido y estoy de acuerdo con el Prontopsy",
    it: "Ho letto, compreso e accetto la Prontopsy",
  },
  dApP: {
    en: "Data and Privacy Policy",
    es: "Política de datos y privacidad",
    it: "Dati e Informativa sulla privacy",
  },
  informedConsent: {
    en: "Informed Consent",
    es: "Consentimiento informado",
    it: "Consenso informato",
  },
  tPc: {
    en: "to the Psychological Consult.",
    es: "a la Consulta Psicológica.",
    it: "al Consultorio Psicologico.",
  },
  addStripe: {
    en: "Add Stripe Card First.",
    es: "Primero la tarjeta en la franja publicitaria.",
    it: "Scheda prima nella striscia pubblicitaria.",
  },
  completeProfile: {
    en: "Complete your profile first.",
    es: "Completa tu perfil primero.",
    it: "Completa prima il tuo profilo.",
  },
  sCW: {
    en: "Start conversation with",
    es: "Iniciar conversación con",
    it: "Inizia la conversazione con",
  },
  startAudioConversationWith: {
    en: "Start AUDIO conversation with",
    es: "Iniciar conversación de AUDIO con",
    it: "Avvia una conversazione AUDIO con",
  },
  startVideoConversationWith: {
    en: "Start VIDEO conversation with",
    es: "Iniciar conversación de VIDEO con",
    it: "Avvia una conversazione VIDEO con",
  },
  startChatConversationWith: {
    en: "Start CHAT conversation with",
    es: "Iniciar conversación de CHAT con",
    it: "Avvia conversazione CHAT con",
  },
  confirmationAlert: {
    en: "Confirmation Alert",
    es: "Alerta de confirmación",
    it: "Avviso di conferma",
  },
  confirm: {
    en: "Confirm",
    es: "Confirmar",
    it: "Confermare",
  },
  cancel: {
    en: "Cancel",
    es: "Cancelar",
    it: "Annulla",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
    it: "Continua",
  },
  sureToDisconnect: {
    en: "Are you sure to disconnect the call?",
    es: "¿Estás seguro de desconectar la llamada?",
    it: "Sei sicuro di disconnettere la chiamata?",
  },
  closeYourAccount: {
    en: "Close your account",
    es: "Cierra tu cuenta",
    it: "Chiudi il tuo account",
  },
  cCaTa: {
    en: "Consultant cannot attend the appointment.",
    es: "El consultor no puede asistir a la cita.",
    it: `Il consulente non può presenziare all'appuntamento.`,
  },
  cUsTa: {
    en: "User cannot attend the appointment.",
    es: "El usuario no puede asistir a la cita.",
    it: `L'utente non può partecipare all'appuntamento.`,
  },
  yMaTtAC: {
    en: "You must accept the terms and conditions.",
    es: "Debe aceptar los Términos y Condiciones.",
    it: "Devi accettare i termini e le condizioni.",
  },
  clientReviewContent: {
    en: "How it was? Please rate your Consultant and leave a feed back about your experience. Thank you",
    es: "¿Cómo fue? Por favor, califique a su consultor y deje un comentario sobre tu experiencia. Gracias",
    it: "Come è andata? Per favore valuta il tuo consulente e lascia un feedback sulla tua esperienza. Grazie",
  },
  consultantReviewContent: {
    en: "Please remember to email the invoice to the customer according to the laws in force in your country. The Customer's personal information and information relating to this call are stored on the Appointment Details page. Thank you",
    es: "Por favor,recuerde enviar por correo electrónico la factura al cliente de acuerdo con las leyes vigentes en su país. La información personal del cliente y la información relacionada con esta llamada se almacenan en la página Detalles de la cita. Gracias",
    it: "Per favore, ricordati di inviare via email la fattura al cliente in base alle leggi in vigore nel tuo paese. Le informazioni personali del Cliente e quelle relative a questa chiamata sono memorizzate nella pagina Dettagli Appuntamenti. Grazie",
  },
  defaultReview: {
    en: "Thank you!",
    es: "Gracias",
    it: "Grazie",
  },
  attention: {
    en: "Attention!",
    es: "Atención",
    it: "Attenzione",
  },
  oksure: {
    en: "Ok",
    es: "OK",
    it: "Ok",
  },
  appointmentClosed: {
    en: "Appointment is closed",
    es: "la cita esta cerrada",
    it: `L'appuntamento è chiuso`,
  },
};

export const contactLang = {
  getInTouch: {
    en: "Get in touch with us!",
    es: "¡Ponte en contacto con nosotros!",
    it: "Mettiti in contatto con noi!",
  },
  manyVariations: {
    en: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.",
    es: "Hay muchas variaciones de pasajes de Lorem Ipsum disponibles, pero la mayoría ha sufrido alteraciones de alguna forma.",
    it: "Sono disponibili molte varianti di passaggi di Lorem Ipsum, ma la maggior parte ha subito alterazioni in qualche forma.",
  },
};

export const seoLang = {
  title: {
    en: "ProntoPsy | Online Psychologist Service 24/7 | First Portal of Smart Psychology",
    es: "ProntoPsy | Servicio de Psicólogo Online 24/7 | Primer Portal de Psicología Inteligente",
    it: "ProntoPsy | Servizio di psicologia online 24 ore su 24, 7 giorni su 7 | Primo portale di psicologia intelligente",
  },
};

export const chatLang = {
  enter_message: {
    en: "Enter Message here...",
    es: "Introduzca el mensaje aquí...",
    it: "Inserisci il messaggio qui...",
  },
};
