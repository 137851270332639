import { GET_PAGES_HOW_IT_WORKS } from "../../../actions/types";

const howItWorksRecuder = (state = {}, action) => {
  switch(action.type) {
    case GET_PAGES_HOW_IT_WORKS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default howItWorksRecuder;