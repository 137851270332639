import React from 'react';
import { connect } from 'react-redux';
import { fiscalLang } from '../../../../config/lang';

const BusinessInfo = ({ user, appointment, profQua, lang }) => {
  const config = {
    cbi: {
      en: 'Client Business Information',
      es: 'Información comercial del cliente',
      it: 'Informazioni commerciali del cliente'
    },
    consultantBi: {
      en: 'Consultant Business Information',
      es: 'Información comercial del consultor',
      it: 'Informazioni commerciali del consulente'
    },
    consultantName: {
      en: 'Consultant Name',
      es: 'Nombre del consultor',
      it: 'Nome consulente'
    },
    professionalQualification: {
      en: 'Professional Qualification',
      es: 'Cualificación profesional',
      it: 'Qualificazione professionale'
    }
  }

  return (
    <>
      <div className="appointment-title">
        { (user?.role === 'user') ?
           `${config.consultantBi[lang] || config.consultantBi.en}`
          : `${config.cbi[lang] || config.cbi.en}`
        }
      </div>
      <div className="row form-row mb-3">
        {user?.role === 'user' && <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { config.consultantName[lang] || config.consultantName.en }
            </div>
            <div className="control-data">{ appointment.consultant_fiscal_business_name }</div>
          </div>
        </div>}
        {user?.role === 'user' && <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { config.professionalQualification[lang] || config.professionalQualification.en }
            </div>
            <div className="control-data">
              { profQua.find(qua => (
                qua.id === appointment.consultant_professional_qualifications
              ))?.name || '' }
            </div>
          </div>
        </div>}
        {user?.role === 'consultant' && <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              { fiscalLang.businessName[lang] || fiscalLang.businessName.en }
            </div>
            <div className="control-data">{ appointment.user_fiscal_business_name }</div>
          </div>
        </div>}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    profQua: Object.values(state.cms.professionalQualificatonList)
  }
}

export default connect(mapStateToProps, null)(BusinessInfo);
