import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import InsuranceForm from '../Children/Forms/InsuranceForm';

const SecInsuranceInfo = ({ history, config, lang }) => {

  return (
    <AccountContainer title={config.insurance[lang] || config.insurance.en}>
      <InsuranceForm history={history} />
    </AccountContainer>
  );
}

export default SecInsuranceInfo;