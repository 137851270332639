import React, { useState } from 'react';
import CallTemplate from '../../containers/CallTemplate';
import ChatBody from './ChatBody';
import Footer from './Footer';
import FileUpload from './FileUpload';

const SecAudioVideo = props => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [targetFile, setTargetFile] = useState(null);

  return (
    <CallTemplate
      appointment={props.appointment} user={props.user} lang={props.lang} seconds={props.seconds} 
      setAppointmentEnded={props.setAppointmentEnded} setJoin={props.setJoin}
      messages={props.messages}
    >
      <ChatBody 
        messages={props.messages} 
        lang={props.lang} 
        user={props.user} 
        listRef={props.scrollRef} 
        appointment={props.appointment} 
        rcv={props.rcv} 
        seconds={props.seconds} 
        setAppointmentEnded={props.setAppointmentEnded} setJoin={props.setJoin}
      />
      <Footer 
        lang={props.lang} user={props.user} appointmentId={props.appointmentId} consultantId={props.consultantId} 
        listRef={props.scrollRef}
        appointment={props.appointment}
        file={file} setFile={setFile} setTargetFile={setTargetFile}
        open={open} setOpen={setOpen}
      />
      <FileUpload 
        targetFile={targetFile} setTargetFile={setTargetFile} 
        open={open} setOpen={setOpen} setFile={setFile}
      />
    </CallTemplate>
  )
}

export default SecAudioVideo
