import React from 'react';
import LayoutContainer from '../containers/LayoutContainer';
import SecResetPassword from '../components/Auth/SecResetPassword';

const ResetPassword = ({ history, location }) => {
  return (
    <LayoutContainer>
      <SecResetPassword history={history} location={location} />
    </LayoutContainer>
  )
}

export default ResetPassword;