import React from 'react';
import { connect } from 'react-redux';
import { noImage } from '../../config/host';
// import { consultantLang } from '../../config/lang';

function SingleReview({ review, admin, app,lang, ...props }) {

  // const textConfig = {
  //   personalExperience: {
  //     en: 'Personal Experience',
  //     es: 'Experiencia personal',
  //     it: 'Esperienza personale'
  //   },
  //   noteForPsychologist: {
  //     en: 'Note for Psychologist',
  //     es: 'Nota para el psicólogo',
  //     it: 'Nota per lo Psicologo'
  //   },
  //   noteForProntopsy: {
  //     en: 'Note for Prontopsy',
  //     es: 'Nota para Prontopsy',
  //     it: 'Nota per Prontopsy'
  //   },
  //   submit: {
  //     en: 'Submit',
  //     es: 'Entregar',
  //     it: 'Invia'
  //   }
  // }
  
  const getRating = int => {
    
    const rating = parseFloat(int).toFixed(1);
    const percentage = (rating / 5) * 100;

    return percentage.toString();
  }

  const purifyReview = review => {
    if(review) {
      const purifiedReview = review.replace(/(<([^>]+)>)/ig, '');

      return purifiedReview;
    }

    return review;
  }

  const formatRevewerName = name => {
    let nameSplit = name.split(' ');

    if(nameSplit.length === 1) return nameSplit[0];

    return nameSplit[0] + ' ' + nameSplit[1].substr(0, 1)+'.';
  }

  return (
    
    <div className="item">
      <div className="item-image">
        <img src={ review.logo || noImage } alt={ review.personal_experience } 
          onError={(e) => e.target.src=`${noImage}`}
        />
      </div>
      <div className="item-content">
        <div className="item-header">
          <div className="item-name">{ (review.review_by)?formatRevewerName(review.review_by):'' }</div>
        </div>
        <div className="item-rating">
          <div className="rating">
            <div className="back" style={{ width: '100%' }}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <div className="front" style={{ width: `${review.rating ? getRating(review.rating) : 0}%` }}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
          </div>
        </div>
        <div className="item-comments">
          <div className="detailed-review">
            <div className="review-summary">
              <div className="summary-item">
                {/* <strong>{ textConfig.personalExperience[props.lang] || textConfig.personalExperience.en }: </strong> */}
                 { purifyReview(review.personal_experience) }
              </div>
              {/* <div className="summary-item">
                <strong>{ textConfig.noteForPsychologist[props.lang] || textConfig.noteForPsychologist.en }: </strong> 
                { purifyReview(review.personal_note_for_consultant) }
              </div>
              <div className="summary-item">
                <strong>{ textConfig.noteForProntopsy[props.lang] || textConfig.noteForProntopsy.en }: </strong> 
                { purifyReview(review.comment_for_prontopsy_staff) }
              </div> */}
            </div>
            {/*<div className="review-rating">
              <div className="item-rating">
                <div className="rating-title">{ consultantLang.skillAndKnowledge[lang] || consultantLang.skillAndKnowledge.en }</div>
                <div className="rating">
                  <div className="back" style={{ width: '100%' }}>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <div className="front" style={{ width: `${review.empathy_and_flexibility ? getRating(review.empathy_and_flexibility) : 0}%` }}>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div className="item-rating">
                <div className="rating-title">{ consultantLang.cordiality[lang] || consultantLang.cordiality.en }</div>
                <div className="rating">
                  <div className="back" style={{ width: '100%' }}>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <div className="front" style={{ width: `${review.communication ? getRating(review.communication) : 0}%` }}>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div className="item-rating">
                <div className="rating-title">{ consultantLang.internetAccess[lang] || consultantLang.internetAccess.en }</div>
                <div className="rating">
                  <div className="back" style={{ width: '100%' }}>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <div className="front" style={{ width: `${review.reliable_and_trustworthy ? getRating(review.reliable_and_trustworthy) : 0}%` }}>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="item-date">
          { review.date }
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(SingleReview);