import React from 'react';
import { connect } from 'react-redux';
import SingleEmail from '../Children/SingleEmail';
import NoDataFound from './NoDataFound';

const SecEmail = ({ emails, isLoaded, config, lang }) => {
  return (
    <div className="col-sm-12 col-md-9 col-lg-10">
      <div className="card-container">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-myaccount-header">
              <div className="lable-text">{ config.email[lang] || config.email.en }</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="emails-list">
              { (emails && emails.length) ?
                emails.map((email, i) => {
                  return (
                    <SingleEmail key={email.id} email={email} />
                  )
                })
                : isLoaded ? <NoDataFound /> : ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    emails: Object.values(state.emails)
  }
}

export default connect(mapStateToProps, null)(SecEmail);