import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { langConfig } from "../../../config/lang";
import { deleteCard } from "../../../actions/payment";
import { toast } from "react-toastify";
import { async } from "q";
import ConfirmationModal from "./Modals/ConfirmationModal";
const StripeCardListItem = ({
  setRadioChecked,
  radioChecked,
  mykey,
  item,
  setCardId,
  setCustomerId,
  isDelete,
  user_id,
  deleteCard,
}) => {
  const handleChange = (event) => {
    console.log(event);
    setCardId(event.target.value);
    setRadioChecked(mykey);
  };
  const [cardId, setCardsId] = useState("");
  const deleteCardHandle = async (e) => {
    setCardsId(e.id);
  };
  // console.log("Delete : " + isDelete);

  return (
    <>
      {item ? (
        <div className="card-item">
          <div className={"custom-radio " + (isDelete ? "disable" : "")}>
            {!isDelete ? (
              <input
                className="custom-radio-input"
                type="radio"
                name="stripeCardRadio"
                value={item.id}
                checked={mykey === radioChecked}
                onChange={handleChange}
              />
            ) : null}
            <div className="custom-radio-label">
              <div className="payment_card_img">
                <div className="img-wrap">
                  <img
                    src={
                      require(`../../../assets/img/cardicon/${item.brand}.png`)
                        .default
                    }
                    alt="icon"
                  />
                </div>

                {/* {item.brand} */}
              </div>
              <div className="text"> XXXX-XXXX-XXXX-{item.last4}</div>
              <div className="">
                {isDelete && (
                  <div
                    className="fa-fw ml-3 redButtonColor "
                    data-toggle="modal"
                    data-target="#ConfirmationModal"
                    onClick={() => deleteCardHandle(item)}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div style={{ position: "relative" }}>
        <ConfirmationModal userId={user_id} cardId={cardId} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { deleteCard })(StripeCardListItem);
