import React from "react";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { socialLogin } from "../../actions/auth";
import { appId, clientId } from "../../config/social";
import LogoFacebook from "../../assets/img/logo_facebook.svg";
import LogoGoogle from "../../assets/img/logo_google.svg";
import history from "../../config/history";

const SocialAuthLogin = ({ lang, socialLogin }) => {
  const responseGoogle = async (response) => {
    console.log(response);

    try {
      if (response && response.profileObj) {
        await socialLogin({
          provider: "GOOGLE",
          lang,
          email: response.profileObj.email,
          providerId: response.profileObj.googleId,
          firstname: response.profileObj.givenName,
          lastname: response.profileObj.familyName,
          name: response.profileObj.name,
          profile_image: response.profileObj.imageUrl,
        });

        history.push(`/${lang}/dashboard`);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const responseFacebook = async (response) => {
    console.log(response);

    try {
      if (response && response.id) {
        // localStorage.setItem('fb_access_token', response.accessToken)
        await socialLogin({
          provider: "FACEBOOK",
          lang,
          email: response.email,
          providerId: response.id,
          firstname: response.name.split(" ")[0],
          lastname: response.name.split(" ")[1],
          name: response.name,
          profile_image: response.picture.data.url,
        });
      }

      history.push(`/${lang}/dashboard`);
    } catch (err) {
      console.log(err.message);
    }
  };

  const config = {
    cWg: {
      en: "Continue with Google",
      es: "Continuar con Google",
      it: "Continua con Google",
    },
    cWf: {
      en: "Continue with Facebook",
      es: "Continuar con Facebook",
      it: "Continua con Facebook",
    },
  };

  return (
    <div className="social-login">
      <FacebookLogin
        appId={appId}
        callback={responseFacebook}
        autoLoad={false}
        render={(renderProps) => (
          <div className="btn-facebook" onClick={renderProps.onClick}>
            <div className="icon">
              <img alt="Facebook logo" src={LogoFacebook} />
            </div>
            <div className="title">{config.cWf[lang] || config.cWf.en}</div>
          </div>
        )}
        fields="name, email, picture"
        scope="public_profile, email"
      />

      <GoogleLogin
        clientId={clientId}
        render={(renderProps) => (
          <div className="btn-google">
            <div className="icon">
              <img alt="Google logo" src={LogoGoogle} />
            </div>
            <div onClick={renderProps.onClick} className="title">
              {config.cWg[lang] || config.cWg.en}
            </div>
          </div>
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </div>
    // <></>
  );
};

export default connect(null, { socialLogin })(SocialAuthLogin);
