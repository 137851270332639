import { combineReducers } from "redux";
import consultantListReducer from "./consultantListReducer";
import consultantDetailsReducer from "./consultantDetailsReducer";
import consultantReviewsReducer from "./consultantReviewsReducer";
import timeSlotReducer from "./timeSlotReducer";


export default combineReducers({
  consultantList: consultantListReducer,
  consultantDetails: consultantDetailsReducer,
  consultantReviews: consultantReviewsReducer,
  timeSlotList: timeSlotReducer
})