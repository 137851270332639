import { combineReducers } from "redux";
import home from './Home';
import others from './Others';
import cms from "./CMS";
import blogs from './Blog';

import consultants from './Consutant';
import auth from './Auth';
import profile from './Profile';
import wishlist from './Wishlist';
import dashboardReducer from "./Dashboard";
import appointmentReducer from "./Appointments";
import clientsReducer from "./Clients";
import emailsReducer from "./Emails";
import emailDetailsReducer from "./Emails/emailDetailsReducer";
import noteListReducer from "./Notes";
import langReducer from "./Lang";
import appointmentMessagesReducer from "./Messages";
import socketReducer from "./socketReducer";
import paymentInfoReducer from "./Profile/paymentInfoReducer";

export default combineReducers({
  auth: auth,
  home,
  others,
  cms,
  blogs,
  consultants,
  profile,
  wishlist,
  dashboard: dashboardReducer,
  appointments: appointmentReducer,
  emails: emailsReducer,
  emailDetails: emailDetailsReducer,
  clients: clientsReducer,
  notes: noteListReducer,
  defaultLang: langReducer,
  appointmentMessages: appointmentMessagesReducer,
  socket: socketReducer,
  paymentInfo: paymentInfoReducer
})