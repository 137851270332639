import React, { useState, useEffect } from 'react';
import About from './About';
import InterventionAndSpecialization from './InterventionAndSpecialization';
import ServicesOffered from './ServicesOffered';
import Reviews from './Reviews';

const DetailsPanel = ({ details, reviews, id, isLoaded, isReviewLoaded, lang }) => {
  const [serviceDetails, setServiceDetails] = useState(null);
  const [reviewDetails, setReviewDetails] = useState(null);

  useEffect(() => {
    loadServiceDetails(details, setServiceDetails);
    setReviewDetails(reviews)
  }, [details, reviews])

  const loadServiceDetails = (details, setServiceDetails) => {
    if(Object.keys(details).length) setServiceDetails(details.service_details);
  }

  return (
    <div className="consultant-details-panel">
      <About isLoaded={isLoaded} details={details} lang={lang} />
      <InterventionAndSpecialization isLoaded={isLoaded} details={details} lang={lang} />
      <ServicesOffered isLoaded={isLoaded} serviceDetails={serviceDetails} lang={lang} />
      <Reviews isReviewLoaded={isReviewLoaded} reviews={reviewDetails} id={id} lang={lang} consultantDetails={true} />
    </div>
  )
}

export default DetailsPanel;