import mapKeys from 'lodash/mapKeys';
import { GET_CONSULTANT_LIST } from '../../actions/types';

// const mapKeys = array => {
//   const obj = {};

//   if(array.length) {
//     array.forEach(item => {
//       obj[item.id] = item;
//     })
//   }

//   return obj;
// }

const consultantListReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_CONSULTANT_LIST:
      return { ...mapKeys(action.payload, 'id') };
      //return mapKeys(action.payload);
    default:
      return state;
  }
}

export default consultantListReducer;