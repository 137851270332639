import React from 'react';
import { langConfig } from '../../config/lang';

const ServiceItem = ({ item, lang }) => {
  const getModes = modes => {
    // const modesArr = modes.split(',');
    let newModesStr = '';

    // if(modesArr && modesArr[1]) {
    //   modesArr.forEach(el => {
    //     if(el === '1') newModesArr.push('Chat');
    //     if(el === '2') el = newModesArr.push('Audio');
    //     if(el === '3') el = newModesArr.push('Video');
    //   });

    //   return newModesArr.join(', ');
    // }

    if(modes.includes('1')) {
      if(!newModesStr) {
        newModesStr = langConfig.chat[lang] || langConfig.chat.en
      } else {
        newModesStr = newModesStr + `, ${langConfig.chat[lang] || langConfig.chat.en}`;
      }
    }

    if(modes.includes('2')) {
      if(!newModesStr) {
        newModesStr = 'Audio'
      } else {
        newModesStr = newModesStr + ', Audio';
      }
    }

    if(modes.includes('3')) {
      if(!newModesStr) {
        newModesStr = 'Video'
      } else {
        newModesStr = newModesStr + ', Video';
      }
    }

    if(newModesStr) return newModesStr;
    

    return null;
  }

  return (
    <div className="item">
      <div className="item-name">{ item.name }</div>
      <div className="item-cost">{ item.currency } { item.price } / min</div>
      <div className="item-mode">
        { getModes(item.mode) }
      </div>
      <div className="item-duration">{ item.duration } min (Max)</div>
    </div>
  )
}

export default ServiceItem
