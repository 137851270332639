import React, { useState, useEffect } from 'react';
const SecShowHidePassword = ({setisPassword,isPassword }) => {

  const [isEye, setisEye] = useState('fal fa-eye-slash');
  const alterShowHide = (event) => {
    if(isPassword == 'password'){
      setisPassword('text');
      setisEye('fal fa-eye')
    }else{
      setisPassword('password'); 
      setisEye('fal fa-eye-slash')
    }
};
  return (
    <button onClick={alterShowHide} className="password-show-hide" type="button"><i className={isEye}></i></button>
  );
}



export default SecShowHidePassword;