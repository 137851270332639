import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";
import history from "../../../config/history";
import { noImage } from "../../../config/host";

import { removeEmail } from "../../../actions/email";
import { toast } from "react-toastify";
const SingleEmail = ({ email, removeEmail, lang, user, socket }) => {
  const deleteSingleEmail = async (e, emailId) => {
    e.preventDefault();

    try {
      // const message = await deleteEmail({ lang, emailId });
      if (socket?.io) {
        socket.emit("deleteEmail", { emailId, userId: user.id });
        removeEmail(emailId);
      }
      // $.dialog({ title: 'Success', content: message });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleRedirect = () => {
    history.push(
      `/${lang}/email-details?email_id=${email.id}&parent_id=${email.parent_id}&receiver_id=${email.receiver_id}&sender_id=${email.sender_id}`
    );
  };

  return (
    <div className="email">
      <div className="e-image">
        <img
          src={
            email.sender_id.toString() === user.id
              ? email.receiver_image
              : email.sender_image
          }
          alt={
            email.sender_id.toString() === user.id
              ? email.receiver_name
              : email.sender_name
          }
          onError={(e) => (e.target.src = `${noImage}`)}
        />
      </div>
      <div
        className="e-content"
        onClick={handleRedirect}
        style={{ cursor: "pointer" }}
      >
        <div className="e-name">
          {email.sender_id.toString() === user.id
            ? email.receiver_name
            : email.sender_name}
        </div>
        <div className="e-text">{email.description}</div>
        <div className="e-date">
          {moment(new Date(email.created_at)).format("H:mm A ")}|
          {moment(new Date(email.created_at)).format(" MMM D")}
        </div>
      </div>
      <div className="e-option">
        <a
          href="#!"
          className="universal-delete-btn"
          onClick={(e) => deleteSingleEmail(e, email.id)}
        >
          <i className="fal fa-fw fa-trash-alt"></i>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket,
  };
};

export default connect(mapStateToProps, { removeEmail })(SingleEmail);
