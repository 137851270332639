import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import ServiceForm from '../Children/Forms/ServiceForm';

const SecServiceInfo = ({ history, setServiceLoaded, config, lang }) => {

  return (
    <AccountContainer title={config.serviceOffered[lang] || config.serviceOffered.en}>
      <ServiceForm history={history} setServiceLoaded={setServiceLoaded} />
    </AccountContainer>
  );
}

export default SecServiceInfo;