import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import { createSession } from "../../asynchronus/Appointment";
import {
  bookImmeadiateAppointment,
  getAppointment,
} from "../../actions/appointments";
import { consultantLang, langConfig, cardConfig } from "../../config/lang";
// import {Elements} from 'react-stripe-elements';
import { loadStripe } from "@stripe/stripe-js";
import StripeFormOnAppointmentBooking from "../Admin/Children/StripeFormOnAppointmentBooking";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import StripeCardListItem from "../Admin/Children/StripeCardListItem";
import { clientId, apiKey } from "../../config/stripe";
import { API_KEY, API_SECRET } from "../../config/opektok";
import { modalLang } from "../../config/lang";
import history from "../../config/history";
import { toast } from "react-toastify";
import VisaImage from "../../assets/img/card/visa.png";
const ConversationModal = ({
  consultant,
  lang,
  user,
  bookImmeadiateAppointment,
  id,
  socket,
  getAppointment,
}) => {
  const stripePromise = loadStripe(apiKey);
  const [serviceType, setServiceType] = useState("");
  const [consent, setConsent] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState("");
  const [audioIconClassname, setAudioIconClassname] = useState("fas fa-phone");
  const [videoIconClassname, setVideoIconClassname] = useState("fas fa-video");
  const [chatIconClassname, setChatIconClassname] = useState(
    "fas fa-comments-alt"
  );
  const [cardId, setCardId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isNewCard, setIsNewCard] = useState(2);
  const [videoCheck, setVideoCheck] = useState("");
  const [audioCheck, setAudioCheck] = useState("");
  const [chatCheck, setChatCheck] = useState("");
  const [consentPrivacy, setConsentPrivacy] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [token, setToken] = useState(null);
  const [radioChecked, setRadioChecked] = useState(0);
  const closeModal = useRef(null);
  const checkConsultantService = (serviceId) => {
    if (consultant?.service_details) {
      const service = consultant?.service_details.find(
        (item) => item.id === "1"
      );

      if (service?.mode.includes(serviceId)) return true;
    }

    return false;
  };

  useEffect(() => {
    const ac = new AbortController();
    if (consultant?.service_details) {
      const service = consultant?.service_details.find(
        (item) => item.id === "1"
      );

      if (service?.mode.includes(3)) {
        setServiceType("3");
        setVideoCheck("checked");
        setAudioCheck("");
        setChatCheck("");
        setAudioIconClassname("fas fa-phone");
        setChatIconClassname("fas fa-comments-alt");
        setVideoIconClassname("far fa-video");
      } else if (service?.mode.includes(2)) {
        setServiceType("2");
        setVideoCheck("");
        setAudioCheck("checked");
        setChatCheck("");
        setAudioIconClassname("far fa-phone");
        setChatIconClassname("fas fa-comments-alt");
        setVideoIconClassname("fas fa-video");
      } else if (service?.mode.includes(1)) {
        setServiceType("1");
        setVideoCheck("");
        setAudioCheck("");
        setChatCheck("checked");
        setAudioIconClassname("fas fa-phone");
        setChatIconClassname("far fa-comments-alt");
        setVideoIconClassname("fas fa-video");
      } else {
        setServiceType("");
        setVideoCheck("");
        setAudioCheck("");
        setChatCheck("");
        setAudioIconClassname("fas fa-phone");
        setChatIconClassname("fas fa-comments-alt");
        setVideoIconClassname("fas fa-video");
      }
    }
    if (consultant?.stripeCardDetails?.length) {
      setCardId(consultant.stripeCardDetails[0].id);
    }
    return () => ac.abort();
  }, [consultant]);
  const getData = (user, consultant, appointment, sessionData) => {
    return {
      appointment_id: appointment.data.appointment_id,
      consultant_timezone: user.timezone,
      from_timezone: user.timezone,
      consultant_name: consultant.consultant_details.name,
      user_name: user.name,
      user_image: user.image,
      consultant_image: consultant.consultant_details.logo,
      service_type: serviceType,
      consultant_id: id,
      user_id: user.id,
      amount: consultant.consultant_details.servicePrice,
      currency: consultant.consultant_details.serviceCurrency,
      appointment_type: "1",
      consultant_status: consultant.consultant_details.activeStatus,
      opentok_sessionId: sessionData ? sessionData.sessionId : null,
      opentok_token: sessionData ? sessionData.token : null,
    };
  };

  const immeadiateAppointment = async () => {
    const currentDate = new Date().toISOString();
    setSpinner(true);

    try {
      if (user?.id && socket?.io) {
        let sessionData = null;
        let opentokSessionId = "";
        let opentokToken = "";

        if (serviceType !== "1") {
          sessionData = await createSession(API_KEY, API_SECRET);
          opentokSessionId = sessionData.sessionId;
          opentokToken = sessionData.token;
        }

        if (isNewCard == 1 && (token == null || token == "")) {
          toast.error(cardConfig.addCard[lang]);
        } else {
          const appointment = await bookImmeadiateAppointment({
            lang,
            userId: user.id,
            serviceType,
            cardId: cardId,
            token: token,
            isNewCard: isNewCard,
            customerId: customerId,
            amount: consultant?.consultant_details?.servicePrice,
            currency: consultant?.consultant_details?.serviceCurrency,
            consultantId: id,
            appointmentDate: currentDate,
            appointmentStartTime: currentDate,
            termsAndCondition: consent ? "1" : null,
            privacyPolicy: consentPrivacy ? "1" : null,
            opentokSessionId,
            opentokToken,
          });

          const checkConsultant = await getAppointment({
            lang,
            appointmentId: appointment.data.appointment_id,
          });

          closeModal.current.click();
          if (serviceType === "1") {
            socket.emit("checkConsultant", checkConsultant);

            history.push(
              `/${lang}/appointment/${appointment.data.appointment_id}`
            );
          } else {
            try {
              const data = getData(user, consultant, appointment, sessionData);
              socket.emit("callConsultant", data);
              socket.emit("checkConsultant", checkConsultant);
              const newWindow = history.push(
                `/${lang}/appointment/${appointment.data.appointment_id}`
              );
              if (!newWindow || typeof newWindow.closed === "undefined") {
                history.push(
                  `/${lang}/appointment/${appointment.data.appointment_id}`
                );
              }
            } catch (err) {
              console.log(err.message);
            }
          }
        }

        setSpinner(false);
      } else {
        setSpinner(false);
        toast.error("You must be logged in.");
      }
    } catch (err) {
      setSpinner(false);
      toast.error(err.message);
    }
  };

  const handleRedirect = (e) => {
    e.preventDefault();

    closeModal.current.click();
    history.push(`/${lang}/terms-conditions`);
  };

  const handleRedirectPrivacy = (e) => {
    e.preventDefault();

    closeModal.current.click();
    history.push(`/${lang}/privacy-policy`);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setButtonDisabled("disabled");
      setIsNewCard(1);
    } else {
      setButtonDisabled("");
      setIsNewCard(2);
    }
  };
  return (
    <div className="conversation-modal">
      <div className="modal fade" id="consultation" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeModal}
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
              <div className="subtitle">
                {serviceType === "2"
                  ? consultantLang.startAudioConversationWith[lang] ||
                    consultantLang.startAudioConversationWith.en
                  : serviceType === "3"
                  ? consultantLang.startVideoConversationWith[lang] ||
                    consultantLang.startVideoConversationWith.en
                  : serviceType === "1"
                  ? consultantLang.startChatConversationWith[lang] ||
                    consultantLang.startChatConversationWith.en
                  : consultantLang.startConversationWith[lang] ||
                    consultantLang.startConversationWith.en}
              </div>
              <div className="title">
                {consultant?.consultant_details?.name}
              </div>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <div
                      className="control-label"
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        // color: 'var(--primaryColor)'
                      }}
                    >
                      {/* SET THE TIME */}
                      {consultant?.consultant_details?.serviceCurrency}
                      <span className="ml-1">
                        {consultant?.consultant_details?.servicePrice}
                        <small>/min</small>
                      </span>
                    </div>
                    <div
                      className="consultant-rate d-none"
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "var(--primaryColor)",
                      }}
                    >
                      {consultant?.consultant_details?.serviceCurrency}{" "}
                      {consultant?.consultant_details?.servicePrice}
                      <small>/min</small>
                    </div>
                  </div>
                  <div className="col-8">
                    <div
                      className="control-label"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      {langConfig.maxCapture[lang] || langConfig.maxCapture.en}
                      {consultant?.consultant_details?.serviceCurrency}
                      <span className="ml-1">
                        {consultant?.consultant_details?.servicePrice
                          ? (
                              consultant?.consultant_details?.servicePrice * 60
                            ).toFixed(2)
                          : ""}
                      </span>
                    </div>
                    <div
                      className="consultant-rate d-none"
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "var(--primaryColor)",
                      }}
                    >
                      {consultant?.consultant_details?.serviceCurrency}{" "}
                      {consultant?.consultant_details?.servicePrice}
                      <small>/min</small>
                    </div>
                  </div>
                  <div className="col-8 d-none">
                    <div className="dropdown d-none">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Time
                      </button>
                      <div className="dropdown-menu d-none">
                        <button type="button" className="dropdown-item">
                          25 min (Total Cost: €25.00)
                        </button>
                        <button type="button" className="dropdown-item">
                          50 min (Total Cost: €50.00)
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="communication-mode">
                {checkConsultantService("2") && (
                  <div className="communication">
                    <input
                      className="communication-input"
                      name="communication-mode"
                      type="radio"
                      value="2"
                      checked={audioCheck === "checked"}
                      onChange={() => {
                        setServiceType("2");
                        setAudioCheck("checked");
                        setVideoCheck("");
                        setChatCheck("");
                        setAudioIconClassname("far fa-phone");
                        setChatIconClassname("fas fa-comments-alt");
                        setVideoIconClassname("fas fa-video");
                      }}
                    />
                    <div className="communication-label">
                      {langConfig.voice[lang] || langConfig.voice.en}
                      <i className={"fa-fw ml-1 " + audioIconClassname}></i>
                    </div>
                  </div>
                )}
                {checkConsultantService("3") && (
                  <div className="communication">
                    <input
                      className="communication-input"
                      name="communication-mode"
                      type="radio"
                      value="3"
                      checked={videoCheck === "checked"}
                      onChange={() => {
                        setServiceType("3");
                        setAudioCheck("");
                        setVideoCheck("checked");
                        setChatCheck("");
                        setAudioIconClassname("fas fa-phone");
                        setChatIconClassname("fas fa-comments-alt");
                        setVideoIconClassname("far fa-video");
                      }}
                    />
                    <div className="communication-label">
                      {langConfig.video[lang] || langConfig.video.en}
                      <i className={"fa-fw ml-1 " + videoIconClassname}></i>
                    </div>
                  </div>
                )}
                {checkConsultantService("1") && (
                  <div className="communication">
                    <input
                      className="communication-input"
                      name="communication-mode"
                      type="radio"
                      value="1"
                      checked={chatCheck === "checked"}
                      onChange={() => {
                        setServiceType("1");
                        setAudioCheck("");
                        setVideoCheck("");
                        setChatCheck("checked");
                        setAudioIconClassname("fas fa-phone");
                        setChatIconClassname("far fa-comments-alt");
                        setVideoIconClassname("fas fa-video");
                      }}
                    />
                    <div className="communication-label">
                      {langConfig.chat[lang] || langConfig.chat.en}
                      <i className={"fa-fw ml-1 " + chatIconClassname}></i>
                    </div>
                  </div>
                )}
              </div>
              <div className="text d-none">
                You will be charged €25.00 at the end of the conversation.
              </div>

              {consultant?.stripeCardDetails?.length ? (
                <div className="form-group stripe-card-list mt-2">
                  {isNewCard == 2 &&
                    consultant?.stripeCardDetails.map((item, i) => {
                      return (
                        <StripeCardListItem
                          key={i}
                          mykey={i}
                          item={item}
                          setRadioChecked={setRadioChecked}
                          radioChecked={radioChecked}
                          setCardId={setCardId}
                          cardId={cardId}
                          setCustomerId={setCustomerId}
                          customerId={customerId}
                        />
                      );
                    })}
                </div>
              ) : (
                ""
              )}
              <div>
                <label className="d-flex align-items-start add-card-sec">
                  <input
                    type="checkbox"
                    name="stripeCardcheckbox1"
                    className="mt-4"
                    value={1}
                    onChange={handleChange}
                  />
                  <div className="right_sec card_list_sec ml-3">
                    <div className="add_card_title">
                      {cardConfig.addNewCard[lang]}
                    </div>
                    <div className="new_card_img d-flex align-items-center">
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/card/visa.png").default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/card/masterCard.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/card/American Express.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/Diners Club.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/Discover.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/JCB.png").default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/UnionPay.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/elo.png").default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/maestro.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/troy.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/hipercard-29.png")
                              .default
                          }
                          alt="card_image"
                        />
                      </div>
                      <div className="img">
                        <img
                          src={
                            require("../../assets/img/cardicon/mnp.png").default
                          }
                          alt="card_image"
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div className="stripe">
                <div className="stripe-label">
                  <i className="fas fa-fw fa-lock mr-2"></i>{" "}
                  <span>
                    Guaranteed <strong>safe & secure</strong> checkout
                  </span>
                </div>
                <div className="stripe-img">
                  <img
                    src={require("../../assets/img/card/stripe.svg").default}
                    alt="stripe"
                  />
                </div>
              </div>
              <div className="form-group mt-5">
                {isNewCard == 1 && (
                  <Elements stripe={stripePromise} options={{ locale: lang }}>
                    <StripeFormOnAppointmentBooking
                      setToken={setToken}
                      lang={lang}
                      isNewCard={isNewCard}
                    />
                  </Elements>
                )}
              </div>
              <div className="form-group checkbox-term mt-2 mb-0">
                <div className="checkbox-term-checkbox">
                  <input
                    className="checkbox-term-input"
                    name="checkbox-terms"
                    type="checkbox"
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                  />
                  <div className="checkbox-term-label"></div>
                </div>
                <div className="checkbox-term-text">
                  {modalLang.rA[lang] || modalLang.rA.en}{" "}
                  <Link
                    to={`/${lang}/terms-conditions`}
                    onClick={handleRedirect}
                  >
                    <u>
                      {modalLang.informedConsent[lang] ||
                        modalLang.informedConsent.en}
                    </u>
                  </Link>{" "}
                  {modalLang.tPc[lang] || modalLang.tPc.en}
                </div>
              </div>
              <div className="form-group checkbox-term">
                <div className="checkbox-term-checkbox">
                  <input
                    className="checkbox-term-input"
                    name="checkbox-terms"
                    type="checkbox"
                    checked={consentPrivacy}
                    onChange={() => setConsentPrivacy(!consentPrivacy)}
                  />
                  <div className="checkbox-term-label"></div>
                </div>
                <div className="checkbox-term-text">
                  {modalLang.iAgree[lang] || modalLang.iAgree.en}{" "}
                  <Link
                    to={`/${lang}/privacy-policy`}
                    onClick={handleRedirectPrivacy}
                  >
                    <u>{modalLang.dApP[lang] || modalLang.dApP.en}</u>
                  </Link>
                </div>
              </div>

              <div
                className="form-group form-group-actions"
                onClick={immeadiateAppointment}
              >
                <button className="btn-submit" type="button" disabled={spinner}>
                  <div className="d-flex align-items-center">
                    <span>
                      {langConfig.continue[lang] || langConfig.continue.en}
                    </span>
                    {spinner && (
                      <div className="loader-spinner ml-2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    socket: state.socket,
  };
};

export default connect(mapStateToProps, {
  bookImmeadiateAppointment,
  getAppointment,
})(ConversationModal);
