import React from "react";
import { connect } from "react-redux";

const SaveChanges = ({ noMargin, smallTop, lang, spinner }) => {
  const config = {
    saveChanges: {
      en: "Save Changes",
      es: "Guardar cambios",
      it: "Salvare le modifiche",
    },
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div
          className={`sec-myaccount-header border-0 ${
            smallTop ? "mt-1" : "mt-3"
          } ${noMargin ? "mb-0" : ""}`}
        >
          <div className="lable-text">
            <button type="submit" className="btn-universal" disabled={spinner}>
              <div className="d-flex align-items-center">
                <span>{config.saveChanges[lang] || config.saveChanges.en}</span>
                {spinner && (
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
  };
};

export default connect(mapStateToProps, null)(SaveChanges);
