import { 
  GET_UPCOMING_APPOINTMENT_COUNT, 
  DECREASE_UPCOMING_APPOINTMENT_COUNT 
} from "../../../actions/types";

const upcomingAppointmentCountReducer = (state = 0, action) => {
  switch(action.type) {
    case GET_UPCOMING_APPOINTMENT_COUNT:
      return action.payload;
    case DECREASE_UPCOMING_APPOINTMENT_COUNT:
      return action.payload ? action.payload : 0;
    default:
      return state;
  }
}

export default upcomingAppointmentCountReducer;