import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { addNote } from '../../../../actions/note';
import { toast } from 'react-toastify';
const NoteModal = ({ lang, userId, consultantId, addNote, config }) => {
  const [spinner, setSpinner] = useState(false);
  const [note, setNote] = useState('');
  const closeRef = useRef(null);

  const add = async () => {
    setSpinner(true);

    try {
      const message = await addNote({ lang, userId, consultantId, note });
      setNote('');

      setSpinner(false);
      toast.success(message);
      closeRef.current.click();

    } catch(err) {
      setSpinner(false);
      toast.error(err.message);
    }
  }

  return (
    <div className="modal fade" id="noteModal" role="dialog" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              { config.addNote[lang] || config.addNote.en }
            </h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"
              ref={closeRef}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="form-input-group">
                <div className="icon"><i className="fal fa-fw fa-user"></i></div>
                <input className="form-control ng-pristine ng-valid ng-touched" 
                  type="text" 
                  value={note}
                  placeholder={ config.note[lang] || config.note.en } 
                  onChange={(e) => setNote(e.target.value)} 
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" type="button" onClick={add}>
              <div className="d-flex align-items-center">
                <span>{ config.save[lang] || config.save.en }</span>
                {
                  spinner &&
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}



export default connect(null, { addNote })(NoteModal);
