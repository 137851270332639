import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getConsultantReviews } from '../../actions/consultant';
import { consultantLang } from '../../config/lang';
import OverallRating from './OverallRating';
import SingleReview from './SingleReview';
import NoDataFound from '../../components/Admin/Sections/NoDataFound';

const Reviews = ({ 
  reviews, list, id, isReviewLoaded, getConsultantReviews, lang, consultantDetails
}) => {
  const [spinner, setSpinner] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstReview, setFirstReview] = useState(null);
  const [allReviews, setAllReviews] = useState(false);

  useEffect(() => {
    const loadFirstReview = () => {
      if(!isLoaded && reviews && reviews.length) {
        setFirstReview(reviews[0]);
        setIsLoaded(true);
      }
    }

    loadFirstReview();
  }, [isLoaded, reviews]);

  useEffect(() => {
    if(allReviews.length && consultantDetails) {
      let reliableAndTrustworthy = 0;
      let communication = 0;
      let empathyAndFlexibility = 0;

      allReviews.forEach(review => {
        reliableAndTrustworthy += parseInt(review.reliable_and_trustworthy);
        communication += parseInt(review.communication);
        empathyAndFlexibility += parseInt(review.empathy_and_flexibility);
      });

      reliableAndTrustworthy = reliableAndTrustworthy / allReviews.length;
      communication = communication / allReviews.length;
      empathyAndFlexibility = empathyAndFlexibility / allReviews.length;

      setFirstReview({  
        ...firstReview,
        reliable_and_trustworthy: `${reliableAndTrustworthy}`,
        communication: `${communication}`,
        empathy_and_flexibility: `${empathyAndFlexibility}`
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ allReviews.length, consultantDetails ]);
  
  const viewAllReviews = async (e, id) => {
    e.preventDefault();
    setSpinner(true);

    try {
      await getConsultantReviews({ userId: id });
      setAllReviews(true);
      setSpinner(false);

    } catch(err) {
      setSpinner(false);
      console.log(err.message);
    }
  }

  const viewMore = {
    en: 'View more',
    es: 'Ver más',
    it: 'SCOPRI DI PIÙ'
  };

  return (
    <>
      <div className="consultant-reviews">
        <div className="title">
          { consultantLang.reviews[lang] || consultantLang.reviews.en }
        </div>
      { (reviews && reviews.length) ?
        <>
          <OverallRating consultantDetails={consultantDetails} rating={firstReview} />
          <div className="review-list">
            { reviews.length > 0 && reviews.sort((a, b) => new Date(b.date) - new Date(a.date) ).map(item => <SingleReview key={item.id} review={item} />) }
          </div>
          {(!list && firstReview && !allReviews) && 
            <div className="review-action">
              <Link className="btn-action" to={`/review-list?id=${id}`}
                onClick={(e) => viewAllReviews(e, id)}
              >
                <div className="d-flex align-items-center">
                  <span>{ viewMore[lang] || viewMore.en }</span>
                  {
                    spinner &&
                    <div className="loader-spinner ml-2">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </Link>
            </div>
          }
        </>
        
        : isReviewLoaded ? <NoDataFound /> : ''
      } 
      </div> 
    </>
  );
}

export default connect(null, { getConsultantReviews })(Reviews);