import React from "react";
import { connect } from "react-redux";
// import $ from 'jquery';
import { closeAccount } from "../../../actions/profile";
import { logout } from "../../../actions/auth";
import CloseAccountModal from "../../Modals/CloseAccountModal";

const SaveAccountChanges = ({
  userId,
  closeAccount,
  logout,
  lang,
  spinner,
}) => {
  const config = {
    saveChanges: {
      en: "Save Changes",
      es: "Guardar cambios",
      it: "Salvare le modifiche",
    },
    closeMyAccount: {
      en: "Close my account",
      es: "cerrar mi cuenta",
      it: "Chiudi il mio account",
    },
  };

  return (
    <div className="row form-row">
      <div className="col-md-6 d-flex">
        <button type="submit" className="btn-universal" disabled={spinner}>
          <div className="d-flex align-items-center">
            <span>{config.saveChanges[lang] || config.saveChanges.en}</span>
            {spinner && (
              <div className="loader-spinner ml-2">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
        </button>
        <button
          type="button"
          className="btn-universal-dark ml-2"
          data-toggle="modal"
          data-target="#closeAccount"
        >
          {config.closeMyAccount[lang] || config.closeMyAccount.en}
        </button>

        <CloseAccountModal userId={userId} />
      </div>
    </div>
  );
};

export default connect(null, { closeAccount, logout })(SaveAccountChanges);
