import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecAddReview from '../../components/Admin/Sections/SecAddReview';
import './css/rating.css';

const AddReview = ({ 
  location, user, nationalities, siteSettings, languages, timezones, countries
}) => {
  const id = new URLSearchParams(location.search).get('appointment_id');
  const consultantId = new URLSearchParams(location.search).get('consultant_id');

  const [loader, setLoader] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      !$.isEmptyObject(siteSettings) && languages.length && timezones.length && countries.length;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries ])

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title="Add Review">
        <SecAddReview id={id} user={user} consultantId={consultantId} />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList)
  }
}

export default connect(mapStateToProps, null)(AddReview);