import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { GET_EMAIL_LIST, GET_EMAIL_DETAIL, DELETE_EMAIL } from '../../actions/types';

const emailsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_EMAIL_LIST:
      return { ...mapKeys(action.payload, 'id') };
    case GET_EMAIL_DETAIL:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_EMAIL:
      return omit(state, action.payload);
    default: 
      return state;
  }
}

export default emailsReducer;