import React from "react";
import { connect } from "react-redux";
import {
  consultantLang,
  dailyLang,
  langConfig,
  insuranceLang,
  acNavLangFormal,
} from "../../../../config/lang";
import { modes } from "../../../../helpers/appointment";
import moment from "moment";
const InsuranceInfo = ({ appointment, services, user, timezones, lang }) => {
  const timezone = timezones.find((item) => item.id === user?.timezone);

  return (
    <>
      <div className="appointment-title">
        {acNavLangFormal.insurance[lang] || acNavLangFormal.insurance.en}
      </div>
      <div className="row form-row">
      
        <div className="col-md-12">
          <div className="form-group">
            <div className="control-label">
              {insuranceLang.iCn[lang] || insuranceLang.iCn.en}
            </div>
            <div className="control-data">
              {(appointment.consultant_insurance_company_name &&  appointment.consultant_insurance_available_status && appointment.consultant_insurance_available_status=='2')
                ? appointment.consultant_insurance_company_name
                : "N.A."}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {insuranceLang.insuranceNumber[lang] || insuranceLang.insuranceNumber.en}
            </div>
            <div className="control-data">
            {appointment.consultant_insurance_number &&  appointment.consultant_insurance_available_status && appointment.consultant_insurance_available_status=='2'
                ? appointment.consultant_insurance_number
                : "N.A."}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="control-label">
              {insuranceLang.iEd[lang] || insuranceLang.iEd.en}
            </div>
            <div className="control-data">
              {appointment.consultant_insurance_expiration &&  appointment.consultant_insurance_available_status && appointment.consultant_insurance_available_status=='2'?
                    appointment.consultant_insurance_expiration:'N.A.'}
              
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    timezones: Object.values(state.cms.timeZoneList),
    
  };
};

export default connect(mapStateToProps, null)(InsuranceInfo);
