import React, { useState, useEffect, useRef } from "react";
import slugify from "slugify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { noImage } from "../../config/host";
import { langConfig, homeLang } from "../../config/lang";
import NotificationList from "./NotificationList";
import ProfileCompleteCheckModal from "../Modals/ProfileCompleteCheckModal";
import { getExtension } from "../../helpers";

const BottomHeader = ({ siteSettings, user, lang, interventionList }) => {
  const pathname = window.location.pathname;

  // const hasUser = localStorage.getItem('userId');
  const [shown, setShown] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const redirectRoute = {
    myaccount: "myaccount",
    fiscalInfo: "fiscal-info",
    paymentInfo: "payment-info",
    memberInfo: "member-info",
    insuranceInfo: "insurance-info",
    biographyInfo: "biography-info",
    interventionSpecialization: "intervention-specialization",
    serviceInfo: "service-info",
    dailySchedule: "daily-schedule",
  };
  const config = { ...homeLang };
  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (user.role == "user") {
        if (
          !user?.stripe_customer_id ||
          user?.personal_info_completed == 2 ||
          user?.fiscal_info_completed == 2
        ) {
          !user?.stripe_customer_id
            ? setRedirectUrl(redirectRoute.paymentInfo)
            : user?.personal_info_completed == 2
            ? setRedirectUrl(redirectRoute.myaccount)
            : user?.fiscal_info_completed == 2
            ? setRedirectUrl(redirectRoute.fiscalInfo)
            : setRedirectUrl("");
          setShown(true);
        } else {
          setShown(false);
        }
      }
      if (user.role == "consultant") {
        if (
          !user?.stripe_account_id ||
          user?.personal_info_completed == 2 ||
          user?.fiscal_info_completed == 2 ||
          user?.service_offered_info_completed == 2 ||
          user?.insurance_info_completed == 2 ||
          user?.membership_info_completed == 2 ||
          user?.daily_schedule_info_completed == 2 ||
          user?.intervention_info_completed == 2 ||
          user?.biography_info_completed == 2
        ) {
          !user?.stripe_account_id
            ? setRedirectUrl(redirectRoute.paymentInfo)
            : user?.personal_info_completed == 2
            ? setRedirectUrl(redirectRoute.myaccount)
            : user?.fiscal_info_completed == 2
            ? setRedirectUrl(redirectRoute.fiscalInfo)
            : user?.membership_info_completed == 2
            ? setRedirectUrl(redirectRoute.memberInfo)
            : user?.insurance_info_completed == 2
            ? setRedirectUrl(redirectRoute.insuranceInfo)
            : user?.intervention_info_completed == 2
            ? setRedirectUrl(redirectRoute.interventionSpecialization)
            : user?.service_offered_info_completed == 2
            ? setRedirectUrl(redirectRoute.serviceInfo)
            : user?.daily_schedule_info_completed == 2
            ? setRedirectUrl(redirectRoute.dailySchedule)
            : user?.biography_info_completed == 2
            ? setRedirectUrl(redirectRoute.biographyInfo)
            : setRedirectUrl("");
          setShown(true);
        } else {
          setShown(false);
        }
      }
    }
  }, [user]);
  useEffect(() => {
    if (pathname && redirectUrl) {
      if (getExtension(pathname) !== redirectUrl) {
        setIsModalOpen(true);
      }
    }
  }, [pathname, redirectUrl]);

  return (
    <div className="header-bottom-wrap">
      <div className="container">
        <div className="header-bottom-content">
          <div className="brand">
            <Link to={`/${lang}`} className="logo">
              {siteSettings?.logo && <img src={siteSettings.logo} alt="Logo" />}
            </Link>
          </div>
          <div className="navigation">
            <div className="navigation-wrap">
              <ul className="navigation-menu">
                <li className={pathname === `/${lang}` ? "active" : ""}>
                  <Link to="/" className="menu-item">
                    {config.whatIsProntopsy[lang] || config.whatIsProntopsy.en}
                  </Link>
                </li>
                <li
                  className={pathname === `/${lang}/consultant` ? "active" : ""}
                >
                  <Link to={`/${lang}/consultant`} className="menu-item">
                    {config.findAPsychologist[lang] ||
                      config.findAPsychologist.en}
                  </Link>
                </li>
                <li
                  className={
                    pathname === `/${lang}/interventions` ? "active" : ""
                  }
                >
                  <div className="dropdown intervention-dropdown">
                    <Link to={`/${lang}/interventions`} className="menu-item">
                      {config.areaOfIntervention[lang] ||
                        config.areaOfIntervention.en}
                    </Link>
                    <button
                      type="button"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    ></button>
                    <div className="dropdown-menu">
                      {interventionList.length > 0 &&
                        interventionList.map((item) => {
                          return (
                            <Link
                              key={item.id}
                              to={`/${lang}/intervention-detail/${
                                item.id
                              }/${slugify(item.name.toLowerCase())}`}
                              className="dropdown-item"
                            >
                              {item.name}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </li>
                <li
                  className={
                    pathname === `/${lang}/how-it-works-consultant`
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={`/${lang}/how-it-works-consultant`}
                    className="menu-item"
                  >
                    {config.howItWorks[lang] || config.howItWorks.en}
                  </Link>
                </li>
                <li className={pathname === `/${lang}/blog` ? "active" : ""}>
                  <Link to={`/${lang}/blog`} className="menu-item">
                    Blog
                  </Link>
                </li>
                {localStorage.getItem("userId") ? (
                  <li
                    className={
                      pathname === `/${lang}/myaccount` ? "active" : ""
                    }
                  >
                    <Link
                      className="menu-item user-item d-block d-xl-none"
                      to={`/${lang}/myaccount`}
                    >
                      {config.myAccount[lang] || config.myAccount.en}
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              {!localStorage.getItem("userId") ? (
                <ul className="authentication-menu">
                  <li className="signin">
                    <Link to={`/${lang}/login`} className="menu-item">
                      {langConfig.login[lang] || langConfig.signup.en}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/register`} className="menu-item">
                      {langConfig.signup[lang] || langConfig.signup.en}
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="authentication-menu">
                  <li className="user-account">
                    <Link className="user-item" to={`/${lang}/myaccount`}>
                      <div className="user-img">
                        <img
                          alt="Profile logo img"
                          src={user?.image || noImage}
                          onError={(e) => (e.target.src = `${noImage}`)}
                        />
                      </div>
                      <span className="">
                        {config.myAccount[lang] || config.myAccount.en}
                      </span>
                    </Link>
                  </li>
                  <NotificationList />
                </ul>
              )}
              <a
                href="#!"
                className="btn-menu"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fal fa-fw fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {user && isModalOpen && (
        <ProfileCompleteCheckModal
          setShown={setShown}
          shown={shown}
          lang={lang}
          redirectUrl={redirectUrl}
          redirectRoute={redirectRoute}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.loggedIn,
    user: state.auth.user,
    siteSettings: state.cms.siteSettings,
    lang: state.defaultLang,
    interventionList: Object.values(state.home.homeInterventions),
  };
};

export default connect(mapStateToProps, null)(BottomHeader);
