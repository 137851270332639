import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { langConfig, modalLang } from "../../../../config/lang";
import { deleteCard } from "../../../../actions/payment";
import { toast } from "react-toastify";
const ConfirmationModal = ({ lang, userId, cardId, deleteCard, config }) => {
  const [spinner, setSpinner] = useState(false);
  const closeRef = useRef(null);

  const closeModal = async () => {
    closeRef.current.click();
  };
  const deleteCardData = async () => {
    setSpinner(true);

    try {
      const cardData = await deleteCard({ id: cardId, user_id: userId });
      toast.success(cardData?.message);
      setSpinner(false);
      closeRef.current.click();
    } catch (err) {
      setSpinner(false);
      // toast.error(err.message);
      // closeRef.current.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="ConfirmationModal"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {langConfig.wantToDelete[lang] || langConfig.wantToDelete.en}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              ref={closeRef}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-footer">
            <button
              className="btn-universal"
              type="button"
              onClick={closeModal}
            >
              <div className="d-flex align-items-center">
                <span>{modalLang.cancel[lang] || modalLang.cancel.en}</span>
              </div>
            </button>
            <button
              className="btn-universal"
              type="button"
              onClick={deleteCardData}
              disabled={spinner}
            >
              <div className="d-flex align-items-center">
                <span>{modalLang.confirm[lang] || modalLang.confirm.en}</span>
                {spinner && (
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { deleteCard })(ConfirmationModal);
