import {
  // GET_APPOINTMENTS,
  GET_PAST_APPOINTMENTS,
  GET_PAST_APPOINTMENT_COUNT,
  GET_PAST_APPOINTMENT,
  GET_INPROGRESS_APPOINTMENTS,
  GET_INPROGRESS_APPOINTMENT_COUNT,
  GET_INPROGRESS_APPOINTMENT,
  GET_UPCOMING_APPOINTMENTS,
  GET_UPCOMING_APPOINTMENT_COUNT,
  GET_UPCOMING_APPOINTMENT,
  GET_CLIENTS,
  REMOVE_INPROGRESS_APPOINTMENT,
  GET_BLOCKED_USERS,
  BLOCK_USER,
  UNBLOCK_USER,
  GET_LAST_APPOINTMENT,
  SET_LAST_APPOINTMENT,
  REMOVE_APPOINTMENT,
  REMOVE_UPCOMING_APPOINTMENT,
  GET_SOCKET,
  DECREASE_UPCOMING_APPOINTMENT_COUNT,
  GET_MODIFIED_INPROGRESS_APPOINTMENTS,
  GET_MODIFIED_UPCOMING_APPOINTMENTS,
  GET_APPOINTMENT_NOTE,
} from "./types";
import {
  getAppointmentsAsync,
  getAppointmentAsync,
  getClientListAsync,
  blockUserManageAsync,
  getBlockedUserListAsync,
  bookImmeadiateAppointmentAsync,
  endAppointmentMailSendAsync,
  endAppointmentAsync,
  scheduleAppointmentAsync,
  consultantJoinAsync,
  consultantRejectAsync,
  consultantLeaveAsync,
  deleteAppointmentAsync,
  sockConnect,
  refundAmountAsync,
  userJoinLeaveAsync,
  getPastAppointmentAsync,
  getInProgressAppointmentAsync,
  getUpcomingAppointmentAsync,
  addAppointmentNodeAsync,
} from "../asynchronus/Appointment";

export const getAppointments =
  (user, signal = null, last = false) =>
  async (dispatch) => {
    try {
      const appointments = await getAppointmentsAsync(user, signal);
      let currentDateTime = new Date().toISOString();
      currentDateTime = currentDateTime.replace("Z", "");
      currentDateTime = new Date(currentDateTime);

      if (appointments.status === 200) {
        const pastAppointments = appointments.data.filter((item) => {
          return (
            new Date(`${item.appointment_end_time}`) < currentDateTime ||
            item.status === "3"
          );
        });

        const inprogressAppointments = appointments.data.filter((item) => {
          return (
            (new Date(`${item.appointment_start_time}`) <= currentDateTime &&
              new Date(`${item.appointment_end_time}`) >= currentDateTime) ||
            (!item.appointment_end_time && item.status !== "3")
          );
        });

        const upcomingAppointments = appointments.data.filter((item) => {
          return (
            new Date(`${item.appointment_start_time}`) > currentDateTime &&
            new Date(`${item.appointment_end_time}`) > currentDateTime &&
            item.status !== "3"
          );
        });

        dispatch({ type: GET_PAST_APPOINTMENTS, payload: pastAppointments });
        dispatch({
          type: GET_PAST_APPOINTMENT_COUNT,
          payload: pastAppointments.length,
        });

        dispatch({
          type: GET_INPROGRESS_APPOINTMENTS,
          payload: inprogressAppointments,
        });
        dispatch({
          type: GET_INPROGRESS_APPOINTMENT_COUNT,
          payload: inprogressAppointments.length,
        });

        dispatch({
          type: GET_UPCOMING_APPOINTMENTS,
          payload: upcomingAppointments,
        });
        dispatch({
          type: GET_UPCOMING_APPOINTMENT_COUNT,
          payload: upcomingAppointments.length,
        });

        if (last) {
          dispatch({
            type: GET_LAST_APPOINTMENT,
            payload: appointments.data[0],
          });
        }

        // dispatch({ type: GET_APPOINTMENTS, payload: appointments.data });
        return appointments.data;
      } else {
        throw new Error(appointments.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const modifyAppointments = (appointments, currentTime) => (dispatch) => {
  const pastAppointments = appointments.filter((item) => {
    return (
      new Date(`${item.appointment_end_time}`) < currentTime ||
      item.status === "3"
    );
  });

  const inprogressAppointments = appointments.filter((item) => {
    return (
      (new Date(`${item.appointment_start_time}`) <= currentTime &&
        new Date(`${item.appointment_end_time}`) >= currentTime) ||
      (!item.appointment_end_time && item.status !== "3")
    );
  });

  const upcomingAppointments = appointments.filter((item) => {
    return (
      new Date(`${item.appointment_start_time}`) > currentTime &&
      new Date(`${item.appointment_end_time}`) > currentTime &&
      item.status !== "3"
    );
  });

  // console.log('inprogressAppointments', inprogressAppointments)

  dispatch({ type: GET_PAST_APPOINTMENTS, payload: pastAppointments });
  dispatch({
    type: GET_PAST_APPOINTMENT_COUNT,
    payload: pastAppointments.length,
  });

  dispatch({
    type: GET_MODIFIED_INPROGRESS_APPOINTMENTS,
    payload: inprogressAppointments,
  });
  dispatch({
    type: GET_INPROGRESS_APPOINTMENT_COUNT,
    payload: inprogressAppointments.length,
  });

  dispatch({
    type: GET_MODIFIED_UPCOMING_APPOINTMENTS,
    payload: upcomingAppointments,
  });
  dispatch({
    type: GET_UPCOMING_APPOINTMENT_COUNT,
    payload: upcomingAppointments.length,
  });
};

export const getPastAppointments =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const appointments = await getPastAppointmentAsync(body, signal);

      if (appointments.status === 200) {
        dispatch({ type: GET_PAST_APPOINTMENTS, payload: appointments.data });
        dispatch({
          type: GET_PAST_APPOINTMENT_COUNT,
          payload: appointments.count,
        });
      } else {
        throw new Error(appointments.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getInProgressAppointments =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const appointments = await getInProgressAppointmentAsync(body, signal);

      if (appointments.status === 200) {
        dispatch({
          type: GET_INPROGRESS_APPOINTMENTS,
          payload: appointments.data,
        });
        dispatch({
          type: GET_INPROGRESS_APPOINTMENT_COUNT,
          payload: appointments.count,
        });
      } else {
        throw new Error(appointments.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getUpcomingAppointments =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const appointments = await getUpcomingAppointmentAsync(body, signal);

      if (appointments.status === 200) {
        dispatch({
          type: GET_UPCOMING_APPOINTMENTS,
          payload: appointments.data,
        });
        dispatch({
          type: GET_UPCOMING_APPOINTMENT_COUNT,
          payload: appointments.count,
        });
      } else {
        throw new Error(appointments.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getAppointment =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const appointment = await getAppointmentAsync(body, signal);
      let currentDateTime = new Date().toISOString();
      currentDateTime = currentDateTime.replace("Z", "");
      currentDateTime = new Date(currentDateTime);

      if (appointment.status === 200) {
        if (
          new Date(`${appointment.data.appointment_end_time}`) <
            currentDateTime ||
          appointment.data.status === "3"
        ) {
          dispatch({ type: GET_PAST_APPOINTMENT, payload: appointment.data });
        } else if (
          (new Date(`${appointment.data.appointment_start_time}`) <=
            currentDateTime &&
            new Date(`${appointment.data.appointment_end_time}`) >=
              currentDateTime) ||
          (!appointment.data.appointment_end_time &&
            appointment.data.status !== "3")
        ) {
          dispatch({
            type: GET_INPROGRESS_APPOINTMENT,
            payload: appointment.data,
          });
        } else if (
          new Date(`${appointment.data.appointment_start_time}`) >
            currentDateTime &&
          new Date(`${appointment.data.appointment_end_time}`) >
            currentDateTime &&
          appointment.data.status !== "3"
        ) {
          dispatch({
            type: GET_UPCOMING_APPOINTMENT,
            payload: appointment.data,
          });
        }

        return appointment.data;
      } else {
        throw new Error(appointment.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const addAppointmentNode = (body) => async (dispatch) => {
  try {
    const note = await addAppointmentNodeAsync(body);

    if (note.status === 200) {
      dispatch({ type: GET_APPOINTMENT_NOTE, payload: note.data });

      return note.message;
    } else {
      throw new Error(note.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
export const getClientList =
  (user, signal = null) =>
  async (dispatch) => {
    try {
      const clients = await getClientListAsync(user, signal);

      if (clients.status === 200) {
        dispatch({ type: GET_CLIENTS, payload: clients.data });
      } else {
        throw new Error(clients.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const blockUserManage =
  (user, blockStatus = false) =>
  async (dispatch) => {
    try {
      const block = await blockUserManageAsync(user);

      if (block.status === 200) {
        if (!blockStatus) {
          dispatch({
            type: BLOCK_USER,
            payload: { id: block.data.id, user_id: user.userId },
          });
        } else {
          dispatch({ type: UNBLOCK_USER, payload: user.userId });
        }

        return block.message;
      } else {
        throw new Error(block.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getBlockedUserList =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const blockedUsers = await getBlockedUserListAsync(body, signal);

      if (blockedUsers.status === 200) {
        dispatch({ type: GET_BLOCKED_USERS, payload: blockedUsers.data });
      } else {
        throw new Error(blockedUsers.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const endAppointmentMailSend = (body) => async (dispatch) => {
  try {
    const appointment = await endAppointmentMailSendAsync(body);

    if (appointment.status === 200) {
      return appointment;
    } else {
      throw new Error(appointment.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
export const bookImmeadiateAppointment = (body) => async (dispatch) => {
  try {
    const appointment = await bookImmeadiateAppointmentAsync(body);

    if (appointment.status === 200) {
      return appointment;
    } else {
      throw new Error(appointment.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const endAppointment =
  (body, signal = null, ids) =>
  async (dispatch, getState) => {
    try {
      const appointment = await endAppointmentAsync(body, signal);

      if (appointment.status === 200) {
        dispatch({ type: REMOVE_INPROGRESS_APPOINTMENT, payload: ids });
        dispatch({ type: REMOVE_UPCOMING_APPOINTMENT, payload: ids });
        return appointment;
      } else {
        throw new Error(appointment.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const removeInprogressAppointment = (ids) => (dispatch) => {
  dispatch({ type: REMOVE_INPROGRESS_APPOINTMENT, payload: ids });
};

export const removeUpcomingAppointment = (ids) => (dispatch) => {
  dispatch({ type: REMOVE_UPCOMING_APPOINTMENT, payload: ids });
};

export const scheduleAppointment = (body) => async (dispatch) => {
  try {
    const appointment = await scheduleAppointmentAsync(body);

    if (appointment.status === 200) {
      const appointmentId = appointment.data.appointmentId;
      await getAppointment({ lang: body.lang, appointmentId });

      return { message: appointment.message, data: appointment.data };
    } else {
      throw new Error(appointment.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const consultantJoin =
  (body, signal = null) =>
  async (dispatch, getState) => {
    try {
      const appointment = await consultantJoinAsync(body, signal);

      if (appointment.status === 200) {
        await getAppointment({
          lang: getState().defaultLang,
          appointmentId: body.appointmentId,
        });
        return appointment.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const consultantLeave =
  (body, signal = null) =>
  async (dispatch, getState) => {
    try {
      const appointment = await consultantLeaveAsync(body, signal);

      if (appointment.status === 200) {
        await getAppointment({
          lang: getState().defaultLang,
          appointmentId: body.appointmentId,
        });
        return appointment.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const consultantReject =
  (body, signal = null) =>
  async (dispatch, getState) => {
    try {
      const appointment = await consultantRejectAsync(body, signal);

      if (appointment.status === 200) {
        await getAppointment({
          lang: getState().defaultLang,
          appointmentId: body.appointmentId,
        });
        return appointment.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const setLastAppointment = (appointment) => (dispatch) => {
  dispatch({ type: SET_LAST_APPOINTMENT, payload: appointment });
};

export const deleteAppointment =
  (appointmentId) => async (dispatch, getState) => {
    try {
      const appointment = await deleteAppointmentAsync({
        lang: getState().defaultLang,
        appointmentId,
      });

      dispatch({
        type: DECREASE_UPCOMING_APPOINTMENT_COUNT,
        payload: getState().appointments.counts.upcomingCount - 1,
      });

      dispatch({ type: REMOVE_APPOINTMENT, payload: appointmentId });
      return appointment.message;
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const decreaseUpcomingAppointmentCount = (dispatch, getState) => {
  dispatch({
    type: DECREASE_UPCOMING_APPOINTMENT_COUNT,
    payload: getState().appointments.counts.upcomingCount - 1,
  });
};

export const refundAmount = (body) => async (dispatch) => {
  try {
    const res = await refundAmountAsync(body);

    if (res.status === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const userJoinLeave =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await userJoinLeaveAsync(body, signal);

      if (res.status === 200) {
        return res.message;
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

export const getSocket = () => (dispatch) => {
  const socket = sockConnect();
  dispatch({ type: GET_SOCKET, payload: socket });
};
