import React, { useRef } from 'react';
import slugify from 'slugify';
import { Link } from 'react-router-dom';
import history from '../../config/history';

const InterventionItem = ({ item, lang }) => {
  // const [background, setBackground] = useState('#fff');
  // const [buttonBackground, setButtonBackground] = useState(item.primary_color);
  // const [buttonColor, setButtonColor] = useState('#fff');

  const anchorRef = useRef(null);

  // const onHover = () => {
  //   setBackground(item.primary_color || 'var(--primaryColor)');
  //   setButtonBackground('#fff');
  //   setButtonColor('var(--secondayColor)');
  // }

  // const onLeave = () => {
  //   setBackground('#fff');
  //   setButtonBackground(item.primary_color);
  //   setButtonColor('#fff');
  // }

  const handleRedirect = e => {
    if(e.target !== anchorRef.current) {
      history.push(`/${lang}/intervention-detail/${item.id}/${slugify(item.name.toLowerCase(), '-')}`)
    }
  }

  const config = {
    details: {
      en: 'Details',
      es: 'Detalles',
      it: 'Dettagli'
    },
    consultantRespondingText: {
      en: 'CONSULTANTS ARE RESPONDING TO USERS',
      es: 'CONSULTORES ESTÁN RESPONDIENDO A LOS USUARIOS.',
      it: 'CONSULENTI STANNO RISPONDENDO AGLI UTENTI'
    }
  }
  
  return (
    <div 
      className="item pointer" 
      /*style={{ backgroundColor: background, color: item.primary_color || 'var(--primaryColor)' }}*/
      
      //onMouseEnter={onHover}
      //onMouseLeave={onLeave}
      onClick={handleRedirect}
    >
			<div className="item-count">
        { item.totalcount }
      </div>
      <div className="item-sub-title">{ config.consultantRespondingText[lang] || config.consultantRespondingText.en }</div>
      <div className="item-title">{ item.name }</div>

      <div>
        <Link 
          to={`/${lang}/intervention-detail/${item.id}/${slugify(item.name.toLowerCase(), '-')}`} 
          className="btn ml-2" ref={anchorRef} >
          { config.details[lang] || config.details.en }
        </Link>
      </div>
    </div>
  )
}

export default InterventionItem
