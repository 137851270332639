import React from 'react';

const Loader = ({ loaded }) => {
  return (
    
    <div className="preloader" style={{ display: `${ !loaded ? 'block'  : 'none' }` }}>

      <div className="preloader-wrap">
        <div className="loading-box">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-bricks" style={{ background: 'none' }}><rect fill="#2f2f95" x="15" y="15" width="30" height="30" rx="4" ry="4"><animate attributeName="x" calcMode="linear" values="15;55;55;55;55;15;15;15;15" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="2.5" begin="-2.2916666666666665s" repeatCount="indefinite"></animate><animate attributeName="y" calcMode="linear" values="15;55;55;55;55;15;15;15;15" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="2.5" begin="-1.6666666666666665s" repeatCount="indefinite"></animate></rect><rect fill="#ff2cb0" x="53.3429" y="55" width="30" height="30" rx="4" ry="4"><animate attributeName="x" calcMode="linear" values="15;55;55;55;55;15;15;15;15" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="2.5" begin="-1.4583333333333335s" repeatCount="indefinite"></animate><animate attributeName="y" calcMode="linear" values="15;55;55;55;55;15;15;15;15" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="2.5" begin="-0.8333333333333333s" repeatCount="indefinite"></animate></rect><rect fill="#00C94D" x="55" y="15" width="30" height="30" rx="4" ry="4"><animate attributeName="x" calcMode="linear" values="15;55;55;55;55;15;15;15;15" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="2.5" begin="-0.625s" repeatCount="indefinite"></animate><animate attributeName="y" calcMode="linear" values="15;55;55;55;55;15;15;15;15" keyTimes="0;0.083;0.25;0.333;0.5;0.583;0.75;0.833;1" dur="2.5" begin="0s" repeatCount="indefinite"></animate></rect></svg> */}
      </div>
    </div>
  )
}

export default Loader;