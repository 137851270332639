import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { usePageVisibility } from 'react-page-visibility';
import { 
	endAppointment, 
	removeInprogressAppointment, 
	removeUpcomingAppointment,
	consultantJoin,
	userJoinLeave,
	consultantLeave
} from '../../actions/appointments';
import { updateActiveStatus } from '../../actions/profile';
import { convertIsoTime } from '../../helpers';
import history from '../../config/history';
import { toHHMMSS } from '../../helpers';
// import { noImage } from '../../config/host';
import disconnect from '../../assets/audio/disconnect.mp3';
import EndAppointmentModal from '../Modals/EndAppointmentModal';
import { toast } from 'react-toastify';

const Header = ({ 
	consultant, appointment, user, lang, endAppointment,seconds, updateActiveStatus,
	removeInprogressAppointment, removeUpcomingAppointment, setAppointmentEnded, socket,
	setJoin, consultantJoin, userJoinLeave
}) => {
	const [name, setName] = useState('');

	const online = user?.id === appointment?.consultant_id ? 
									appointment?.client_active_status === '1'
	: consultant?.consultant_details?.activeStatus === '1';

	const isVisible = usePageVisibility();

	const [spinner, setSpinner] = useState(false);
	const [shown, setShown] = useState(false);
	const [browserClosed, setBrowserClosed] = useState(false);
	const [lastActive, setLastActive] = useState(null);
	const [joined, setJoined] = useState(false);

	const closeRef = useRef(null);
	const endAppointmentButtonRef = useRef(null);

	useEffect(() => setShown(false), []);

	const endOngoingAppointment = async e => {
		if(e) e.preventDefault();
		setSpinner(true);
		
		try {
			setAppointmentEnded(true);

			const endTime = new Date().toISOString();

			const endAppointmentRes = await endAppointment({
				lang, appointmentEndTime: endTime,
				reject: appointment?.consultant_join !== '1' ? '1' : '2',
				appointmentId: appointment.appointment_id,
				perMinuteAmount: consultant.consultant_details.servicePrice
			}, null, appointment.ids);

			if(socket?.io) {
				socket.emit('appintmentDetails', { 
					...appointment, appointment_end_time: endTime,
					success_message: endAppointmentRes.message,
					ended: true
				});

				socket.emit('endAppointment', { ...appointment });

				socket.emit('updateConsultantStatus', { 
          userId: appointment.consultant_id, activeStatus: '1', isManual: '2'
        });
			}

			if(user?.role === 'consultant') {
				await updateActiveStatus({
					lang, userId: user?.id, activeStatus: '1', isManual: '2'
				})
			}

			localStorage.removeItem('opentok_sessionId');
      localStorage.removeItem('opentok_token');

			removeInprogressAppointment(appointment.ids);
			removeUpcomingAppointment(appointment.ids);
			toast.error(endAppointmentRes.message);
			new Audio(disconnect).play();
			setSpinner(false);
			history.push(`/${lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`);
		} catch(err) {
			setAppointmentEnded(true);

			const endTime = new Date().toISOString();

			const endAppointmentRes = await endAppointment({
				lang, appointmentEndTime: endTime,
				reject: '1',
				appointmentId: appointment.appointment_id,
				perMinuteAmount: consultant.consultant_details.servicePrice
			}, null, appointment.ids);

			if(socket?.io) {
				socket.emit('appintmentDetails', { 
					...appointment, appointment_end_time: endTime,
					success_message: endAppointmentRes.message,
					ended: true
				});

				socket.emit('endAppointment', { ...appointment });

				socket.emit('updateConsultantStatus', { 
          userId: appointment.consultant_id, activeStatus: '1', isManual: '2'
        });
			}

			if(user?.role === 'consultant') {
				await updateActiveStatus({
					lang, userId: user?.id, activeStatus: '1', isMaunal: '2'
				})
			} 

			localStorage.removeItem('opentok_sessionId');
      localStorage.removeItem('opentok_token');

			removeInprogressAppointment(appointment.ids);
			removeUpcomingAppointment(appointment.ids);

			toast.error(endAppointmentRes.message);
			new Audio(disconnect).play();
			setSpinner(false);
			history.push(`/${lang}/appointment-details/${appointment?.appointment_id}?review_tab=true`);
			// $.dialog({ title: 'Failure', content: err.message });
		}
	}

	const joinLeave = async () => {
    try {
      if(user?.role === 'consultant') {
        if(socket?.io) {
          socket.emit({ ...appointment, consultant_join: '2' });
        }

        await consultantLeave({
          lang: lang, appointmentId: appointment.appointment_id,
          consultantJoin: '2', consultantJoinTime: appointment.consultant_join_time
        });
      }

      if(user?.role === 'user') {
        if(socket?.io) {
          socket.emit({ ...appointment, user_join: '2' });
        }

        await userJoinLeave({
          appointmentId: appointment?.appointment_id,userJoin: '2'
        });
      }

			setJoin(false);
    } catch(err) {
      console.log(err.message);
    }
  }

	useEffect(() => {
		if(user?.id && appointment?.consultant_id && !name) {
			if(user?.id === appointment?.consultant_id) {
				setName(appointment.user_profile_name)
			} else {
				setName(appointment.consultant_profile_name)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ user?.id, appointment?.consultant_id ]);

	useEffect(() => {
    if(!isVisible && !appointment?.ended && !shown && !browserClosed) {
			// console.log('clicked')
      // endAppointmentButtonRef.current.click();
      setShown(true);
    }

  	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ appointment?.ended, browserClosed, shown, isVisible ]);

	useEffect(() => {
    if(socket?.io) {
      socket.on('browser-closed', (res) => {
        if(res.appointmentId === appointment?.appointment_id) {
          if(res.userId !== user?.id && !browserClosed) {
						setBrowserClosed(true);
						setJoined(false);
					}

					if(res.userId !== user?.id) {
            setLastActive(new Date(res.last_active));
          }
        }
      });

			socket.on('browser-loaded', (res) => {
        if(res.appointmentId === appointment?.appointment_id) {
          if(res.userId !== user?.id) {
            setLastActive(null);
            setJoined(true);
          }
        }
      })
    }

    if(browserClosed && !shown) setShown(true);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ appointment?.appointment_id, user?.id, browserClosed, shown, socket ]);

	useEffect(() => {
    // const handleEndAppointment = async () => await endOngoingAppointment();

    if(lastActive && !joined) {
      if(((convertIsoTime().getTime() - lastActive.getTime()) / 1000) > 17) {
        // handleEndAppointment();
        console.log((convertIsoTime().getTime() - lastActive.getTime()) / 1000)
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lastActive, joined, seconds ]);

	// Internet speed detection
	// useEffect(() => {
	// 	const image = noImage;
	// 	const byteSize = 9283;

		
	// }, []);

	const textConfig = {
		message: {
		  en: 'Message',
		  es: 'Mensaje',
		  it: 'Messaggio'
		},
		disconnectText: {
		  en: 'Disconnect',
		  es: 'Desconectar',
		  it: 'Disconnetti'
		},
		rejectedText: {
		  en: 'Consultant has rejected the call.',
		  es: 'El consultor ha rechazado la convocatoria.',
		  it: 'Il consulente ha rifiutato la chiamata.'
		},
		waitingUser: {
		  en: 'Waiting for the user to join.',
		  es: 'Esperando a que el usuario se una.',
		  it: `In attesa che l'utente si unisca.`
		},
		waitingConsultant: {
		  en: 'Waiting for the consultant to join.',
		  es: 'Esperando a que el consultor se una.',
		  it: 'In attesa che il consulente si unisca.'
		}
	  }

  return (
    <div className="message-header">
			<div className="message-user">
				<div className="title">{ name }</div>
				<div className={`status ${online ? 'online' : ''} d-none`}>
					{ online ? 'Online' : 'Offline' }
				</div>
			</div>
			<div className="message-header-action">
				{/* <a href="#!" className="btn-action btn-bold d-none">
					<i className="fas fa-fw fa-video"></i>
				</a> */}
        <div className="call-timer">{ seconds ? toHHMMSS(seconds) :  '' }</div>
				{/* <span className="mr-1"
					style={{ 
						fontSize: '20px', fontWeight: '700', color: 'var(--primaryColor)',
						lineHeight: '1', letterSpacing: '-0.5px', alignSelf: 'center'
					}}
				>
					{ seconds ? toHHMMSS(seconds) :  '' }
				</span> */}
				{/* <button className="btn btnLeave mx-3 d-none" 
					onClick={joinLeave}
				>
					Leave call
				</button> */}
        <button type="button" className="btn-action" data-toggle="modal" 	
					data-target="#endAppointment" 
					data-keyboard="false" 
					data-backdrop="static"
					ref={endAppointmentButtonRef}>
          <i className="fas fa-fw fa-phone-slash text-danger"></i>
          <span> { textConfig.disconnectText[lang] || textConfig.disconnectText.en }</span>
          </button>
				{/* <a 
					href="#!" 
					className="btn-action btn-bold" 
					data-toggle="modal" 	
					data-target="#endAppointment" 
					data-keyboard="false" 
					data-backdrop="static"
					ref={endAppointmentButtonRef}
				>
					<i className="fas fa-fw fa-phone-slash text-danger"></i>
					
				</a> */}
				<EndAppointmentModal closeRef={closeRef} spinner={spinner} 
					shown={shown}
					setShown={setShown} 
					endOngoingAppointment={endOngoingAppointment}
					setBrowserClosed={setBrowserClosed}
					lang={lang}
				/>

				<div className="dropdown d-none">
					<a href="!#" className="btn-action dropdown-toggle" data-toggle="dropdown"><i className="far fa-fw fa-ellipsis-v"></i></a>
					<div className="dropdown-menu">
						<a className="dropdown-item" href="!#">Action</a>
						<a className="dropdown-item" href="!#">Another action</a>
						<a className="dropdown-item" href="!#">Something else here</a>
					</div>
				</div>
			</div>
		</div>
  )
}

const mapStateToProps = state => {
	return {
		socket: state.socket,
		lang: state.defaultLang,
		consultant: state.consultants.consultantDetails,
	}
}

export default connect(mapStateToProps, { endAppointment,consultantLeave, updateActiveStatus, removeInprogressAppointment, removeUpcomingAppointment, consultantJoin, userJoinLeave })(Header);