import { host } from '../config/host';
import { fetchData } from './fetch';

export const stripeConnectAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/connect-stripe-account`, body, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addCardAsync = async body => {
  try {
    const res = await fetchData('POST', `${host}/stripe-card-update`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}
export const deleteCardAsync = async body => {
  try {
    const res = await fetchData('POST', `${host}/stripe-card-delete`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}