import { HOW_IT_WORKS_LIST, GET_TESTIMONIAL_LIST, GET_FAQ_LIST } from "./types";
import { howItWorksListAsync, testimonialListAsync, faqListAsync } from "../asynchronus/Others";
import { postContactAsync } from "../asynchronus/contact";
// import { lang } from "../config/lang";

export const getHowItWorksList = (body, signal = null) => async dispatch => {
  try {
    const list = await howItWorksListAsync(body, signal = null);
    dispatch({ type: HOW_IT_WORKS_LIST, payload: list.data });
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTestimonialList = (body = null, signal = null) => async dispatch => {
  try {
    const list = await testimonialListAsync(body, signal);
    dispatch({ type: GET_TESTIMONIAL_LIST, payload: list.data });

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getFaqList = (body = null, signal = null) => async dispatch => {
  try {
    const faqs = await faqListAsync(body, signal);
    dispatch({ type: GET_FAQ_LIST, payload: faqs.data });
    
  } catch(err) {
    throw new Error(err.message);
  }
} 

export const postContact = async data => {
  try {
    const res = await postContactAsync(data);
    
    if(res.status === 200) {
      return res;
    } else {
      throw new Error(res.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}