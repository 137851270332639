import React from 'react';
import { connect } from 'react-redux';
import SingleRecent from './SingleRecent';

const RecentBlogs = ({ items, lang }) => {
  const config = {
    recentBlogs: {
      en: 'Recent Blogs',
      es: 'Blogs recientes',
      it: 'Blog recenti'
    }
  }

  return (
    <div className="col-lg-4">
      <div className="recent-blogs">
        <div className="recent-blogs-title">
          { config.recentBlogs[lang] || config.recentBlogs.en }
        </div>
        <div className="recent-blogs-list">
          {
            items.map(item => {
              return <SingleRecent key={item.id} item={item} />
            })
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(RecentBlogs);
