import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { getFaqList } from "../../actions/otherPages";
import { getfaqCategoryList } from "../../actions/cms";
import CommonNavBarItem from "../HowItWorks/CommonNavBarItem";

const SecFAQ = ({
  getfaqCategoryList,
  getFaqList,
  faqs,
  lang,
  faqCategoryList,
}) => {
  const pathname = window.location.pathname;
  useEffect(() => {
    const ac = new AbortController();

    const loadFAQs = async () => {
      try {
        if (!faqs.length) {
          await getFaqList({ lang }, ac.signal);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    loadFAQs();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  useEffect(() => {
    const ac = new AbortController();

    const loadFAQCategory = async () => {
      try {
        await getfaqCategoryList({ lang, limit: 100, type: 2 }, ac.signal);
      } catch (err) {
        console.log(err.message);
      }
    };

    loadFAQCategory();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const config = {
    howCanWeHelp: {
      en: "How can we help?",
      es: "¿Cómo Podemos Ayudarte?",
      it: "Come Possiamo Aiutarti?",
    },
  };
  const langConfig = {
    user: {
      en: "Client",
      es: "Cliente",
      it: "Cliente",
    },
    consultant: {
      en: "Consultant",
      es: "Consultor",
      it: "Consulente",
    },
  };
  const handleClick = (id) => {
    $(`#` + id)
      .parent()
      .next()
      .slideToggle();
    $(`#` + id)
      .parent()
      .parent()
      .siblings()
      .find(".accrodion-item-body")
      .slideUp();
    $(`#` + id)
      .parent()
      .parent()
      .toggleClass("show");
    $(`#` + id)
      .parent()
      .parent()
      .siblings()
      .removeClass("show");
  };
  useEffect(() => {}, [faqs.length]);
  const [isFor, setIsFor] = useState();
  const [commonNavItemData, setCommonNavItemData] = useState([
    {
      name: `${langConfig.user[lang] || langConfig.user.en}`,
      link: `/${lang}/faq-user`,
      setIsFor: 1,
      iconClass: "fas fa-user",
    },
    {
      name: `${langConfig.consultant[lang] || langConfig.consultant.en}`,
      link: `/${lang}/faq-consultant`,
      setIsFor: 2,
      iconClass: "fas fa-user-md-chat",
    },
  ]);
  useEffect(() => {
    commonNavItemData.map((item, i) => {
      pathname == item.link && setIsFor(item.setIsFor);
    });
  }, []);
  return (
    <section className="sec-faq prefooter-last">
      <div className="container">
        <div className="section-title">
          <h1 className="title">
            {config.howCanWeHelp[lang] || config.howCanWeHelp.en}
          </h1>
        </div>

        <div className="universal-text-center">
          <ul className="universal-tabs-btn">
            {commonNavItemData.map((item, i) => {
              return (
                <CommonNavBarItem
                  key={i}
                  item={item}
                  lang={lang}
                  setIsFor={setIsFor}
                />
              );
            })}
          </ul>
        </div>

        <div className="universal-tab-content">
          <div>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {faqCategoryList.length
                  ? faqCategoryList
                      .filter((el) => el.is_for == isFor)
                      .map((item, i) => {
                        return (
                          <a
                            className={
                              "nav-item nav-link " + (i == 0 ? "active" : "")
                            }
                            id={"item" + item.id + "-tab"}
                            data-toggle="tab"
                            href={"#item" + item.id}
                            role="tab"
                            aria-controls={"item" + item.id}
                            aria-selected="true"
                          >
                            {item.name}
                          </a>
                        );
                      })
                  : ""}
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {faqCategoryList.length
                ? faqCategoryList
                    .filter((el) => el.is_for == isFor)
                    .map((items, key) => {
                      return (
                        <div
                          className={
                            "tab-pane fade" + (key == 0 ? " show active" : "")
                          }
                          id={"item" + items.id}
                          role="tabpanel"
                          aria-labelledby={"item" + items.id + "-tab"}
                        >
                          <div className="universal-faq-content">
                            <div className="sec-custom-accrodion">
                              {faqs.length
                                ? faqs.map((item) => {
                                    return item.is_for == isFor &&
                                      item.category == items.category_id ? (
                                      <div
                                        className="accrodion-item"
                                        key={item.id}
                                      >
                                        <div className="accrodion-item-header">
                                          <button
                                            id={
                                              "item" + item.category + item.id
                                            }
                                            onClick={() => {
                                              handleClick(
                                                "item" + item.category + item.id
                                              );
                                            }}
                                            className="custom-accrodion-btn"
                                            type="button"
                                            dangerouslySetInnerHTML={{
                                              __html: item.question,
                                            }}
                                          ></button>
                                        </div>
                                        <div
                                          className="accrodion-item-body"
                                          dangerouslySetInnerHTML={{
                                            __html: item.answer,
                                          }}
                                        ></div>
                                      </div>
                                    ) : (
                                      ""
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
                      );
                    })
                : ""}
            </div>
          </div>
        </div>
      </div>

      <div className="prefooter-overlay">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#2f2f95"
            fillOpacity="1"
            d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,117.3C1200,96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    faqCategoryList: Object.values(state.cms.faqCategoryList),
  };
};

export default connect(mapStateToProps, { getFaqList, getfaqCategoryList })(
  SecFAQ
);
