import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import slugify from "slugify";
import {
  clearConsultant,
  getConsultantDetails,
  clearTimeSlots,
} from "../../actions/consultant";
import { addToWishlist } from "../../actions/wishlist";
import history from "../../config/history";
import { noImage } from "../../config/host";
import flags from "../../helpers/flags";
import ModeItem from "./ModeItem";
import { toast } from "react-toastify";
const ConsultantItem = ({
  item,
  addToWishlist,
  wishlist,
  user,
  lang,
  nationalities,
  socket,
  ...props
}) => {
  const [spinner, setSpinner] = useState(false);
  const [activeStatus, setActiveStatus] = useState();
  const modeRef = useRef(null);
  const modalRef = useRef(null);
  // useEffect(() => {
  //   setActiveStatus(item?.activeStatus);
  // }, [item?.activeStatus]);
  useEffect(() => {
    if (socket?.io) {
      socket.on("update-consultant-status", (res) => {
        if (res?.userId === item?.id) {
          setActiveStatus(res.activeStatus);
        }
      });
      socket.on("get-consultant-list", (res) => {
        if (res.result) {
          res.result.map((items) => {
            if (items.id == item?.id) {
              setActiveStatus(items.active_status);
            }
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id, socket]);
  const getRating = (int) => {
    const rating = parseFloat(int).toFixed(1);
    const percentage = (rating / 5) * 100;

    return percentage.toString();
  };

  const wishlistAdd = async (e, id, wishlist) => {
    try {
      if (localStorage.getItem("userId")) {
        e.preventDefault();
        const message = await addToWishlist(id, wishlist);
        toast.success(message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const checkWishlist = (consultantId) =>
    wishlist.find((el) => el.consultant_id === consultantId);

  const findNationality = (ntnly) => {
    return nationalities.find((el) => el.nationality === ntnly)?.alpha_2_code;
  };

  const basedOn = {
    en: "Based on",
    es: "Residencia en",
    it: "Basato su",
  };
  var langsen = {
    en: "English",
    es: "Spanish",
    it: "Italian",
  };
  var langsit = {
    en: "Inglese",
    es: "Spagnolo",
    it: "Italiano",
  };
  var langses = {
    en: "Inglés",
    es: "Español",
    it: "Italiano",
  };

  const getConsultant = async () => {
    props.clearTimeSlots();
    props.clearConsultant();
    props.setId && props.setId(item.id);

    setSpinner(true);

    try {
      await props.getConsultantDetails({
        lang,
        id: item.id,
        userId: localStorage.getItem("userId", null),
      });
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      console.log(err.message);
    }
  };

  const handleDetailsRedirect = () => {
    props.clearConsultant();
    props.clearTimeSlots();

    history.push(
      `/${lang}/consultant-details/${item.id}/${slugify(
        item.name.toLowerCase(),
        "-"
      )}`
    );
  };

  return (
    <>
      <div
        className={`item${
          activeStatus == "1"
            ? " online"
            : activeStatus == "3"
            ? " busy"
            : " offline"
        }`}
      >
        <div className="item-stats">
          <div
            className="item-image"
            style={{ cursor: "pointer" }}
            onClick={handleDetailsRedirect}
          >
            <LazyLoadImage
              src={item.logo}
              alt={item.name}
              onError={(e) => (e.target.src = `${noImage}`)}
              loading="lazy"
            />
          </div>
          <div className="rating-wrap">
            <div className="rating">
              <div className="back">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <div
                className="front"
                style={{
                  width: `${
                    item.rating
                      ? getRating(
                          Math.round((+item.rating + Number.EPSILON) * 10) / 10
                        )
                      : 0
                  }%`,
                }}
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
            </div>
            <div className="average-rating">
              <span>
                {item.rating
                  ? Math.round((+item.rating + Number.EPSILON) * 10) / 10
                  : 0}
              </span>
            </div>
          </div>

          <div className="review-count">
            <div className="title">{basedOn[lang] || basedOn.en}</div>
            <div className="count">{item.totalReview}</div>
          </div>
          <div className="online-status">
            <div className="status">
              <div className="icon"></div>
              {item?.id && (
                <div
                  className={`text${
                    activeStatus == "1"
                      ? " online"
                      : activeStatus == "3"
                      ? " busy"
                      : " offline"
                  }`}
                >
                  {activeStatus == "1"
                    ? "Online"
                    : activeStatus == "3"
                    ? "Busy"
                    : "Offline"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="item-info">
          <Link
            target="_blank"
            to={`/${lang}/consultant-details/${item.id}/${slugify(
              item.name.toLowerCase(),
              "-"
            )}`}
            className="item-name"
          >
            {item.name}
          </Link>
          <div className="item-designation">
            {item.professionalQualification}
          </div>
          <div className="item-intervention">
            <div className="intervention-data">{item.interventionName}</div>
          </div>
          {nationalities.length > 0 && (
            <div className="item-nationality">
              <div className="nationality-data">
                <div className="nationality-flag">
                  <LazyLoadImage
                    alt={item.nationality}
                    src={flags[findNationality(item.nationality)]?.default}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="item-meta">
            {(item.servicePrice || item.price) && (
              <div className="item-rate">
                {activeStatus == "2"
                  ? item.schedule_currency
                  : activeStatus == "1"
                  ? item.currency
                  : ""}{" "}
                {activeStatus == "2"
                  ? item.price
                  : activeStatus == "1"
                  ? item.servicePrice
                  : ""}
                <small>
                  {activeStatus == "2" && item.price
                    ? "/min"
                    : activeStatus == "1" && item.servicePrice
                    ? "/min"
                    : ""}
                </small>
              </div>
            )}
            <div className="item-language">
              <div className="language-icon">
                <i className="far fa-fw fa-language"></i>
              </div>
              <div className="language-name">
                {item.default_lang && lang
                  ? eval("langs" + lang)[item.default_lang]
                  : item.language}
              </div>
            </div>
          </div>
          <div
            className="item-description"
            dangerouslySetInnerHTML={{ __html: item.bioDescription }}
          ></div>

          {/*   {(item.activeStatus === '1' && user?.role !== 'consultant') ? (modes && modes[0]) && <div className="item-action">
            {modes.includes('2') && <a href="#!" className="btn-action" data-toggle="modal" data-target="#Voice">
              <i className="fal fa-fw fa-phone fa-flip-horizontal"></i>
              <span>Voice</span>
            </a>}
            {modes.includes('1') && <a href="#!" className="btn-action" data-toggle="modal" data-target="#Voice">
              <i className="fal fa-fw fa-comments-alt"></i>
              <span>Chat</span>
            </a>}
            {modes.includes('3') && <a href="#!" className="btn-action action-full" data-toggle="modal" data-target="#Voice">
              <i className="fal fa-fw fa-video"></i>
              <span>Video</span>
            </a>}
            {(item.activeStatus === '1') && <a className="btn-action action-full btn-action-alt" href="#!" data-toggle="modal" data-target="#book">
              <i className="fal fa-fw fa-calendar-alt"></i><span>Book a Consult</span>
            </a>} 
          </div> : ''} */}

          {(item.price || (item.activeStatus == "1" && item.servicePrice)) &&
            user?.role !== "consultant" && (
              <div
                className="consultant-details"
                ref={modeRef}
                onClick={getConsultant}
                style={{
                  display: "block",
                  flexWrap: "unset",
                  padding: "0 20px",
                }}
              >
                <ModeItem
                  item={item}
                  status={activeStatus}
                  modalRef={modalRef}
                  spinner={spinner}
                />
              </div>
            )}
        </div>
        {user?.role && user.role === "user" && (
          <a
            className="btn-wishlist"
            href="#!"
            onClick={(e) => wishlistAdd(e, item.id, wishlist)}
          >
            <i
              className={`${
                !checkWishlist(item.id) ? "fal" : "fas"
              } fa-fw fa-heart`}
            ></i>
          </a>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    socket: state.socket,
    details: state.consultants.consultantDetails,
    wishlist: Object.values(state.wishlist.wishlist),
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
  };
};

export default connect(mapStateToProps, {
  addToWishlist,
  clearConsultant,
  getConsultantDetails,
  clearTimeSlots,
})(ConsultantItem);
