import { GET_SERVICES_LIST } from "../../actions/types";

const servicesListReducer = (state = [], action) => {
  switch(action.type) {
    case GET_SERVICES_LIST:
      return [ ...action.payload ];
    default:
      return state;
  }
}

export default servicesListReducer;