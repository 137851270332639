import React from "react";
import { Link } from "react-router-dom";

const CommonNavBarItem = ({ item, lang, setIsFor }) => {
  const pathname = window.location.pathname;
  return (
    <>
      <li
        className={`universal-tabs-click${
          pathname === `${item.link}` ? " active" : ""
        }`}
      >
        <Link
          className={`universal-tabs-link`}
          to={item.link}
          onClick={() => setIsFor(item.setIsFor)}
        >
          <i className={`${item.iconClass} mr-2`}></i>
          {item.name}
        </Link>
      </li>
    </>
  );
};

export default CommonNavBarItem;
