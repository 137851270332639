import mapKeys from "lodash/mapKeys";
import omit from 'lodash/omit';
import { GET_NOTIFICATIONS, DELETE_NOTIFICATION } from "../../actions/types";

const notificationReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_NOTIFICATIONS:
      return { ...mapKeys(action.payload, 'id') };
    case DELETE_NOTIFICATION:
      return omit(state, action.payload);
    default:
      return state;
  }
}

export default notificationReducer;
