import React from 'react';
import ServiceItem from './ServiceItem';
import { consultantLang, langConfig } from '../../config/lang';
import NoDataFound from '../Admin/Sections/NoDataFound';

const ServicesOffered = ({ serviceDetails, isLoaded, lang }) => {
  return (
		<>
    {(serviceDetails && serviceDetails.length) ? 
			<div className="consultant-service join-table">
				<div className="consultant-service-title">
					{ consultantLang.serviceOffered[lang] || consultantLang.serviceOffered.en }
				</div>
				<div className="consultant-service-cost">
					
					<div className="item item-header">
						<div className="item-name">
							{ consultantLang.serviceName[lang] || consultantLang.serviceName.en }
						</div>
						<div className="item-cost">
							{ consultantLang.cost[lang] || consultantLang.cost.en }
						</div>
						<div className="item-mode">
							{ langConfig.mode[lang] || langConfig.mode.en }
						</div>
						<div className="item-duration">
							{ consultantLang.duration[lang] || consultantLang.duration.en }
						</div>
					</div>

					{
						serviceDetails ? 
						serviceDetails.map((item, i) => <ServiceItem key={item.id} item={item} 
							lang={lang}
						/>)
						: ''
					}
				</div>
			</div> 
			: isLoaded ? <NoDataFound /> : ''
		}
		</>
  )
}

export default ServicesOffered;