import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTimeZoneList, getCountryList } from '../actions/cms';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import SecAuthRegister from '../components/Auth/SecAuthRegister';
import SecOTPVerification from '../components/Auth/SecOTPVerification';
import '../assets/css/timezone.css';

const Register = ({ 
  history, timezones, getTimeZoneList, siteSettings, languages, getCountryList, countries
}) => {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadZones = async () => {
      try {
        await getTimeZoneList(ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }
    
    loadZones();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    const loadCountries = async () => {
      try {
        await getCountryList(ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    loadCountries();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && timezones.length) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [languages.length, siteSettings, timezones.length]);

  return (
    <LayoutContainer>
      <Loader loaded={loader} />
      {!status && 
        <SecAuthRegister history={history} status={status} setStatus={setStatus} 
          setUserId={setUserId} timezones={timezones} setEmail={setEmail}
          countries={countries}
        />
      }
      {status && 
        <SecOTPVerification history={history} status={status} setStatus={setStatus} 
          userId={userId} email={email}
        /> 
      }
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    timezones: Object.values(state.cms.timeZoneList),
    countries: Object.values(state.cms.countryList),
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
  }
}

export default connect(mapStateToProps, { getTimeZoneList, getCountryList })(Register);