import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setNotifications, deleteNotification } from "../../actions/cms";
import NotificationItem from "./NotificationItem";
const NotificationList = ({
  lang,
  user,
  socket,
  notifications,
  setNotifications,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const config = {
    noNotifications: {
      en: "No Notifications",
      es: "No Notificaciones",
      it: "Nessuna notifica",
    },
    clearAll: {
      en: "Clear All",
      es: "Limpiar todo",
      it: "Cancella tutto",
    },
  };
  // Notification socket service
  useEffect(() => {
    if (socket?.io && user?.id) {
      socket.emit("getNotification", {
        lang_code: lang,
        user_id: user.id,
        last_id:
          props.lastNotification.length > 0 && props.lastNotification[0].id
            ? props.lastNotification[0].id
            : "0",
      });

      socket.on("get-notification", (res) => {
        if (res && res?.result?.length) {
          setNotifications(res.result);
          setIsLoaded(true);
        } else if (!res || !res.result.length) {
          setIsLoaded(true);
        }
      });
    }
  }, [lang, user?.id, notifications.length, socket]);

  const clearNotifications = () => {
    if (socket?.io && user?.id) {
      socket.emit("clearNotification", { user_id: user.id, id: "0" });
    }
  };

  return (
    <li className="notification dropdown">
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        href="#!"
        aria-expanded="false"
      >
        <i className="fas fa-fw fa-bell m-auto"></i>
        {notifications.length ? (
          <span className="count" id="notificationCount">
            {notifications.length}
          </span>
        ) : (
          ""
        )}
      </a>
      <div
        className="dropdown-menu"
        id="notificationList"
        x-placement="bottom-start"
        style={{
          position: "absolute",
          transform: "translate3d(5px, 28px, 0px)",
          top: "0px",
          left: "0px",
          willChange: "transform",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="noti-wrap">
          {notifications.length > 0 &&
            notifications.map((item) => (
              <NotificationItem
                key={item.id}
                item={item}
                user={user}
                deleteNotification={props.deleteNotification}
              />
            ))}
          {isLoaded && !notifications.length && (
            <div className="noti-none">
              <i className="fal fa-fw fa-bell"></i>
              <div className="text">
                {config.noNotifications[lang] || config.noNotifications.en}
              </div>
            </div>
          )}
        </div>
        {notifications.length > 0 && (
          <a
            href="#!"
            className="noti-clear"
            onClick={(e) => {
              e.preventDefault();
              setNotifications([]);
              clearNotifications();
            }}
          >
            {config.clearAll[lang] || config.clearAll.en}
          </a>
        )}
      </div>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket,
    notifications: Object.values(state.cms.notifications).sort(
      (a, b) => b?.id - a?.id
    ),
    lastNotification: Object.values(state.cms.notifications).slice(-1),
  };
};

export default connect(mapStateToProps, {
  setNotifications,
  deleteNotification,
})(NotificationList);
