import { host } from "../../config/host";
import { fetchData } from "../fetch";

export const getAppointmentMessagesAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/message/list`, body, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const sendAppointmentMessageAsync = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${host}/message/send`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}