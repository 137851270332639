import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import reducers from './reducers';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/select2.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'jquery-datetimepicker/jquery.datetimepicker.css'
import './assets/css/style.css';
import './assets/css/responsive.css';

import 'popper.js';
import 'bootstrap/dist/js/bootstrap';
import 'select2';
import 'jquery-confirm';
import 'jquery-datetimepicker';
import './assets/js/jquery.cookie.js'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));


ReactDOM.render(
  
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();