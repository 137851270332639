import mapKeys from 'lodash/mapKeys';
import { GET_INTERVENTION_LIST } from '../../../actions/types';

const inteventionReducer = (state = {}, action) => {
  switch(action.type) {

    case GET_INTERVENTION_LIST:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default inteventionReducer;