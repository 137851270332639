import { DASHBOARD } from '../../actions/types';

const dashboardReducer = (state = {}, action) => {
  switch(action.type) {
    case DASHBOARD:

      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default dashboardReducer;