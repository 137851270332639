import mapKeys from 'lodash/mapKeys';
import { GET_FAQ_LIST } from "../../actions/types";

const faqsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_FAQ_LIST:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default faqsReducer;