import { host } from "../../config/host";
import { fetchData } from "../fetch";

export const getNoteListAsync = async body => {
  try {
    const res = await fetchData('POST', `${host}/note/list`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addNoteAsync = async body => {
  try {
    const res = await fetchData('POST', `${host}/note/add`, body);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}