import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";
import { updateInsuranceInfo } from "../../../../actions/profile";
import { useForm } from "react-hook-form";
import { insuranceLang } from "../../../../config/lang";
import SaveChanges from "../SaveChanges";
import { toast } from "react-toastify";
import { insuranceTooltipLang } from "../../../../config/lang";
import TooltipSec from "./TooltipSec";
const AccountInfoForm = ({ auth, user, updateInsuranceInfo, lang }) => {
  const [spinner, setSpinner] = useState(false);
  const { status } = auth;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [noInsurance, setNoInsurance] = useState(false);
  const toggle = (value) => !value;

  useEffect(() => {
    const loadDefaults = (user) => {
      manageCheck(user?.insurance_available_status, setNoInsurance);
      setValue("insuranceCompanyName", user?.insurance_company_name, {
        shouldValidate: true,
      });
      setValue("insuranceNumber", user?.insurance_number, {
        shouldValidate: true,
      });
      setValue(
        "insuranceExpiration",
        user?.insurance_expiration ? user.insurance_expiration : "",
        { shouldValidate: true }
      );
    };

    loadDefaults(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    $("#insurance_expiration").datetimepicker({
      value: user?.insurance_expiration ? user?.insurance_expiration : "",
      timepicker: false,
      format: "d-m-Y",
      scrollMonth: false,
      onSelectDate: function (e) {
        setValue("insuranceExpiration", moment(new Date(e)).format("DD-MM-Y"));
      },
    });
  }, [user?.insurance_expiration]);
  useEffect(() => {
    if (noInsurance) {
      $("#insurance_expiration").datetimepicker("destroy");
    } else {
      $("#insurance_expiration").datetimepicker("hide");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noInsurance]);

  const manageCheck = (val, callback) =>
    val && val === "1" ? callback(true) : callback(false);

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    setSpinner(true);

    if (!Object.keys(errors).length && userId && status) {
      try {
        const userData = { ...data, userId, lang };
        userData.insuranceAvailableStatus = noInsurance ? "1" : "2";
        const message = await updateInsuranceInfo(userData);

        setSpinner(false);
        toast.success(message);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="col-md-2 col-lg-6">
          {/* Insurance company name */}
          <div className="form-group">
            <label className="control-label required">
              {insuranceLang.iCn[lang] || insuranceLang.iCn.en}
              <TooltipSec
                title={
                  insuranceTooltipLang.iCN[lang] || insuranceTooltipLang.iCN.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-pristine ng-valid ng-touched"
              name="insurance_company_name"
              type="text"
              placeholder={insuranceLang.iCn[lang] || insuranceLang.iCn.en}
              readOnly={noInsurance}
              defaultValue={status ? user.insurance_company_name : ""}
              {...register("insuranceCompanyName")}
            />
          </div>
        </div>

        <div className="col-6 col-md-6 col-lg-3">
          {/* Insurance Number */}
          <div className="form-group">
            <label className="control-label required">
              {insuranceLang.insuranceNumber[lang] ||
                insuranceLang.insuranceNumber.en}
              <TooltipSec
                title={
                  insuranceTooltipLang.iN[lang] || insuranceTooltipLang.iN.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control ng-pristine ng-valid ng-touched"
              name="insurance_number"
              type="text"
              placeholder={
                insuranceLang.insuranceNumber[lang] ||
                insuranceLang.insuranceNumber.en
              }
              defaultValue={status ? user.insurance_number : ""}
              readOnly={noInsurance}
              {...register("insuranceNumber")}
            />
          </div>
        </div>

        <div className="col-6 col-md-6 col-lg-3">
          {/* Insurance expiration date */}
          <div className="form-group">
            <label className="control-label required">
              {insuranceLang.iEd[lang] || insuranceLang.iEd.en}
              <TooltipSec
                title={
                  insuranceTooltipLang.iED[lang] || insuranceTooltipLang.iED.en
                }
              ></TooltipSec>
            </label>
            <input
              className="form-control"
              id="insurance_expiration"
              name="insurance_expiration"
              type="text"
              placeholder={insuranceLang.iEd[lang] || insuranceLang.iEd.en}
              defaultValue={status ? user.insurance_expiration : ""}
              readOnly={noInsurance}
              {...register("insuranceExpiration")}
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6">
          {/* Check insurance */}
          <div className="form-group">
            <div className="checkbox-group">
              <div className="custom-checkbox">
                <input
                  className="custom-checkbox-input"
                  name="checkbox-insurance_available_status"
                  type="checkbox"
                  checked={noInsurance}
                  onChange={() => setNoInsurance(toggle)}
                />
                <div className="custom-checkbox-label">
                  {insuranceLang.dHI[lang] || insuranceLang.dHI.en}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SaveChanges spinner={spinner} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    auth: state.auth.loggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { updateInsuranceInfo })(
  AccountInfoForm
);
