import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { usePageVisibility } from "react-page-visibility";
import { updateActiveStatus } from "../actions/profile";
import {
  endAppointment,
  endAppointmentMailSend,
  removeInprogressAppointment,
  removeUpcomingAppointment,
  userJoinLeave,
  consultantJoin,
  consultantLeave,
} from "../actions/appointments";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import { Link } from "react-router-dom";
import { API_KEY, API_SECRET } from "../config/opektok";
import { generateOpentokToken } from "../asynchronus/Appointment";
import { toHHMMSS } from "../helpers";
import { convertIsoTime } from "../helpers";
import history from "../config/history";
import disconnect from "../assets/audio/disconnect.mp3";
import EndAppointmentModal from "../components/Modals/EndAppointmentModal";
//import { createASession } from '../asynchronus/Appointment';
import { toast } from "react-toastify";
const CallTemplate = (props) => {
  const [active, setActive] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [token, setToken] = useState(null); //localStorage.getItem('opentok_token')
  const [name, setName] = useState("");
  const [audioVolume, setAudioVolume] = useState(100);
  const [previousVolume, setPreviousVolume] = useState(1);
  const [publisherMicStatus, setPublisherMicStatus] = useState(true);
  const [publisherVideoStatus, setPublisherVideoStatus] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [shown, setShown] = useState(false);
  const [browserClosed, setBrowserClosed] = useState(false);
  const [lastActive, setLastActive] = useState(null);
  const [joined, setJoined] = useState(false);
  const [pubProps, setPubProps] = useState({
    width: "100%",
    height: "100%",
  });

  const isVisible = usePageVisibility();

  const closeRef = useRef(null);
  const publisher = useRef(null);
  const subscriber = useRef(null);
  const endAppointmentButtonRef = useRef(null);

  const { appointment } = props;

  useEffect(() => {
    if (
      appointment &&
      appointment?.service_type &&
      appointment?.service_type !== "1"
    ) {
      setSessionId(appointment.opentok_session_id);
      // setToken(appointment.opentok_token)

      generateOpentokToken(API_KEY, API_SECRET, appointment.opentok_session_id)
        .then((res) => setToken(res))
        .catch((err) => console.log("err", err));
      // setSessionId(localStorage.getItem('opentok_sessionId') || appointment.opentok_session_id)
      // setToken(localStorage.getItem('opentok_token') || appointment.opentok_token)

      // const loadSession = async () => {
      //   try {
      //     const ac = new AbortController();
      //     const sessionData = await createASession(API_KEY, API_SECRET, ac.signal);
      //     setSessionId(sessionData.sessionId);
      //     setToken(sessionData.token);
      //   } catch(err) {
      //     console.log(err.message);
      //   }
      // };
      // loadSession();
    }

    if (appointment?.service_type === "3" && publisher.current) {
      setPublisherVideoStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.service_type]);

  useEffect(() => {
    if (appointment?.service_type === "2") {
      setPubProps({ ...pubProps, videoSource: null });
    }

    if (appointment?.service_type === "3") {
      setPubProps({ ...pubProps, resolution: "1920x1080" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.service_type]);

  // Page visibility
  useEffect(() => {
    if (props.socket?.io) {
      props.socket.emit("appintmentDetails", {
        ...appointment,
        visibility: { userId: props.user?.id, isVisible },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.id, isVisible, props.socket]);

  const endOngoingAppointment = async (e) => {
    if (e) e.preventDefault();
    setSpinner(true);

    try {
      props.setAppointmentEnded(true);

      const endTime = new Date().toISOString();

      const endAppointmentRes = await props.endAppointment(
        {
          lang: props.lang,
          appointmentEndTime: endTime,
          reject: appointment?.consultant_join !== "1" ? "1" : "2",
          appointmentId: props.appointment.appointment_id,
          perMinuteAmount: props.consultant.consultant_details.servicePrice,
        },
        null,
        props.appointment.ids
      );
      props.endAppointmentMailSend(
        {
          lang: props.lang,
          appointment_id: props.appointment.appointment_id,
        },
        null,
        props.appointment.ids
      );

      if (props.socket?.io) {
        props.socket.emit("appintmentDetails", {
          ...props.appointment,
          appointment_end_time: endTime,
          // message: endAppointmentRes.message,
          success_message: endAppointmentRes.message,
          ended: true,
        });

        props.socket.emit("endAppointment", { ...props.appointment });

        props.socket.emit("updateConsultantStatus", {
          userId: appointment.consultant_id,
          activeStatus: "1",
          isManual: "2",
        });
      }

      // if(props.user?.role === 'consultant') {
      await props.updateActiveStatus({
        lang: props.lang,
        userId: props.user?.id,
        activeStatus: "1",
        isManual: "2",
      });
      // }

      localStorage.removeItem("opentok_sessionId");
      localStorage.removeItem("opentok_token");

      if (closeRef && closeRef?.current) {
        closeRef.current.click();
      }

      props.removeInprogressAppointment(appointment.ids);
      props.removeUpcomingAppointment(appointment.ids);

      toast.success(endAppointmentRes.message);

      new Audio(disconnect).play();
      setSpinner(false);
      history.push(
        `/${props.lang}/appointment-details/${props.appointment?.appointment_id}?review_tab=true`
      );
    } catch (err) {
      props.setAppointmentEnded(true);

      const endTime = new Date().toISOString();

      const endAppointmentRes = await props.endAppointment(
        {
          lang: props.lang,
          appointmentEndTime: endTime,
          reject: "1",
          appointmentId: props.appointment.appointment_id,
          perMinuteAmount: props.consultant.consultant_details.servicePrice,
        },
        null,
        props.appointment.ids
      );
      props.endAppointmentMailSend(
        {
          lang: props.lang,
          appointment_id: props.appointment.appointment_id,
        },
        null,
        props.appointment.ids
      );
      if (props.socket?.io) {
        props.socket.emit("appintmentDetails", {
          ...props.appointment,
          appointment_end_time: endTime,
          success_message: endAppointmentRes.message,
          ended: true,
        });

        props.socket.emit("endAppointment", { ...props.appointment });

        props.socket.emit("updateConsultantStatus", {
          userId: appointment.consultant_id,
          activeStatus: "1",
          isManual: "2",
        });
      }

      if (props.user?.role === "consultant") {
        await props.updateActiveStatus({
          lang: props.lang,
          userId: props.user?.id,
          activeStatus: "1",
          isMaunal: "2",
        });
      }

      localStorage.removeItem("opentok_sessionId");
      localStorage.removeItem("opentok_token");

      if (closeRef && closeRef?.current) {
        closeRef.current.click();
      }

      props.removeInprogressAppointment(appointment.ids);
      props.removeUpcomingAppointment(appointment.ids);
      toast.success(endAppointmentRes.message);
      new Audio(disconnect).play();
      setSpinner(false);
      history.push(
        `/${props.lang}/appointment-details/${props.appointment?.appointment_id}?review_tab=true`
      );
    }
  };

  const joinLeave = async () => {
    try {
      if (props.user?.role === "consultant") {
        if (props.socket?.io) {
          props.socket.emit("appintmentDetails", {
            ...appointment,
            consultant_join: "2",
          });
        }

        await props.consultantLeave({
          lang: props.lang,
          appointmentId: appointment.appointment_id,
          consultantJoin: "2",
          consultantJoinTime: appointment.consultant_join_time,
        });
      }

      if (props.user?.role === "user") {
        if (props.socket?.io) {
          props.socket.emit("appintmentDetails", {
            ...appointment,
            user_join: "2",
          });
        }

        await props.userJoinLeave({
          appointmentId: appointment?.appointment_id,
          userJoin: "2",
        });
      }

      props.setJoin(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (props.user?.id && appointment?.consultant_id && !name) {
      if (props.user?.id === appointment?.consultant_id) {
        setName(appointment.user_profile_name);
      } else {
        setName(appointment.consultant_profile_name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.id, props.appointment?.consultant_id]);

  useEffect(() => {
    if (!active && props.messages.length) setActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messages.length]);

  useEffect(() => {
    if (subscriber.current) {
      subscriber.current.state.subscriber.setAudioVolume(audioVolume);
    }
  }, [subscriber, audioVolume]);

  useEffect(() => {
    if (publisher.current && publisher.current?.state?.publisher) {
      publisher.current.state.publisher.publishAudio(publisherMicStatus);
    }
  }, [publisher, publisherMicStatus]);

  useEffect(() => {
    if (publisher.current && publisher.current?.state?.publisher) {
      publisher.current.state.publisher.publishVideo(publisherVideoStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, publisherVideoStatus]);

  useEffect(() => {
    if (!isVisible && !props.appointment?.ended && !shown && !browserClosed) {
      // endAppointmentButtonRef.current.click();
      setShown(true);
    }
  }, [shown, isVisible, props.appointment?.ended, browserClosed]);

  useEffect(() => {
    const { socket } = props;

    if (socket?.io) {
      socket.on("browser-closed", (res) => {
        if (res.appointmentId === props.appointment?.appointment_id) {
          if (res.userId !== props.user?.id && !browserClosed) {
            setBrowserClosed(true);
            setJoined(false);
          }

          if (res.userId !== props.user?.id) {
            setLastActive(new Date(res.last_active));
          }
        }
      });

      socket.on("browser-loaded", (res) => {
        if (res.appointmentId === props.appointment?.appointment_id) {
          if (res.userId !== props.user?.id) {
            setLastActive(null);
            setJoined(true);
          }
        }
      });
    }

    if (browserClosed && !shown) {
      // endAppointmentButtonRef.current.click();
      setShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.appointment?.appointment_id,
    props.user?.id,
    browserClosed,
    shown,
    props.socket,
  ]);

  useEffect(() => {
    // const handleEndAppointment = async () => await endOngoingAppointment();

    if (lastActive && !joined) {
      if ((convertIsoTime().getTime() - lastActive.getTime()) / 1000 > 17) {
        // handleEndAppointment();
        //console.log((convertIsoTime().getTime() - lastActive.getTime()) / 1000)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActive, joined, props.seconds]);

  const handleMicStatus = () => setPublisherMicStatus(!publisherMicStatus);

  const handleVideoStatus = () => {
    if (appointment?.service_type === "3") {
      setPublisherVideoStatus(!publisherVideoStatus);
    }
  };

  const manageVolume = () => {
    if (audioVolume > 0) {
      setPreviousVolume(audioVolume);
      setAudioVolume(0);
    } else {
      setPreviousVolume(1);
      setAudioVolume(previousVolume);
    }
  };

  const textConfig = {
    message: {
      en: "Message",
      es: "Mensaje",
      it: "Messaggio",
    },
    disconnectText: {
      en: "Disconnect",
      es: "Desconectar",
      it: "Disconnetti",
    },
    rejectedText: {
      en: "Consultant has rejected the call.",
      es: "El consultor ha rechazado la convocatoria.",
      it: "Il consulente ha rifiutato la chiamata.",
    },
    waitingUser: {
      en: "Waiting for the user to join.",
      es: "Esperando a que el usuario se una.",
      it: `In attesa che l'utente si unisca.`,
    },
    waitingConsultant: {
      en: "Waiting for the consultant to join.",
      es: "Esperando a que el consultor se una.",
      it: "In attesa che il consulente si unisca.",
    },
  };

  return (
    <div className={`sec-comunication ${active ? "active" : ""}`}>
      <div className="comunication-header">
        <div className="header-brand">
          <Link to="/" className="logo">
            <img src={props.siteSettings?.logo} alt="Logo" />
          </Link>
        </div>
        <div className="header-info">
          <div className="header-title">{name}</div>
          <div
            className={`header-text ${
              props.user?.role !== "user" ? "d-none" : ""
            }`}
          >
            {props.consultant?.consultant_details?.professionalQualification ||
              ""}
          </div>
        </div>
        <div className="header-options">
          <div className="call-timer">
            {props.seconds ? toHHMMSS(props.seconds) : ""}
          </div>
          <div className="header-actions">
            <button
              type="button"
              className="btn-action"
              onClick={() => setActive(!active)}
            >
              <i className="fas fa-fw fa-comment-alt-lines"></i>
              <span>
                {textConfig.message[props.lang] || textConfig.message.en}
              </span>
            </button>
            <button
              type="button"
              className="btn-action d-none"
              onClick={joinLeave}
            >
              <i className="fas fa-fw fa-arrow-to-right"></i>
              <span>Leave</span>
            </button>
            <button
              type="button"
              className="btn-action text-danger"
              data-toggle="modal"
              data-target="#endAppointment"
              data-keyboard="false"
              data-backdrop="static"
              ref={endAppointmentButtonRef}
            >
              <i className="fas fa-fw fa-phone-slash"></i>
              <span>
                {textConfig.disconnectText[props.lang] ||
                  textConfig.disconnectText.en}
              </span>
            </button>

            <EndAppointmentModal
              endOngoingAppointment={endOngoingAppointment}
              closeRef={closeRef}
              spinner={spinner}
              setShown={setShown}
              shown={shown}
              setBrowserClosed={setBrowserClosed}
              lang={props.lang}
            />
          </div>
        </div>
      </div>
      {/* Audio/video starts  */}
      <div className="comunication-body" id="videos">
        {sessionId && token && (
          <OTSession
            apiKey={API_KEY}
            sessionId={sessionId}
            token={token}
            onError={(err) => {
              console.log(err);
              toast.error(err.message);
            }}
          >
            <OTPublisher
              properties={pubProps}
              ref={publisher}
              onError={(err) => {
                console.log(err);
                toast.error(err.message);
              }}
              onConnect={() => console.log("published")}
            />

            <OTStreams>
              <OTSubscriber
                properties={{ ...pubProps, audioVolume }}
                onError={(err) => {
                  console.log(err);
                  toast.error(err.message);
                }}
                ref={subscriber}
              />
            </OTStreams>

            <div className="communication-waiting text-center">
              {props.appointment?.consultant_join === "3"
                ? "Consultant has rejected the call."
                : props.user?.role === "consultant"
                ? textConfig.waitingUser[props.lang] ||
                  textConfig.waitingUser.en
                : textConfig.waitingConsultant[props.lang] ||
                  textConfig.waitingConsultant.en}
            </div>
          </OTSession>
        )}

        {subscriber.current && (
          <>
            <div className="communication-volume-container">
              <div
                className="communication-volume-toggle-audio"
                onClick={handleMicStatus}
              >
                {publisherMicStatus ? (
                  <i className="fas fa-microphone"></i>
                ) : (
                  <i className="fas fa-microphone-slash"></i>
                )}
              </div>
              <div className="communication-volume">
                <div className="communication-mute" onClick={manageVolume}>
                  {audioVolume === 0 ? (
                    <i className="fas fa-volume-mute"></i>
                  ) : audioVolume < 2 ? (
                    <i className="fas fa-volume-off"></i>
                  ) : audioVolume < 50 ? (
                    <i className="fas fa-volume-down"></i>
                  ) : audioVolume > 50 ? (
                    <i className="fas fa-volume-up"></i>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="range"
                  value={audioVolume}
                  max={100}
                  className="communication-volume-button"
                  step={1}
                  onChange={(e) => setAudioVolume(parseInt(e.target.value))}
                />
              </div>
              <div
                className={`communication-volume-toggle-audio ${
                  appointment?.service_type === "2" ? "not-allowed" : ""
                }`}
                onClick={handleVideoStatus}
              >
                {publisherVideoStatus ? (
                  <i className="fas fa-video"></i>
                ) : (
                  <i className="fas fa-video-slash"></i>
                )}
              </div>
            </div>

            {!subscriber.current?.state?.stream?.hasAudio && (
              <div className="communication-sub-audio">
                <i className="fas fa-microphone-slash"></i>
              </div>
            )}
          </>
        )}

        {/* { (
            subscriber.current && 
            props.user?.role === 'user' && 
            props.appointment?.visibility?.userId === props.appointment?.consultant_id && 
            !props.appointment?.visibility?.isVisible
          ) &&
          <div className="communication-toast show d-none">
            <span>The consultant has changed the tab.</span>
          </div>
        } */}

        {/* {
          (
            subscriber.current && 
            props.user?.role === 'consultant' && 
            props.appointment?.visibility?.userId === props.appointment?.user_id && 
            !props.appointment?.visibility?.isVisible
          ) &&
          <div className="communication-toast show d-none">
            <span>The user has changed the tab.</span>
          </div>
        } */}
      </div>
      {/* Audio/video ends */}
      <div className="comunication-panel">
        <div className="message-container">{props.children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    socket: state.socket,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    messages: Object.values(state.appointmentMessages),
    timezones: Object.values(state.cms.timeZoneList),
    consultant: state.consultants.consultantDetails,
  };
};

export default connect(mapStateToProps, {
  endAppointment,
  endAppointmentMailSend,
  consultantLeave,
  updateActiveStatus,
  removeInprogressAppointment,
  removeUpcomingAppointment,
  consultantJoin,
  userJoinLeave,
})(CallTemplate);
