import React, { useState, useEffect, useRef } from 'react';
import { hostUrl } from '../../config/host';
// import { downloadInvoice } from '../../asynchronus/downloadInvoice';

const InvoiceModal = ({ appointment, user, lang }) => {
  const [url, setUrl] = useState('');
  const closeRef = useRef(null);
  const downloadRef = useRef(null);

  useEffect(() => {
    if(user?.role === 'user') {
      setUrl(
        `${hostUrl}appointments/invoice/${appointment?.appointment_id}?lang=${lang}&type=client`
      );
    } else {
      setUrl(
        `${hostUrl}appointments/consultant-invoice/${appointment?.appointment_id}?lang=${lang}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.role ]);

  const closeModal = () => closeRef.current.click();

  const redirectInvoice = () => {
    // downloadRef.current.click();

    if(url) {
      closeModal();
      downloadRef.current.click();
      // window.open(url, '_blank');
    }
  }

  return (
    <div className="conversation-modal">
      <div className="modal fade" id="invoice" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">

						<div className="modal-body" style={{ height: '375px' }}>
							<button type="button" className="modal-close" data-dismiss="modal" 
								aria-label="Close" ref={closeRef}
							>
								<i className="fal fa-fw fa-times"></i>
							</button>
              <object width="100%" height="100%" data={url} type="application/pdf">
                <p>There was an error downloading the invoice.</p>
              </object>
							<div className="subtitle d-none">Start conversation with</div>
							<div className="title text-center mb-0 d-none" 
                style={{ fontSize: '25px', textTransform: 'capitalize' }}
              >
                Download invoice
              </div>
							<div className="py-3 text-center d-none">
                Download the invoice for appointment { appointment?.appointment_id }
              </div>
							<div className="form-group d-none">
								<div className="row d-flex align-items-center">
									<div className="col-4">
										<div className="consultant-rate d-none"
										style={{ fontSize: '16px', fontWeight: '600', color: 'var(--primaryColor)' }}
									>
									</div>
									</div>
								</div>
							</div>

              <div className="communication-mode w-100 d-none">
								<div className="communication" style={{ width: '50%', maxWidth: 'unset' }}>
									{/* <input className="communication-input" name="communication-mode" type="radio" value=""/> */}
									<button className="communication-label bg-danger" type="button"
                    style={{color: '#fff', cursor: 'pointer' }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
								</div>
								<div className="communication" style={{ width: '50%', maxWidth: 'unset' }}>
									{/* <input className="communication-input" name="communication-mode" type="radio" value="" /> */}
									<button className="communication-label" 
                    style={{ background: 'var(--primaryColor)', color: '#fff', cursor: 'pointer' }}
										type="button" onClick={redirectInvoice}
                  >
                    Confirm
                  </button>

                  <a href={url} className="d-none" ref={downloadRef} target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
								 </div>
							</div>
							<div className="form-group form-group-actions d-none">
								<button className="btn-submit" type="button">Continue</button>
							</div>
						</div>
          </div>
        </div>
      </div>
	  </div>
  )
}

export default InvoiceModal;
