import React from 'react';
import AccountContainer from '../../../containers/Admin/AccountContainer';
import AccountInfoForm from '../Children/AccountInfoForm';
// import SaveAccountChanges from '../Children/SaveAccountChanges';


const SecAccountInfo = ({ history, config, lang }) => {
  return (
    <AccountContainer title={config.account[lang] || config.account.en}>
      <AccountInfoForm history={history} />
      {/* <SaveAccountChanges /> */}
    </AccountContainer>
  );
}

export default SecAccountInfo;