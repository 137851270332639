import React from 'react'

const AddMore = ({ handleAddMore }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <div className="lable-text ml-auto">
            <button type="button" className="btn-universal" onClick={handleAddMore}>
              Add More
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddMore
