import React from 'react';
import { consultantLang, interventionLang } from '../../config/lang';
import NoDataFound from '../Admin/Sections/NoDataFound';

const InterventionAndSpecialization = ({ details, isLoaded, lang }) => {
	const detailsLength = Object.keys(details).length;

  return (
		<div className="consultant-service">
			<div className="consultant-service-title">
				{ consultantLang.interventionSpecialization[lang] || consultantLang.interventionSpecialization.en }
			</div>
		{(detailsLength && 
			(
				details?.specializations_therapeutic_details.length ||
				details?.specializations_mental_details.length || 
				details?.specializations_psychological_details.length ||
				details?.specializations_other_details.length
			)) ?
			<>
				{	detailsLength && details.specializations_therapeutic_details.length ?
					<>
						<div className="title">
							{ 
								interventionLang.theraputicApproach[lang] || 
								interventionLang.theraputicApproach.en 
							}
						</div>
						<div className="text">
							<ul> 
								{detailsLength && details.specializations_therapeutic_details.map((item, i) => {
									return (<li key={i}>{ item.name }</li>)
								})} 
							</ul>
						</div>
					</>
					: ''
				}

				{ (detailsLength && details.specializations_mental_details.length) ?
					<>
						<div className="title">
							{ interventionLang.mentalApproach[lang] || interventionLang.mentalApproach.en }
						</div>
						<div className="text">
							<ul> 
								{detailsLength && details.specializations_mental_details.map((item, i) => {
									return (<li key={i}>{ item.name }</li>)
								})}
							</ul>
						</div>
					</>
					: ''
				}
				{	(detailsLength && details.specializations_psychological_details.length) ?
					<>
						<div className="title">
							{ 
								interventionLang.psychologicalProblems[lang] || 
								interventionLang.psychologicalProblems.en 
							}
						</div>
						<div className="text">
							<ul> 
								{detailsLength && details.specializations_psychological_details.map((item, i) => {
									return (<li key={i}>{ item.name }</li>)
								})}
							</ul>
						</div>
					</>
					: ''
				}
				{ (detailsLength && details.specializations_other_details.length) ? 
					<>
						<div className="title">
							{ interventionLang.otherTopics[lang] || interventionLang.otherTopics.en }
						</div>
						<div className="text">
							<ul> 
								{detailsLength && details.specializations_other_details.map((item, i) => {
									return (<li key={i}>{ item.name }</li>)
								})}
							</ul>
						</div>
					</>
					: ''
				}
			</>
			: isLoaded ? <NoDataFound /> : ''
		}
		</div>
  )
}

export default InterventionAndSpecialization;