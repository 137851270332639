import { host } from "../../config/host";
import { fetchData } from "../fetch";

// const lang = localStorage.getItem('lang') || 'en';
// const defaultParams = {lang, limit:"10",start:"0",status:"1",keyword:"",orderBy:"id",order:"asc"};

export const getHomeServices = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const defaultParams = {lang, limit:"10",start:"0",status:"1",keyword:"",orderBy:"id",order:"asc"};

    const params = !body ? defaultParams : { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/home-services-list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeOnlineServicesAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const defaultParams = {lang, limit:"10",start:"0",status:"1",keyword:"",orderBy:"id",order:"asc"};

    const params = !body ? defaultParams : { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/home-onlineservices-list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeInterventionsAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const defaultParams = {lang, limit:"",start:"0",status:"1",keyword:"",orderBy:"id",order:"asc"};

    const params = !body ? defaultParams : { ...defaultParams, ...body };
    // console.log(params)
    const res = await fetchData('POST', `${host}/home-interventions-list`, params, signal);
    const data = await res.json();
    
    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

// const gettingStartedParams = {lang, limit:"10",start:"0",status:"1",keyword:"",orderBy:"",order:""};

export const getHomeGettingStartedAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const gettingStartedParams = {lang, limit:"10",start:"0",status:"1",keyword:"",orderBy:"",order:""};

    const params = !body ? gettingStartedParams : { ...gettingStartedParams, ...body };
    const res = await fetchData('POST', `${host}/home-gettingstarted-list`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeCountAsync = async (signal = null) => {
  try {
    const params = { lang: localStorage.getItem('lang') || 'en' };
    const res = await fetchData('POST', `${host}/home-count`, params, signal);
    const data = await res.json();

    return data;

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeBannersAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const defaultParams = {
      lang, limit: "20", start: "", status: "", keyword: "", orderBy: "id", order: "asc"
    }
    
    const params = !body ? defaultParams : { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/banners-list`, params, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeWhatAsync = async (body = null, signal = null) => {
  try {
    const lang = localStorage.getItem('lang') || 'en';
    const defaultParams = { lang, id: "1" };
    const params = { ...defaultParams, ...body };
    const res = await fetchData('POST', `${host}/what-is-prontopsy`, params, signal);
    const data = await res.json();

    return data;
  } catch(err) {
    throw new Error(err.message);
  }
}