import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { clearEmailDetails, setEmailDetails, setEmailDetail } from '../../actions/email';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecEmailDetails from '../../components/Admin/Sections/SecEmailDetails';
import './css/message-details.css';

const EmailDetails = ({
  location, user, nationalities, siteSettings, languages, timezones, countries, lang,
  clearEmailDetails, setEmailDetails, socket, emailDetails, setEmailDetail
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const scrollRef = useRef(null);

  useEffect(() => setLoader(false), []);

  // Auto scroll
  useEffect(() => {
    if(typeof scrollRef.current?.scrollTop === 'number' && 
      typeof scrollRef.current?.scrollHeight === 'number'
    ) {
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }

    // console.log(scrollRef?.current?.scrollTop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ scrollRef.current, emailDetails ]);

  useEffect(() => {
    if(typeof scrollRef.current?.scrollTop === 'number' && 
      typeof scrollRef.current?.scrollHeight === 'number'
    ) {
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }

    // console.log(scrollRef?.current?.scrollTop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ scrollRef.current, emailDetails.length ]);

  const emailId = new URLSearchParams(location.search).get('email_id');
  const receiverId = new URLSearchParams(location.search).get('receiver_id');
  const parentId = new URLSearchParams(location.search).get('parent_id');
  const senderId = new URLSearchParams(location.search).get('sender_id');

  useEffect(() => {
    const ac = new AbortController();
    clearEmailDetails();

    const loadEmailDetails = async () => {
      if(user?.id && emailId && parentId && receiverId) {
        try {
          if(socket?.io) {
            socket.emit('getEmailDetails', { lang, userId: user.id, emailId });

            socket.on('get-email-details', (res) => {
              if(res && res.result && res.result.length) {
                if(res.user_id === user?.id) {
                  setEmailDetails(res.result);
                }
              }
            });

            socket.on('get-last-email', (res) => {
              if(res && res.result && res.result.length && res.receiver_id === user.id) {
                setEmailDetail(res.result[0]);
              }
            });
          }

          if(scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
          }

          setIsLoaded(true);
        } catch(err) {
          console.log(err.message);
          setIsLoaded(true);
        }
      }
    }

    loadEmailDetails();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.id, socket, scrollRef.current ]);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      Object.keys(siteSettings).length && languages.length && timezones.length && 
      countries.length && isLoaded;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries, isLoaded ]);

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title="Email Details">
        <SecEmailDetails user={user} emailId={emailId} receiverId={receiverId} 
          parentId={parentId} senderId={senderId} scrollRef={scrollRef}
        />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    socket: state.socket,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    emailDetails: Object.values(state.emailDetails)
  }
}

export default connect(mapStateToProps, { clearEmailDetails, setEmailDetails, setEmailDetail })(EmailDetails);
