import React from 'react';
import { connect } from 'react-redux';
import { noImage } from '../../config/host';

const AdminTopNav = ({ title, auth, user, setSideNavActive }) => {
  const { status } = auth;
  
  return (
    <div className="row header-row">
      <div className="col-md-12">
        <div className="header-wrap">
          <div className="col-md-6 col-lg-6">
            <div className="heder-title">{ title }</div>
            <button type="button" className="btn btn-link menulink-button d-md-none"
              onClick={() => setSideNavActive(true)}
            >
              <i className="far fa-arrow-right"></i>
            </button>
          </div>
          <div className="col-md-6 col-lg-6 d-flex">
            <div className="header-profile ml-auto">
              <div className="profile-icon">
                {status &&
                  <img alt={user?.name} src={ user.image || noImage } 
                    onError={(e) => e.target.src=`${noImage}`}
                  />
                }
              </div>
              <div className="profilr-text">
                {status && <span className="profile-name">{ user.name }</span>}
                {status && <span className="profile-contact">{ user.email }</span>  }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth.loggedIn,
    user: state.auth.user
  }
}

export default connect(mapStateToProps, null)(AdminTopNav);