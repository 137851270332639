import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { signUp } from "../../actions/auth";
import { langConfig, accountLang } from "../../config/lang";
import SocialAuthLogin from "./SocialAuthLogin";
import SecShowHidePassword from "./SecShowHidePassword";
import { toast } from "react-toastify";
// import flags from '../../helpers/flags';

const SignUpForm = ({
  signUp,
  setStatus,
  setUserId,
  timezones,
  lang,
  setEmail,
  countries,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [allTimezones, setAllTimezones] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isPassword, setisPassword] = useState("password");

  const [isConfirmPassword, setisConfirmPassword] = useState("password");
  const [allCountries, setAllCountries] = useState([]);

  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    if (timezones && timezones.length) {
      setAllTimezones([...timezones]);
      setAllCountries(countries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones]);

  const matchPasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) return "Passwords do not match.";
    return null;
  };

  const onSubmit = async (data) => {
    setSpinner(true);

    if (data?.password && data?.confirmpassword) {
      const confirmPasswordErr = matchPasswords(
        data.password,
        data.confirmpassword
      );

      if (confirmPasswordErr) {
        errors.confirmpassword = confirmPasswordErr;
        toast.error(errors.confirmpassword);
        setSpinner(false);
      }
    }

    if (!Object.keys(errors).length) {
      try {
        // Unset unnecessary properties
        const sendData = { ...data, timezone, phoneCode };
        delete sendData.termCondition;
        delete sendData.privacyPolicy;

        // Register the user
        const userData = await signUp(sendData);
        setSpinner(false);
        setStatus("pending");
        setUserId(userData.data.userId);
      } catch (err) {
        setSpinner(false);
        toast.error(err.message);
      }
    }
  };

  return (
    <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <div className="radio-group">
          <div className="custom-radio ml-3 d-none">
            <input
              className="custom-radio-input d-none"
              type="radio"
              value="user"
              checked
              {...register("type")}
            />
            <div className="custom-radio-label">
              {accountLang.iAmAClient[lang] || accountLang.iAmAClient.en}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="form-group">
        <div className="form-input-group">
          <div className="icon"><i className="fal fa-fw fa-user"></i></div>
          <input type="text" className="form-control" 
            placeholder={accountLang.name[lang] || accountLang.name.en}
            {...register('firstname') }
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-input-group">
          <div className="icon"><i className="fal fa-fw fa-user"></i></div>
          <input type="text" className="form-control" 
            placeholder={accountLang.surname[lang] || accountLang.surname.en}
            {...register('lastname')}
          />
        </div>
      </div> */}

      <div className="form-group">
        <div className="form-input-group">
          <div className="icon">
            <i className="fal fa-fw fa-envelope"></i>
          </div>
          <input
            type="email"
            className="form-control"
            autoComplete="new-email"
            placeholder={langConfig.email[lang] || langConfig.email.en}
            {...register("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      {/* Phone code */}
      {/* <div className="form-group">
        <div className="form-input-group overflow-unset">
          <div className="icon">
            <i className="fal fa-fw fa-phone"></i>
          </div>
          
          <div className="timezone dropdown">
            <a href="#!" data-toggle="dropdown" className="dropdown-toggle" id="dropdown-toggle"
              onClick={(e) => e.preventDefault()}
            >
              <span className="timezone-name">
                { 
                  phoneCode ? 
                  countries.find(el => el.phonecode === phoneCode).phonecode
                  : `${accountLang.selectPhoneCode[lang] || accountLang.selectPhoneCode.en}`
                }
              </span>
            </a>

            <div className="dropdown-menu" x-placement="top-start">
              <div className="input-search">
                <input type="text" 
                  placeholder={ langConfig.search[lang] || langConfig.search.en } 
                  className="form-control" 
                  onChange={(e) => searchCountries(e.target.value)}
                />
              </div>

              <div className="dropdown-menu-wrap">
                {
                  allCountries.length ?
                  allCountries.map(item => {
                    return (
                      <a href="#!" className="dropdown-item" key={item.id}
                        onClick={(e) => { e.preventDefault(); setPhoneCode(item.phonecode || '') }}
                      >
                        <span className="timezone-name">{ item.phonecode || '' }</span>
                      </a>
                    )
                  })
                  : ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="form-input-group">
          <div className="icon"><i className="fal fa-fw fa-mobile"></i></div>
          <input type="number" className="form-control" 
            placeholder={accountLang.contact[lang] || accountLang.contact.en}
            {...register('phone') }
          />
        </div>
      </div> */}

      {/* <div className="form-group">
        <div className="form-input-group overflow-unset">
          <div className="icon">
            <i className="fal fa-fw fa-clock"></i>
          </div>

          <div className="timezone dropdown">
            <a
              href="#!"
              data-toggle="dropdown"
              className="dropdown-toggle"
              id="dropdown-toggle"
              onClick={(e) => e.preventDefault()}
            >
              <span className="timezone-name">
                {timezone
                  ? timezones.find((el) => el.id === timezone).zone
                  : `${
                      accountLang.selectTimezone[lang] ||
                      accountLang.selectTimezone.en
                    }`}
              </span>
            </a>

            <div className="dropdown-menu" x-placement="top-start">
              <div className="input-search">
                <input
                  type="text"
                  placeholder={langConfig.search[lang] || langConfig.search.en}
                  className="form-control"
                  onChange={(e) => searchTimezones(e.target.value)}
                />
              </div>

              <div className="dropdown-menu-wrap">
                {allTimezones.length
                  ? allTimezones.map((item) => {
                      return (
                        <a
                          href="#!"
                          className="dropdown-item"
                          key={item.id}
                          onClick={(e) => {
                            e.preventDefault();
                            setTimezone(item.id);
                          }}
                        >
                          <span className="timezone-name">{item.zone}</span>
                        </a>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="form-group">
        <div className="form-input-group">
          <div className="icon">
            <i className="fal fa-fw fa-key"></i>
          </div>
          <input
            type={isPassword}
            className="form-control"
            autoComplete="new-password"
            placeholder={langConfig.password[lang] || langConfig.password.en}
            {...register("password")}
          />
          <SecShowHidePassword
            setisPassword={setisPassword}
            isPassword={isPassword}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-input-group">
          <div className="icon">
            <i className="fal fa-fw fa-key"></i>
          </div>
          <input
            type={isConfirmPassword}
            className="form-control"
            placeholder={
              accountLang.confirmPassword[lang] ||
              accountLang.confirmPassword.en
            }
            {...register("confirmpassword")}
          />
          <SecShowHidePassword
            setisPassword={setisConfirmPassword}
            isPassword={isConfirmPassword}
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <div className="checkbox-group">
            <div className="custom-checkbox">
              <input
                className="custom-checkbox-input"
                name="checkbox-term"
                type="checkbox"
                value="1"
                {...register("termsAndCondition")}
              />
              <div className="custom-checkbox-label">
                {accountLang.agree[lang] || accountLang.agree.en}{" "}
                <Link to={`/${lang}/terms-conditions`}>
                  <u>
                    {langConfig.termCondition[lang] ||
                      langConfig.termCondition.en}
                  </u>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <div className="checkbox-group">
            <div className="custom-checkbox">
              <input
                className="custom-checkbox-input"
                name="checkbox-term"
                type="checkbox"
                value="1"
                {...register("policy")}
              />
              <div className="custom-checkbox-label">
                {accountLang.agree[lang] || accountLang.agree.en}{" "}
                <Link to={`/${lang}/privacy-policy`}>
                  <u>
                    {langConfig.privacyPoliciy[lang] ||
                      langConfig.privacyPoliciy.en}
                  </u>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group text-center">
        <button type="submit" className="btn-submit" disabled={spinner}>
          <i className="fal fa-fw fa-sign-in-alt"></i>
          <div className="d-flex align-items-center">
            <span>{langConfig.signup[lang] || langConfig.signup.en}</span>
            {spinner && (
              <div className="loader-spinner ml-2">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
        </button>
      </div>
      <SocialAuthLogin lang={lang} />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    // countries: Object.values(state.cms.countryList)
  };
};

export default connect(mapStateToProps, { signUp })(SignUpForm);
