import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getConsultantList, clearConsultant } from "../../actions/consultant";
import { getWishlist } from "../../actions/wishlist";

import { langConfig } from "../../config/lang";
import { getNationalities } from "../../actions/cms";
import ConsultantItem from "../Consultant/ConsultantItem";
import ConversationModal from "../Modals/ConversationModal";
import BookAppointmentModalAlt from "../Modals/BookAppointmentModalAlt";

const Consultant = ({
  consultants,
  getConsultantList,
  getWishlist,
  clearConsultant,
  lang,
  user,
  nationalities,
  getNationalities,
  details,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [id, setId] = useState(null);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("activeStatus");
  const [allConsultants, setAllConsultants] = useState([]);
  useEffect(() => {
    const ac = new AbortController();

    const loadData = async (params = null) => {
      try {
        if (!isLoaded) {
          clearConsultant();
          await getConsultantList(params, ac.signal);

          if (user?.id) {
            await getWishlist(ac.signal);
          }

          setIsLoaded(true);
        }
      } catch (err) {
        // setIsLoaded(true);
        console.log(err.message);
      }
    };

    loadData({ limit: "4", lang });

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, lang]);

  useEffect(() => {
    const ac = new AbortController();

    const loadNationalities = async () => {
      try {
        if (!nationalities.length) {
          await getNationalities({ lang }, ac.signal);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    loadNationalities();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, nationalities.length]);
  useEffect(() => {
    if (consultants.length) {
      const sortedConsultants = consultants.sort((a, b) => {
        if (orderBy === "name") {
          return order.toLowerCase() === "asc" && a.name > b.name ? 1 : -1;
        }

        return order.toLowerCase() === "asc"
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy];
      });

      setAllConsultants(sortedConsultants);
    } else {
      setAllConsultants([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, consultants]);
  const config = {
    availableConsultants: {
      en: "AVAILABLE CONSULTANTS",
      es: "CONSULTORES DISPONIBLES",
      it: "CONSULENTI DISPONIBILI",
    },
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <ConversationModal
          consultant={details}
          lang={lang}
          user={user}
          id={id}
        />
        <BookAppointmentModalAlt
          consultant={details}
          lang={lang}
          user={user}
          id={id}
        />
      </div>

      <section className="sec-consultant">
        <div className="container">
          <div className="section-title">
            <h1 className="title">
              {config.availableConsultants[lang] ||
                config.availableConsultants.en}
            </h1>
          </div>
          <div className="consultant-wrap">
            {allConsultants &&
              allConsultants.map((item, i) => {
                return <ConsultantItem key={i} item={item} setId={setId} />;
              })}
          </div>
          <div className="blog-actions">
            <Link className="btn-action" to={`/${lang}/consultant`}>
              {langConfig.viewMore[lang] || langConfig.viewMore.en}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    consultants: Object.values(state.consultants.consultantList),
    nationalities: Object.values(state.cms.nationalities),
    details: state.consultants.consultantDetails,
  };
};

export default connect(mapStateToProps, {
  getConsultantList,
  getWishlist,
  clearConsultant,
  getNationalities,
})(Consultant);
