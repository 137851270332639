import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { acNavLangFormal } from '../../config/lang';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecFiscalInfo from '../../components/Admin/Sections/SecFiscalInfo';

const FiscalInfo = ({ 
  history, user, nationalities, siteSettings, languages, timezones, countries, lang
}) => {
  const [loader, setLoader] = useState(false);
  
  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      Object.keys(siteSettings).length && languages.length && timezones.length && 
      countries.length;

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ user, nationalities, siteSettings, languages, timezones, loader, countries ])

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={acNavLangFormal.billing[lang] || acNavLangFormal.billing.en}>
        <SecFiscalInfo history={history} config={acNavLangFormal} lang={lang} />
      </AdminContainer>
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList)
  }
}

export default connect(mapStateToProps, null)(FiscalInfo);