import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { deleteConsultantSerice } from "../../../../../actions/profile";
import {
  langConfig,
  serviceofferedTooltipLang,
} from "../../../../../config/lang";
// import { lang } from '../../../../../config/lang';
import { toast } from "react-toastify";
import TooltipSec from "../TooltipSec";
const ServiceFormSec = ({
  countries,
  services,
  index,
  setFirstService,
  setSecondService,
  firstService,
  secondService,
  deleteServiceForm,
  deleteConsultantSerice,
  user,
  history,
  firstCurrency,
  setFirstCurrency,
  secondCurrency,
  setSecondCurrency,
  allServices,
  firstServiceId,
  setFirstServiceId,
  secondServiceId,
  setSecondServiceId,
  firstDuration,
  setFirstDuration,
  secondDuration,
  setSecondDuration,
  lang,
}) => {
  const [modes, setModes] = useState([]);

  const handleModes = (value) => {
    if (modes.includes(value)) {
      setModes(modes.filter((el) => el !== value));
    } else {
      setModes([...modes, value]);
    }
  };

  useEffect(() => {
    // Init modes
    if (index === 0 && firstService?.mode) {
      setModes(firstService.mode.split(","));
    } else if (secondService?.mode) {
      setModes(secondService.mode.split(","));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (index === 0) {
      setFirstService({ ...firstService, mode: modes.join(",") });
    } else {
      setSecondService({ ...secondService, mode: modes.join(",") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modes]);

  useEffect(() => {
    if (index !== 0 && !secondService?.serviceId) {
      setSecondService({
        ...secondService,
        serviceId: "1",
        duration: user.schedule_duration,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatModes = (names) => {
    if (modes.length) {
      return modes.map((el, i) => (
        <span key={i}>
          {el === "2"
            ? langConfig.voice[lang] || langConfig.voice.en
            : el === "1"
            ? langConfig.chat[lang] || langConfig.chat.en
            : el === "3"
            ? "Video"
            : ""}
        </span>
      ));
    }

    return null;
  };

  const manageDeleteKey = async (e, index) => {
    e.preventDefault();

    if (index === 0) {
      if (!firstService?.id) {
        toast.error("Service cannot be deleted.");
      } else {
        try {
          const message = await deleteConsultantSerice(
            {
              lang,
              userId: user.id,
              serviceId: firstService.serviceId,
            },
            firstService.id
          );

          toast.success(message);
          history.push(`/${lang}/service-info`);
        } catch (err) {
          toast.error(err.message);
        }
      }
    } else {
      if (!secondService?.id) {
        deleteServiceForm(index);
        setSecondService({});
      } else {
        try {
          setSecondService({});
          const message = await deleteConsultantSerice(
            {
              lang,
              userId: user.id,
              serviceId: secondService.serviceId,
            },
            secondService.id
          );

          toast.success(message);
        } catch (err) {
          toast.error(err.message);
        }
      }
    }
  };

  useEffect(() => {
    $(".select2").select2();
    $(".select2-nosearch").select2({ minimumResultsForSearch: -1 });
  }, []);

  // Currency
  useEffect(() => {
    if (countries.length) {
      if (index === 0 && firstService?.currency) {
        setFirstCurrency(firstService?.currency);
        $(`#currency${index}`).val(firstService?.currency).trigger("change");
      } else if (index !== 0 && secondService?.currency) {
        setSecondCurrency(secondService?.currency);
        $(`#currency${index}`).val(secondService?.currency).trigger("change");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length]);

  // ServiceId
  useEffect(() => {
    if (index === 0 && firstService?.serviceId) {
      setFirstServiceId(firstService.serviceId);
      setFirstDuration(firstService.duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstService?.serviceId]);

  useEffect(() => {
    if (index !== 0 && secondService?.serviceId) {
      setSecondServiceId(secondService.serviceId);
      setSecondDuration(secondService.duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondService?.serviceId]);

  useEffect(() => {
    // Set currency
    $(`#currency${index}`).on("change", function (e) {
      const value = this.value;

      if (index === 0) {
        setFirstCurrency(value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCurrency]);

  useEffect(() => {
    // Set currency
    $(`#currency${index}`).on("change", function (e) {
      const value = this.value;

      if (index !== 0) {
        setSecondCurrency(value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondCurrency]);

  useEffect(() => {
    if (index === 0 && firstService?.serviceId) {
      $(`#serviceId${index}`).val(firstService?.serviceId).trigger("change");
    } else if (secondService?.serviceId) {
      $(`#serviceId${index}`).val(secondService?.serviceId).trigger("change");
    }

    // Init price
    if (index === 0 && firstService?.price) {
      $(`#service_price${index}`).val(firstService?.price).trigger("change");
    } else if (secondService?.price) {
      $(`#service_price${index}`).val(secondService?.price).trigger("change");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set first service service id
  useEffect(() => {
    $(`#serviceId${index}`).on("change", function (e) {
      const value = this.value;
      const duration = services
        .find((el) => el.id === value)
        .duration.substr(0, 2);

      if (index === 0) {
        // setFirstService({ ...firstService, serviceId: value, duration });
        setFirstServiceId(value);
        setFirstDuration(duration);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstServiceId]);

  // Set second service service id
  useEffect(() => {
    $(`#serviceId${index}`).on("change", function (e) {
      const value = this.value;
      const duration = services
        .find((el) => el.id === value)
        .duration.substr(0, 2);

      if (index !== 0) {
        // setSecondService({ ...secondService, serviceId: value, duration });
        setSecondServiceId(value);
        setSecondDuration(duration);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondServiceId]);

  // Set first service price
  useEffect(() => {
    if (index === 0 && firstService?.price) {
      $(`#service_price${index}`).val(firstService?.price).trigger("change");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstService]);

  // Set second service price
  useEffect(() => {
    if (index !== 0 && secondService?.price) {
      $(`#service_price${index}`).val(secondService?.price).trigger("change");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondService]);

  const updatePrice = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (index === 0) {
      setFirstService({ ...firstService, price: e.target.value });
    } else {
      setSecondService({ ...secondService, price: e.target.value });
    }
  };

  return (
    <>
      <div className="form-row">
        <div className="col-md-12">
          {/* Service */}
          <div className="form-group">
            <label className="control-label required">
              {langConfig.service[lang] || langConfig.service.en}
              <TooltipSec
                title={
                  serviceofferedTooltipLang.service[lang] ||
                  serviceofferedTooltipLang.service.en
                }
              ></TooltipSec>
            </label>
            {index !== 0 ? (
              <a
                href="#!"
                className="btn-action service-remove float-right"
                onClick={(e) => manageDeleteKey(e, index)}
              >
                <i className="fal fa-fw fa-trash-alt"></i>
              </a>
            ) : null}
            <select
              className="form-control select2 select2-hidden-accessible"
              id={`serviceId${index}`}
            >
              <option value="" disabled>
                {langConfig.select[lang] || langConfig.select.en}
              </option>
              {services.length
                ? services.map((service, i) => {
                    return (
                      <option key={i} value={service.id}>
                        {service.name}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6">
          {/* Mode */}
          <div className="form-group">
            <label className="control-label required">
              {langConfig.mode[lang] || langConfig.mode.en}
              <TooltipSec
                title={
                  serviceofferedTooltipLang.mode[lang] ||
                  serviceofferedTooltipLang.mode.en
                }
              ></TooltipSec>
            </label>
            <div className="select-dropdown dropdown">
              <a
                href="#!"
                className="data-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {!modes.length
                  ? langConfig.select[lang] || langConfig.select.en
                  : ""}
                {modes.length ? formatModes(modes) : ""}
              </a>

              <div className="dropdown-menu">
                <div className="dropdown-list">
                  <div className="item">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-checkbox-input"
                        checked={modes.includes("2")}
                        onChange={() => handleModes("2")}
                      />
                      <label className="custom-checkbox-label">
                        {langConfig.voice[lang] || langConfig.voice.en}
                      </label>
                    </div>
                  </div>

                  <div className="item">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-checkbox-input"
                        checked={modes.includes("1")}
                        onChange={() => handleModes("1")}
                      />
                      <label className="custom-checkbox-label">
                        {langConfig.chat[lang] || langConfig.chat.en}
                      </label>
                    </div>
                  </div>

                  <div className="item">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-checkbox-input"
                        checked={modes.includes("3")}
                        onChange={() => handleModes("3")}
                      />
                      <label className="custom-checkbox-label">Video</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 col-md-3">
          {/* Currency */}
          <div className="form-group">
            <label className="control-label required">
              {langConfig.currency[lang] || langConfig.currency.en}
              <TooltipSec
                title={
                  serviceofferedTooltipLang.currency[lang] ||
                  serviceofferedTooltipLang.currency.en
                }
              ></TooltipSec>
            </label>
            <select
              className="form-control select2 select2-hidden-accessible"
              id={`currency${index}`}
            >
              <option value="">
                {langConfig.select[lang] || langConfig.select.en}{" "}
                {langConfig.currency[lang] || langConfig.currency.en}
              </option>
              {countries &&
                countries.map((country, i) => {
                  if (country.currency) {
                    return (
                      <option key={i} value={country.currency}>
                        {country.currency}
                      </option>
                    );
                  }

                  return null;
                })}
            </select>
          </div>
        </div>

        <div className="col-6 col-md-3">
          <div className="form-group">
            <label className="control-label required">
              {langConfig.pPm[lang] || langConfig.pPm.en}
              <TooltipSec
                title={
                  serviceofferedTooltipLang.pricePerMinuite[lang] ||
                  serviceofferedTooltipLang.pricePerMinuite.en
                }
              ></TooltipSec>
            </label>
            <input
              type="number"
              step="any"
              className="form-control"
              placeholder={langConfig.price[lang] || langConfig.price.en}
              name="service_price"
              id={`service_price${index}`}
              onChange={updatePrice}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { deleteConsultantSerice })(ServiceFormSec);
