import React from 'react'

const Item = ({ item }) => {
  // const iconCondition = item.icon.includes('fad ') || item.icon.includes('fa ');
  // {iconCondition ? item.icon : "fad " + item.icon}
  return (
    <div className="item">
      <div className="item-img">
        <img 
          src={ item.image }
          alt={ item.name }
        />
      </div>
      <div className="item-content">
        <div className="item-title">{ item.name }</div>
        <div className="item-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        >
        </div>
      </div>
    </div>
  )
}

export default Item
