import React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { consultantLang } from "../../config/lang";
import { toast } from "react-toastify";

const ModeItem = ({
  serviceDetails,
  item,
  status,
  lang,
  modalRef,
  spinner,
  maxWidth,
  user,
}) => {
  // const modes = (details && details.serviceMode) ? details.serviceMode.split(',') : null;

  const direct = serviceDetails?.length
    ? serviceDetails.find((sr) => sr.id === "1")
    : null;
  const schedule = serviceDetails?.length
    ? serviceDetails.find((sr) => sr.id === "2")
    : null;

  const langConfig = {
    en: "You must be logged in.",
    es: "Usted debe estar conectado.",
    it: `Devi effettuare l'accesso.`,
  };

  const executeNoAuth = () => {
    if (!user.id) {
      toast.error(langConfig[lang] || langConfig.en);
    }
  };

  return (
    <>
      {(serviceDetails || item) && (
        <div
          className={`consultant-action${maxWidth ? " flex-column" : ""}`}
          ref={modalRef}
        >
          {((direct && direct?.mode && status == "1") ||
            (item?.consultantServices?.includes("1") && status == "1")) && (
            <button
              type="button"
              className="btn-action"
              data-toggle="modal"
              data-target={user?.id ? "#consultation" : ""}
              onClick={executeNoAuth}
              style={{
                width: item ? "100%" : maxWidth ? "100%" : undefined,
                maxWidth: item ? "unset" : maxWidth ? "100%" : undefined,
              }}
              disabled={spinner}
            >
              <div className="d-flex align-items-center">
                <span>
                  {consultantLang.immeadiateConsultation[lang] ||
                    consultantLang.immeadiateConsultation.en}
                </span>
                {spinner && (
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </button>
          )}
          {((schedule && schedule?.mode) ||
            item?.consultantServices?.includes("2") ||
            (schedule && schedule?.mode)) && (
            <button
              type="button"
              className="btn-action alt"
              data-toggle="modal"
              data-target={user?.id ? "#bookInterview" : ""}
              onClick={executeNoAuth}
              style={{
                width: item ? "100%" : maxWidth ? "100%" : undefined,
                maxWidth: item ? "unset" : maxWidth ? "100%" : undefined,
              }}
              disabled={spinner}
            >
              <div className="d-flex-align-items-center">
                <span>
                  {consultantLang.bookAnInterview[lang] ||
                    consultantLang.bookAnInterview.en}
                </span>
                {spinner && (
                  <div className="loader-spinner ml-2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </button>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    // details: state.consultants.consultantDetails.consultant_details,
    serviceDetails: state.consultants.consultantDetails.service_details,
  };
};

export default connect(mapStateToProps, null)(ModeItem);
