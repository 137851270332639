import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPages } from '../actions/cms';
import { GET_PAGES_ABOUT, GET_PAGES_HOME } from '../actions/types';
// import { lang } from '../config/lang';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import SecAbout from "../components/Home/About";
import Service from "../components/Home/Service";
import What from "../components/Home/What";
import Widget from "../components/Home/Widget";
import Banner from '../containers/Banner';

const About = ({ getPages, about, banners, nationalities, siteSettings, languages, lang, home }) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const loadAboutPage = async () => {
      if(!about?.id) {
        try {
          await getPages({ lang, slug: 'about' }, GET_PAGES_ABOUT);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.error(err.message);
        }
      }
    }

    loadAboutPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, about?.id ]);

  useEffect(() => {
    const loadHomePage = async () => {
      if(!home?.id) {
        try {
          await getPages({ lang, slug: 'home' }, GET_PAGES_HOME);
        } catch(err) {
          console.error(err.message);
        }
      }
    }

    loadHomePage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, home?.id ]);

  useEffect(() => {
    const loadBanner = () => {
      if(!banner && about && banners) {
        const aboutBanner = banners.find(el => el.page_id === about.id);
        setBanner(aboutBanner);
      }
    }

    loadBanner();
  }, [ about, banners, banner ]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && (about?.id || isLoaded)) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [isLoaded, about?.id, languages, nationalities, siteSettings]);

  return (
    <LayoutContainer
      seoTitle={about?.seo?.title}
      seoDescription={about?.seo?.description}
      seoKeywords={about?.seo?.keywords}
    >
      <Loader loaded={loader} />
      <Banner banner={banner} />
      <SecAbout />
      <Service />
      <What />
      <Widget />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    about: state.cms.pages.about,
    home: state.cms.pages.home,
    banners: Object.values(state.home.banners),
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages)
  }
}

export default connect(mapStateToProps, { getPages })(About);