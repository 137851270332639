import mapKeys from 'lodash/mapKeys';
import { GET_CLIENTS } from '../../actions/types';

const clientsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_CLIENTS:
      return { ...state, ...mapKeys(action.payload, 'ids') };
    default:
      return state;
  }
}

export default clientsReducer;