import React, { useState, useEffect } from 'react';
import Reviews from './Reviews';

const ReviewDetails = ({ reviews, list }) => {
  const [reviewDetails, setReviewDetails] = useState(null);

  useEffect(() => {
    setReviewDetails(reviews)
  }, [ reviews ])

  return (
    <div className="consultant-details-panel">
      <Reviews reviews={reviewDetails} list={list} />
    </div>
  )
}

export default ReviewDetails;