import { combineReducers } from "redux";
import consultantRecuder from "./pages/consultantReducer";
import termConditionReducer from "./pages/termConditionReducer";
import homeRecuder from './pages/homeReducer';
import aboutRecuder from "./pages/aboutReducer";
import howItWorksRecuder from './pages/howItWorksReducer';
import blogRecuder from "./pages/blogReducer";
import contactReducer from './pages/contactReducer';
import privacyPolicyReducer from './pages/privacyPolicyReducer';
import faqReducer from './pages/faqReducer';
import interventionReducer from "./pages/interventionReducer";

export default combineReducers({
  consultants: consultantRecuder,
  termCondition: termConditionReducer,
  home: homeRecuder,
  about: aboutRecuder,
  howItWorks: howItWorksRecuder,
  blog: blogRecuder,
  contact: contactReducer,
  privacyPolicy: privacyPolicyReducer,
  interventionList: interventionReducer,
  faq: faqReducer
});