import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPages } from '../actions/cms';
import { GET_PAGES_HOW_IT_WORKS } from '../actions/types';
// import { lang } from '../config/lang';
import Loader from '../Loader';
import LayoutContainer from '../containers/LayoutContainer';
import PageBanner from '../components/HowItWorks/PageBanner';
// import Steps from '../components/HowItWorks/Steps';
import SecHowItWorks from '../components/HowItWorks/SecHowItWorks';

const HowItWorks = ({ 
  getPages, howItWorks, banners, siteSettings, languages, lang
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const loadWorksPage = async () => {
      if(!howItWorks?.id) {
        try {
          await getPages({ lang, slug: 'how-it-works' }, GET_PAGES_HOW_IT_WORKS);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.error(err.message);
        }
      }
    }

    loadWorksPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, howItWorks?.id ]);

  useEffect(() => {
    const loadBanner = () => {
      if(!banner && howItWorks && banners) {
        const worksBanner = banners.find(el => el.page_id === howItWorks.id);
        setBanner(worksBanner);
      }
    }

    loadBanner();
  }, [ howItWorks, banner, banners ]);

  useEffect(() => {
    const handleLoader = () => {
      if(Object.keys(siteSettings).length && languages.length && (howItWorks?.id || isLoaded)) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [isLoaded, howItWorks?.id, languages.length, siteSettings]);

  return (
    <LayoutContainer
      seoTitle={howItWorks?.seo?.title}
      seoDescription={howItWorks?.seo?.description}
      seoKeywords={howItWorks?.seo?.keywords}
    >
      <Loader loaded={loader} />
      <PageBanner banner={banner} />
		  {/*<Steps />*/}
      <SecHowItWorks />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    howItWorks: state.cms.pages.howItWorks,
    banners: Object.values(state.home.banners),
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages)
  }
}

export default connect(mapStateToProps, { getPages })(HowItWorks);