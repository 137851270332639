import { GET_PAYMENT_INFO, LOGOUT } from "../../actions/types"

const paymentInfoReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_PAYMENT_INFO:
      return { ...state, ...action.payload };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default paymentInfoReducer;