import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPages } from "../actions/cms";
import { GET_PAGES_HOME } from "../actions/types";
// import { lang } from "../config/lang";
import Loader from '../Loader';
import LayoutContainer from "../containers/LayoutContainer";
import SearchContainer from "../components/Home/SearchContainer";
import SectionBanner from "../components/Home/SectionBanner";
import About from "../components/Home/About";
import Service from "../components/Home/Service";
import Steps from "../components/Home/Steps";
import Consultant from "../components/Home/Consultant";
import Category from "../components/Home/Category";
import What from "../components/Home/What";
import Widget from "../components/Home/Widget";
import Testimonials from "../components/Home/Testimonials";
import Blog from "../components/Home/Blog";

const Home = ({ getPages, nationalities, siteSettings, languages, lang, homePage }) => {
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadHomePage = async () => {
      if(!homePage?.id) {
        try {
          await getPages(
            { lang, slug: 'home' }, 
            GET_PAGES_HOME, ac.signal
          );
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      }
    }

    loadHomePage();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lang, homePage?.id ]);

  useEffect(() => {
    const handleLoader = () => {
      if(
        Object.keys(siteSettings).length && languages.length && (homePage?.id || isLoaded)
      ) {
        setLoader(true);
      }
    }

    handleLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ homePage?.id, siteSettings, languages.length, imgLoader ]);

  return (
    <LayoutContainer
      seoTitle={homePage?.seo?.title}
      seoDescription={homePage?.seo?.description}
      seoKeywords={homePage?.seo?.keywords}
    >
      <Loader loaded={loader} />
      <SearchContainer search={search} setSearch={setSearch} />
      <SectionBanner setImgLoader={setImgLoader} search={search} setSearch={setSearch} />
      <About />
      
      <Service />
      
      <Steps />
      <Category isFeatured={true}/>
      <Consultant />
     
      <What />
      <Widget />
      <Testimonials />
      
      <Blog />
    </LayoutContainer>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    homePage: state.cms.pages.home,
    nationalities: Object.values(state.cms.nationalities),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages)
  }
}

export default connect(mapStateToProps, { getPages })(Home);