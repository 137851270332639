import React from 'react';
// import UserPanel from './UserPanel';
import ContentPanel from './ContentPanel';
import PrefooterOverlay from '../../prefooterOverlay';

const SecMessages = ({ 
  messages, lang, user, appointmentId, consultantId, appointment, scrollRef, seconds, rcv,
  setAppointmentEnded, setJoin, socket
}) => {
  /*useEffect(() => {
    const fetchGoogle = async () => {
      try {
        const res = await fetch('https://www.google.com', { mode: 'no-cors', method: 'GET' });
        await res.text();

        // console.log(data);
      } catch(err) {
        // if(socket?.io) {
        //   socket.emit('appintmentDetails', appointment);
        // }
        console.log(err.message, 'Network is offline.');
      }
    }

    const interval = setInterval(fetchGoogle, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ appointment, socket ]);*/

  return (
    <section className="sec-message-page prefooter-last">
      <div className="container">
        <div className="message-container">'
          {/* <UserPanel appointmentId={appointmentId} appointment={appointment} 
            user={user}
          /> */}
          <ContentPanel messages={messages} lang={lang} user={user} rcv={rcv}
            appointmentId={appointmentId} consultantId={consultantId}
            appointment={appointment}
            scrollRef={scrollRef}
            seconds={seconds}
            setAppointmentEnded={setAppointmentEnded} setJoin={setJoin}
          />
        </div>
      </div>

      <PrefooterOverlay />
    </section>
  );
}

export default SecMessages