import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { acNavLangFormal } from '../../config/lang';
import { getConsultantServices } from '../../actions/profile';
import Loader from '../../Loader';
import LayoutContainer from '../../containers/LayoutContainer';
import AdminContainer from '../../containers/AdminContainer';
import SecDailySchedule from '../../components/Admin/Sections/SecDailySchedule';

const DailySchedule = ({ 
  history, user, getConsultantServices, dailyTimetable, profile,
  nationalities, siteSettings, languages, timezones, countries, lang
}) => {
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dailyLoading, setDailyLoading] = useState(false);

  useEffect(() => setLoader(false), []);

  useEffect(() => {
    const ac = new AbortController();

    const loadDailyTimeTable = async () => {
      if(!isLoaded && user?.id) {
        try {
          await getConsultantServices({ lang, userId: user.id }, ac.signal);
          setIsLoaded(true);
        } catch(err) {
          setIsLoaded(true);
          console.log(err.message);
        }
      }
    }

    loadDailyTimeTable();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user ]);

  useEffect(() => {
    const handleLoader = () => {
      const condition = user?.id && nationalities.length &&
      Object.keys(siteSettings).length && languages.length && timezones.length && countries.length
      && isLoaded

      if(condition) {
        setLoader(true);
      }
    }

    handleLoader();
  }, [ 
    user, nationalities, siteSettings, languages, timezones, loader, countries, profile, isLoaded 
  ])

  return (
    <LayoutContainer admin={true}>
      <Loader loaded={loader} />
      <AdminContainer title={acNavLangFormal.daily[lang] || acNavLangFormal.daily.en}>
        <SecDailySchedule user={user} dailyTimetable={dailyTimetable} history={history} 
          profile={profile} isLoaded={dailyLoading} setIsLoaded={setDailyLoading}
          config={acNavLangFormal} lang={lang}
        />
      </AdminContainer>
    </LayoutContainer>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang,
    user: state.auth.user,
    nationalities: Object.values(state.cms.nationalities),
    countries: Object.values(state.cms.countryList),
    siteSettings: state.cms.siteSettings,
    languages: Object.values(state.cms.languages),
    timezones: Object.values(state.cms.timeZoneList),
    profile: state.profile.profileInfo,
    dailyTimetable: Object.values(state.profile.dailyTimetable).slice(-7)
  }
}

export default connect(mapStateToProps, { getConsultantServices })(DailySchedule);
