export function checkActiveUser(cookieName="",type="") {
  var session_out_time = localStorage.getItem('siteSettings')? parseInt(JSON.parse(localStorage.getItem("siteSettings")).offline_duration):1000;
  //  session_out_time = 60*24;
 
  if (
    getCookie(cookieName) !== "" &&
    getCookie(cookieName) !== "undefined" &&
    getCookie(cookieName) !== undefined &&
    getCookie(cookieName) !== null &&
    getCookie(cookieName) !== "Invalid Date"
  ) {
    var date1 = new Date();
    var date2 = new Date(getCookie(cookieName));
    var timeDiff = date2.getTime() - date1.getTime();
    var timeDiffInSecond = Math.ceil(timeDiff / 1000);

 
    if (timeDiffInSecond > 0) {
      if(!type){
      var date = new Date();
      var newTime = parseInt(session_out_time) * 60 * 1000;
      date.setTime(date.getTime() + parseInt(newTime));
      document.cookie = cookieName + "=" + (date || "") + "; path=/";
      return false
      }else{
       
        return false
      }

    } else {
  
      eraseCookie(cookieName);
      return true
    }
  } else {
    var date = new Date();
    var newTime = parseInt(session_out_time) * 60 * 1000;
    date.setTime(date.getTime() + parseInt(newTime));
    document.cookie = cookieName + "=" + (date || "") + "; path=/";
    return false
  }
}
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
