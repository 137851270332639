import React from 'react';

const GettingStartedItem = ({ item }) => {
  const iconCondition = (item.icon.includes('fab ') || item.icon.includes('fas '));
  return (
    <div className="item">
			<div className="item-wrap">
				<div className="icon">
					<i className={iconCondition ? item.icon : "fad " + item.icon } ></i>
				</div>
				<div className="item-content">
					<div className="item-title">{ item.name }</div>
					<div className="item-text"
						dangerouslySetInnerHTML={{ __html: item.description }}
					>
					</div>
				</div>
			</div>
    </div>
  )
}

export default GettingStartedItem
