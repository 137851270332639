import React from 'react';
import { connect } from 'react-redux';
import { termsAndConditionLang } from '../../../../config/lang';

const TermsAndConditionInfo = ({  lang }) => {


  return (
    <>
      
      <div className="form-group checkbox-term">
          <div className="checkbox-group">
              <div className="custom-checkbox">
                  <input className="custom-checkbox-input" name="checkbox-terms" type="checkbox" value="1"  disabled  checked/>
                  <div className="custom-checkbox-label">{termsAndConditionLang.firstCheckboxContent[lang] || termsAndConditionLang.firstCheckboxContent.en} </div>
              </div>
          </div>
      </div>
      <div className="form-group checkbox-term">
          <div className="checkbox-group">
              <div className="custom-checkbox">
                  <input className="custom-checkbox-input" name="payment_terms" type="checkbox" value="1"  disabled checked />
                  <div className="custom-checkbox-label">{termsAndConditionLang.secondCheckboxContent[lang] || termsAndConditionLang.secondCheckboxContent.en}</div>
              </div>
          </div>
      </div>

    </>
  )
}



export default(TermsAndConditionInfo);
