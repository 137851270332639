import React from 'react';
import SingleMessage from './SingleMessage';

const ChatBody = ({ messages, user, listRef, appointment }) => {
	const getSameSenderPrevMsg = (senderId, index) => {
		return messages.find((message, i) => (message.sender_id === senderId && i < index)) || null;
	}

  return (
    <div className="message-body">
			<div className="d-flex">
				<div 
					className={`comment-list`} 
					ref={listRef}
					style={{
						position: 'absolute',
						display: 'flex',
						flexDirection: 'column',
						bottom: '0',
						width: '100%',
						left: '0',
						right: 'unset'
					}}
				>
					{
						messages.length ?
						messages.map((item, i) => (
							<SingleMessage key={item.id} message={item} user={user} 
								appointment={appointment}
								prevMsg={getSameSenderPrevMsg(item.sender_id, i)}
								acPrevMsg={i ? messages[i - 1] : null}
								nxtMsg={messages[i + 1] || null}
							/>
						))
						: ''
					}
				</div>
			</div>
		</div>
  )
}


export default ChatBody;