import mapKeys from 'lodash/mapKeys';
import { 
  GET_APPOINTMENT_MESSAGES, CLEAR_APPOINTMENT_MESSAGES, SEND_APPOINTMENT_MESSAGE, LOGOUT 
} from "../../actions/types";

const appointmentMessagesReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_APPOINTMENT_MESSAGES:
      return { ...state, ...mapKeys(action.payload, 'id') };
    case SEND_APPOINTMENT_MESSAGE:
      return { ...state, [action.payload.id]: action.payload };
    case CLEAR_APPOINTMENT_MESSAGES:
      return {};
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default appointmentMessagesReducer;