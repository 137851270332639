import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getBlogDetail } from '../../actions/blog';
import RecentBlogs from '../Blog/RecentBlogs';
import RelatedBlogs from '../Blog/RelatedBlogs';
// import Blog6 from '../../assets/img/blog/blog-06.jpg';

const SecBlogDetail = ({ detail, setImgLoader }) => {
  return (
		<>
    <section className="sec-blog-details">
      <div className="container">
		
				<div className="row">
					<div className="col-lg-8">
						<div className="blog-details">
							<div className="blog-image">
								{(detail?.publishDate) && 
									<div className="blog-date">
										<span>{ moment(new Date(detail.publishDate)).format('D') }</span>
										{ moment(new Date(detail.publishDate)).format('MMM') }, { moment(new Date(detail.publishDate)).format('YY') }
									</div>
								}
								{(detail?.logo) && <img src={detail.logo} alt={ detail.name } 
									onLoad={() => setImgLoader(true)}
								/>}
							</div>
							<div className="blog-details-wrap">
								{(detail?.name) && 
									<div className="blog-title">{detail.name}</div>
								}
									<div className="blog-content">
									{(detail?.description) && 
										<p dangerouslySetInnerHTML={{ __html: detail.description }}></p>
									}
								</div>
							</div>
						</div>
					</div>
					
					{detail?.recent && <RecentBlogs items={detail?.recent} />}
				</div>
      </div>
    </section>
		
		{ detail?.related && <RelatedBlogs items={detail?.related} />}
		</>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    detail: state.blogs.blogList[ownProps.params.id]
  }
}

export default connect(mapStateToProps, { getBlogDetail })(SecBlogDetail);