import mapKeys from 'lodash/mapKeys';
import { GET_OTHER_LIST } from '../../../actions/types';

const otherReducer = (state = {}, action) => {
  switch(action.type) {

    case GET_OTHER_LIST:
      return { ...state, ...mapKeys(action.payload, 'id') };
    default:
      return state;
  }
}

export default otherReducer;