import { GET_PAGES_TERM_CONDITION } from "../../../actions/types";

const termConditionReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_PAGES_TERM_CONDITION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default termConditionReducer;