import React from 'react';
import { connect } from 'react-redux';
import { consultantLang } from '../../config/lang';
import NoDataFound from '../Admin/Sections/NoDataFound';
// import './css/consultant.css';

const OverallRating = ({ rating, app, lang, reviewable, ...props }) => {
  const getRating = int => {
    if(!int) return 0;
    
    const rating = parseFloat(int).toFixed(1);
    const percentage = (rating / 5) * 100;
    return percentage.toString();
  }

  const handleRatingChange = (callback, value, currVal) => {
    if(app && !reviewable) {
      if(currVal === 1 && value === 1) {
        callback(0)
      } else {
        callback(value); 
      }
    }
  }

  // const getDuration = (startTime, endTime) => {
  //   const dateDiff = new Date(endTime) - new Date(startTime);
  //   const duration = Math.ceil(((dateDiff % 86400000) % 3600000) / 60000);
  //   const pluralize = (duration > 1) ? ` ${appointmentLang.minutes[lang] || appointmentLang.minutes.en}` : ` ${appointmentLang.minute[lang] || appointmentLang.minute.en}`;
  //   return duration + pluralize;
  // }

  const getDurationInNumber = (startTime, endTime) => {
    const dateDiff = new Date(endTime) - new Date(startTime);
    const duration = Math.ceil(((dateDiff % 86400000) % 3600000) / 60000);
    return duration;
  }

  // console.log('duration', getDurationInNumber(
  //   props.appointment.consultant_join_time, props.appointment.appointment_end_time
  // ));
  return (
    <>
      {
        (
          props.appointment?.consultant_join_time && 
          ( 
            getDurationInNumber(
              props.appointment.consultant_join_time, 
              props.appointment.appointment_end_time
            ) > 1
          ) 
        ) || props.consultantDetails || props.all
        ? 
        <div className="overall-rating">
          <div className="overall-rating-list">
            <div className="item">
              <div className="item-name" style={{ textTransform: 'uppercase' }}>
                { consultantLang.skillAndKnowledge[lang] || consultantLang.skillAndKnowledge.en }
              </div>
              <div className={`item-rating ${(app && !reviewable) && 'pointer'}`}>
                <div className="rating">
                  <div className="back">
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setComm, 1, props.comm)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setComm, 2, props.comm)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setComm, 3, props.comm)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setComm, 4, props.comm)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setComm, 5, props.comm)}
                    ></i>
                  </div>
                <div className="front" 
                  style={{ width: 
                    `${rating?.reliable_and_trustworthy ? getRating(rating.reliable_and_trustworthy) : (app&&!reviewable) ? getRating(props.comm) : 0}%` 
                  }}
                >
                  <i className="fas fa-star"
                    onClick={() => handleRatingChange(props.setComm, 1, props.comm)}
                  ></i>
                  <i className="fas fa-star"
                    onClick={() => handleRatingChange(props.setComm, 2, props.comm)}
                  ></i>
                  <i className="fas fa-star"
                    onClick={() => handleRatingChange(props.setComm, 3, props.comm)}
                  ></i>
                  <i className="fas fa-star"
                    onClick={() => handleRatingChange(props.setComm, 4, props.comm)}
                  ></i>
                  <i className="fas fa-star"
                    onClick={() => handleRatingChange(props.setComm, 5, props.comm)}
                  ></i>
                </div>
              </div>
              </div>
            </div>
            <div className="item">
              <div className="item-name" style={{ textTransform: 'uppercase' }}>
                { consultantLang.cordiality[lang] || consultantLang.cordiality.en }
              </div>
                <div className={`item-rating ${(app && !reviewable) && 'pointer'}`}>
                  <div className="rating">
                    <div className="back">
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setAccess, 1, props.access)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setAccess, 2, props.access)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setAccess, 3, props.access)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setAccess, 4, props.access)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setAccess, 5, props.access)}
                      ></i>
                    </div>
                  <div className="front" 
                    style={{ 
                      width: `${rating?.communication ? getRating(rating.communication): 
                        (app&&!reviewable) ? getRating(props.access) :0}%` 
                    }}
                  >
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setAccess, 1, props.access)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setAccess, 2, props.access)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setAccess, 3, props.access)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setAccess, 4, props.access)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setAccess, 5, props.access)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-name" style={{ textTransform: 'uppercase' }}>
                { consultantLang.internetAccess[lang] || consultantLang.internetAccess.en }
              </div>
                <div className={`item-rating ${(app && !reviewable) && 'pointer'}`}>
                  <div className="rating">
                    <div className="back">
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setSkill, 1, props.skill)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setSkill, 2, props.skill)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setSkill, 3, props.skill)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setSkill, 4, props.skill)}
                      ></i>
                      <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setSkill, 5, props.skill)}
                      ></i>
                    </div>
                  <div className="front"
                    style={{ 
                      width: `${rating?.empathy_and_flexibility ? getRating(rating.empathy_and_flexibility) : (app&&!reviewable) ? getRating(props.skill) : 0}%`
                    }}
                  >
                    <i className="fas fa-star"
                        onClick={() => handleRatingChange(props.setSkill, 1, props.skill)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setSkill, 2, props.skill)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setSkill, 3, props.skill)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setSkill, 4, props.skill)}
                    ></i>
                    <i className="fas fa-star"
                      onClick={() => handleRatingChange(props.setSkill, 5, props.skill)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="overall-rating-image">
            <img 
              src="https://newprontopsy.mdemo.us/assets/img/rating-bg.png" 
              alt="Rating" 
            />
          </div>*/}
        </div> : <NoDataFound />
      }
    </>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(OverallRating);
