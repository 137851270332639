import { GET_WISHLIST, REMOVE_FROM_WISHLIST } from "./types";
import { wishlistAsync, addToWishlistAsync } from "../asynchronus/Wishlist";

export const getWishlist = (body = null, signal = null) => async dispatch => {
  try {

    const wishlist = await wishlistAsync(body, signal);
    if(wishlist.status === 200) {
      dispatch({ type: GET_WISHLIST, payload: wishlist.data });
    } else {
      throw new Error(wishlist.message);
    }
    
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addToWishlist = (consultantId, wishlistMain, signal = null) => async dispatch => {
  try {
    const wishlist = await addToWishlistAsync(consultantId, signal);

    if(wishlist.status === 200) {

      if(wishlist.message.includes('add')) {
        const wishlistData = await wishlistAsync(signal);
        dispatch({ type: GET_WISHLIST, payload: wishlistData.data });
        return wishlist.message;
      } else {
        const removedWishlist = wishlistMain.find(el => el.consultant_id === consultantId);
        if(removedWishlist) {
          dispatch({ type: REMOVE_FROM_WISHLIST, payload: removedWishlist.wishlist_id });
        }
        return wishlist.message;
      }

    } else {
      throw new Error(wishlist.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const removeFromWishlist = (consultantId, wishlist_id) => async dispatch => {
  try {
    const removeWishlist = await addToWishlistAsync(consultantId);

    if(removeWishlist.status === 200) {
      dispatch({ type: REMOVE_FROM_WISHLIST, payload: wishlist_id });
      return removeWishlist.message;
    } else {
      throw new Error(removeWishlist.message);
    }
    
  } catch(err) {
    throw new Error(err.message);
  }
}