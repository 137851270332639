import React from 'react';
import { connect } from 'react-redux';

const NoDataFound = ({ lang }) => {
  const config = {
    noDataFound: {
      en: 'No Data Found',
      es: 'Datos No Encontrados',
      it: 'Nessun Dato Trovato'
    },
    noDataFoundSub: {
      en: 'It seems no data to be found in this page',
      es: 'Parece que no se encuentran datos en esta página',
      it: 'Sembra che non siano presenti dati in questa pagina'
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="nodata-wrap">
          <div className="nodata-content">
            <div className="icon">
              <span className="fa-stack fa-2x">
                <i className="fal fa-fw fa-stack-2x fa-circle"></i>
                <i className="fal fa-fw fa-stack-2x fa-slash" style={{ opacity: '0.5' }}></i>
                <i className="fas fa-fw fa-stack-1x fa-list"></i>
              </span>
            </div>
            <div className="nodata-title">
              { config.noDataFound[lang] || config.noDataFound.en }
            </div>
            <div className="nodata-text">
              { config.noDataFoundSub[lang] || config.noDataFoundSub.en }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lang: state.defaultLang
  }
}

export default connect(mapStateToProps, null)(NoDataFound);