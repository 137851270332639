import { 
  GET_HOME_SERVICES, 
  GET_HOME_ONLINE_SERVICES,
  GET_HOME_INTERVENTIONS,
  GET_HOME_GETTING_STARTED,

  GET_HOME_COUNT,
  GET_BANNERS,
  GET_HOME_WHAT
} from "./types";
import { 
  getHomeServices, 
  getHomeOnlineServicesAsync,
  getHomeInterventionsAsync,
  getHomeGettingStartedAsync,
  getHomeCountAsync, getHomeBannersAsync, getHomeWhatAsync
} from "../asynchronus/Home";

export const getHomeServicesList = (body = null, signal = null) => async dispatch => {
  try {
    const homeServices = await getHomeServices(body, signal);
    dispatch({ type: GET_HOME_SERVICES, payload: homeServices.data });

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeOnlineServicesList = (body = null, signal = null) => async dispatch => {
  try {
    const homeOnlineServices = await getHomeOnlineServicesAsync(body, signal);
    dispatch({ type: GET_HOME_ONLINE_SERVICES, payload: homeOnlineServices.data });

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeInterventionsList = (body = null, signal = null) => async dispatch => {
  try {
    const homeInterventions = await getHomeInterventionsAsync(body, signal);
    dispatch({ type: GET_HOME_INTERVENTIONS, payload: homeInterventions.data });

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeGettingStartedList = (body = null, signal = null) => async dispatch => {
  try {
    const homeGettingStarted = await getHomeGettingStartedAsync(body, signal);
    dispatch({ type: GET_HOME_GETTING_STARTED, payload: homeGettingStarted.data });

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeCount = (signal = null) => async dispatch => {
  try {
    const homeCount = await getHomeCountAsync(signal);
    if(homeCount.status === 200) {
      dispatch({ type: GET_HOME_COUNT, payload: homeCount.data });
    } else {
      throw new Error(homeCount.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const homeBannerList = (body = null, signal = null) => async dispatch => {
  try {
    const homeBanners = await getHomeBannersAsync(body, signal);

    if(homeBanners.status === 200) {
      dispatch({ type: GET_BANNERS, payload: homeBanners.data });
    } else {
      throw new Error(homeBanners.message);
    }

  } catch(err) {
    throw new Error(err.message);
  }
}

export const getHomeWhat = (body = null, signal = null) => async dispatch => {
  try {
    const what = await getHomeWhatAsync(body, signal);
    
    if(what.status === 200) {
      dispatch({ type: GET_HOME_WHAT, payload: what.data });
    } else {
      throw new Error(what.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}